import React, { useEffect, useState } from 'react';

import "./SkillsDisplay.styles.css";

function SkillsDisplay(props) {
  const [skillsList, setSkillsList] = useState([]);
  const {
    skills,
    deletable,
    onUpdate,
    showLabel,
  } = props;

  useEffect(() => {
    if (skills) {
      if (Array.isArray(skills)) {
        setSkillsList(skills);
      } else {
        setSkillsList(skills.split("&&&&"));
      }
    }
  }, [skills]);

  return (
    <div className={`skills-display ${deletable ? 'with-delete' : 'without-delete'}`}>
      {showLabel ?
        <span className="skills-display-label">
          Skills:
        </span>
      : null}
      {skillsList.map((item, idx) => {
        return (
          <span key={idx} className="skills-display-skill">
            {/*<img src={process.env.PUBLIC_URL + '/icons/skill-white.png'} alt="{item}" />*/}
            <span>{item}</span>

            {deletable ?
              <button
                className="skills-display-remove-skill"
                onClick={(e) => {
                  e.preventDefault();
                  const skillsCopy = JSON.parse(JSON.stringify(skillsList));
                  skillsCopy.splice(idx, 1);
                  onUpdate(skillsCopy);
                }}>
                <img src={process.env.PUBLIC_URL + '/icons/remove.png'} alt="Remove skill" />
              </button>
            : null}
          </span>
        );
      })}
    </div>
  );
}

export default SkillsDisplay;
