import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';

import Discussion from '../discussion/Discussion';

import "./SessionDetail.styles.css";

export default function SessionDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getSession } = useContext(DataContext);
  const { id } = useParams();
  const [session, setSession] = useState();
  const [isDiscussionOpen, setDiscussionOpen] = useState(false);

  useEffect(() => {
    setPageTitle('Session');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    const session = getSession(id);
    setSession(session);
  }, [session, id]);

  return (
    <div>
      {session && isDiscussionOpen ?
        <Discussion
          entity={session}
          entityType="session"
          entityName={session.name}
          entityThumbnailUrl={process.env.PUBLIC_URL + '/icons/session-' + session.type + '.png'}
          onClose={() => {setDiscussionOpen(false)}}
          />
      : null }

      <div className={"session-detail-page page full-screen-page " + (isDiscussionOpen ? "non-scrollable" : "") }>

        <div className="full-screen-header">
          <h2>Session</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
          </Link>
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate('/projects/my-projects');
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {session ?

          <div className={`full-screen-body session-detail ${session.isPaid ? "paid" : ""}`}>
            <div className="session-header">
              <div className="session-thumbnail-container">
                <img src={process.env.PUBLIC_URL + '/icons/session-' + session.type + '.png'} alt={session.name} className="session-thumbnail" />
                <span className="session-type">{session.type}</span>
              </div>
              <div className="session-title-container">
                <p className="session-name">{session.name}</p>
                <div className="session-host">
                  with
                  <Link to={`/user-profile/${session.hostUserId}`}>
                    <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Host" />
                    <span className="dark-blue-link">{session.host}</span>
                  </Link>
                </div>
              </div>
            </div>

            <div className="session-metadata">

              <div className="session-metadata-section">
                {session?.timeString ?
                  <div className="session-datetime">
                    <img src={process.env.PUBLIC_URL + '/icons/time-icon.svg'} alt="Date & time" />
                    <span>{session.timeString}</span>
                  </div>
                : null }
                {session?.durationString ?
                  <div className="session-duration">
                    <img src={process.env.PUBLIC_URL + '/icons/duration-icon.svg'} alt="Duration" />
                    <span>{session.durationString}</span>
                  </div>
                : null }
                {session?.numParticipants ?
                  <span className="session-participants">
                    <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Users" />
                    {session.numParticipants > 1 ? 
                      <span>{session.numParticipants}  participants</span> 
                    :
                      <span>{session.numParticipants} participant</span>
                    }
                  </span>
                : null }

                {session?.project ?
                  <p className="session-project">
                    This session is a part of
                    <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + session.project?.type + '.png'} alt={session.project.name} className="project-thumbnail" />
                    <span className="dark-blue-link">{session.project.name}</span>
                    .
                  </p>
                : null }

              </div>

              <div className="session-metadata-section">
                <div className="session-price">
                  {session.price ?
                    <span>
                      <span className={(session.isPaid ? " paid" : " tag primary-green") }>
                        {session.priceInCurrency} to join
                      </span>
                      {session.isPaid ? 
                        <img className="paid" src={process.env.PUBLIC_URL + '/vector/paid.png'} alt="paid" />
                      : null }
                    </span>

                  :
                    <div className="tag primary-blue">
                      FREE to join
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className="session-status">
              Status: <span className="session-status-value">{session.status}</span>
            </div>

            <div className="session-description">
              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(session.description)}} />
              
            </div>

            {session?.skills?.length ?
              <div className="session-skills">
                <h4>Skills related to this session</h4>
                {session.skills?.map((item, idx) => {
                  return (
                    <div key={idx} className="session-skill-card">
                      <img src={process.env.PUBLIC_URL + '/icons/skill.png'} alt="Skill" />
                      <div>{item.name}</div>
                    </div>
                  );
                })}
              </div>
            : null }
          </div>
        : null }

        <div className="session-detail-actions form-footer">
          <div className="session-detail-primary-actions">
            {session?.isJoined ?
              <div className="session-joined-indicator">
                <img src={process.env.PUBLIC_URL + '/icons/green-check.png'} alt="Green check" />
                <span>Joined</span>
              </div>
            :
              <Button color="primary" size="sm" onClick={() => {
              }}>
                Join this session
              </Button>        
            }
          </div>

          <div className="session-detail-secondary-actions">
            <Button color="secondary" outline size="sm" className="session-detail-discussion-button" onClick={() => {
              setDiscussionOpen(true);
            }}>
              Discussion
              <span className="discussion-count">{session?.discussionCount}</span>
            </Button>

            <Button color="link" size="sm" className="session-detail-more-actions" onClick={() => {
            }}>
              <img src={process.env.PUBLIC_URL + '/icons/ellipsis.png'} alt="More actions" />
            </Button>
          </div>
        </div>
            
      </div>
    </div>
  );
}