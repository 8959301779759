import React, { useState, useContext, useEffect }  from 'react';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { useNavigate, Link } from "react-router-dom";

import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";
import { UserContext } from '../../contexts/user-context';

import Basics from './Basics';
import SetupProfile from './SetupProfile';
import SetupSkills from './SetupSkills';
import EmailPreferences from './EmailPreferences';
// import ProfileVerification from './ProfileVerification';
import TermsAndPrivacy from './TermsAndPrivacy';
import Confirmation from './Confirmation';

import PageError from '../messages/PageError';
import PageSuccess from '../messages/PageSuccess';

import "./Basics.scss";

const onboardingHeaders = {
  basics: 'Finish your profile: Basics',
  setupProfile: 'Finish your profile: profile details',
  setupSkills: 'Finish your profile: skills',
  emailPreferences: 'Finish your profile: Email preferences',
  termsAndPrivacy: 'Terms, conditions and privacy policy',
  confirmation: 'Profile complete',
}

export default function FinishProfile() {
  const { getLogin, postFinishUserProfile, checkExistingUserName, logView } = useContext(DataContext);
  const navigate = useNavigate();
  const { loginUser, isLoggingIn } = useContext(UserContext);

  const [currentStep, setCurrentStep] = useState('basics');

  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [phone, setPhone] = useState('');
  const [about, setAbout] = useState('');
  const [skills, setSkills] = useState('');
  const [isGetProjectUpdateEmails, setGetProjectUpdateEmails] = useState(true);
  const [isGetFeatureUpdateEmails, setGetFeatureUpdateEmails] = useState(true);

  const [updateError, setUpdateError] = useState(null);
  const [existingUserError, setExistingUserError] = useState(null);

  useEffect(() => {
    logView('finish-profile-modal');
  }, []);

  return (
    <div className="finish-profile-modal">
      <Modal
        isOpen={true}
        backdrop="static"
        fade={true}
        centered={true}>
        <ModalHeader>
          <span>{onboardingHeaders[currentStep]}</span>
        </ModalHeader>
        <ModalBody>
          <div className="finish-profile-modal-body">
            {updateError ? 
              <div className="page-error-container">
                <PageError messageText={updateError} />
              </div>
            : null}

            {existingUserError ? 
              <div className="page-error-container">
                <PageError messageText={existingUserError} />
              </div>
            : null}

            <Basics
              isVisible={currentStep === 'basics'}
              username={username}
              country={country}
              onNext={(username, country) => {
                setUsername(username);
                setCountry(country);

                checkExistingUserName(username).then((response) => {
                  setExistingUserError(null);
                  setCurrentStep('setupProfile');
                }).catch((error) => {
                  // logError(error);
                  setExistingUserError('This username is taken. Please choose a different username.');
                })
              }} />

            <SetupProfile
              isVisible={currentStep === 'setupProfile'}
              phone={phone}
              about={about}
              onBack={() => {
                setCurrentStep('basics')}
              }
              onNext={(phone, about) => {
                setPhone(phone);
                setAbout(about);
                setCurrentStep('setupSkills')
              }} />

            <SetupSkills
              isVisible={currentStep === 'setupSkills'}
              skills={skills}
              onBack={() => {setCurrentStep('setupProfile')}}
              onNext={(_skills) => {
                setSkills(_skills);
                setCurrentStep('emailPreferences');
              }} />
 
             <EmailPreferences
              isVisible={currentStep === 'emailPreferences'}
              isGetProjectUpdateEmails={isGetProjectUpdateEmails}
              isGetFeatureUpdateEmails={isGetFeatureUpdateEmails}
              onBack={() => {setCurrentStep('setupSkills')}}
              onNext={(_isGetProjectUpdateEmails, _isGetFeatureUpdateEmails) => {
                setGetProjectUpdateEmails(_isGetProjectUpdateEmails);
                setGetFeatureUpdateEmails(_isGetFeatureUpdateEmails);
                setCurrentStep('termsAndPrivacy');
              }} />
 
              <TermsAndPrivacy
                isVisible={currentStep === 'termsAndPrivacy'}
                onBack={() => {setCurrentStep('emailPreferences')}}
                onNext={() => {
                  postFinishUserProfile(username, country, about, skills, isGetProjectUpdateEmails, isGetFeatureUpdateEmails).then((response) => {
console.log(response);
                    if (response?.data?.result?.affectedRows) {
                      setUpdateError(null);
                      setCurrentStep('confirmation');
                    }
                  }).catch((error) => {
console.log(error);
                    setUpdateError('Could not update profile. Please check all inputs and try again.');
                    // logError(error);
                    setCurrentStep('basics')
                  });
                }} />

            <Confirmation
              isVisible={currentStep === 'confirmation'}
              onFinish={() => {
                getLogin().then((response) => {
                  if (response.data?.auth) {
                    loginUser(response);
                  }
                }).catch((error) => {
console.log(error);
                    setUpdateError('An error occured logging you in. Please refresh the browser window and log in again.');
                    // logError(error);
                  // No-op
                })

              }} />
         </div>
        </ModalBody>
      </Modal>
    </div>
  );
}