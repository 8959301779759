import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./Emails.scss";

export default function Emails() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getSeeEmails } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [emails, setEmails] = useState('');
  const [currentEmail, setCurrentEmail] = useState(null);
  const [currentEmailIndex, setCurrentEmailIndex] = useState(null);

  useEffect(() => {
    setPageTitle('Preview emails');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
  }, []);

  useEffect(() => {
    fetchEmails();
  }, []);

  const fetchEmails = () => {
    getSeeEmails().then((response) => {
      // console.log(response);
      if (response.data?.result?.length) {
        setEmails(response?.data?.result);
        setCurrentEmailIndex(0);
        setCurrentEmail(response.data.result[0]);
      }
    }).catch((err) => {
      console.log(err);
    });
  };

  const previousEmail = () => {
    setCurrentEmailIndex(currentEmailIndex - 1);
    setCurrentEmail(emails[currentEmailIndex - 1]);
  };

  const nextEmail = () => {
    console.log(currentEmailIndex, emails.length);
    setCurrentEmailIndex(currentEmailIndex + 1);
    setCurrentEmail(emails[currentEmailIndex + 1]);
  };

  return (
    <div className="terms full-screen-page">
      <Helmet>
        <title>Emails - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        <h2>Preview emails</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body emails-body">
        {emails.length ?
          <>
            <div className="email-preview email-field-container">
              <div className="email-field">
                <span className="field-name">Result item</span>
                <span className="field-value">{currentEmailIndex}</span>
              </div>
              <div className="email-field">
                <span className="field-name">Id</span>
                <span className="field-value">{currentEmail?.id}</span>
              </div>
              <div className="email-field">
                <span className="field-name">Email address</span>
                <span className="field-value">{currentEmail?.email_address}</span>
              </div>
              <div className="email-field">
                <span className="field-name">For user</span>
                <span className="field-value">{currentEmail?.for_user}</span>
              </div>
              <div className="email-field">
                <span className="field-name">By user</span>
                <span className="field-value">{currentEmail?.sent_by_user}</span>
              </div>
              <div className="email-field">
                <span className="field-name">Subject</span>
                <span className="field-value">{currentEmail?.subject}</span>
              </div>
              <div className="email-field">
                <span className="field-name">Created at</span>
                <span className="field-value">{new Date(currentEmail?.created_at).toDateString()}</span>
              </div>
            </div>
            <div>
              <div className="full-width message-container">
                <div className="field-name">Message</div>
                <div className="message-body">
                  <div className="message" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(currentEmail?.message)}} />
                  <pre>{currentEmail?.message}</pre>
                </div>
              </div>
            </div>

            <div className="emails-actions">
              <Button color="secondary" disabled={currentEmailIndex === undefined || currentEmailIndex === null || currentEmailIndex < 1} outline size="sm" className="previous" onClick={previousEmail}>
                Previous
              </Button>
              <Button color="secondary" outline size="sm" className="next" onClick={() => {
                fetchEmails();
              }}>
                Refresh
              </Button>
              <Button color="secondary" disabled={currentEmailIndex === undefined || currentEmailIndex === null || currentEmailIndex === emails?.length - 1} outline size="sm" className="next" onClick={nextEmail}>
                Next
              </Button>
            </div>
          </>
        :
          <p>No emails, or currentEmailIndex not set</p>
        }

      </div>
    </div>
  );
}