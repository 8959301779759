import React, { useEffect, useContext } from 'react';

import { PageContext } from '../../../contexts/page-context';
import { useNavigate } from "react-router-dom";

import "./Splash.styles.css";

export default function Splash() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Splash');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    let redirectUrl = "/projects/";

    const queryParams = new URLSearchParams(window.location.search);
    const campaign = queryParams.get("c");
    const sid = queryParams.get("s");

    if (campaign) {
      // console.log("Campaign found: " + campaign);
      redirectUrl = redirectUrl + "?c=" + campaign;
    }

    if (sid) {
      // console.log("Campaign found: " + campaign);
      redirectUrl = redirectUrl + (campaign ? "&" : "?") + "s=" + sid;
    }

    navigate(redirectUrl, { replace: true });
    // setTimeout(() => { 
    //   navigate("/projects/", { replace: true });
    // }, 20000);
  });


  return (
    <div className="splash-container">
      <img src={process.env.PUBLIC_URL + '/logo-with-text.png'} alt="Logo" className="logo" />
    </div>
  );
}