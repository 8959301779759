import React, { useEffect, useContext } from 'react';
import { useNavigate, useSearchParams } from "react-router-dom";
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { UserContext } from '../../../contexts/user-context';

export default function Login() {
  const { User } = useContext(UserContext);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (User?.id) {
      const projectId = searchParams.get('project-id');

      if (projectId) {
        navigate('/project-detail/' + projectId, { replace: true });
      } else {
        navigate('/projects/my-projects');
      }
    }
  }, [User]);

  return (
    <>
      <Helmet>
        <title>Login - Project Klub</title>
      </Helmet>
    </>
 );
}