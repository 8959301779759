export const myProjects = [
  {
    id: 1,
    name: 'Redesigning a WordPress site',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    isJoined: true,
    isPaid: true,
    skills: [
        { slug: 'wordpress', name: 'WordPress' },
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    numParticipants: 5,
    price: 0,
    discussionCount: 5,
  },
  {
    id: 5,
    name: 'Implement SSR for React app',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    skills: [
        { slug: 'react', name: 'react' },
        { slug: 'node', name: 'node' },
      ],
    numParticipants: 1,
    price: 0,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 2,
    name: 'A dice rolling simulator',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'guided',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'python', name: 'Python' },
      ],
    numParticipants: 3,
    price: 400,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 3,
    name: 'Chennai traffic predictor',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'contest',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'java', name: 'Java' },
      ],
    numParticipants: 22,
    price: 250,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 1,
    name: 'Redesigning a WordPress site',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    isJoined: true,
    isPaid: true,
    skills: [
        { slug: 'wordpress', name: 'WordPress' },
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    numParticipants: 5,
    price: 0,
    discussionCount: 5,
  },
  {
    id: 5,
    name: 'Implement SSR for React app',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    skills: [
        { slug: 'react', name: 'react' },
        { slug: 'node', name: 'node' },
      ],
    numParticipants: 1,
    price: 0,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 2,
    name: 'A dice rolling simulator',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'guided',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'python', name: 'Python' },
      ],
    numParticipants: 3,
    price: 400,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 3,
    name: 'Chennai traffic predictor',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'contest',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'java', name: 'Java' },
      ],
    numParticipants: 22,
    price: 250,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 1,
    name: 'Redesigning a WordPress site',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    isJoined: true,
    isPaid: true,
    skills: [
        { slug: 'wordpress', name: 'WordPress' },
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    numParticipants: 5,
    price: 0,
    discussionCount: 5,
  },
  {
    id: 5,
    name: 'Implement SSR for React app',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'work',
    skills: [
        { slug: 'react', name: 'react' },
        { slug: 'node', name: 'node' },
      ],
    numParticipants: 1,
    price: 0,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 2,
    name: 'A dice rolling simulator',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'guided',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'python', name: 'Python' },
      ],
    numParticipants: 3,
    price: 400,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },
  {
    id: 3,
    name: 'Chennai traffic predictor',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'contest',
    skills: [
        { slug: 'dsa', name: 'DSA' },
        { slug: 'java', name: 'Java' },
      ],
    numParticipants: 22,
    price: 250,
    isJoined: true,
    isPaid: true,
    discussionCount: 5,
  },

];
