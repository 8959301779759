import React from 'react';

export default function TermsContent() {
  return (
    <>

      <h1>Terms and conditions</h1>

      <p>
        Please read these Terms and Conditions before using https://www.projectklub.com and its sub-domain https://app.projectklub.com (hereinafter collectively referred to as the Website or the Platform), operated by owners of the same (hereinafter referred to as Platform Owners).
      </p>

      <p>
        YOUR ACCESS TO AND USE OF THE SERVICE IS CONDITIONED ON YOUR ACCEPTANCE OF AND COMPLIANCE WITH THESE TERMS. THESE TERMS APPLY TO ALL VISITORS, USERS AND OTHERS WHO ACCESS OR USE THE SERVICE. BY ACCESSING OR USING THE SERVICE YOU AGREE TO BE BOUND BY THESE TERMS. IF YOU DISAGREE WITH ANY PART OF THE TERMS THEN YOU ARE REQUESTED TO REFRAIN FROM ACCESSING THE SERVICE.
      </p>


      <h2>DEFINITIONS</h2>
      <ol>
        <li>“We”, “Our”, and “Us” - shall mean and refer to the Platform Owners</li>
        <li>“You”, “User” – the person, firm or organisation engaging our services.</li>
        <li>“Software” - any executable files, content, help files and all other documentation and content that is in addition to the service available on the Website for download, installation or purchase by Users.</li>
      </ol>

      <h2>SCOPE OF SERVICES</h2>
      {/*<p>
        We provide a platform to help people to build web projects and skills for building web projects. We offer two major types of project-building functionality: "practice" projects, and "standalone" projects. Practice projects usually have modules and resources described, that users can "join", submit solutions, and engage in discussion to help each other learn better. "Standalone" projects are user-created projects, and serve as a place to find other people help / work on these projects. Users submit "solutions" and "help requests" that other users can see, and offer each other feedback. Users can optionally include contact information in help requests, for other users to find and contact them outside the website.
      </p>*/}
      <p>
        We provide a platform to help people to build web projects and skills for building web projects. We offer project-building functionality for practice web projects, which are guided projects meant for the purposes of learning. Projects usually have modules and resources described, that users can "join", submit solutions, and engage in discussion to help each other learn better. Users submit "solutions" and "help requests", and offer each other feedback. Users can optionally include contact information in help requests, for other users to find and contact them outside the website.
      </p>


      <h2>INTELLECTUAL PROPERTY RIGHTS</h2>
      <p>
        Nothing contained herein shall give the user a right to use any of the Website’s trade names, trademarks, service marks, logos, domain names, information, questions, answers, solutions, reports and other distinctive features, save according to the provisions of these Terms. All logos, content, trademarks, brand names, service marks, domain names are the property of the Platform Owners or the respective copyright or trademark owner. Furthermore, with respect to the application created by the Platform Owners, the Platform Owners shall be the exclusive owner of all the designs, graphics and the like, related to the Website.
      </p>

      <p>
        The Website contains copyrighted material, trademarks, trade secrets, patents, and other proprietary information, including, but not limited to, text, software, photos, video, graphics, music, sound, and the entire contents of the Platform Owners protected by copyright as a collective work under the applicable copyright laws.
      </p>

      <p>
        You shall not decipher, decompile, disassemble, or reverse engineer any of the software comprising or in any way making up a part of the Website. No user is authorised to copy or adapt the Website’s software, including but not limited to HTML, JavaScript, CSS, React or other code.
      </p>

      <p>
        We reserve the right to remove any content from our Website that is alleged to infringe any other person’s intellectual property.
      </p>

      <h3>PROJECTS</h3>
      <p>Projects, their descriptions, assets, modules, resources, comments and other entities associated with them are owned by Project Klub and are not to be used or copied anywhere without written permission from Project Klub.</p>

      <p>Solutions submited to projects are visible to other users, and are considered to be <b>unlicensed</b> unless specified otherwise. If you do not want your solutions to be visible to or used by other users on or outside Project Klub, please refrain from submitting them on projects.</p>

      <p>Projects, and solutions and comments on projects, are visible to all users. Project Klub does not have control over how these solutions and comments are used, copied or replicated, and is not responsible for the where/how they are used/copied and any outcome of such usage. The Platform Owners is not responsible for such usage, authorized or otherwise, by users of the Platform and/or others.</p>

      <h3>PLATFORM AND PROJECT PRICING</h3>
      <p>The Platform Owners reserve the right to charge a fee for allowing users to join a project for a specified period of time. The Platform Owners further reserve the right to revise the pricing for each project without notice. Project pricing will vary based on factors like content, popularity and other factors at the discretion of the Platform Owners.</p>
      <p>The Platform Owners may charge a fee for "premium" membership that allows users to join and participate in projects marked as "Premium". The Platform Owners reserves the right to specify and/or change the fee required for such "Premium" membership. The Platform Owners also reserves the right to change the duration for a Premium subscription without notice.</p>
      <p>The Platform Owners reserves the right to change a project's access type from "Free" to "Premium" without notice, and to require Premium membership to access any given project at any given time.</p>

{/*      <h3>STANDALONE PROJECTS</h3>
      <p>Standalone projects, their descriptions, assets, modules, resources, comments and other entities associated with them are owned by their respective project owners, and their usage rights are according to the copyright/licensing information described in the project descriptions/</p>

      <p>Standalone projects, their descriptions, assets, modules, resources, comments and other entities associated with them are visible to all users added to the respective projects. Project Klub does not have control over how these assets are used, copied or replicated, and is not responsible for the where/how they are used/copied and any outcome of such usage.</p>

      <p>Standalone projects that do not specify any license or copyright policy are assumed to be unlicensed, and we request you to refrain from copying or using their assets in any manner without the written explicit permission of the project owner(s).</p>

      <p><b>NOTE: </b>Solutions to standalone projects are considered by default to be owned by the <b>project owner</b>, NOT the user who submits the solution. However, Project Klub provides users the ability to add/delete/edit solutions. It is up to the project owner to ensure solutions are added/edited/deleted as per their requirements, with their relationship with other users off-platform. Project Klub is no responsible for any loss of data owing to incorrect additions/editions/deletions.</p>*/}


      <h2>PROJECT SHUTDOWN AND PLATFORM OUTAGES</h2>
      <p>Project Klub does not take responsibility for any data or information loss due to project deletion, incorrect edits,  platform outage, or other unforseen circumstances. Project owners and solution owners are encouraged to keep backups of their project and solution artifacts elsewhere (like: github or a cloud drive), and not rely on Project Klub as the only place where their artifacts are stored.</p>

      <p>We reserve the right to edit or delete projects, modules, resources, solutions, help requests, comments and other information on the site without notice, at our discetion.</p>


      <h2>EXERCISE CAUTION WHEN DEALING WITH THIRD PARTIES AND OTHER USERS</h2>
      <p>We urge users to exercise extreme caution when engaging with other users. Keep in mind that they are strangers, and being other users of Project Klub does not automatically make someone more trustworthy than any other stranger you do not know. Follow all safety and security precations you would follow elsewhere, before sharing project documents and other artifacts (for example: requiring people to sign a non-disclosure agreement if your project needs it).</p>

      <p>Project Klub surfaces functionality where users are able to connect each other, using help requests and comments. If you use this functionality to contact another user for whatsoever reason, exercise extreme caution when dealing with the other user, and do not trust anyone without reason. Project Klub does not take responsibility for any users' behavior, promises, or other exchange that happens between users, and we do not have control over such activities nor the resources to vet and verify each users' credentials and trustworthiness.</p>


      <h2>REGISTRATION AND ACCOUNT TERMINATION</h2>
      <ul>
        <li>Users shall register in order to get access to the services provided by the Platform Owners through the Website.</li>
        <li>Only those individuals who have attained majority can register themselves with Us. Individuals under the age of 18 years shall utilise or browse our Website only with the involvement, guidance and supervision of their parents and / or legal guardians, using such parent /legal guardian’s registered account.</li>
        <li>Users are required to verify their email address after registration, using a verification code sent on their registered email.</li>
        <li>Users can delete their account using the Profile settings page, and their user account is deleted permanently</li>
        <li>You may discontinue from our services at any point of time, by either opting out of your membership from the Website.</li>
        <li>We reserve the right to unilaterally terminate your use of the Website without notice or any liability for reasons of breach of the terms mentioned herein.</li>
        <li>We also reserve the right to deny access to particular Users, to all/any of the services without any prior notice or explanation in order to protect the interests of other visitors to the Website.</li>
        <li>You shall continue to be bound by these Terms, and shall have no right to terminate these Terms till the expiry of the same.</li>
      </ul>


      <h2>USE OF THIRD PARTIES</h2>
      <p>The Platform uses the services of a third party "Kinde" for login and account creation functionality. The Platform may share cookies, session information, user data and/or other information with third parties including but not limited to Kinde, for essential functioning.</p>


      <h2>WARRANTIES AND LIABILITIES</h2>
      <ul>
        <li>We do not warrant, guarantee or endorse the accuracy, completeness or quality of content and representations offered on our Website.</li>
        <li>We undertake no liability, in any circumstances whatsoever, for the standard of services provided by third-parties on our website, including but not limited to other users of our website.</li>
        <li>We are not liable for events beyond the platform owners’ control such as natural calamities, acts of terrorism or other similar factors which cannot be reasonably mitigated against.</li>
        <li>We are not responsible for any interruption of service or any loss of data while transmitting any information over the internet.</li>
        <li>We do not have any control over the content and resources provided by the third party websites through ads or links.</li>
      </ul>


      <h2>CONDUCT</h2>
      <ul>
        <li>
          You agree not to use the website in any way that breaks the law.
        </li>
        <li>
          <p>
            You agree and acknowledge that You are a restricted user of this Website, and that You shall refrain from providing any information:
          </p>
          <ul>
            <li>which is false, fraudulent, inaccurate, misleading or incomplete; or</li>
            <li>which is defamatory, libellous, unlawfully threatening or unlawfully harassing; or</li>
            <li>which contains any viruses, trojan horses, worms, time bombs, cancelbots, easter eggs or other computer programming routines or executable files that may damage, detrimentally interfere with, surreptitiously intercept or expropriate any system, data or personal information of any person whatsoever; or</li>
            <li>which creates liability or causes us to lose in whole or in part, the services of our Internet Service Providers or other service providers/suppliers.</li>
          </ul>
        </li>
        <li>
          <p>You further undertake that You shall not engage in the following activities:</p>
          <ul>
            <li>Systematic retrieval of data or other content from the Website to create or compile, directly or indirectly, a collection, compilation, database, or directory without written permission from us.</li>
            <li>Any unauthorized use of the Website, including collection of usernames and/or email addresses of other users by electronic or other means for the purpose of sending unsolicited email, or creating user accounts by automated means or under false pretences.</li>
            <li>Circumvention, disablement, or interference with security-related features of the Website, including features that prevent or restrict the use or copying of any content or enforce limitations on the use of the Website.</li>
            <li>Unauthorized framing of or linking to the Website.</li>
            <li>Trick, defraud, or mislead Us and other Users, especially in any attempt to learn sensitive account information such as user passwords.</li>
            <li>Attempt to impersonate another user or person or use the username of another user.</li>
            <li>Sell or otherwise transfer Your profile.</li>
            <li>Use any information obtained from the Website in order to harass, abuse, or harm another person.</li>
            <li>Use the Website as part of any effort to compete with us or for any revenue-generating endeavour or commercial enterprise.</li>
            <li>Disparage, tarnish, or otherwise harm, in our opinion, Us and/or the Website.</li>
            <li>Use the Website in a manner inconsistent with any applicable laws or regulations.</li>
            <li>Follow the user guide and policy prescribed and updated time to time by the Platform Owners</li>
          </ul>
        </li>
      </ul>


      <h2>INDEMNITY</h2>
      <p>
        You agree to indemnify, defend and hold Us harmless from and against all losses, liabilities, claims, damages, demands, costs and expenses (including legal fees and disbursements in connection therewith and interest chargeable thereon) asserted against or incurred by Us that arise out of or result from, any breach or non-performance of any representation, warranty, covenant or agreement or obligation to be performed by You pursuant to these terms of use. Further, You agree to hold us harmless against any claims made by any third party due to, or arising out of, or in connection with:
      </p>

      <ul>
        <li>Your use of the Platform;</li>
        <li>Your violation of these Terms and Conditions;</li>
        <li>Your violation of any rights of another;</li>
        <li>Your alleged improper conduct pursuant to these Services;</li>
        <li>Your conduct in connection with the Website;</li>
      </ul>

      <p>
        You agree to fully co-operate in indemnifying us at your expense. You also agree not to reach a settlement with any party without our consent.
      </p>

      <p>
        In no event shall We be liable to compensate You or any third party for any special, incidental, indirect, consequential or punitive damages whatsoever, including those resulting from loss of use, data or profits, whether or not foreseeable, and whether or not We had been advised of the possibility of such damages, or based on any theory of liability, including breach of contract or warranty, negligence or other tortuous action, or any other claim arising out of or in connection with the Your use of or access to the Website and/or the Services or materials contained therein.
      </p>


      <h2>DISPUTE RESOLUTION TERMS</h2>
      <p>It is expressly agreed to by the parties hereto that the formation, interpretation and performance of these Terms and any disputes arising therefrom will be resolved through a two-step Alternate Dispute Resolution mechanism. It is further agreed to by the parties that the contents of this Section shall survive even after the termination or expiry of the Terms and/or the Privacy Policy.</p>

      <p>In case of any dispute between the parties, the parties will attempt to resolve the same amicably amongst themselves, to the mutual satisfaction of all parties. In the event that the Parties are unable to reach such an amicable solution within thirty (30) days of one party communicating the existence of a dispute to the other, the dispute shall be resolved by arbitration.</p>

      <p>The parties expressly agree that the Terms, Policy and any other agreements entered into between the parties are governed by the laws, rules and regulations of India. The policy shall be governed by and construed in accordance with the laws of India. Each party hereby irrevocably submits to the exclusive jurisdiction of the courts of Chennai, India, for the adjudication of any dispute hereunder or in connection herewith.</p>


      <h2>NOTICES</h2>
      <p>Any and all communication relating to any dispute or grievance experienced by the User may be communicated to the Platform Owners by the Customer by emailing us at admin@projectklub.com</p>


      <h2>MISCELLANEOUS PROVISIONS</h2>
      <ul>
      <li><b>Entire Agreement:</b> These Terms, read with the Privacy Policy, form the complete and final contract between the User and the Platform Owners with respect to the subject matter hereof and supersedes all other communications, representations and agreements (whether oral, written or otherwise) relating thereto.</li>
      <li><b>Waiver:</b> The failure of either You or Us, at any time to require performance of any provision of these Terms, shall in no manner affect such party's right at a later time to enforce the same. No waiver by either party of any breach of these Terms, whether by conduct or otherwise, in any one or more instances, shall be deemed to be or construed as a further or continuing waiver of any such breach, or a waiver of any other breach of these Terms.</li>
      <li><b>Severability:</b> If any provision of these Terms is held to be illegal, invalid, or unenforceable by any court or authority of competent jurisdiction, the legality, validity, and enforceability of the remaining provisions of these Terms shall in no way be affected or impaired thereby, and each such provision shall be valid and enforceable to the fullest extent permitted by law. In such case, these Terms shall be reformed to the minimum extent necessary to correct any illegality, invalidity or unenforceability.</li>
      </ul>



    </>
 );
}