import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Helmet } from 'react-helmet-async';

import { keysToCamel } from '../../util/caseConverters';
import ConfirmationModal from "../common/ConfirmationModal";
import InformationModal from "../common/InformationModal";
import UserProfileModal from "../common/UserProfileModal";
import ChangeStatusModal from "../solutions/ChangeStatusModal";
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import PageError from '../messages/PageError';

import Discussion from '../discussion/Discussion';

import "./SolutionDetail.scss";

import { solutionStatusOptions } from '../../constants/solutionStatusOptions';

export default function SolutionDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getSolution, getProjectRole, deleteSolution, updateEntityVisit, getIsFollowingEntity, followSolution, unfollowSolution, postFetchModuleNames, logView, logError, postClap, postDeleteClap, getAllClaps } = useContext(DataContext);
  const { User, userUpdates, refreshUserUpdates } = useContext(UserContext);
  const { projectId, solutionId } = useParams();
  const [solution, setSolution] = useState();
  const [moduleNames, setModuleNames] = useState([]);
  const [role, setRole] = useState('');
  const [isFollowingEntity, setIsFollowingEntity] = useState(false);
  const [isSolutionOwner, setIsSolutionOwner] = useState(false);
  const [isDiscussionOpen, setDiscussionOpen] = useState(false);
  const [hasUnreadComments, setHasUnreadComments] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDeleteInformationModal, setShowDeleteInformationModal] = useState(false);
  const [deleteInformationMessage, setDeleteInformationMessage] = useState('The solution has been deleted.');
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [isAllowComments, setIsAllowComments] = useState(true);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);
  const [showStatusModalVisible, setShowStatusModalVisible] = useState(false);
  const [entityClaps, setEntityClaps] = useState([]);

  const fetchData = function(showLoader) {
    let solution;
    setPageErrorMessage(null);

    getSolution(projectId, solutionId, showLoader).then((response) => {
      if (response?.data?.length) {
        solution = keysToCamel(response).data[0];
        setSolution(solution);

        const userId = User?.id;

        setIsSolutionOwner(solution.ownerId === userId);

        if (userId) {
          getProjectRole(userId, projectId, showLoader).then((response) => {
            if (response?.data?.length) {
              setRole(response.data[0].role);
            } else {
              setRole('outsider');
            }
          }).catch((error) => {
            setPageErrorMessage('An error occured. Please refresh the page and try again.');
            console.log(error);
            // logError(error);
            setRole('outsider');
          });

          updateEntityVisit(solutionId, 'solution').then((response) => {
            refreshUserUpdates();
          }).catch((error) => {
            setPageErrorMessage('An error occured. Please refresh the page and try again.');
            console.log(error);
            // logError(error);
          });
        }

        if (solution.modules) {
          postFetchModuleNames(solution.modules, showLoader).then((response) => {
            setModuleNames(response.data);
          }).catch((error) => {
            console.log(error);
            // logError(error);
            setPageErrorMessage('An error occured. Please refresh the page and try again.');
          });
        } else {
          setModuleNames([]);
        }

        setIsAllowComments(solution.allowComments);
      }
    }).catch((error) => {
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
      console.log(error);
      // logError(error);
    });

    if (solutionId) {
      fetchFollowingData();
      fetchClapsData();
    }
  };

  const fetchClapsData = function() {
    if (solutionId) {
      getAllClaps().then((response) => {
        setEntityClaps(response.data.filter(item => item.entity_type === 'solution' && item.entity_id === parseInt(solutionId)));
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }
  };

  const fetchFollowingData = function() {
    setPageErrorMessage(null);

    if (User?.id) {
      getIsFollowingEntity('solution', solutionId).then((response) => {
        setIsFollowingEntity(response.data);
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }
  };

  const doFollowSolution = function() {
    setPageErrorMessage(null);

    followSolution(solutionId, projectId).then((response) => {
      fetchFollowingData();
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
    });
  };

  const doUnfollowSolution = function() {
    setPageErrorMessage(null);
    
    unfollowSolution(solutionId).then((response) => {
      fetchFollowingData();
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
    });
  };

  const doPostLike = function() {
    setPageErrorMessage(null);

    if (User?.id && solution) {
      postClap(solution.ownerId, solutionId, 'solution', solution.title, projectId, 'project').then((response) => {
        fetchClapsData();
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }
  };

  const doPostUnlike = function() {
    setPageErrorMessage(null);

    if (User?.id && solution) {
      postDeleteClap(solutionId, 'solution').then((response) => {
        fetchClapsData();
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }
  };

  useEffect(() => {
    setPageTitle('Solution');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    fetchData(true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchData(false);
      }
    }, 10000);
    return () => {
      clearInterval(intervalId);
    };
  }, [projectId, solutionId]);

  useEffect(() => {
    if (solutionId && userUpdates?.withUpdates) {
      const hasNewComments = !!(userUpdates.withUpdates.find(item => item.entityType === 'solution' && item.entityId === parseInt(solutionId)));
      setHasUnreadComments(hasNewComments);
    }
  }, [userUpdates]);

  useEffect(() => {
    logView('solution-detail', `${projectId}-${solutionId}`);
  }, []);

  return (
    <div>
      <Helmet>
        <title>
          {solution?.title || 'Solution detail'} - Project Klub
        </title>
      </Helmet>

      {solution && isDiscussionOpen ?
        <Discussion
          projectId={projectId}
          entityId={solutionId}
          entity={solution}
          entityType="solution"
          entityUrl={`/solution-detail/${projectId}/${solutionId}`}
          entityName={solution?.title}
          entityThumbnailUrl={process.env.PUBLIC_URL + '/icons/solution.png'}
          onClose={() => {
            setDiscussionOpen(false);
            fetchData();
          }} />
      : null }

      <div className={"solution-detail-page full-screen-page " + (isDiscussionOpen ? "non-scrollable" : "") }>

        <div className="full-screen-header">
          <h2>Solution</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              // navigate('/project-detail/' + projectId);
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {solution ?
          <div className={`full-screen-body solution-detail`}>
            <div className="solution-header">
              <div className="solution-thumbnail-container">
                <div className="solution-detail-thumbnail-section">
                  <img src={process.env.PUBLIC_URL + '/icons/solution.png'} alt={solution.title} className="solution-thumbnail" />

                  {solution?.status ?
                    <div className={`solution-status solution-status-${solution.status}`}>{solutionStatusOptions.find(item => item.value===solution.status)?.label }</div>
                  : null}
                </div>
              </div>
              <div className="solution-title-container">
                <p className="solution-title">Solution: {solution?.title}</p>
                {solution?.ownerName ?
                  <div className="solution-owner">by
                    <Button color="link" className="button-as-link" outline size="sm" type="button" onClick={() => {
                      setShowUserProfileModal(true);
                    }}>
                      {solution.ownerName}
                    </Button>

                    {/*<strong></strong>*/}
                  </div>
                : null}
              </div>

              {isSolutionOwner ?
                <div>
                  <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-solution" onClick={() => { navigate('/edit-project-solution/' + projectId + '/' + solutionId ); }}>
                    Edit
                  </Button>
                </div>
              : null}
{/*              <div>
                {!isSolutionOwner ?
                  <>
                    {isFollowingEntity ?
                      <>
                        <span>Following this solution comments.</span>
                        <Button type="link" size="sm" color="none" onClick={() => { doUnfollowSolution(); }}>
                          Unfollow
                        </Button>
                      </>
                    :
                      <>
                        <span>Not following this solution comments.</span>
                        <Button type="link" size="sm" color="none" onClick={() => { doFollowSolution(); }}>
                          Follow
                        </Button>
                      </>
                    }
                  </>
                : null}
              </div>*/}
            </div>


            {moduleNames?.length ?
              <div className="solution-detail-modules">
                <div>
                  <p className="solution-detail-modules-desc">
                    This solution is specific to these modules:
                  </p>
                  <ul>
                    {moduleNames.map((item, idx) => {
                      return (<li key={idx}>
                        <img className="module-icon" src={process.env.PUBLIC_URL + '/icons/module-thumbnail.png'} alt="Module" />
                        <span>{item.title}</span>
                      </li>);
                    })}
                  </ul>
                </div>
              </div>
            : null}

            {solution?.url ?
              <div className="solution-detail-section">
                <div className="solution-detail-section-header">
                  <h3>Solution live link</h3>
                </div>

                <div className="solution-link">
                  <a href={solution.url} target="_blank">{solution.url}</a>
                </div>
              </div>
            : null}

            {solution?.codeUrl ?
              <div className="solution-detail-section">
                <div className="solution-detail-section-header">
                  <h3>Solution code link</h3>
                </div>

                <div className="solution-code-link solution-link">
                  <a href={solution.codeUrl} target="_blank">{solution.codeUrl}</a>
                </div>
              </div>
            : null}

            <div className="solution-detail-section">
              <div className="solution-detail-section-header">
                <h3>Solution description</h3>
              </div>

              <div className="solution-description" onClick={(e) => {
                if (e?.target?.href) {
                  window.open(e.target.href, '_new', 'noopener=yes');
                  e.preventDefault();
                }
              }}>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(solution?.body)}} />
              </div>
            </div>

            {solution?.allowComments && solution?.feedbackAreas ?
              <div className="solution-detail-section">
                <div className="solution-detail-section-header">
                  <h3>Questions / areas the owner would like feedback around</h3>
                </div>

                <div className="solution-feedback-areas">
                  <p>{solution.feedbackAreas}</p>
                </div>
              </div>
            : null}
          </div>
        : null }

        <div className="solution-detail-actions form-footer">
          <div className="solution-detail-secondary-actions">
            <div className="likes-count-container">
              {entityClaps?.length ?
                <span className="likes-count">{entityClaps?.length}</span>
              : null}
            </div>
            <div className="solution-detail-actions-buttons">
              <span className="solution-detail-actions-likes-actions">
                {User?.id && solution?.ownerId && User.id !== solution.ownerId ?
                  <>
                    {entityClaps?.find(item => item.clapper === User.id) ?
                      <Button color="none" className="liked-button" outline size="sm" onClick={() => { doPostUnlike() }}>
                        <span>Liked</span>
                      </Button>
                    :
                      <Button color="none" className="like-button" outline size="sm" onClick={() => { doPostLike() }}>
                        <span>Like</span>
                      </Button>
                    }
                  </>
                : null}
              </span>

              {isSolutionOwner ?
                <Button color="secondary" outline={true} size="sm" className="solution-detail-update-status-button" onClick={() => {
                  setShowStatusModalVisible(true);
                }}>
                  Change status
                </Button>
              : null}

              <Button color="secondary" outline={true} size="sm" className="solution-detail-discussion-button" disabled={!isAllowComments} onClick={() => {
                setDiscussionOpen(true);
              }}>
                {isAllowComments ? 'Comments' : 'Comments disabled by owner'}

                {solution?.numMessages ?
                  <span className={`discussion-count ${hasUnreadComments ? 'has-unread' : ''}`}>{solution?.numMessages}</span>
                : null}
              </Button>

              {isSolutionOwner ?
                <Menu menuButton={
                      <MenuButton color="link" size="sm" className="solution-detail-more-actions" onClick={() => {
                      }}>
                        <img src={process.env.PUBLIC_URL + '/icons/ellipsis.png'} alt="More actions" />
                      </MenuButton>
                    }
                  position="auto"
                  direction="top"
                  align="end"
                  arrow={true}
                  gap={4}
                  >
                  <MenuItem onClick={() => { setShowStatusModalVisible(true); }}>Change status</MenuItem>
                  <MenuItem onClick={() => { navigate('/edit-project-solution/' + projectId + '/' + solutionId ); }}>Edit solution</MenuItem>
                  <MenuItem onClick={() => { setShowDeleteConfirmationModal(true); }}>Delete solution</MenuItem>
                </Menu>
              : null}
            </div>
          </div>

        </div>
      </div>


      {showDeleteConfirmationModal ?
        <ConfirmationModal 
          closeLabel="Cancel"
          onClose={() => {setShowDeleteConfirmationModal(false)}}
          submitLabel="Yes"
          onSubmit={() => {
            // API call to delete the solution
            deleteSolution(projectId, solutionId).then((response) => {
              if (response?.data?.result?.affectedRows) {
                setDeleteInformationMessage('The solution has been deleted.')
              } else {
                setDeleteInformationMessage('The solution could not be be deleted.')
              }
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
            }).catch((error) => {
              setDeleteInformationMessage('The solution could not be be deleted.')
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
              // logError(error);
            });
          }}
          headerMessage="Confirmation"
          bodyMessage="Are you sure you want to delete this solution? This action cannot be undone."
          />
      : null}

      {showDeleteInformationModal ?
        <InformationModal 
          closeLabel="Close"
          onClose={() => {
            setShowDeleteInformationModal(false);
            navigate('/project-detail/' + projectId);
          }}
          headerMessage="Delete solution"
          bodyMessage={deleteInformationMessage}
          />
      : null}

      {showStatusModalVisible && solution ?
        <ChangeStatusModal
          onClose={() => {
            setShowStatusModalVisible(false);
          }}
          onSave={() => {
            setShowStatusModalVisible(false);
            fetchData();
          }}
          solution={solution}
          />
      : null}

      {showUserProfileModal && solution?.ownerId ?
        <UserProfileModal 
          userId={solution.ownerId}
          onClose={() => setShowUserProfileModal(false)}
          />
      : null}
    </div>
  );
}