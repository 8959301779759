import React, { useState, useEffect, useContext } from 'react';
import { Button, Label, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Select from 'react-select'

import PageError from '../messages/PageError';
import { DataContext } from '../../contexts/data-context';
import { solutionStatusOptions } from '../../constants/solutionStatusOptions';

import "./ChangeStatusModal.scss";

function ChangeStatusModal(props) {
  const {
    solution,
    onClose,
    onSave,
  } = props;
  const [status, setStatus] = useState(solutionStatusOptions[0]);
  const { changeSolutionStatus, logView } = useContext(DataContext);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);

  const handleSave = () => {
    setPageErrorMessage(null);

    changeSolutionStatus(solution.id, status.value).then((response) => {
      if (response) {
        onSave();
      } else {
        setPageErrorMessage('An error occurred. Please refresh the window and try again.');
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred. Please refresh the window and try again.');
      // logError(error);
    });
  };

  useEffect(() => {
    setPageErrorMessage(null);
    logView('change-solution-status', `${solution?.id}`);
  }, []);

  useEffect(() => {
    setStatus(solutionStatusOptions.find(item => item.value === solution.status));
  }, [solution]);

  return (
    <Modal
      className="change-status-modal"
      isOpen={true}
      toggle={onClose}
      backdrop="static"
      centered={true}>
      <ModalHeader>Change solution status</ModalHeader>
      <ModalBody>
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        <form onSubmit={handleSave}>
          <div className={`form-group full-width`}>
            <Label for="solution-status">Solution status</Label>
            <div className="solution-status-select-container">
              <Select
                name="solution-status"
                id="solution-status"
                options={solutionStatusOptions}
                value={status}
                onChange={setStatus}
                isSearchable={false}
                />
            </div>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
          <Button color="secondary" outline={true} onClick={() => { onClose(); }}>
            Cancel
          </Button>
          <Button color="primary" onClick={handleSave}>
            Save
          </Button>
      </ModalFooter>
    </Modal>
  );
}

export default ChangeStatusModal;
