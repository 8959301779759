import React from 'react';

import "./Spinner.scss";

function Spinner(props) {
  return (
    <div className="spinner-container">
      <div className="spinner-overlay" aria-hidden="true"></div>

      <div className="spinner-logo-container">
        <img className="spinner-logo" src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" />
      </div>
    </div>
  );
}

export default Spinner;
