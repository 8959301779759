import React  from 'react';

import "./PageError.scss";

export default function PageError(props) {
  const {
    message,
    messageText
  } = props;

  return (
    <div className="page-error">
      <img src={process.env.PUBLIC_URL + '/icons/error-exclamation-white.svg'} alt="Alert" />
      <span>{messageText}</span>
    </div>
  );
}