import React, { useState, useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Select from 'react-select'
import { MultiSelect } from "react-multi-select-component";
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import PageError from '../messages/PageError';
import { keysToCamel } from '../../util/caseConverters';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import "./CreateResource.scss";

export default function CreateResource() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, resourceId, moduleId } = useParams();
  const [role, setRole] = useState('');
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { createResource, editResource, getResource, getProjectRole, getAllProjectModules, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [projectModules, setProjectModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModulesStr, setSelectedModulesStr] = useState('');
  const [scope, setScope] = useState('all');
  const [editHtml, setEditHtml] = useState(false);

  const resourceTypeOptions = [
    { value: 'note', label: 'Note' },
    { value: 'document', label: 'Document' },
    { value: 'image', label: 'Image' },
    { value: 'video', label: 'Video' },
    { value: 'audio', label: 'Audio' },
    { value: 'other', label: 'Other' },
  ];


  const html = '<p></p>';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  // const editorState = EditorState.createWithContent(contentState);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [resource, setResource] = useState();
  const [title, setTitle] = useState('');
  const [body, setBody] = useState(html);
  const [url, setUrl] = useState('');
  const [type, setType] = useState(resourceTypeOptions[0]);

  const [isTitleError, setTitleError] = useState(false);
  const [isBodyError, setBodyError] = useState(false);
  const [isModulesError, setModulesError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const [pageErrorMessage, setPageErrorMessage] = useState(null);

  useEffect(() => {
    setPageTitle('Add a resource');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('create-resource');
  }, []);

  useEffect(() => {
    if (projectId) {
      const userId = User?.id;
      getProjectRole(userId, projectId).then((response) => {
        if (response?.data?.length) {
          setRole(response.data[0].role);
        } else {
          setRole('outsider');
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setRole('outsider');
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (resourceId) {
      setPageTitle('Edit Resource');
      let resource;
      setPageErrorMessage(null);

      getResource(projectId, resourceId).then((response) => {
        if (response?.data?.length) {
          resource = keysToCamel(response).data[0];
          setResource(resource)
          setTitle(resource.title || '');
          if (resource.body) {
            setBody(resource.body);
            const contentBlock = htmlToDraft(resource.body);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            // const editorState = EditorState.createWithContent(contentState);
            setEditorState(EditorState.createWithContent(contentState));
          }
          // setBody(resource.body || '');
          setUrl(resource.url || '');
          setType(resourceTypeOptions.find(item => item.value === resource.type));
        }
      }).catch((error) => {
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
        console.log(error);
      });
    }

    getAllProjectModules(projectId).then((response) => {
      if (response?.data) {
        setProjectModules(response.data.map((item) => {
         return { value: item.id, label: item.title };
        }));

        if (moduleId) {
          const projectMatchingModule = response.data.find(item => item.id === parseInt(moduleId));
          // console.log(projectMatchingModule);
          if (projectMatchingModule) {
            setSelectedModules([{
              label: projectMatchingModule.title,
              value: parseInt(moduleId)
            }]);
            setScope('modules');
          }
        }
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
    });

  }, [projectId, resourceId, moduleId]);

  useEffect(() => {
    if (resource?.modules && projectModules) {
      const moduleIds = resource.modules.split(',').map(item => parseInt(item));
      setSelectedModules(projectModules.filter(item => moduleIds.includes(item.value)));
      setScope(resource.modules.length ? 'modules' : 'all');
    }
  }, [resource, projectModules]);

  useEffect(() => {
// console.log('FOO', selectedModules);
// console.log(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''));
    setSelectedModulesStr(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''));
    // if (selectedModules.length) {
    //   setScope('modules');
    // } else {
    //   setScope('all');
    // }
  }, [selectedModules]);

  useEffect(() => {
    const _isTitleError = !title.trim().length;
    setTitleError(_isTitleError);

    const _isBodyError = !body.trim().length || body.trim()==='<p></p>'
    setBodyError(_isBodyError);

    const _isModulesError = scope === 'modules' && !selectedModulesStr?.length;
    // const _isModulesError = true;
    // alert(scope === 'modules' && !selectedModulesStr?.length);
    setModulesError(_isModulesError);

    const _doesFormHaveErrors = _isTitleError || _isBodyError || _isModulesError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [title, body, scope, selectedModulesStr]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);
    setPageErrorMessage(null);
    const errorMessage = 'Resource write failed. Please check your inputs and try again.';

    if (!doesFormHaveErrors) {
      if (resourceId) {
        editResource(projectId, resourceId, title, body, url, type.value, selectedModulesStr).then((response) => {
          if (response) {
            setPageErrorMessage(null);
            navigate(`/resource-detail/${projectId}/${resourceId}`);
          }
        }).catch((error) => {
          setPageErrorMessage(errorMessage);
          // logError(error);
        });
      } else {
        createResource(projectId, title, body, url, type.value, selectedModulesStr).then((response) => {
          if (response) {
            setPageErrorMessage(null);
            navigate('/project-detail/' + projectId);
          }
        }).catch((error) => {
          setPageErrorMessage(errorMessage);
          // logError(error);
        });
      }
    }
  };

  return (
    <div className="create-resource full-screen-page">
      <Helmet>
        <title>Create resource - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        {resourceId ? <h2>Edit resource</h2> : <h2>Add a resource</h2> }

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="create-resource-body">
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {!projectId || (role === 'owner' || role === 'administrator') ?
          <>
            <div className={`full-screen-page-body`}>
              <form onSubmit={submitForm} className={`${showFormErrors ? 'form-errors' : ''}`}>

                <div className={`form-group full-width ${isTitleError ? 'error-field' : ''}`}>
                  <Label for="resource-title">Resource title</Label>
                  <Input
                    id="resource-title"
                    name="resource-title"
                    placeholder="Enter a title for the resource"
                    type="text"
                    value={title}
                    autoComplete="off"
                    // disabled={!!resourceId}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                  {showFormErrors && isTitleError ?
                    <div className="form-field-error-message">
                      Enter a title
                    </div>
                   : null}
                </div>

                <div className={`form-group full-width body-field-container ${isBodyError ? 'error-field' : ''}`}>
                  <Label for="resource-description">Resource body</Label>

                  <div className="inline-radio-group">
                    <div className="styled-radio inline styled-radio-tab">
                      <label>
                        <Input type="radio" name="edit-mode" checked={!editHtml} onChange={() => {setEditHtml(false); }}
                          />
                        <span>Visual editor</span>
                      </label>
                    </div>
                    <div className="styled-radio inline styled-radio-tab">
                      <label>
                        <Input type="radio" name="edit-mode" checked={!!editHtml} onChange={() => {setEditHtml(true); }}
                          />
                        <span>HTML editor</span>
                      </label>
                    </div>
                  </div>

                  <div className="styled-radio-tab-body">
                    {editHtml ?
                      <Input
                        id="resource-body-html"
                        name="resource-body-html"
                        placeholder="Enter description"
                        type="textarea"
                        value={body}
                        autoComplete="off"
                        onChange={(e) => {
                          const html=e.target.value;
                          setBody(html);
                          const contentBlock = htmlToDraft(html);
                          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                          setEditorState(EditorState.createWithContent(contentState));
                        }}
                      />
                    :
                      <Editor
                        id="resource-description"
                        editorState={editorState}
                        wrapperClassName="resource-editor-wrapper"
                        editorClassName="resource-editor"
                        onEditorStateChange={onEditorStateChange}
                      />
                    }

                    <p className="styled-radio-tab-body-help-text">If you need to copy-paste code blocks, we recommend using <b>HTML mode</b>. Make sure you <b>escape your code</b> block (using <a href="https://codebeautify.org/html-escape-unescape" target="_new">a tool like this</a>), and enclose it in a <b>&lt;pre&gt;...&lt;/pre&gt;</b> block.</p>
                  </div>

                  {showFormErrors && isBodyError ?
                    <div className="form-field-error-message">
                      Enter resource body
                    </div>
                   : null}
                </div>

{/*                <div className={`form-group full-width ${isBodyError ? 'error-field' : ''}`}>
                  <Label for="resource-body">Body</Label>
                  <Input
                    id="resource-body"
                    name="resource-body"
                    placeholder="Enter a body"
                    type="textarea"
                    value={body}
                    autoComplete="off"
                    onChange={(e) => {
                      setBody(e.target.value);
                    }}
                  />
                  {showFormErrors && isBodyError ?
                    <div className="form-field-error-message">
                      Enter body
                    </div>
                   : null}
                </div>*/}

                <div className="form-group full-width inline-radio-field resource-options">
                  <Label>Resource scope</Label>
                  <div className="inline-radio-group">
                    <div className="styled-radio inline">
                      <label>
                        <Input type="radio" name="size" value={scope} checked={scope === 'all'} onChange={() => {
                          setScope('all');
                        }}/>
                        <span>Project-wide</span>
                      </label>
                    </div>
                    <div className="styled-radio inline">
                      <label>
                        <Input type="radio" name="size" disabled={!projectModules?.length} value={scope} checked={scope === 'modules'} onChange={() => { setScope('modules'); }}
                          />
                        <span>Specify modules</span>
                      </label>
                    </div>
                  </div>
                </div>
                {/*<pre>{JSON.stringify(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''))}</pre>*/}
                {/*<pre>{JSON.stringify(selectedModulesStr)}</pre>*/}
                
                {scope !== 'all' ?
                  <div className={`form-group full-width ${isBodyError ? 'error-field' : ''}`}>
                    <Label for="resource-modules">Modules</Label>
                    <MultiSelect
                      id="resource-modules"
                      name="resource-modules"
                      options={projectModules}
                      value={selectedModules}
                      onChange={setSelectedModules}
                      hasSelectAll={false}
                      labelledBy="Choose modules this resource applies to"
                    />
                    {showFormErrors && isModulesError ?
                      <div className="form-field-error-message">
                        Choose modules
                      </div>
                     : null}
                  </div>
                : null}

{/*                <div className={`form-group full-width`}>
                  <Label for="resource-title">Link URL (optional)</Label>
                  <Input
                    id="resource-url"
                    name="resource-url"
                    placeholder="https://www..."
                    type="url"
                    value={url}
                    autoComplete="off"
                    onChange={(e) => {
                      setUrl(e.target.value);
                    }}
                  />
                </div>

                <div className={`form-group full-width`}>
                  <Label for="resource-type">Resource type</Label>
                  <Select
                    name="resource-type"
                    id="resource-type"
                    options={resourceTypeOptions}
                    value={type}
                    onChange={setType}
                    isSearchable={false}
                    />
                </div>*/}
              </form>
            </div>

            <div className="create-project-actions form-footer">
              <div>
                <Button color="secondary" outline onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" onClick={submitForm}>
                  {resourceId ?
                    <span>Update resource</span>
                  :
                    <span>Add resource</span>
                  }
                </Button>
              </div>
            </div>
          </>
          :
            <div className="page-error-container">
              <PageError messageText="ou need to be an owner or administrator of this project to edit the project." />
            </div>
          }
      </div>

    </div>
  );
}