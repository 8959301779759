import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DOMPurify from 'dompurify';

import "./RichTextModal.scss";

function RichTextModal(props) {
  const {
    onClose,
    title,
    size,
    body
  } = props;

  const closeForm = () => {
    onClose();
  };

  return (
    <Modal
      className="rich-text-modal"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      size={size || "lg"}
      centered={true}>
      <ModalHeader>{title}</ModalHeader>
      <ModalBody>
        <div className="rich-text-modal-body">
          <div className="rich-text-modal-html-container" dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(body)}} />
        </div>
      </ModalBody>
      <ModalFooter className="single-action">
        <div className="primary-actions">
          <Button color="primary" onClick={closeForm}>
            <span>Close</span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default RichTextModal;
