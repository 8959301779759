export const userLocale = {
  country: {
    code: 'IN',
    name: 'India',
  },
  region: {
    name: 'Tamil Nadu'
  },
  language: 'en-US',
  currency: {
    code: 'â‚¹',
    name: 'Rupee'
  },
}

export const userDetail = {
  id: "user1",
  name: "John Doe",
  dob: "26-08-1983",
  email: "johndoe@johndoe.com",
  phone: "+919876543210",
  about: "Lorem ipsum dolor sit amet. Eum voluptatem sunt qui tempore maiores non galisum veritatis est aperiam quod.",
  joined: 1664541900,
  pic: process.env.PUBLIC_URL + '/icons/person.svg',
  skills: [
    { slug: 'react', name: 'React' },
    { slug: 'node', name: 'Node' },
  ],
  projects: [
    {
      id: 1,
      name: 'Redesigning a WordPress site',
      type: 'work',
    },
    {
      id: 5,
      name: 'Implement SSR for React app',
      type: 'work',
    },
    {
      id: 2,
      name: 'A dice rolling simulator',
      type: 'guided',
    },
  ]
};
