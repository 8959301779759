import React, { useState, useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import PageError from '../messages/PageError';
import { keysToCamel } from '../../util/caseConverters';

import ChooseProjectType from './ChooseProjectType';
import ProjectBasics from './ProjectBasics';
import DifficultySettings from './DifficultySettings';
import HelpCategories from './HelpCategories';
import VisibilitySettings from './VisibilitySettings';
import EarningsEnrollment from './EarningsEnrollment';
import Confirmation from './Confirmation';

import "./CreateProject.scss";


export default function CreateProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const { id } = useParams();
  const [project, setProject] = useState();
  const [role, setRole] = useState('');
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { createProject, editProject, checkExistingProject, getProject, getProjectRole, suggestPracticeProject, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  // const [currentStep, setCurrentStep] = useState('chooseProjectType');
  const [currentStep, setCurrentStep] = useState('projectBasics');

  const [projectTitle, setProjectTitle] = useState('');
  const [projectDescription, setProjectDescription] = useState('');
  const [projectShortSummary, setProjectShortSummary] = useState('');
  const [projectSkills, setProjectSkills] = useState('');
  const [projectSize, setProjectSize] = useState('');
  const [projectDifficulty, setProjectDifficulty] = useState('');
  const [isProjectPublic, setProjectPublic] = useState(true);
  const [isProjectOwnerVisible, setProjectOwnerVisible] = useState(true);
  const [isProjectInstantJoin, setProjectInstantJoin] = useState(true);
  const [helpCategories, setHelpCategories] = useState('');

  // Future features
  // Keep this fixed as standalone for now. Open this option in UI when we add this feature.
  // Edit: Only practice projects for now
  const [projectType, setProjectType] = useState('guided');
  // Keep this true for now. Open this in UI when we add this feature.
  const [isProjectAllowHelp, setProjectAllowHelp] = useState(true);
  // Keep this false for now. Open this in UI when we add this feature.
  const [isProjectEarningsEnrolled, setProjectEarningsEnrolled] = useState(false);

  useEffect(() => {
    setPageTitle('Start a project');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('create-project');
  }, []);

  useEffect(() => {
    if (id) {
      setPageTitle('Edit Project');
      setPageErrorMessage(null);
      let project;

      getProject(id).then((response) => {
        if (response?.data?.length) {
          project = keysToCamel(response).data[0];
          setProject(project);
          setCurrentStep('projectBasics');
          setProjectTitle(project.title || '');
          setProjectType(project.type || '');
          setProjectDescription(project.description || '');
          setProjectShortSummary(project.shortDescription || '');
          setProjectSkills(project.skills || '');
          setProjectSize(project.size || 0);
          setProjectDifficulty(project.difficulty || 0);
          setProjectPublic(!!project.isPublic);
          setProjectOwnerVisible(!!project.isOwnerVisible);
          setProjectAllowHelp(!!project.allowSeekHelp);
          setProjectInstantJoin(!!project.instantJoin);
          setProjectEarningsEnrolled(!!project.earningsEnrolled);
          setHelpCategories(project.helpCategories || '');

          const userId = User?.id;
          if (userId) {
// console.log(project);
            if (project?.ownerId === userId) {
              setRole('owner');
            } else {
              getProjectRole(userId, id).then((response) => {
                if (response?.data?.length) {
                  setRole(response.data[0].role);
                } else {
                  setRole('outsider');
                }
              }).catch((error) => {
                console.log(error);
                setRole('outsider');
              });        
            }
          } else {
            setRole('outsider');
          }
        }
      }).catch((error) => {
        setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        console.log(error);
        // logError(error);
      });
    }

    // setProject(getProject(id));
    // setProjectSolutions(getProjectSolutions(id));
    // setProjectResources(getProjectResources(id));
  }, [id]);

  return (
    <div className="create-project full-screen-page">
      <Helmet>
        <title>Suggest a project - Project Klub</title>
      </Helmet>

      <div className="full-screen-header">
        {id ?
          <h2>Edit project</h2>
        :
          <h2>Suggest a project</h2>
        }

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate('/projects/my-projects');
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="create-project-body">
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {!id || (role === 'owner' || role === 'administrator') ?
          <div>
            <ChooseProjectType
              isVisible={currentStep === 'chooseProjectType'}
              isEdit={!!id}
              projectType={projectType}
              onCancel ={() => {
                navigate(location.key === 'default' ? '/projects/' : -1);
              }}
              onNext ={(type) => {
                setProjectType(type);
                setCurrentStep('projectBasics');
                logView('create-project-basics');
              }} />

           <ProjectBasics
              isVisible={currentStep === 'projectBasics'}
              projectType={projectType}
              isEdit={!!id}
              projectTitle={projectTitle}
              projectShortSummary={projectShortSummary}
              projectDescription={projectDescription}
              // onCancel ={() => {
              //   navigate('/projects/my-projects');
              // }}
              onCancel ={() => {
                navigate('/projects/my-projects');
              }}
              onPrevious ={() => {
                setCurrentStep('chooseProjectType');
              }}
              onNext ={(title, description, shortSummary) => {
                setProjectTitle(title);
                setProjectDescription(description);
                setProjectShortSummary(shortSummary);

                if (projectType === 'guided' && !id && !User.is_superadmin) {
                  suggestPracticeProject(title, shortSummary, description).then((response) => {
                    if (response) {
                      setPageErrorMessage(null);
                      setCurrentStep('confirmation');
                      logView('create-project-confirmation');
                    }
                  }).catch((error) => {
                    setPageErrorMessage('An error occured. Please refresh your browser window and try again.');
                    setCurrentStep('projectBasics');
                  });
                } else {
                  if (!!id) {
                    setPageErrorMessage(null);
                    setCurrentStep('difficultySettings');
                    logView('create-project-difficulty-settings');
                  } else {
                    checkExistingProject(title).then((response) => {
                      setPageErrorMessage(null);
                      setCurrentStep('difficultySettings');
                      logView('create-project-difficulty-settings');
                    }).catch((response) => {
                      setPageErrorMessage('A project already exists with this title. Please enter a different title and try again.');
                    })
                  }
                }
              }} />

           <DifficultySettings
              isVisible={currentStep === 'difficultySettings'}
              projectSize={projectSize}
              projectSkills={projectSkills}
              projectDifficulty={projectDifficulty}
              onPrevious ={() => { 
                setCurrentStep('projectBasics');
                logView('create-project-basics');
              }}
              onNext ={(size, difficulty, skills) => { 
                setProjectSize(size);
                setProjectSkills(skills);
                setProjectDifficulty(difficulty)
                setCurrentStep('visibilitySettings');
                logView('create-project-visibility-settings');
              }} />

           <VisibilitySettings
              isVisible={currentStep === 'visibilitySettings'}
              isProjectPublic={isProjectPublic}
              isProjectOwnerVisible={isProjectOwnerVisible}
              isProjectAllowHelp={isProjectAllowHelp}
              isProjectInstantJoin={isProjectInstantJoin}
              onPrevious ={() => { 
                setCurrentStep('difficultySettings');
                logView('create-project-difficulty-settings');
              }}
              onNext ={(_isProjectPublic, _isProjectOwnerVisible, _isProjectAllowHelp, _isProjectInstantJoin) => { 
                setProjectPublic(_isProjectPublic);
                setProjectOwnerVisible(_isProjectOwnerVisible);
                setProjectAllowHelp(_isProjectAllowHelp);
                setProjectInstantJoin(_isProjectInstantJoin);
                setCurrentStep('helpCategories');
                logView('create-project-help-categories');
              }} />

           <HelpCategories
              isVisible={currentStep === 'helpCategories'}
              helpCategories={helpCategories}
              onPrevious ={() => { 
                setCurrentStep('visibilitySettings');
                logView('create-project-visibility-settings');
              }}
              onNext ={(_helpCategories) => { 
                setHelpCategories(_helpCategories)

                if (!!id) {
                  editProject(
                    id,
                    projectDescription,
                    projectShortSummary,
                    projectSkills,
                    projectSize,
                    projectDifficulty,
                    isProjectPublic,
                    isProjectOwnerVisible,
                    isProjectAllowHelp,
                    isProjectInstantJoin,
                    isProjectEarningsEnrolled,
                    _helpCategories
                    ).then((response) => {
                    // if (response?.data?.result?.affectedRows) {
                    if (response) {
                      setPageErrorMessage(null);
                      setCurrentStep('confirmation');
                      logView('create-project-confirmation');
                    }
                  }).catch((error) => {
                    // logError(error);
                    setPageErrorMessage('Project write failed. Please check your inputs and try again.');
                    setCurrentStep('projectBasics');
                  });
                } else {
                  createProject(
                    projectType,
                    projectTitle,
                    projectDescription,
                    projectShortSummary,
                    projectSkills,
                    projectSize,
                    projectDifficulty,
                    isProjectPublic,
                    isProjectOwnerVisible,
                    isProjectAllowHelp,
                    isProjectInstantJoin,
                    isProjectEarningsEnrolled,
                    _helpCategories
                    ).then((response) => {
                    // if (response?.data?.result?.affectedRows) {
                    if (response) {
                      setPageErrorMessage(null);
                      setCurrentStep('confirmation');
                      logView('create-project-confirmation');
                    }
                  }).catch((error) => {
                    // logError(error);
                    setPageErrorMessage('Project write failed. Please check your inputs and try again.');
                    setCurrentStep('projectBasics');
                  });
                }
              }} />

           {/* Commenting this out for now. Add back later when we add this to feature set.*/}
           {/*<EarningsEnrollment
              isVisible={currentStep === 'earningsEnrollment'}
              isProjectEarningsEnrolled={isProjectEarningsEnrolled}
              onPrevious ={() => { 
                setCurrentStep('visibilitySettings');
              }}
              onNext ={(_isProjectEarningsEnrolled) => { 
                setProjectEarningsEnrolled(_isProjectEarningsEnrolled);
    // console.log(projectType, projectTitle, projectDescription, projectShortSummary, projectSkills, projectSize, projectDifficulty, isProjectPublic, isProjectOwnerVisible, isProjectAllowHelp, isProjectInstantJoin, _isProjectEarningsEnrolled);
                if (!!id) {
                  editProject(
                    id,
                    projectDescription,
                    projectShortSummary,
                    projectSkills,
                    projectSize,
                    projectDifficulty,
                    isProjectPublic,
                    isProjectOwnerVisible,
                    isProjectAllowHelp,
                    isProjectInstantJoin,
                    _isProjectEarningsEnrolled
                    ).then((response) => {
                    // if (response?.data?.result?.affectedRows) {
                    if (response) {
                      setPageErrorMessage(null);
                      setCurrentStep('confirmation');
                    }
                  }).catch((response) => {
                    setPageErrorMessage('createProjectFailed');
                    setCurrentStep('projectBasics');
                  });
                } else {
                  createProject(
                    projectType,
                    projectTitle,
                    projectDescription,
                    projectShortSummary,
                    projectSkills,
                    projectSize,
                    projectDifficulty,
                    isProjectPublic,
                    isProjectOwnerVisible,
                    isProjectAllowHelp,
                    isProjectInstantJoin,
                    _isProjectEarningsEnrolled
                    ).then((response) => {
                    // if (response?.data?.result?.affectedRows) {
                    if (response) {
                      setPageErrorMessage(null);
                      setCurrentStep('confirmation');
                    }
                  }).catch((response) => {
                    setPageErrorMessage('createProjectFailed');
                    setCurrentStep('projectBasics');
                  });
                }
              }} />*/}

            <Confirmation
              isVisible={currentStep === 'confirmation'}
              projectType={projectType}
              isEdit={!!id}
              onFinish={() => {
                if (id) {
                  navigate(`/project-detail/${id}`);
                } else {
                  navigate('/projects/my-projects');
                }
              }} />
          </div>
          :
            <div className="page-error-container">
              <PageError messageText="You need to be an owner or administrator of this project to edit the project." />
            </div>
          }
      </div>

    </div>
  );
}