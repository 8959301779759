import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";
import EditSkills from '../common/EditSkills';
import "./DifficultySettings.scss";

export default function DifficultySettings(props) {
  const [size, setSize] = useState('tiny');
  const [difficulty, setDifficulty] = useState('');
  const [skills, setSkills] = useState('');

  const [isVisible, setIsVisible] = useState(false);
  const [isSizeError, setSizeError] = useState(false);
  // const [isDifficultyError, setDifficultyError] = useState(false);
  const [isSkillsError, setSkillsError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const {
    onPrevious,
    onNext,
  } = props;

  useEffect(() => {
    setSize(props.projectSize);
    // setDifficulty(props.projectDifficulty)
    setIsVisible(props.isVisible);
    setSkills(props.projectSkills);
  }, [props.projectSize, props.projectDifficulty, props.isVisible, props.projectSkills,]);

  useEffect(() => {
    const _isSizeError = !size;
    setSizeError(_isSizeError);

    // const _isDiffiicultyError = !difficulty;
    // setDifficultyError(_isDiffiicultyError);
    // const _doesFormHaveErrors = _isSizeError || _isDiffiicultyError;

    const _isSkillsError = !skills.trim().length;
    setSkillsError(_isSkillsError);

    const _doesFormHaveErrors = _isSizeError || _isSkillsError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [size, difficulty, skills]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(size, difficulty, skills);
    }
  };

  return (
    <div className={`difficulty-settings ${isVisible ? 'visible' : 'hidden'}`}>
      <div className={`full-screen-page-body`}>
        
        <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>

          <div className={`form-group full-width skills-section ${isSkillsError ? 'error-field' : ''}`}>
            <h3 className="full-screen-sub-header">Skills this project needs</h3>
            <EditSkills
              skills={skills}
              onUpdate={(updatedSkills) => { setSkills(updatedSkills); }}
              />
            {showFormErrors && isSkillsError ?
              <div className="form-field-error-message">
                Enter at least one skill this project needs
              </div>
             : null}
          </div>

          <div className={`project-size-section form-group full-width inline-radio-field ${isSizeError ? 'error-field' : ''}`}>
            {/*<span className="radio-title">Project size</span>*/}
            <h3 className="full-screen-sub-header">Project size</h3>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="size" value={size} checked={size === 'tiny'} onChange={() => { setSize('tiny'); }}
                    />
                  <span>Tiny</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="size" value={size} checked={size === 'small'} onChange={() => { setSize('small'); }}
                    />
                  <span>Small</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="size" value={size} checked={size === 'medium'} onChange={() => { setSize('medium'); }}
                    />
                  <span>Medium</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="size" value={size} checked={size === 'large'} onChange={() => { setSize('large'); }}
                    />
                  <span>Large</span>
                </label>
              </div>
            </div>

            {showFormErrors && isSizeError ?
              <div className="form-field-error-message">
                Choose a project size
              </div>
             : null}
          </div>

{/*          <div className={`form-group full-width inline-radio-field ${isDifficultyError ? 'error-field' : ''}`}>
            <span className="radio-title">Project difficulty</span>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="difficulty" value={difficulty} checked={difficulty === 'easy'} onChange={() => { setDifficulty('easy'); }}
                    />
                  <span>Easy</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="difficulty" value={difficulty} checked={difficulty === 'medium'} onChange={() => { setDifficulty('medium'); }}
                    />
                  <span>Medium</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio" name="difficulty" value={difficulty} checked={difficulty === 'difficult'} onChange={() => { setDifficulty('difficult'); }}
                    />
                  <span>Difficult</span>
                </label>
              </div>
            </div>
            {showFormErrors && isDifficultyError ?
              <div className="form-field-error-message">
                Choose a project difficulty
              </div>
             : null}
          </div>*/}
        </form>
      </div>

      <div className="create-project-actions form-footer">
        <div>
          <Button color="secondary" outline onClick={() => { onPrevious() }}>
            Previous
          </Button>
   
          <Button color="primary" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}