import React, { useState, useEffect, useContext }  from 'react';
// import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PageError from '../messages/PageError';
import { DataContext } from '../../contexts/data-context';

import SkillsDisplay from "../cards/SkillsDisplay";
import ProfileProjectCard from '../cards/ProfileProjectCard';

import { toUTCDate } from '../../util/dateTime';

import "./UserProfileModal.scss";

function UserProfileModal(props) {
  // const navigate = useNavigate();
  const { getUserBasics, getUserPublicProjects, getUserSolutions, logView, getAllClaps } = useContext(DataContext);
  const [userBasics, setUserBasics] = useState();
  const [userProjects, setUserProjects] = useState();
  const [userSolutions, setUserSolutions] = useState();
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [receivedClaps, setReceivedClaps] = React.useState([]);
  const [givenClaps, setGivenClaps] = React.useState([]);

  const {
    userId,
    onClose,
  } = props;

  useEffect(() => {
    setPageErrorMessage(null);
    logView('user-profile');
    getData();
  }, [userId]);

  function getData() {
    setPageErrorMessage(null);

    getUserBasics(userId).then((response) => {
      if (response?.data?.length) {
        setUserBasics(response.data[0]);
      } else {
        setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      console.log(error);
    });

    getUserPublicProjects(userId).then((response) => {
      if (response?.data) {
        setUserProjects(response.data);
      } else {
        setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      console.log(error);
    });

    getUserSolutions(userId).then((response) => {
      if (response?.data) {
        setUserSolutions(response.data);
      } else {
        setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred. Please refresh the browser window and try again.');
      console.log(error);
    });

    getAllClaps().then((response) => {
      setReceivedClaps(response.data.filter(item => item.clappee === userId));
      setGivenClaps(response.data.filter(item => item.clapper === userId));
    }).catch((error) => {
      console.log(error);
      // logError(error);
      // No-op, silent fail
    });
  }

  return (
      <Modal
        className="user-profile-modal"
        isOpen={true}
        toggle={onClose}
        backdrop="static"
        centered={true}>
        <ModalHeader>
          <span>User profile summary</span>
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              onClose();
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>
        </ModalHeader>
        <ModalBody>
          <div className="modal-content-body">

            {pageErrorMessage ? 
              <div className="page-error-container">
                <PageError messageText={pageErrorMessage} />
              </div>
            : null}

            {userBasics?.name ?
              <>
                <div className="user-profile-modal-basics">
                  <div className="user-profile-name">
                    <div className="user-profile-icon">
                      {userBasics?.premium_till && new Date(Date.parse(userBasics.premium_till)) > toUTCDate(new Date()) ?
                        <>
                          <img src={process.env.PUBLIC_URL + '/icons/person-purple.svg'} alt="Premium user" />
                          <div className="premium-container">
                            <span className="project-price premium"><span>Premium</span></span>
                          </div>
                        </>
                      :
                        <>
                          <img src={process.env.PUBLIC_URL + '/icons/person-light-gray.svg'} alt="User" />
                        </>
                      }
                    </div>

                    <div>
                      <p className="username">{userBasics.username}</p>
                      <p className="user-full-name">{userBasics.name}</p>
                      <SkillsDisplay skills={userBasics.skills} />
                    </div>
                  </div>

                  {userBasics.about?.length ?
                    <p className="user-profile-about">
                      <span>{userBasics.username}&apos;s self-intro: </span>  &quot;{userBasics.about}&quot;
                    </p>
                  : null}
                </div>

                {receivedClaps?.length || givenClaps?.length ?
                  <div className="user-profile-likes">
                    <span>
                      Likes:
                    </span>
                    <div>
                      Received:&nbsp;
                      <span className="likes-count">{receivedClaps.length}</span>
                    </div>

                    <div>
                      Given:&nbsp;
                      <span className="likes-count">{givenClaps.length}</span>
                    </div>
                  </div>
                : null}

                {userProjects?.length && userSolutions ?
                  <div className="user-profile-projects">
                    <h2>{userBasics.username}&apos;s projects</h2>

                    <div className="user-projects-list">
                      {userProjects.map((item, idx) => {
                        return <ProfileProjectCard
                          project={item}
                          key={idx}
                          solutionStatus={userSolutions.find(solution => solution.project_id === item.id)?.status}
                          />
                      })}
                    </div>

                  </div>
                : null}

                {userProjects && !userProjects.length ?
                  <div className="user-profile-projects-empty-state">
                    <img src={process.env.PUBLIC_URL + '/vector/projects-empty-state.png'} alt="No projects joined" />
                    <p>This user has not joined any projects yet.</p>
                  </div>
                : null}
              </>
            : null}

          </div>
        </ModalBody>
        <ModalFooter className="single-action">
          <div className="primary-actions">
            <Button color="primary" onClick={() => {onClose();}}  type="button">
              Close
            </Button>
          </div>
        </ModalFooter>
      </Modal>
  );
}

export default UserProfileModal;
