import React, { useState, useEffect, useContext }  from 'react';
import { Button, Input } from 'reactstrap';

import TermsContent from '../legal/TermsContent';
import PrivacyPolicyContent from '../legal/PrivacyPolicyContent';

import { DataContext } from '../../contexts/data-context';

import "./TermsAndPrivacy.scss";

export default function TermsAndPrivacy(props) {
  const { logView } = useContext(DataContext);
  const [isChecked, setIsChecked] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    onBack,
    onNext,
  } = props;

  useEffect(() => {
    setIsVisible(props.isVisible)
  }, [props.isVisible]);

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-terms-policy');
    }
  }, [isVisible]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (isChecked) {
      onNext();
    }
  };

  return (
    <div className={`onboarding-modal-terms-and-privacy ${isVisible ? 'visible' : 'hidden'}`}>
      <form className={`${showFormErrors ? 'form-errors' : ''}`}>

        <div className="onboarding-terms-policy-content">
          <div className="onboarding-terms-policy-content-subsection">
            <TermsContent />
          </div>

          <div className="onboarding-terms-policy-content-subsection">
            <PrivacyPolicyContent />
          </div>
        </div>

        <div className={`modal-content-body ${!isChecked ? 'error-field' : ''}`}>
          <div className="form-group full-width onboarding-tnc-check">
            <div className="styled-radio">
              <label>
                <Input type="checkbox"
                  checked={isChecked}
                  onChange={(e) => {
                    setIsChecked(e.target.checked);
                  }} />
                
                <span className="onboarding-tnc-check-label">I agree to the terms, conditions and privacy policy</span>

              </label>

              {showFormErrors && !isChecked ?
                <div className="form-field-error-message">
                  Please agree to the terms, conditions and privacy policy to proceed
                </div>
              : null}

            </div>

          </div>
        </div>

        <div className="modal-actions-footer two-actions">
          <Button color="secondary" outline  size="sm" onClick={onBack}>
            Back
          </Button>

          <Button type="submit" color="primary"  size="sm" onClick={submitForm}>
            Finish
          </Button>
        </div>
      </form>
    </div>
  );
}