import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import Select from 'react-select'

import { countryOptions } from '../../constants/countries';
import { isUsername } from '../../util/validators';

import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
// import { useNavigate } from "react-router-dom";


import "./Basics.scss";

export default function Basics(props) {
  const { logView } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [username, setUsername] = useState('');
  const [country, setCountry] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isUsernameError, setUsernameError] = useState(false);
  const [isCountryError, setCountryError] = useState(false);

  const {
    onLogin,
    onNext,
  } = props;

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-basics');
    }
  }, [isVisible]);

  useEffect(() => {
    setUsername(props.username);
    setIsVisible(props.isVisible);
  }, [props.username, props.country, props.isVisible]);

  useEffect(() => {
    const _isUsernameError = !isUsername(username);
    const _isCountryError = !country && !country?.value;
    setUsernameError(_isUsernameError);
    setCountryError(_isCountryError);

    const _doesFormHaveErrors = _isUsernameError || _isCountryError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [username, country]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(username, country.value)
    }
  };

  return (
    <div className={`onboarding-modal-signup ${isVisible ? 'visible' : 'hidden'}`}>
      <form className={`${showFormErrors ? 'form-errors' : ''}`}>
        <div className="modal-content-body signup-form-container">

          {User?.name ?
            <div className="signup-welcome-intro">
              <h3 className="signup-welcome-header">Welcome onboard, {User.name}!</h3>
              <p>We need a few more details to finish setting up your profile.</p>
            </div>
          : null}

          <div className={`form-group full-width ${isUsernameError ? 'error-field' : ''}`}>
            <Label for="signup-username">Username</Label>
            <Input
              id="signup-username"
              name="signup-username"
              placeholder="Letters, numbers, special characters"
              type="text"
              value={username}
              autoComplete="on"
              onChange={(e) => { setUsername(e.target.value);}}
            />
            {showFormErrors && isUsernameError ?
              <div className="form-field-error-message">
                Enter a username
              </div>
             : null}
          </div>

          <div className={`form-group full-width ${isCountryError ? 'error-field' : ''}`}>
            <Label for="setup-profile-country">Country</Label>
            <Select
              name="setup-profile-country"
              id="setup-profile-country"
              className="setup-profile-country"
              options={countryOptions}
              value={country}
              onChange={(selected) => { setCountry(selected) }}
              isSearchable={true}
              />
            {showFormErrors && isCountryError ?
              <div className="form-field-error-message">
                Select your country
              </div>
             : null}
          </div>

        </div>
        <div className="modal-actions-footer modal-footer single-action">
          <Button type="submit" className="email-password-button" color="primary" size="sm" onClick={submitForm}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
}