import React from "react";
import { HelmetProvider } from 'react-helmet-async';

import 'bootstrap/dist/css/bootstrap.min.css'
import "react-toggle/style.css"
import './App.scss';
import './stylesheets/Modal.css';
import './stylesheets/Form.scss';
import './stylesheets/ActionBar.scss';
import './stylesheets/StyledRadio.scss';
import './stylesheets/TabbedNav.css';
import './stylesheets/RadioFieldset.css';
import './stylesheets/FullScreenHeader.css';
import './stylesheets/Spinner.css';
import './stylesheets/Carousel.css';
import './stylesheets/Likes.css';
import './stylesheets/Comments.css';
import './stylesheets/Dropdown.scss';
import './stylesheets/Descriptions.scss';
import './stylesheets/Shared.scss';

import { PageProvider } from './contexts/page-context';
import { UserProvider } from './contexts/user-context';
import { DataProvider } from './contexts/data-context';

import MainRoute from './MainRoute';

function App() {
  return (
    <HelmetProvider>
      <PageProvider>
        <DataProvider>
          <UserProvider>
            <MainRoute />
          </UserProvider>
        </DataProvider>
      </PageProvider>
    </HelmetProvider>
  );
}

export default App;
