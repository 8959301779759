import React, { useEffect, useContext } from 'react';
import { Outlet } from 'react-router-dom';
import { Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import TabLink from '../common/TabLink';
import { PageContext } from '../../contexts/page-context';
import Footer from '../nav/Footer';
import "./Projects.scss";

export default function Projects() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Projects');
    setTopNavEnabled(true);
    setBottomNavEnabled(false);
  }, []);

  return (
    <div className="projects">
      <Helmet>
        <title>Projects - Project Klub</title>
      </Helmet>
{/*      <Button color="primary" outline size="sm" className="projects-start-new" onClick={(e) => {
          e.preventDefault();
          navigate('/create-project');
        }}>
        <img src={process.env.PUBLIC_URL + '/icons/plus.png'} alt="Add document" />
        <span className="widescreen-only">
          <span>New project</span>
        </span>
        <span className="smallscreen-only">New</span>
      </Button>*/}


      <nav className="projects-tab-links">
        <TabLink linkTo="my-projects" route="/projects/my-projects" linkText="My projects" />
        <TabLink linkTo="available-projects" indexRoute="/projects" route="/projects/available-projects" linkText="Available projects" />
      </nav>


      <div className="projects-page-content">
        <Outlet />
      </div>

      <Footer />
    </div>
  )
}