import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { UserContext } from '../../contexts/user-context';
import { DataContext } from '../../contexts/data-context';
import "./EditHelpRequest.scss";

function EditHelpRequest(props) {
  const { User } = useContext(UserContext);
  const { logView } = useContext(DataContext);
  const [helpRequestText, setHelpRequestText] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [isContactCommentsPreferred, setContactCommentsPreferred] = useState(false);
  const [isContactEmailPreferred, setContactEmailPreferred] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isDescriptionError, setDescriptionError] = useState(false);
  const [isOptionsError, setOptionsError] = useState(false);
  const [isEmailError, setEmailError] = useState(false);
  const [showIntro, setShowIntro] = useState(false);

  const {
    onClose,
    onSave,
    onRemove
  } = props;

  useEffect(() => {
    setHelpRequestText(props.text);
    setContactEmail(props.contactEmail || User?.email);
    setContactEmailPreferred(props.isContactEmailPreferred);
    setContactCommentsPreferred(props.isContactCommentsPreferred);
    setShowIntro(!props.text);
  }, [props.text, props.isContactEmailPreferred, props.isContactCommentsPreferred, props.contactEmail]);

  useEffect(() => {
    logView('edit-help-request');
  }, []);

  useEffect(() => {
    const _isDescriptionError = !helpRequestText.trim().length || helpRequestText.trim()==='<p></p>';
    setDescriptionError(_isDescriptionError);

    const _isEmailError = !!(isContactEmailPreferred && !contactEmail.trim());
    setEmailError(_isEmailError);

    const _isOptionsError = !isContactEmailPreferred && !isContactCommentsPreferred;
    setOptionsError(_isOptionsError);    

    const _doesFormHaveErrors = _isDescriptionError || _isOptionsError || _isEmailError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [helpRequestText, contactEmail, isContactEmailPreferred, isContactCommentsPreferred]);

  const closeForm = () => {
    onClose();
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onSave(helpRequestText, contactEmail, isContactEmailPreferred, isContactCommentsPreferred);
    }
  };

  return (
    <Modal
      className="edit-help-request"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      centered={true}>
      <ModalHeader>
        <span>Project help request</span>
      </ModalHeader>
      <ModalBody>
        <h3>Get help with your project</h3>

        {showIntro ?
          <div className="help-request-intro">
            <div className="illustration-container">
              <img src={process.env.PUBLIC_URL + '/vector/get-help.png'} alt="Get help" />
            </div>
            <p>Post a help request that other users can see and respond to.</p>
            <ul>
              <li>Describe what kind of help you need</li>
              <li>Choose how other users can contact you to offer help / feedback</li>
            </ul>
            <p>Build, learn, and grow together!</p>
          </div>
        :
          <form onSubmit={handleSubmit} className={`help-request-form ${showFormErrors ? 'form-errors' : ''}`}>
            <div className={`form-group full-width ${isDescriptionError ? 'error-field' : ''}`}>
              <Label for="help-request-description">Describe what kind of help you need</Label>
              <Input
                id="help-request-description"
                name="help-request-description"
                className="help-request-description-input"
                placeholder="Example: design, architecture, feedback, code, debugging, project buddies, etc."
                type="textarea"
                value={helpRequestText}
                autoComplete="off"
                onChange={(e) => {
                  setHelpRequestText(e.target.value);
                }}
              />
              {showFormErrors && isDescriptionError ?
                <div className="form-field-error-message">
                  Enter a description detailing what kind of help you need with this project.
                </div>
               : null}
            </div>

            <div className={`form-group full-width user-contact-section inline-radio-field ${isOptionsError ? 'error-field' : ''}`}>
              <Label>How can users contact you to offer help?</Label>

              <div className="help-contact-method">
                <div className="styled-radio">
                  <label>
                    <Input type="checkbox"
                      name="help-request-comments-preferred"
                      checked={isContactCommentsPreferred}
                      onChange={(e) => {
                        setContactCommentsPreferred(e.target.checked);
                      }} />
                    <span>Add a comment in my solution comments or the project comments</span>
                  </label>
                </div>
              </div>

              <div className="help-contact-method">
                <div className="styled-radio">
                  <label>
                    <Input type="checkbox"
                      name="help-request-email-preferred"
                      checked={isContactEmailPreferred}
                      onChange={(e) => {
                        setContactEmailPreferred(e.target.checked);
                      }} />
                    <span>Send me an email</span>
                  </label>
                </div>
              </div>

              {showFormErrors && isOptionsError ?
                <div className="form-field-error-message">
                  Choose at least one contact method
                </div>
               : null}

            </div>

            {isContactEmailPreferred ?
              <div className={`email-field-container form-group full-width ${isEmailError ? 'error-field' : ''}`}>
                <Label for="contact-email">Contact email</Label>
                <span className="contact-email-help-text">(other users can see this)</span>
                <Input
                  id="contact-email"
                  name="contact-email"
                  className="contact-email-input"
                  placeholder="Enter the email address other users can email you at"
                  type="email"
                  value={contactEmail}
                  autoComplete="off"
                  onChange={(e) => {
                    setContactEmail(e.target.value);
                  }}
                />
                {showFormErrors && isEmailError ?
                  <div className="form-field-error-message">
                    Enter an email address
                  </div>
                 : null}
              </div>
            : null}
          </form>
        }
      </ModalBody>
      <ModalFooter>
        <div className="secondary-actions">
          <Button color="secondary" outline={true} onClick={closeForm}>
            Cancel
          </Button>
        </div>

        <div className="primary-actions">
          {helpRequestText ?
            <Button color="none" outline={true} className="delete-button" outline onClick={() => { onRemove(); }}>
              Delete
            </Button>
          : null}

          {showIntro ?
            <Button color="primary"
              type="Button"
              onClick={() => { setShowIntro(false) }}>
              Get started
            </Button>
          :
            <Button color="primary"
              type="submit"
              disabled={showFormErrors && doesFormHaveErrors}
              onClick={handleSubmit}>
              Save
            </Button>
          }
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default EditHelpRequest;
