import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./Sessions.styles.css";

export default function Sessions() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { sessions } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Sessions');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
  }, []);

  return (
    <div className="sessions page full-screen-page">

      {location.key !== 'default' ?
        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(-1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
          <span>Back</span>
        </Link>
      : null}

      <div className="full-screen-header">
        <h2>Sessions</h2>

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate('/projects/my-projects');
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body session-lists">
        <div className="mentorships">
          <h3>Mentorship sessions</h3>
          <p>Lorem ipsum dolor sit amet. Non incidunt voluptatem id recusandae repudiandae.</p>

          {sessions.filter(item => item.type === 'mentorship').map((item, idx) => {
            return (
              <div key={idx} className={`session-card-container ${item.isPaid ? "paid" : ""}`}>
                <div className="session-card" role="button" onClick={(e) => {
                  e.preventDefault();
                  navigate('/session-detail/' + item.id);
                }}>
                  <div className="session-card-thumbnail-section">
                    <img src={process.env.PUBLIC_URL + '/icons/session-' + item.type + '.png'} alt={item.name} className="session-thumbnail" />
                    <span className="session-type">{item.type}</span>
                  </div>

                  <div className="session-card-body">
                    <span className="session-name dark-blue-link">{item.name}</span>
                    <div className="session-card-body-metadata">
                      <span className="session-host">
                        with

                        <Link
                          to={`/user-profile/${item.hostUserId}`}
                        >
                          <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Host" />
                          <span className="dark-blue-link">{item.host}</span>
                        </Link>
                      </span>

                      {item.project ?
                        <div className="session-price tag primary-orange">
                          See project
                        </div>
                      :
                        <div className="session-price">
                          {item.price ?
                            <span>
                              {item.isPaid ?
                                <div>
                                  <span>{item.priceInCurrency} </span>
                                  {/*<span className="tag primary-green">PAID</span>*/}
                                  <img className="paid" src={process.env.PUBLIC_URL + '/vector/paid.png'} alt="paid" />
                                </div>
                              :
                                <span className="tag primary-green">{item.priceInCurrency} to join</span>
                              }
                            </span>
                            :
                              <div className="tag primary-blue">
                                FREE to join
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="session-card-metadata">
                    {item?.timeString ?
                      <div className="session-datetime">
                        <img src={process.env.PUBLIC_URL + '/icons/time-icon.svg'} alt="Date & time" />
                        <span>{item.timeString}</span>
                      </div>
                    : null }
                    {item?.durationString ?
                      <div className="session-duration">
                        <img src={process.env.PUBLIC_URL + '/icons/duration-icon.svg'} alt="Duration" />
                        <span>{item.durationString}</span>
                      </div>
                    : null }
                  </div>
                </div>
              );
            })}

          <div className="mentorship-button-container">
            <Button color="primary" className="mentorship-request-button" onClick={(e) => {
              e.preventDefault();
              navigate('/create-mentorship');
            }}>
              <img src={process.env.PUBLIC_URL + '/icons/mentorship.png'} alt="Mentorship" />
              Schedule a mentorship session
            </Button>
          </div>
        </div>

        <div className="other-sessions">
          <h3>Open sessions</h3>

          {sessions.filter(item => item.type !== 'mentorship').map((item, idx) => {
            return (
              <div key={idx} className={`session-card-container ${item.isPaid ? "paid" : ""}`}>
                <div className="session-card" onClick={(e) => {
                  e.preventDefault();
                  navigate('/session-detail/' + item.id);
                }}>
                  <div className="session-card-thumbnail-section">
                    <img src={process.env.PUBLIC_URL + '/icons/session-' + item.type + '.png'} alt={item.name} className="session-thumbnail" />
                    <span className="session-type">{item.type}</span>
                  </div>

                  <div className="session-card-body">
                    <span className="session-name dark-blue-link">{item.name}</span>
                    <div className="session-card-body-metadata">
                      <span className="session-host">
                        with
                        <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Host" />
                        <span className="dark-blue-link">{item.host}</span>
                      </span>

                      {item.project ?
                        <div className="session-price tag primary-orange">
                          See project
                        </div>
                      :
                        <div className="session-price">
                          {item.price ?
                            <span>
                              {item.isPaid ?
                                <div>
                                  <span>{item.priceInCurrency} </span>
                                  {/*<span className="tag primary-green">PAID</span>*/}
                                  <img className="paid" src={process.env.PUBLIC_URL + '/vector/paid.png'} alt="paid" />
                                </div>
                              :
                                <span className="tag primary-green">{item.priceInCurrency} to join</span>
                              }
                            </span>
                            :
                              <div className="tag primary-blue">
                                FREE to join
                              </div>
                            }
                          </div>
                        }
                      </div>
                    </div>
                  </div>

                  <div className="session-card-metadata">
                    {item?.timeString ?
                      <div className="session-datetime">
                        <img src={process.env.PUBLIC_URL + '/icons/time-icon.svg'} alt="Date & time" />
                        <span>{item.timeString}</span>
                      </div>
                    : null }
                    {item?.durationString ?
                      <div className="session-duration">
                        <img src={process.env.PUBLIC_URL + '/icons/duration-icon.svg'} alt="Duration" />
                        <span>{item.durationString}</span>
                      </div>
                    : null }
                  </div>

                  {item?.project ?
                    <p className="session-card-metadata session-card-project">
                      This session is a part of
                      <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + item.project?.type + '.png'} alt={item.project.name} className="project-thumbnail" />
                      <span className="dark-blue-link">{item.project.name}</span>
                      .
                    </p>
                  : null }
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}