export const projectResources = [
  {
    set: "Requirements",
    documents: [
      {
        title: "Requirement overview",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem."
      },
      {
        title: "Wireframes",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
      {
        title: "Accessibility",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
    ],
  },
  {
    set: "Setup notes",
    documents: [
      {
        title: "Tech stack",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
      {
        title: "Environment setuip",
        linkUrl: "https://www.something.com/",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
    ],
  },
];
