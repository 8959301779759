// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/icons/discussions.svg", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("/public/icons/discussions-red.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".comments-count {\n  color: #555;\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.comments-count.has-unread {\n  color: #E34234;\n}\n\n.comments-count:before {\n  content: '';\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 11px auto;\n  background-repeat: no-repeat;\n  background-position: center;\n  margin-right: 3px;\n  height: 10px;\n  width: 11px;\n  display: inline-block;\n  transform: translateY(1px);\n}\n\n.comments-count.has-unread:before {\n  color: #E34234;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ");\n}\n", "",{"version":3,"sources":["webpack://./src/stylesheets/Comments.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,eAAe;EACf,iBAAiB;AACnB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,WAAW;EACX,yDAAsD;EACtD,0BAA0B;EAC1B,4BAA4B;EAC5B,2BAA2B;EAC3B,iBAAiB;EACjB,YAAY;EACZ,WAAW;EACX,qBAAqB;EACrB,0BAA0B;AAC5B;;AAEA;EACE,cAAc;EACd,yDAA0D;AAC5D","sourcesContent":[".comments-count {\n  color: #555;\n  font-size: 12px;\n  font-weight: bold;\n}\n\n.comments-count.has-unread {\n  color: #E34234;\n}\n\n.comments-count:before {\n  content: '';\n  background-image: url('/public/icons/discussions.svg');\n  background-size: 11px auto;\n  background-repeat: no-repeat;\n  background-position: center;\n  margin-right: 3px;\n  height: 10px;\n  width: 11px;\n  display: inline-block;\n  transform: translateY(1px);\n}\n\n.comments-count.has-unread:before {\n  color: #E34234;\n  background-image: url('/public/icons/discussions-red.svg');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
