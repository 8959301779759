import React from 'react';
import { useNavigate } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ProjectCard.styles.css";
import SkillsDisplay from "./SkillsDisplay";

const typeDisplay = {
  standalone: 'Standalone',
  guided: 'Practice',
};

function ProjectCard(props) {
  const navigate = useNavigate();
  const {
    project,
    showPointsToJoin
  } = props;

  return (
    <div className={`project-card-container ${project?.type?.toLowerCase()} ${project?.status?.toLowerCase()}`}>
      {project.status === 'draft' || project.status==='pending' ?
        <span className="project-name-floating-status-display">{project.status.toUpperCase()}</span>
      : null}

      <div className="project-card" role="button" onClick={(e) => {
          e.preventDefault();
          navigate('/project-detail/' + project.id);
        }}>

        <div className="project-card-main">
          <div className="project-card-thumbnail-section">
            <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="project-thumbnail" />
            <span className={"project-type " + project?.type}>{typeDisplay[project.type]}</span>

            {!project.isPublic ?
              <div className="project-card-private">
                <img src={process.env.PUBLIC_URL + '/icons/lock.svg'} alt="Private" />
                <span>Private</span>
              </div>
            : null}

  {/*          {showPointsToJoin ?
              <div className="project-card-points-to-join">
                <img src={process.env.PUBLIC_URL + '/icons/coin.png'} alt="Points to join" />
                <span>{project.pointsToJoin || 'FREE'} points</span>
              </div>
            : null}*/}
          </div>

          <div className="project-card-body">
            <span className="project-name dark-gray-link">{project.title}</span>

            {project.size ?
              <span className="project-card-size">
                <img src={process.env.PUBLIC_URL + '/icons/size-icon.png'} alt="Size" />
                <span>{project.size}</span>
              </span>
            : null}

            {project.skills ?
              <div className="project-card-skills">
                <SkillsDisplay skills={project.skills} />
              </div>
            : null}
          </div>
        </div>

        { (project?.numSolutions > 0 || project?.numModules > 0 || project?.numResources || project?.numMessages > 0) ?
          <div className="project-card-metadata-container">
            {/*<span className="project-participants project-card-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Users" />
              <span>{project.numParticipants || 0} 
                {project.numParticipants === 1 ? <span>participant</span> : <span>participants</span>}
              </span>
            </span>*/}

            {project.numSolutions && project.numSolutions > 0 ?
              <span className="project-participants project-card-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Participants" />
                <span>{project.numSolutions || 0} 
                  {project.numSolutions > 1 ? <span>solutions</span> : <span>solution</span>}
                </span>
              </span>
            : null}

            {project.numModules && project.numModules > 0 ?
              <span className="project-modules project-card-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/module-thumbnail-grayscale.png'} alt="Modules" />
                <span>{project.numModules || 0} 
                  {project.numModules > 1 ? <span>modules</span> : <span>module</span>}
                </span>
              </span>
            : null}

            {project.numResources && project.numResources > 0 ?
              <span className="project-resources project-card-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/resource-thumbnail-grayscale.png'} alt="Resources" />
                <span>{project.numResources || 0} 
                  {project.numResources > 1 ? <span>resources</span> : <span>resource</span>}
                </span>
              </span>
            : null}

            {project.numMessages && project.numMessages > 0 ?
              <span className="project-messages project-card-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/discussions.png'} alt="Comments" />
                <span>{project.numMessages || 0} 
                  {project.numMessages > 1 ? <span>comments</span> : <span>comment</span>}
                </span>
              </span>
            : null}
          </div>
        : null }
      </div>

{/*      {project.numHelpRequests && project.numHelpRequests > 0 ?
        <div className="project-card-help-requests">
          <img src={process.env.PUBLIC_URL + '/icons/hand-green.png'} alt="Help / feedback requests" />
          <span>This project has {project.numHelpRequests} 
            {project.numHelpRequests > 1 ? <span> help requests</span> : <span> help request</span>}
          </span>
        </div>
      : null}
*/}
      {project.status && (project.status==='draft') ?
        <div className="project-card-status-text">
          <img src={process.env.PUBLIC_URL + '/icons/info-red.png'} alt="Info" />
          <span>Add resources & a solution, then publish this project.</span>
        </div>
      : null}

      {project.status && (project.status==='pending') ?
        <div className="project-card-status-text">
          <img src={process.env.PUBLIC_URL + '/icons/info-red.png'} alt="Info" />
          <span>This project is pending review. Will be published soon.</span>
        </div>
      : null}

    </div>
  );
}

export default ProjectCard;
