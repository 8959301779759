import React  from 'react';

import "./PageSuccess.scss";

export default function PageSuccess(props) {
  const {
    message,
    messageText
  } = props;

  return (
    <div className="page-success">
      <img src={process.env.PUBLIC_URL + '/icons/green-check-on-white.svg'} alt="Info" />
      <span>{messageText}</span>
    </div>
  );
}