import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";

import "./ChooseProjectType.scss";

export default function ChooseProjectType(props) {
  const [type, setType] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const {
    onCancel,
    onNext,
    isEdit,
  } = props;

  useEffect(() => {
    setType(props.projectType);
    setIsVisible(props.isVisible);
  }, [props.projectType, props.isVisible]);

  useEffect(() => {
    const _doesFormHaveErrors = !type;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [type]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(type);
    }
  };

  return (
    <>
    <div className={`full-screen-page-body choose-project-type ${isVisible ? 'visible' : 'hidden'}`}>
      <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>
        <h3 className="full-screen-sub-header">
          Choose project type
        </h3>
        <div className={`project-type-options ${doesFormHaveErrors ? 'error-field' : ''}`}>
          <div className="standalone-project-section styled-radio large full-width">
            <label>
              <Input type="radio"
                name="projectType"
                value="standalone"
                checked={type === "standalone"}
                disabled={isEdit}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                />
              <span>
                <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-standalone.png'} alt="Standalone project" className="project-thumbnail" />
                Start a new standalone project
              </span>
            </label>
          </div>
          <div className="guided-project-section styled-radio large full-width">
            <label>
              <Input type="radio"
                name="projectType"
                value="guided"
                checked={type === "guided"}
                disabled={isEdit}
                onChange={(e) => {
                  setType(e.target.value);
                }}
                />
              <span>
                <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-guided.png'} alt="Practice project" className="project-thumbnail" />

                Suggest a practice project topic
              </span>
            </label>
          </div>

          {showFormErrors && doesFormHaveErrors ?
            <div className="form-field-error-message">
              Choose one of these two options
            </div>
           : null}

        </div>

      </form>

    </div>
    <div className="create-project-actions form-footer">
      <div>
        <Button color="secondary" outline onClick={() => { onCancel() }}>
          Cancel
        </Button>

        <Button color="primary" onClick={handleSubmit}>
          Next
        </Button>
      </div>
    </div>
    </>
  );
}