import React, { useContext } from 'react';
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { UserContext } from '../../contexts/user-context';

import "./HelpRequestCard.scss";
import "./HelpRequestCard.styles.css";

function HelpRequestCard(props) {
  const { User } = useContext(UserContext);
  const {
    helpRequest,
    onClick,
    isDisabled
  } = props;

  return (
    <div className={`help-request-card ${User?.id && User?.id === helpRequest.user ? 'mine' : ''}`} role="button" onClick={() => { if (!isDisabled) { onClick()} }}>
      {User?.id && User?.id === helpRequest.user ?
        <span className="my-help-request-indicator">Mine</span>
      : null}

      <img className="help-request-card-icon" src={process.env.PUBLIC_URL + '/icons/help-request.png'} alt="Help request" />
      <span className="help-request-card-name">{helpRequest.name}</span>
    </div>
  );
}

export default HelpRequestCard;
