import React, { useContext }  from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { DataContext } from '../../contexts/data-context';
import "./DeletedAccountModal.scss";

function DeletedAccountModal(props) {
  const { serverUrl } = useContext(DataContext);

  let logoutUrl = serverUrl + '/do_logout';

  return (
    <Modal
      className="deleted-account-modal"
      isOpen={true}
      backdrop="static"
      centered={true}>
      <ModalHeader>Account marked for deletion</ModalHeader>
      <ModalBody className="deleted-account-modal-body">
        <p>This account has been marked for deletion on the user's request, and will be deleted soon.</p>
        <p>Please log out, and log in with a different account</p>
      </ModalBody>
      <ModalFooter className="single-action">
        <div className="primary-actions">
          <Button color="primary" type="submit" form="delete-account-form" onClick={() => {
            window.location.href = logoutUrl;
          }}>
            Log out
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default DeletedAccountModal;
