import React, { useState, useEffect, useContext } from 'react';
import { Button, Label, Input } from 'reactstrap';

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import { isPhone, isPastDate } from '../../util/validators';

import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";


import "./SetupProfile.scss";

export default function SetupProfile(props) {
  const { logView } = useContext(DataContext);

  // const [dob, setDob] = useState('');
  const [phone, setPhone] = useState('');
  const [about, setAbout] = useState('');
  const [isVisible, setIsVisible] = useState(false);

  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  // const [isDobError, setDobError] = useState(false);
  // const [isPhoneError, setPhoneError] = useState(false);

  const {
    onBack,
    onNext,
  } = props;

  useEffect(() => {
    // setDob(props.dob);
    setPhone(props.phone);
    setAbout(props.about);
    setIsVisible(props.isVisible);
  }, [props.phone, props.about, props.isVisible]);

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-setup-profile');
    }
  }, [isVisible]);
  
  // useEffect(() => {
  //   // const _isPhoneError = !isPhone(phone);
  //   // const _isDobError = !isPastDate(dob);
  //   // setPhoneError(_isPhoneError);
  //   // setDobError(_isDobError);

  //   // const _doesFormHaveErrors = _isPhoneError || _isDobError;
  //   setDoesFormHaveErrors(_doesFormHaveErrors);
  // }, [phone, dob]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(phone, about);
    }
  };

  return (
    <div className={`onboarding-modal-signup onboarding-modal-setup-profile ${isVisible ? 'visible' : 'hidden'}`}>
      <form className={`${showFormErrors ? 'form-errors' : ''}`}>
        <div className="modal-content-body signup-form-container">
          {/*Removing phone and DOB for now*/}
          {/*<div className={`form-group full-width ${isPhoneError ? 'error-field' : ''}`}>
            <Label for="setup-profile-phone-number">Phone number</Label>
            <Input
              id="setup-profile-phone-number"
              name="setup-profile-phone-number"
              placeholder="(+91)9876543210"
              type="text"
              value={phone}
              autoComplete="on"
              onChange={(e) => { setPhone(e.target.value);}}
            />
            {showFormErrors && isPhoneError ?
              <div className="form-field-error-message">
                Enter a valid phone number
              </div>
             : null}
          </div>
          <div className={`form-group full-width ${isDobError ? 'error-field' : ''}`}>
            <Label for="setup-profile-dob">Date of birth</Label>
            <div>
              <div className="dob-container">
                <DatePicker
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={dob}
                  onChange={(date) => setDob(date)}
                  />
              </div>
              {showFormErrors && isDobError ?
                <div className="form-field-error-message">
                  Enter a valid date of birth
                </div>
               : null}
            </div>
          </div>*/}
          <div className="form-group full-width">
            <Label for="setup-profile-about">About <span>(optional)</span></Label>
            <Input
              id="setup-profile-about"
              name="setup-profile-about"
              placeholder="Describe anything about yourself that you'd like to share with other users"
              type="textarea"
              value={about}
              autoComplete="off"
              onChange={(e) => { setAbout(e.target.value);}}
            />
          </div>

          <p className="about-help-text">This is a space where you can introduce yourself with something about yourself that you'd like to share, like: what kind of projects you are interested in doing, what skills you are looking to learn, etc.</p>
          <p className="about-help-text"><b>Note: Other users can see this.</b></p>

        </div>

        <div className="modal-actions-footer two-actions">
          <Button color="secondary" outline  size="sm"onClick={onBack}>
            Back
          </Button>

          <Button type="submit" color="primary"  size="sm" onClick={submitForm}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
}