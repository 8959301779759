import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PageError from '../messages/PageError';
import PageSuccess from '../messages/PageSuccess';
import { DataContext } from '../../contexts/data-context';
import { keysToCamel } from '../../util/caseConverters';

import "./EditProjectParticipants.scss";

function EditProjectParticipants(props) {
  const {
    acceptParticipant,
    declineParticipant,
    makeAdmin,
    makeContributor,
    removeParticipant,
    withdrawInvite,
    getProjectParticipants,
    logView,
    logError
  } = useContext(DataContext);
  const [projectParticipants, setProjectParticipants] = useState();
  const [addedParticipants, setAddedParticipants] = useState();
  const [invitedParticipants, setInvitedParticipants] = useState();
  const [requestedParticipants, setRequestedParticipants] = useState();
  const [pageSuccess, setPageSuccess] = useState(null);
  const [pageError, setPageError] = useState(null);
  const {
    projectId,
    onClose,
    projectType,
    pointsToJoin,
  } = props;

  const fetchData = function() {
    setPageError(null);

    getProjectParticipants(projectId).then((response) => {
      if (response?.data) {
        const responseData = keysToCamel(response.data)
        setProjectParticipants(keysToCamel(responseData));
        setAddedParticipants(responseData.filter(item => item.role === 'contributor' || item.role === 'administrator'));
        setInvitedParticipants(responseData.filter(item => item.role === 'pending'));
        setRequestedParticipants(responseData.filter(item => item.role === 'requested'));
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageError('An error occurred. Please refresh the page and try again.');
    });
  };

  useEffect(() => {
    fetchData();
  }, [projectId]);

  useEffect(() => {
    logView('edit-project-participants');
  }, []);

  const closeForm = () => {
    setPageSuccess(null);
    setPageError(null);
    onClose();
  };

  return (
    <Modal
      className="edit-project-participants"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      centered={true}>
      <ModalHeader>Edit project participants</ModalHeader>
      <ModalBody>
        {pageError ? 
          <div className="page-error-container">
            <PageError messageText={pageError} />
          </div>
        : null}

        {pageSuccess ? 
          <div className="page-success-container">
            <PageSuccess messageText={pageSuccess} />
          </div>
        : null}

        <p className="add-participant-intro">Review participants, joinee requests, and pending invites.</p>

        <div className="edit-participants-section">
          <h3>Join requests</h3>
          {requestedParticipants?.length ?
            <ul className="edit-project-participants-list">
              {requestedParticipants?.map((item, idx) => {
                return (
                  <li key={idx} className="edit-project-participant-card">
                    <div>
                      {/*<p>{item.userId}, {item.username}, <strong>{item.name}</strong>, {item.role}</p>*/}
                      <p><strong>{item.name}</strong> ({item.username})</p>

                      {item.introText ?
                        <div className="request-intro-text">{item.introText}</div>
                      : null}

                      <div className="edit-project-participants-actions">
                        <Button color="primary" outline size="sm" onClick={() => {
                          acceptParticipant(parseInt(projectId), item.userId).then((response) => {
                            if (response?.data?.result?.affectedRows) {
                              setPageError(null);
                              setPageSuccess(`Added ${item.name} to the project`);
                              fetchData();
                            } else {
                              setPageSuccess(null);
                              setPageError('An error occurred. Please try again.')
                            }
                          }).catch((error) => {
                            setPageSuccess(null);
                            // logError(error);
                            setPageError('An error occurred. Please try again.')
                          });
                        }}>
                          Add to project
                        </Button>
                        <Button color="secondary" outline size="sm" onClick={() => {
                          declineParticipant(parseInt(projectId), item.userId).then((response) => {
                            if (response?.data?.result?.affectedRows) {
                              setPageError(null);
                              setPageSuccess(`Declined $<strong>{item.name}</strong>'s request to join the project`);
                              fetchData();
                            } else {
                              setPageSuccess(null);
                              setPageError('An error occurred. Please try again.')
                            }
                          }).catch((error) => {
                            setPageSuccess(null);
                            // logError(error);
                            setPageError('An error occurred. Please try again.')
                          });
                        }}>
                          Deny
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          : null }
        </div>

        <div className="edit-participants-section">
          <h3>Invited participants</h3>
          {invitedParticipants?.length ?
            <ul className="edit-project-participants-list">
              {invitedParticipants?.map((item, idx) => {
                return (
                  <li key={idx} className="edit-project-participant-card">
                    <div>
                      <strong>{item.name}</strong> ({item.username})

                      <div className="edit-project-participants-actions">
                        <Button color="secondary" outline size="sm" onClick={() => {
                          withdrawInvite(parseInt(projectId), item.userId).then((response) => {
                            if (response?.data?.result?.affectedRows) {
                              setPageError(null);
                              setPageSuccess(`Removed $<strong>{item.name}</strong>'s invite to the project`);
                              fetchData();
                            } else {
                              setPageSuccess(null);
                              setPageError('An error occurred. Please try again.')
                            }
                          }).catch((error) => {
                            setPageSuccess(null);
                            // logError(error);
                            setPageError('An error occurred. Please try again.')
                          });
                        }}>
                          Withdraw invite
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          : null }
        </div>

        <div className="edit-participants-section">
          <h3>Current participants</h3>
          {addedParticipants?.length ?
            <ul className="edit-project-participants-list">
              {addedParticipants?.map((item, idx) => {
                return (
                  <li key={idx} className="edit-project-participant-card">
                    <div>
                      <strong>{item.name}</strong> ({item.username}), {item.role}

                      <div className="edit-project-participants-actions">
                        {item.role === 'contributor' ?
                          <Button color="secondary" outline size="sm" onClick={() => {
                            makeAdmin(parseInt(projectId), item.userId).then((response) => {
                              if (response?.data?.result?.affectedRows) {
                                setPageError(null);
                                setPageSuccess(`Updated $<strong>{item.name}</strong> to an administrator`);
                                fetchData();
                              } else {
                                setPageSuccess(null);
                                setPageError('An error occurred. Please try again.')
                              }
                            }).catch((error) => {
                              setPageSuccess(null);
                              // logError(error);
                              setPageError('An error occurred. Please try again.')
                            });
                          }}>
                            Make administrator
                          </Button>
                        :
                          <Button color="secondary" outline size="sm" onClick={() => {
                            makeContributor(parseInt(projectId), item.userId).then((response) => {
                              if (response?.data?.result?.affectedRows) {
                                setPageError(null);
                                setPageSuccess(`Updated $<strong>{item.name}</strong>'s role to contributor`);
                                fetchData();
                              } else {
                                setPageSuccess(null);
                                setPageError('An error occurred. Please try again.')
                              }
                            }).catch((error) => {
                              setPageSuccess(null);
                              // logError(error);
                              setPageError('An error occurred. Please try again.')
                            });
                          }}>
                            Downgrade to contributor
                          </Button>
                        }

                        <Button color="secondary" outline size="sm" onClick={() => {
                          removeParticipant(parseInt(projectId), item.userId).then((response) => {
                            if (response?.data?.result?.affectedRows) {
                              setPageError(null);
                              setPageSuccess(`Removed $<strong>{item.name}</strong> from the project`);
                              fetchData();
                            } else {
                              setPageSuccess(null);
                              setPageError('An error occurred. Please try again.')
                            }
                          }).catch((error) => {
                            setPageSuccess(null);
                            // logError(error);
                            setPageError('An error occurred. Please try again.')
                          });
                        }}>
                          Remove participant
                        </Button>
                      </div>
                    </div>
                  </li>
                );
              })}
            </ul>
          : null }
        </div>

      </ModalBody>
      <ModalFooter className="single-action">
        <div className="secondary-actions">
          <Button color="primary" onClick={closeForm}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default EditProjectParticipants;
