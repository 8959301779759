import React, { useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { UserContext } from '../../contexts/user-context';
import { DataContext } from '../../contexts/data-context';
import "./PremiumReminderModal.scss";

function PremiumReminderModal(props) {
  const { User, refreshUser } = useContext(UserContext);
  const { markPremiumReminderDismissed, upgradeUrl } = useContext(DataContext);
  const {
    onClose,
    messageType,
  } = props;

  const handleClose = function() {
    markPremiumReminderDismissed().then(() => {
      refreshUser().then(() => {
        onClose();
      }).catch(() => {
        // Silent fail;
        onClose();
      });
    }).catch(() => {
      // Silent fail;
      onClose();
    });
  };

  return (
    <Modal
      className="premium-reminder-modal"
      isOpen={true}
      toggle={onClose}
      backdrop="static"
      centered={true}>
      <ModalHeader>Premium status expired</ModalHeader>
      <ModalBody>
        <div className="premium-display">
          <span className="project-price premium"><span>Premium</span></span>
        </div>
        <p>Your account's Premium status has expired.</p>
        <p>Renew your Premium access now, to continue working on our Premium projects.</p>
        <h3>Early access offer</h3>
        <p>Since we are in early alpha, we are offering a FREE upgrade to Premium membership. This is a limited early-access offer.</p>
        <p>Click Renew below to upgrade your account to Premium again.</p>
      </ModalBody>
      <ModalFooter className="two-actions">
        <div className="secondary-actions">
          <Button color="link" onClick={handleClose}>
            Maybe later
          </Button>
        </div>
        <div className="primary-actions">
          <a className="primary" href={upgradeUrl}>Renew Premium</a>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default PremiumReminderModal;
