import React, { useContext, useEffect, useState, useRef, useCallback } from 'react';
import { Button, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useNavigate, Link } from "react-router-dom";
import { keysToCamel } from '../../util/caseConverters';
import PageError from '../messages/PageError';
import UserProfileModal from "../common/UserProfileModal";

import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import "./Discussion.scss";

function Discussion(props) {
  // const [value, setValue] = useState(new Date());
  // const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  // const toggleDateTimePicker = () => setShowDateTimePicker(!showDateTimePicker);
  const navigate = useNavigate();
  // const { discussionMessages } = useContext(DataContext);
  const [discussionMessages, setDiscussionMessages] = useState([]);
  const [messageCount, setMessageCount] = useState(0);
  const [oldMessageCount, setOldMessageCount] = useState(0);
  const [newMessageText, setNewMessageText] = useState('');
  const [hasUnreadMessages, setHasUnreadMessages] = useState('');
  const [numUnreadMessages, setNumUnreadMessages] = useState(0);
  const [showScrollLink, setShowScrollLink] = useState(false);
  const [isFollowingEntity, setIsFollowingEntity] = useState(false);
  const { getProjectComments, getSolutionComments, postComment, deleteComment, getIsFollowingEntity, followSolution, unfollowSolution, updateDiscussionVisit, logView, logError, postClap, postDeleteClap, getAllClaps } = useContext(DataContext);
  const { User, refreshUserUpdates } = useContext(UserContext);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [isAlreadyFetched, setAlreadyFetched] = useState(false);
  const [userProfileModalId, setUserProfileModalId] = useState(null);
  const [claps, setClaps] = useState([]);

  const {
    projectId,
    entity,
    entityId,
    entityName,
    entityUrl,
    entityType,
    entityThumbnailUrl,
    onClose
  } = props;

  const fetchData = function(isFirstRender, showLoader) {
    if (entityId) {
      fetchFollowingData();
      fetchClapsData();
    }

    setPageErrorMessage(null);

    if (projectId) {
      if (entityType === "solution") {
        getSolutionComments(projectId, entityId, showLoader).then((response) => {
// console.log('It is ' + isAlreadyFetched);
//         setAlreadyFetched(true);

          handleFetchReponse(response, isFirstRender);
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred when loading comments. Please refresh the page and try again.');
        });
      } else if (entityType === "project") {
        getProjectComments(projectId, showLoader).then((response) => {
// console.log('It is ' + isAlreadyFetched);
//         setAlreadyFetched(true);

          handleFetchReponse(response, isFirstRender);
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred when loading comments. Please refresh the page and try again.');
        });
      }
    }
  };

  const fetchClapsData = function() {
    getAllClaps().then((response) => {
      setClaps(response.data);
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
    });
  };

  const fetchFollowingData = function() {
    if (entityType && User?.id) {
      getIsFollowingEntity(entityType, entityId).then((response) => {
        setIsFollowingEntity(response.data);

        var element = document.querySelector('#discussion-messages');
        if (element) {
          // 100px tolerance
          const isScrolledDown = element && ((element.scrollHeight - element.scrollTop) - element.clientHeight) < 100;

          // If user is following this entity and is scrolled down to the last message, update their "last visited" time for this discussion
          if (isScrolledDown && response.data) {
            doUpdateDiscussionVisit();
          }
        } else {
          doUpdateDiscussionVisit();
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        // Silent fail
      });
    }
  };

  const handleFetchReponse = (response, isFirstRender) => {
    if (response?.data) {
      const result = keysToCamel(response.data);
      const resultLength = result.length;

      setDiscussionMessages(result || []);
      setMessageCount(resultLength);

      setTimeout(function() {
        var element = document.querySelector('#discussion-messages');

        // 100px tolerance
        const isAlreadyScrolledDown = element && ((element.scrollHeight - element.scrollTop) - element.clientHeight) < 100;

// console.log('isFirstRender is ' + isFirstRender);

        if (isFirstRender || isAlreadyScrolledDown) {
          // console.log('first render, so scrolling down');
          scrollToEnd();
        } /*else {
          if (isAlreadyScrolledDown) {
            // console.log('not first render. already scrolled down, so scrolling down again');
            scrollToEnd();
          } else {
            console.log(resultLength, messageCount);
            if (resultLength > messageCount) {
              console.log('not first render. not already scrolled down, showing new messages');
              setShowScrollLink(true);
            } else {
              console.log('not first render. no new messages');
              setShowScrollLink(false);
            }
          }
        }*/

        // setMessageCount(resultLength);
        // console.log('Setting messageCount to' + resultLength);

//         if (isAlreadyScrolledDown || isFirstRender) {
//           scrollToEnd();
//         } else if (resultLength > messageCount) {
// console.log('has new messages', resultLength, messageCount);
//           setShowScrollLink(true);
//         } else {
// console.log('no new messages');
//           setShowScrollLink(false);
//         }

      }, 0);
    }
  };

  const handleScroll = function() {
    var element = document.querySelector('#discussion-messages');
    // 100px tolerance
    const isScrolledDown = element && ((element.scrollHeight - element.scrollTop) - element.clientHeight) < 100;
    setShowScrollLink(!isScrolledDown);

    if (isScrolledDown) {
      doUpdateDiscussionVisit();
    } else {
      setHasUnreadMessages(false);
      setNumUnreadMessages(0);
    }
  };

  const scrollToEnd = function() {
    var element = document.querySelector('#discussion-messages');
    if (element) {
      element.scrollTop = element.scrollHeight;      
    }
    setShowScrollLink(false);
  };

  const postUserComment = function(message) {
    setPageErrorMessage(null);

    if (message?.trim()) {
      postComment(projectId, entityType, entityId, message).then((response) => {
        fetchData(false, false);
        setNewMessageText('');
        scrollToEnd();

        // If the user is commenting on someone else's solution and hasn't followed that solution, follow it
        // if (entityType === "solution" && entity?.ownerId && User?.id && entity?.ownerId !== User?.id &&!isFollowingSolution) {
        //   doFollowSolution();
        // }

        // Update the "discussion last visited" timestamp for this discussion for this user
        doUpdateDiscussionVisit();
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occurred posting this comment. Please try again.');
      });
    }
  };

  const deleteUserComment = function(id) {
    setPageErrorMessage(null);

    if (id && window.confirm("Are you sure you want to delete this comment? Please tap OK to confirm.")) {
      deleteComment(id, entityType, entityId).then((response) => {
        fetchData(false, false);
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occurred when deleting this comment. Please refresh the page and try again.');
      });
    }
  };

  const doFollowSolution = function() {
    setPageErrorMessage(null);

    followSolution(entityId, projectId).then((response) => {
      fetchFollowingData();
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occurred. Please try again.');
    });
  };

  const doUnfollowSolution = function() {
    setPageErrorMessage(null);

    unfollowSolution(entityId).then((response) => {
      fetchFollowingData();
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occurred. Please try again.');
    });
  };

  const doUpdateDiscussionVisit = function() {
    updateDiscussionVisit(entityId, entityType, projectId).then((response) => {
      refreshUserUpdates();
    }).catch((error) => {
      console.log(error);
      // Silent fail
    });
  };

  const useDebouncedCallback = (callback, delay) => {
    const callbackRef = useRef(callback);
    const timerRef = useRef();
    
    return useCallback(
      (...args) => {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }

        timerRef.current = setTimeout(() => {
          callbackRef.current(...args);
        }, delay);
      },
      []
    );
  };

  const onScroll = useDebouncedCallback(() => {
    handleScroll();
  }, 100);

  const doCommentLike = function(id, ownerId, message) {
    setPageErrorMessage(null);

    if (User?.id) {
      postClap(ownerId, id, 'comment', message, entityId, entityType, entityName, entityUrl).then((response) => {
        fetchData();
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }

    fetchData();
  };

  const doCommentUnlike = function(id) {
    setPageErrorMessage(null);

    if (User?.id) {
      postDeleteClap(id, 'comment').then((response) => {
        fetchData();
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
      });
    }
  };

  // useEffect(() => {
  //   fetchData(true);
  // }, []);

  useEffect(() => {
    fetchData(true, true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchData(false, false);
      }
    }, 5000);

    return () => {
      clearInterval(intervalId);
    };
  }, [projectId]);


  useEffect(() => {
    if (oldMessageCount && messageCount > oldMessageCount) {
      setHasUnreadMessages(true);
      setNumUnreadMessages(messageCount - oldMessageCount);

      var messageCountIndicator = document.querySelector('#message-count');
      messageCountIndicator?.classList?.add('highlight');

      setTimeout(function() {
        messageCountIndicator?.classList?.remove('highlight');
      }, 750);
    }

    setOldMessageCount(messageCount);
  }, [messageCount]);

  useEffect(() => {
    logView('discussion', `${entityType}-${entityId}`);
  }, []);

  // useEffect(() => {
  //   scrollToEnd();
  // }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    postUserComment(newMessageText);
  };

  return (
    <Modal
      className="discussion-modal"
      isOpen={true}
      toggle={onClose}
      backdrop="static"
      centered={true}>
      <ModalHeader>
        <span>Comments</span>

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            onClose();
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </ModalHeader>
      <ModalBody>

        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}


        <div className="discussion">

          {showScrollLink ? 
            <>
              {hasUnreadMessages ?
                <Button color="none" className="scroll-last-message-button has-unread" onClick={scrollToEnd}>
                  <img src={`${process.env.PUBLIC_URL}/icons/red-message.svg`} alt="New comments" />
                  {numUnreadMessages === 1 ?
                    <span>{numUnreadMessages} new comments</span>
                  :
                    <span>{numUnreadMessages} new comments</span>
                  }
                </Button>
              :
                <Button color="none" className="scroll-last-message-button" onClick={scrollToEnd}>
                  Scroll to last comment
                </Button>
              }
            </>
          : null}

          <div className="discussion-entity-blurb">
            <div className="discussion-entity-blurb-header">
              {/*<img src={entityThumbnailUrl} className="entity-thumbnail" alt={entityName} />*/}
              <img src={`${process.env.PUBLIC_URL}/icons/discussions.png`} className="entity-thumbnail" alt={entityName} />
              <div>
                <p className="entity-type">
                  Comments on <strong>{entityName}</strong>
                  {messageCount ?
                    <span id="message-count"> ({messageCount})</span>
                  : null}
                </p>
                {/*<p>Following: {isFollowingEntity.toString()}</p>*/}

                {entityType && entityType === 'solution' && entity?.ownerId && User?.id && entity?.ownerId !== User?.id ?
                  <div className="follow-section">
                    {isFollowingEntity ?
                      <>
                        <span>Following this solution discussion.</span>
                        <Button className="follow-button" type="link" size="sm" color="none" onClick={() => { doUnfollowSolution(); }}>
                          Unfollow
                        </Button>
                      </>
                    :
                      <>
                        <span>Not following this solution discussion.</span>
                        <Button className="unfollow-button" type="link" size="sm" color="none" onClick={() => { doFollowSolution(); }}>
                          Follow
                        </Button>
                      </>
                    }
                  </div>
                : null}
              </div>
            </div>
          </div>

          {discussionMessages?.length ?
            <div className="discussion-messages" id="discussion-messages" onScroll={onScroll}>
              {discussionMessages?.filter(item => !!item.userId).map((item, idx) => {
                let showNameAndDate = true;
                let currentItemDate = new Date(item.dateTime).toDateString();

                if (idx > 0) {
                    let previousItem = discussionMessages[idx - 1];
                    let previousItemDate = new Date(previousItem.dateTime).toDateString();
                    if (item.userId === previousItem.userId && previousItemDate === currentItemDate) {
                      showNameAndDate = false;
                    }
                }

                return (
                  <div key={idx} className={`discussion-message-card-container ${item.userId === User?.id ? 'current-user' : ''}`}>
                    <div className="discussion-message-card">
                      {showNameAndDate ?
                        <div className="discussion-message-header">
                          <div className="discussion-message-user">
                            {/*<Link to={`/user-profile/${item.userId}`}>
                              <img src={`${process.env.PUBLIC_URL}/icons/person.svg`} alt={item.userName} />
                              <span>{item.userName}</span>
                            </Link>*/}
                              <img src={`${process.env.PUBLIC_URL}/icons/person.svg`} alt={item.userName} />

                              <Button color="link" className="button-as-link" outline size="sm" type="button" onClick={() => {
                                setUserProfileModalId(item.userId);
                              }}>
                                {item.userName}
                              </Button>
                          </div>

                          {/*Product call: hide this for now*/}
                          {/*<div className="discussion-message-datetime">
                            <span>{currentItemDate}</span>
                          </div>*/}
                        </div>
                      : null}

                      <div className="discussion-message-text">
                        <p>{item.message}</p>

                        {item.userId === User?.id ?
                          <Button className="delete-button" type="link" size="sm" color="none" onClick={() => {
                            deleteUserComment(item.id);
                          }}>
                            <img src={process.env.PUBLIC_URL + '/icons/delete.png'} alt="Delete comment" />
                          </Button>
                        : null}
                      </div>

                      <div className="discussion-message-likes">
                        <span className="discussion-message-total-likes">
                          {claps?.filter(clapItem => clapItem.entity_type === 'comment' && clapItem.entity_id === item.id).length > 0 ?
                            <span className="likes-count">
                              {claps?.filter(clapItem => clapItem.entity_type === 'comment' && clapItem.entity_id === item.id).length}
                            </span>
                          : null}
                        </span>

                        {item.userId !== User?.id ?
                          <div className="discussion-message-likes-actions">
                            {claps?.filter(clapItem => clapItem.entity_type === 'comment' && clapItem.entity_id === item.id).find(item => item.clapper === User.id) ?
                              <>
                                <Button color="secondary" className="liked-button" outline size="sm" onClick={() => { doCommentUnlike(item.id) }}>
                                  <span>Liked</span>
                                </Button>
                              </>
                            :
                              <Button color="secondary" className="like-button" outline size="sm" onClick={() => { doCommentLike(item.id, item.userId, item.message) }}>
                                <span>Like</span>
                              </Button>
                            }
                          </div>
                        : null}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          :
            <p className="comments-empty-state">
              There are no comments on this {entityType} yet.
            </p>
          }


        </div>

        <form onSubmit={handleSubmit} className="new-message-container">
          <Input
            id="new-message"
            name="new-message"
            className="new-message"
            placeholder="Type comment"
            type="text"
            value={newMessageText}
            onChange={(e) => {
              setNewMessageText(e.target.value);
            }}
          />
          <Button type="submit" className="send-message" color="none" onClick={handleSubmit}>
            <img src={process.env.PUBLIC_URL + '/icons/send-message.png'} alt="Post comment" />
          </Button>
        </form>

        {userProfileModalId ?
          <UserProfileModal 
            userId={userProfileModalId}
            onClose={() => setUserProfileModalId(null)}
            />
        : null}

      </ModalBody>
    </Modal>
  );
}

export default Discussion;
