import React, { useContext, useEffect, useState, useLayoutEffect, createRef } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Helmet } from 'react-helmet-async';

import PageError from '../messages/PageError';

import { keysToCamel } from '../../util/caseConverters';
import ConfirmationModal from "../common/ConfirmationModal";
import InformationModal from "../common/InformationModal";
import ResourceCard from "../cards/ResourceCard";
import SolutionCard from "../cards/SolutionCard";
import ModuleCard from "../cards/ModuleCard";
import RichTextModal from "../common/RichTextModal";
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';

import "./ModuleDetail.scss";

export default function ModuleDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getModule, getSubModules, getProject, getProjectRole, deleteModule, getProjectResources, getProjectSolutions, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const { projectId, moduleId } = useParams();
  const [module, setModule] = useState();
  const [role, setRole] = useState('');
  const [resources, setResources] = useState([]);
  const [solutions, setSolutions] = useState([]);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDeleteInformationModal, setShowDeleteInformationModal] = useState(false);
  const [deleteInformationMessage, setDeleteInformationMessage] = useState('The module has been deleted.');
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [isDescriptionOverflow, setDescriptionOverflow] = React.useState(false);
  const [showFullDescription, setShowFullDescription] = React.useState(false);
  const [project, setProject] = useState();
  const [parentModule, setParentModule] = useState(null);
  const [subModules, setSubModules] = useState(null);

  useEffect(() => {
    setPageTitle('Module');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('module-detail', `${projectId}-${moduleId}`);
  }, []);

  const ref = createRef();
  useLayoutEffect(() => {
    if (ref.current?.clientHeight < ref.current?.scrollHeight) {
      setDescriptionOverflow(true);
    }
  }, [ref]);

  const editAllowedRoles = ['owner', 'administrator'];
  const contributorAllowedRoles = ['owner', 'administrator', 'contributor'];

  const fetchData = function(showLoader) {
    let module;
    setPageErrorMessage(null);

    getModule(projectId, moduleId, showLoader).then((response) => {
      if (response?.data?.length) {
        module = keysToCamel(response).data[0];
        setModule(module);

        const userId = User?.id;
        if (userId) {
          getProjectRole(userId, projectId, showLoader).then((response) => {
            if (response?.data?.length) {
              setRole(response.data[0].role);
            } else {
              setRole('outsider');
            }
          }).catch((error) => {
            console.log(error);
            // logError(error);
            setRole('outsider');
          });        
        }

        if (module.parentModule) {
          getModule(projectId, module.parentModule, showLoader).then((response) => {
            setParentModule(response.data[0]);
          }).catch(error => {
            console.log(error);
            // No-op, silent fail
          });
        }

        getSubModules(module.id, showLoader).then((response) => {
          setSubModules(response.data);
        }).catch(error => {
          console.log(error);
          // No-op, silent fail
        });
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred loading this module. Please refresh the page and try again.');
      console.log(error);
      // logError(error);
    });

    getProjectResources(projectId, showLoader).then((response) => {
      if (response?.data) {
        let moduleResources = response.data.filter((item) => {
          const itemModules = item.modules;
          if (!itemModules?.length) {
            return false;
          }
          const itemModuleIds =itemModules.split(',');
          // console.log(itemModuleIds, moduleId);
          return itemModuleIds.includes(moduleId);
        });
        setResources(moduleResources);
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occurred loading this module. Please refresh the page and try again.');
    });

    getProjectSolutions(projectId, showLoader).then((response) => {
      if (response?.data) {
        let moduleSolutions = response.data.filter((item) => {
          const itemModules = item.modules;
          if (!itemModules?.length) {
            return false;
          }
          const itemModuleIds =itemModules.split(',');
          // console.log(itemModuleIds, moduleId);
          return itemModuleIds.includes(moduleId);
        });
        setSolutions(moduleSolutions);
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occurred loading this module. Please refresh the page and try again.');
    });

    getProject(projectId, false).then((response) => {
      let project;

      if (response?.data?.length) {
        project = keysToCamel(response).data[0];
        setProject(project);
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      // No-op. Silent fail.
    });
  };

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchData(false);
      }
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [projectId, moduleId]);

  return (
    <div>
      <div className={"module-detail-page full-screen-page "}>
        <Helmet>
          <title>{module?.title || 'Module detail'} - Project Klub</title>
        </Helmet>

        <div className="full-screen-header">
          <h2>Module</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              // navigate('/project-detail/' + projectId);
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}
        
        {module ?
          <div className={`full-screen-body module-detail`}>
            <div className="module-header">
              <div className="module-thumbnail-container">
                <div className="module-detail-thumbnail-section">
                  <img src={process.env.PUBLIC_URL + '/icons/module-thumbnail.png'} alt={module.title} className="module-thumbnail" />
                </div>
              </div>
              <div className="module-title-container">
                <p className="project-title">
                  <Link className="project-title-link" to={`/project-detail/${projectId}`}>
                    {project?.title}
                  </Link>
                  {parentModule ?
                    <>
                      {parentModule.parent_module ?
                        <span className="project-title-separator">/<span className="ellipsis">...</span>/</span>
                      :
                        <span className="project-title-separator">/</span>
                      }
                      <Link className="project-title-link" to={`/module-detail/${projectId}/${parentModule.id}`}>
                        {parentModule.title}
                      </Link>
                    </>
                  : null}
                </p>
                <p className="module-title">Module: {module?.title}</p>
              </div>
              {(role && editAllowedRoles.includes(role)) || User.is_superadmin ?
                <div>
                  <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-module" onClick={() => { navigate('/edit-project-module/' + projectId + '/' + moduleId ); }}>
                    Edit
                  </Button>
                </div>
              : null}
            </div>

            {module?.shortDescription ?
              <div className="module-detail-section module-short-description-section tldr">
                <h3>Module short summary</h3>

                {module.shortDescription.split('&&&&').length > 1 ?
                  <ul>
                    {module.shortDescription.split('&&&&').slice(1).map((item, idx) => {
                      return (<li key={idx}>{item}</li>);
                    })}
                  </ul>
                :
                  <>{module.shortDescription}</>
                }
              </div>
            : null}

            <div className="module-detail-section module-description-section">
              <div className="module-detail-section-header">
                {/*<h3>Module summary</h3>*/}
                {/*<Button color="link" outline size="sm" className="title-button expand-button" onClick={() => {
                  setShowExpandedSummary(true);
                }}>
                  Expand
                </Button>*/}
              </div>
              {/*<div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(module?.description)}} />*/}
                <div className={`module-description content-description ${isDescriptionOverflow && !showFullDescription ? 'with-overflow' : ''} ${showFullDescription ? 'show-full' : ''}`} ref={ref}  onClick={(e) => {
                if (e?.target?.href || e?.target?.parentElement?.href) {
                  window.open(e.target?.href || e.target.parentElement.href, '_new', 'noopener=yes');
                  e.preventDefault();
                }
              }}>
                <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(module?.description)}} />
              </div>

              {isDescriptionOverflow && !showFullDescription ?
                <div className="module-description-show-full-container">
                  <Button color="primary" outline size="sm" onClick={() => { setShowFullDescription(true) }}>
                    Show full summary
                  </Button>
                </div>
              : null}
            </div>

            {resources?.length || (role && editAllowedRoles.includes(role)) || User.is_superadmin ?
              <div className="module-detail-section module-detail-resources">
                <div className="module-detail-section-header">
                  <h3>Module resources</h3>

                  {(role && editAllowedRoles.includes(role)) || User.is_superadmin ?
                    <Button color="none" outline size="sm" type="button" className="title-button add-button" onClick={() => {
                      navigate(`/add-module-resource/${projectId}/${moduleId}`);
                    }}>Add</Button>
                  : null}
                </div>
                {/*<p className="module-detail-section-header-help-text">Note: These are only resources specific to this module. To see all resources in this project, go back to the <Link to={`/project-detail/${projectId}`}>project page</Link>.</p>*/}

                {resources?.length ?
                  <ul className="module-resources-list">
                    {resources?.map((item, idx) => {
                      return (
                        <li key={idx} className="module-resource-card">
                          <div>
                            <ResourceCard projectId={projectId} resource={item} isDisabled={false} hideModuleIndicator={true} />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                : null }
              </div>
            : null}

            {subModules?.length ?
              <div className="module-detail-section module-detail-sub-modules">
                <div className="module-detail-section-header">
                  <h3>Sub-modules</h3>
                </div>
                {subModules?.length ?
                  <ul className="module-sub-modules-list">
                    {subModules?.map((item, idx) => {
                      return (
                        <li key={idx} className="module-sub-module-card">
                          <div>
                            <ModuleCard projectId={projectId} module={item} isDisabled={false} />
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                : null }
              </div>
            : null}
            {/*Commenting this out for now, to reduce clutter. Add back later if feel necessary*/}
            {/*<div className="module-detail-section module-detail-solutions">
              <div className="module-detail-section-header">
                <h3>Module-specific solutions</h3>

                {(role && contributorAllowedRoles.includes(role)) || User.is_superadmin ?
                  <Button color="none" outline size="sm" type="button" className="title-button add-button" onClick={() => {
                    navigate(`/add-module-solution/${projectId}/${moduleId}`);
                  }}>Add</Button>
                : null}
              </div>
              <p className="module-detail-section-header-help-text">Note: These are only solutions specific to this module. To see all solutions in this project, go back to the <Link to={`/project-detail/${projectId}`}>project page</Link>.</p>

              {solutions?.length ?
                <ul className="module-solutions-list">
                  {solutions?.map((item, idx) => {
                    return (
                      <li key={idx} className="module-solution-card">
                        <div>
                          <SolutionCard projectId={projectId} solution={item} isDisabled={false} />
                        </div>
                      </li>
                    );
                  })}
                </ul>
              : null }
            </div>*/}
          </div>
        : null }

        {(role && editAllowedRoles.includes(role)) || User.is_superadmin ?
          <div className="module-detail-actions form-footer">
            <div className="module-detail-secondary-actions single-action">
              <Menu menuButton={
                    <MenuButton color="link" size="sm" className="module-detail-more-actions" onClick={() => {
                    }}>
                      <img src={process.env.PUBLIC_URL + '/icons/ellipsis.png'} alt="More actions" />
                    </MenuButton>
                  }
                position="auto"
                direction="top"
                align="end"
                arrow={true}
                gap={4}
                >
                <MenuItem onClick={() => { navigate('/edit-project-module/' + projectId + '/' + moduleId ); }}>Edit module</MenuItem>
                {role === 'owner' ?
                  <MenuItem onClick={() => { setShowDeleteConfirmationModal(true); }}>Delete module</MenuItem>
                : null}
              </Menu>
            </div>
          </div>
        : null}
      </div>

      {showDeleteConfirmationModal ?
        <ConfirmationModal 
          closeLabel="Cancel"
          onClose={() => {setShowDeleteConfirmationModal(false)}}
          submitLabel="Yes"
          onSubmit={() => {
            // API call to delete the module
            deleteModule(projectId, moduleId).then((response) => {
              if (response?.data?.result?.affectedRows) {
                setDeleteInformationMessage('The module has been deleted.')
              } else {
                setDeleteInformationMessage('The module could not be be deleted.')
              }
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
            }).catch((error) => {
              setDeleteInformationMessage('The module could not be be deleted.')
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
              // logError(error);
            });
          }}
          headerMessage="Confirmation"
          bodyMessage="Are you sure you want to delete this module? This action cannot be undone."
          />
      : null}

      {showDeleteInformationModal ?
        <InformationModal 
          closeLabel="Close"
          onClose={() => {
            setShowDeleteInformationModal(false);
            navigate('/project-detail/' + projectId);
          }}
          headerMessage="Delete module"
          bodyMessage={deleteInformationMessage}
          />
      : null}

      {module?.description && showExpandedSummary ?
        <RichTextModal 
          onClose={() => {
            setShowExpandedSummary(false);
          }}
          title="Module summary"
          body={module.description}
          />
      : null}
    </div>
  );
}