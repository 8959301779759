export function unixToDate(UNIX_timestamp){
  var a = new Date(UNIX_timestamp * 1000);
  var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
  var year = a.getFullYear();
  var month = months[a.getMonth()];
  var date = a.getDate();
  var hour = a.getHours();
  var min = a.getMinutes();
  var sec = a.getSeconds();
  var time = date + ' ' + month + ' ' + year + ' ' + hour + ':' + min + ':' + sec ;
  return time;
}

export function dateToUnix(d) {
  return parseInt((d.getTime() / 1000).toFixed(0));
}

export function toSqlDatetime(inputDate) {
  // const date = new Date(inputDate);
  // const dateWithOffest = new Date(date.getTime() - (date.getTimezoneOffset() * 60000));

  // return dateWithOffest
  //   .toISOString()
  //   .slice(0, 19)
  //   .replace('T', ' ');
  return inputDate.toISOString().slice(0, 19).replace('T', ' ');
}

export function toUTCDate(dt) {
  return new Date(toSqlDatetime(dt));
}