import React, { useState, useEffect, useContext } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageError from '../messages/PageError';
import PageSuccess from '../messages/PageSuccess';
import { countryOptions } from '../../constants/countries';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import EditSkills from '../common/EditSkills';
import Spinner from '../common/Spinner';
// import ChangePasswordModal from "../common/ChangePasswordModal";
import DeleteAccountModal from "../common/DeleteAccountModal";
import UserProfileModal from "../common/UserProfileModal";

import { toUTCDate } from '../../util/dateTime';

import "./Profile.styles.css";

export default function Profile() {
  const [showSkillsModal, setShowSkillsModal] = useState(false);
  // const [showChangePasswordModal, setShowChangePasswordModal] = useState(false);
  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);
  const toggleSkillsModal = () => setShowSkillsModal(!showSkillsModal);
  const [pageErrorMessage, setPageErrorMessage] = useState('');
  const [pageSuccessMessage, setPageSuccessMessage] = useState('');

  const { User } = useContext(UserContext);
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getUser, postUpdateUser, logView, logError, upgradeUrl } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  const [skills, setSkills] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [premiumTill, setPremiumTill] = useState('');
  const [userObj, setUserObj] = useState(null);
  // const [dob, setDob] = useState(new Date());
  // const [phone, setPhone] = useState('');
  const [about, setAbout] = useState('');
  const [isGetProjectUpdateEmails, setGetProjectUpdateEmails] = useState(false);
  const [isGetFeatureUpdateEmails, setGetFeatureUpdateEmails] = useState(false);
  const [showUserProfileModal, setShowUserProfileModal] = useState(false);

  useEffect(() => {
    setPageTitle('Profile');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('profile');
  }, []);

  useEffect(() => {
    if (User) {
      fetchData();
    }
  }, [User]);

  const fetchData = function() {
    let solution;
    setPageErrorMessage(null);
    setPageErrorMessage('');

    if (User?.id) {
      getUser(User?.id).then((response) => {
        if (response?.data?.length) {
          const userObj = response.data[0];
// console.log(userObj);
// console.log(new Date(Date.parse(userObj.premium_till)));
// console.log(new Date());
          setSkills(userObj.skills);
          setName(userObj.name);
          setEmail(userObj.email);
          // setDob(new Date(userObj.dob));
          // setPhone(userObj.phone);
          setAbout(userObj.about);

          setPremiumTill(new Date(Date.parse(userObj.premium_till)));
          setUserObj(userObj);

          setGetProjectUpdateEmails(userObj.get_project_update_emails);
          setGetFeatureUpdateEmails(userObj.get_feature_update_emails);
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occurred loading your profile. Please refresh the page and try again.');
      });      
    }
  };

  const updateProfile = () => {
    setPageErrorMessage(null);
    setPageSuccessMessage(null);

    postUpdateUser(name, about, skills, isGetProjectUpdateEmails, isGetFeatureUpdateEmails).then((response) => {
      if (response?.data?.result?.affectedRows) {
        setPageSuccessMessage('Your profile was saved.');
      } else {
        setPageErrorMessage('An error occurred saving your profile. Please refresh the page and try again.');
      }
    }).catch((error) => {
      // logError(error);
      setPageErrorMessage('An error occurred saving your profile. Please refresh the page and try again.');
    });
  };

  return (
    <div className="profile full-screen-page">
      <Helmet>
        <title>Profile settings - Project Klub</title>
      </Helmet>

      <div className="full-screen-header">
        <h2>Profile settings</h2>

{/*        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>*/}

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body profile-container">

        {pageErrorMessage ?
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {pageSuccessMessage ?
          <div className="page-error-container">
            <PageSuccess messageText={pageSuccessMessage} />
          </div>
        : null}

        <div className="profile-settings">
          {userObj?
            <div className="premium-section">
              {premiumTill && premiumTill > toUTCDate(new Date()) ?
                <div>
                  <h3><span className="project-price premium"><span>Premium</span></span> account</h3>
                  <p>Your account has an active Premium status till {premiumTill.toDateString()}.</p>
                </div>
              :
                <div>
                  <h3>Upgrade to <span className="project-price premium"><span>Premium</span></span></h3>
                  <p>Upgrade your account to a Premium account to join and build our best projects!</p>
                  <ul>
                    <li>Since we are in early alpha, we are offering a FREE upgrade to Premium membership.</li>
                    <li>This is a limited early-access offer that is likely to be active as long we are in early alpha.</li>
                    <li>This does not auto-renew. If our early alpha free Premium membership offer is no longer active when your Premium access expires, you will have the option of buying additional Premium membership tenure.</li>
                  </ul>

                  <div className="action-container">
                    {/*<Button color="primary" onClick={() => {
                      // TBD
                    }}>
                      Upgrade now
                    </Button>*/}

                    <a className="primary" href={upgradeUrl}>Upgrade now</a>
                  </div>
                </div>
              }
            </div>
          :
            <Spinner />
          }

          {/*<a href={upgradeUrl}>Go Premium</a>*/}


          <h3>Profile Settings</h3>

          <Form className="profile-settings-form">

            <div className="non-editable-row">
              <div className="form-group username-container">
                <Label for="profile-username" className="full-width">
                  Username
                </Label>
                <p className="value-display">{User?.username}</p>
              </div>

              <div className="form-group email-container">
                <Label for="profile-email" className="full-width">
                  Email
                </Label>
                <p className="value-display">{email}</p>
              </div>

              {User?.country ?
                <div className="form-group country-container">
                  <Label for="profile-country" className="full-width">
                    Country
                  </Label>
                  <p className="value-display">{countryOptions.find(item => item.value === User.country)?.label || ''}</p>
                </div>
              : null}

              {/*Remove phone for now*/}
              {/*<div className="form-group phone-container">
                <Label for="profile-phone" className="full-width">Phone number</Label>
                <Input
                  id="profile-phone"
                  name="profile-phone"
                  placeholder="+919876543210"
                  type="tel"
                  value={phone}
                  onChange={(e)=>{ setPhone(e.target.value) }}
                />
              </div>*/}
            </div>

            <div className="form-row">
              <div className="form-group name-container">
                <Label for="profile-name" className="full-width">Name</Label>
                <Input
                  id="profile-name"
                  name="profile-name"
                  placeholder="John Doe"
                  type="text"
                  value={name}
                  onChange={(e)=>{ setName(e.target.value) }}
                />
              </div>

              {/*Remove dob field for now*/}
              {/*<div className="form-group dob-container">
                <Label for="profile-dob" className="full-width">Date of birth</Label>
                <DatePicker
                  id="profile-dob"
                  name="profile-dob"
                  className="form-control"
                  dateFormat="dd/MM/yyyy"
                  selected={dob}
                  onChange={(date) => setDob(date)}
                  />
              </div>*/}
            </div>

            <div className="form-group about-container">
              <Label for="profile-about" className="full-width">About</Label>
              <Input
                id="profile-about"
                name="profile-about"
                type="textarea"
                value={about}
                onChange={(e)=>{ setAbout(e.target.value) }}
              />
            </div>
          </Form>

          <div className="form-group profile-skills">
            <h3>Skills</h3>

            <EditSkills
              skills={skills}
              onUpdate={(updatedSkills) => { setSkills(updatedSkills); }}
              />
          </div>

          <div className="form-group profile-email-preferences">
            <h3>Email preferences</h3>

            <div className="email-preference">
              <label>
                <Input type="checkbox" checked={true} disabled={true} />

                <span className="email-preference-label">Basic project alerts</span>
                <span className="email-preference-help-text">Example: Project invites, accept / decline events when you request to join a project. These cannot be turned off.</span>
              </label>
            </div>

            <div className="email-preference">
              <label>
                <Input type="checkbox" checked={true} disabled={true} />

                <span className="email-preference-label">Platform updates</span>
                <span className="email-preference-help-text">Example: Major updates that affect how you use the platform, changes in terms, pricing, etc. These cannot be turned off.</span>
              </label>
            </div>

            <div className="email-preference">
              <label>
                <Input type="checkbox"
                  checked={isGetProjectUpdateEmails}
                  onChange={(e) => {
                    setGetProjectUpdateEmails(e.target.checked);
                  }} />

                <span className="email-preference-label">Project update summaries</span>
                <span className="email-preference-help-text">A periodic digest outlining updates to your project. Example: updates to solutions, comments, modules, resources, etc.</span>
              </label>
            </div>

            <div className="email-preference">
              <label>
                <Input type="checkbox"
                  checked={isGetFeatureUpdateEmails}
                  onChange={(e) => {
                    setGetFeatureUpdateEmails(e.target.checked);
                  }} />
                <span className="email-preference-label">Feature announcements</span>
                <span className="email-preference-help-text">New feature updates, or changes to existing features</span>
              </label>
            </div>
          </div>

          <div className="profile-settings-button-container">
            <Button color="primary" onClick={updateProfile}>
              Update profile
            </Button>
          </div>
        </div>


        <div className="profile-preview-container">
          <h3>Profile preview</h3>

          <p>See what your profile looks like to other users.</p>

          <div className="profile-actions profile-settings-button-container">
            <Button color="secondary" outline onClick={() => {
                setShowUserProfileModal(true);
              }}>
              Show preview
            </Button>
          </div>
        </div>

        <div className="profile-action-container">
          <h3>More actions</h3>

          <div className="profile-actions">
            {/*<Button color="secondary" outline onClick={() => {
                setShowChangePasswordModal(true);
              }}>
              Change password
            </Button>*/}

            <Button
              className="profile-action-delete"
              color="secondary" outline 
              onClick={() => {
                setShowDeleteAccountModal(true);
              }}>
              Delete account
            </Button>
          </div>
        </div>
      </div>

      {/*{showChangePasswordModal ?
        <ChangePasswordModal 
          withCancel={true}
          onCancel={() => {
            setShowChangePasswordModal(false);
          }}
          onSuccess={() => {
            setShowChangePasswordModal(false);
          }} />
      : null}*/}

      {showDeleteAccountModal ?
        <DeleteAccountModal 
          onCancel={() => {
            setShowDeleteAccountModal(false);
          }} />
      : null}

      {showUserProfileModal && User?.id ?
        <UserProfileModal 
          userId={User.id}
          onClose={() => setShowUserProfileModal(false)}
          />
      : null}

    </div>
  );
}