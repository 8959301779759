import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./About.scss";

export default function About() {
  const { logView } = useContext(DataContext);
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('About us');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
  }, []);

  useEffect(() => {
    logView('about');
  }, []);

  return (
    <div className="terms full-screen-page about-page">
      <Helmet>
        <title>About us - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        <h2>About Project Klub</h2>

{/*        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>*/}

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body about-body">
        <div className="about-page-section">
          <h3>About Us</h3>
          <p>Project Klub is a platform to <strong>practice building web projects.</strong></p>

          <p>This is a space where web-devs and aspiring web-devs get to tackle web problems in bite-sized pieces, learning to think through real-world challenges one piece at a time.</p>

          <p>We try to make it easier to upskill and master the art of building web projects in pieces that are not intimidating to start, yet are complex and relevant enough to equip the user to build larger things as they go.</p>

          <p>Our focus is on breaking web projects down into grokkable pieces, providing just enough hints and resources to get started and figure it out, but leaving enough space for the user to build the mental muscle of figuring it out on their own.</p>

          <p>Our tools are designed to make it easy to create and share solutions, discuss, exchange feedback, ask for and offer help, grow together.</p>
        </div>

        <div className="about-page-section">
          <h3>Pricing</h3>
          <p>We have "Free" and "Premium" projects.</p>

          <p>Since we are in early alpha, we are offering a FREE upgrade to Premium membership. This is a limited early-access offer that is likely to be active as long we are in early alpha.</p>

          <p>This does not auto-renew. If our early alpha free membership offer has expired by then, you will have the option of buying additional Premium membership tenure after this expires.</p>

          <p>Go to your <Link className="about-link" to="/profile">profile</Link> and click the "Upgrade to Premium" button to get access to our Premium projects!</p>
        </div>

        <div className="about-page-section">
          <h3>Contact us</h3>

          <p>
            If you have any feedack, we'd love to hear from you!&nbsp;

            <Link className="about-link" to={'/contact'}>Use the contact form</Link> to send us any questions, concerns or thoughts you may have.
          </p>

          <p>
            We hope you have a great time building things!
          </p>
        </div>

        {/*<p>
          Project Klub is a platform to start or find & join web projects.
        </p>

        <p>
          This is a space where web-devs can build stuff, exchange help & feedback, and grow together.
        </p>

        <p>
          Our projects belong to two broad types.
        </p>

        <ul>
          <li><strong>Practice projects</strong> to develop skills by solving problems, comparing solutions with others', getting feedback and exchanging help.</li>
          <li><strong>Standalone projects</strong>, which are real-world projects users can start or join. These can be hobbies, school projects, startups, open-source or any other "real" projects.</li>
        </ul>

        <p>
          This platform is built with this vision of making it easier to upskill and master the art of building web projects.
        </p>

        <p>
          If you have any feedack, we'd love to hear from you. Click 'Contact / Feedback' on the navigation menu and use the contact form to send us any questions, concerns or thoughts you may have.
        </p>

        <p>
          We hope you have a great time building things!
        </p>*/}

        <div className="about-illustration-container">
          <img src={process.env.PUBLIC_URL + '/vector/about.svg'} alt="About Project Klub" className="about-illustration" />
        </div>

      </div>

      {/*<div className="about-actions">
        <Button color="secondary" outline size="sm" onClick={() => {
          navigate(location.key === 'default' ? '/projects/' : -1);
        }}>
          Go back
        </Button>
      </div>*/}
    </div>
  );
}