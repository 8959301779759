import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./Updates.scss";

export default function Updates() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled, IsLoading } = useContext(PageContext);
  const { fetchEntityTitles, logView, logError } = useContext(DataContext);
  const { userUpdates } = useContext(UserContext);
  const [userUpdatesWithUpdatesWithTitles, setUserUpdatesWithUpdatesWithTitles] = useState();
  // const [userUpdatesWithoutUpdatesWithTitles, setUserUpdatesWithoutUpdatesWithTitles] = useState([]);
  const [updatesDisplay, setUpdatesDisplay] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Updates');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('updates');
  }, []);

  const entityTypeDisplay = {
    project: "PROJECT",
    solution: "SOLUTION"
  };

  const entitySubTypeDisplay = {
    standalone: "STANDALONE",
    guided: "PRACTICE"
  };

  const entityTypeThumbnail= {
    project: process.env.PUBLIC_URL + '/vector/project-thumbnail-standalone.png',
    solution: process.env.PUBLIC_URL + '/icons/solution.png',
  };

  const subTypeThumbnails = {
    standalone: '/vector/project-thumbnail-standalone.png',
    guided: '/vector/project-thumbnail-guided.png',
  };

  const updateTypeDisplaySets = {
    joinees: {
      userInvite: "You are invited to join!",
      userAcceptInvite: "Invite(s) accepted",
      userDeclineInvite: "Invite(s) declined",
      userRequestJoin: "Join request(s)",
      userJoin: "User(s) joined",
      joinAccept: "Request to join accepted",
      joinDecline: "Sorry, the project owner declined your request to join.",
      roleUpdate: "Role updated",
      userLeave: "User(s) left",
    },
    project: {
      edit: "Project edited",
      statusChangeOpen: "Project is now published",
      statusChangePending: "Project status changed to pending",
    },
    modules: {
      moduleAdd: "Added",
      moduleEdit: "Edited",
      moduleDelete: "Removed",
    },
    resources: {
      resourceCreate: "Added",
      resourceEdit: "Edited",
      resourceDelete: "Removed",
    },
    solutions: {
      solutionCreate: "Added",
      solutionEdit: "Edited",
      solutionDelete: "Removed",
    },
  };


  const getUpdatesToDisplay = function(updateSet) {
    const result = updateSet.map(updateSetItem => {
      const updateSetObj = JSON.parse(JSON.stringify(updateSetItem));
      let updatesDisplay = {};
      const lastVisit = new Date(updateSetItem.lastVisit);

      for (var set in updateTypeDisplaySets) {
        let lineSet = [];
        for (var item in updateTypeDisplaySets[set]) {
          if (updateSetItem[item] && lastVisit < new Date(updateSetItem[item])) {
            lineSet.push(updateTypeDisplaySets[set][item]);
          }
        }
        if (lineSet.length) {
          updatesDisplay[set] = lineSet;
        }
      }

      updateSetObj.updatesDisplay = updatesDisplay;

      return updateSetObj;
    });

    return result;
  };

  useEffect(() => {
    if (userUpdates?.withUpdates && userUpdates?.withoutUpdates) {
      const set = userUpdates.withUpdates.concat(userUpdates.withoutUpdates);
      const projectEntityIds = set.filter(item => item.entityType === 'project').map(item => item.entityId).toString();
      const solutionEntityIds = set.filter(item => item.entityType === 'solution').map(item => item.entityId).toString();
      if (projectEntityIds.length) {
        fetchEntityTitles('project', projectEntityIds, true).then((response) => {
          response.data.forEach(titleItem => {
            const setItem = set.find(item => item.entityType === 'project' && item.entityId === titleItem.id);
            if (setItem) {
              setItem.title = titleItem.title;
              setItem.subType = titleItem.type;
            }
          });

          if (solutionEntityIds.length) {
            fetchEntityTitles('solution', solutionEntityIds, true).then((response1) => {
              response1.data?.forEach(titleItem => {
                const setItem = set.find(item => item.entityType === 'solution' && item.entityId === titleItem.id);
                if (setItem) {
                  setItem.title = titleItem.title;
                  setItem.projectId = titleItem["project_id"];
                }

                setUserUpdatesWithUpdatesWithTitles(getUpdatesToDisplay(userUpdates.withUpdates));
                // setUserUpdatesWithoutUpdatesWithTitles(userUpdates.withoutUpdates);
              });
            }).catch((error1) => {
              // logError(error1);
              console.log(error1);
            });
          } else {
            setUserUpdatesWithUpdatesWithTitles(getUpdatesToDisplay(userUpdates.withUpdates));
            // setUserUpdatesWithoutUpdatesWithTitles(userUpdates.withoutUpdates);
          }
        }).catch((error) => {
          // logError(error);
          console.log(error);
        });
      }
    }
  }, [userUpdates]);

  return (
    <div className="updates full-screen-page">
      <Helmet>
        <title>Project updates - Project Klub</title>
      </Helmet>

      <div className="full-screen-header">
        <h2>Project updates</h2>

{/*        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>
*/}

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body updates-list">
        <ul className="update-cards-with-updates">
          {userUpdatesWithUpdatesWithTitles?.map((item, idx) => {
            return (
              <li key={idx} className="update-card">
                <div className="update-card-body">

                  <div className="entity-display">
                    <div className="entity-thumbnail">
                      {item.subType ?
                        <img src={subTypeThumbnails[item.subType]} alt={entitySubTypeDisplay[item.subType]} />
                      :
                        <img src={entityTypeThumbnail[item.entityType]} alt={entityTypeDisplay[item.entityType]} />
                      }
                    </div>
                    <div className="entity-title">
                      <h3>{item.title}</h3>
                      <div className="entity-type">
                        {item.subType ? <>{entitySubTypeDisplay[item.subType]} </> : null}
                        {entityTypeDisplay[item.entityType]}
                      </div>
                    </div>
                  </div>


                  {/*<p className="updates-message">New updates to this {item.entityType} after your last visit:</p>*/}

                  <ul className="entity-updates-list">
                    {item.hasUpdates ?
                      <>
                        {item.updatesDisplay?.project ?
                          <li className="project">
                            <span><strong>Project updates:</strong><span className="update-values"> {item.updatesDisplay.project.join(', ')}</span></span>
                          </li>
                        : null}

                        {item.updatesDisplay?.joinees ?
                          <li className="joinees">
                            <span><strong>Project joinees:</strong><span className="update-values"> {item.updatesDisplay.joinees.join(', ')}</span></span>
                          </li>
                        : null}

                        {item.updatesDisplay?.modules ?
                          <li className="modules">
                            <span><strong>Modules:</strong><span className="update-values"> {item.updatesDisplay.modules.join(', ')}</span></span>
                          </li>
                        : null}

                        {item.updatesDisplay?.resources ?
                          <li className="resources">
                            <span><strong>Resources:</strong><span className="update-values"> {item.updatesDisplay.resources.join(', ')}</span></span>
                          </li>
                        : null}

                        {item.updatesDisplay?.solutions ?
                          <li className="solutions">
                            <span><strong>Solutions:</strong><span className="update-values"> {item.updatesDisplay.solutions.join(', ')}</span></span>
                          </li>
                        : null}
                      </>
                    : null}

                    {item.hasDiscussionUpdates ?
                      <li className="comments">
                        <span><strong>Comments:</strong><span className="update-values"> New comments</span></span>
                      </li>
                    : null}
                  </ul>

                  {/*Uncomment these later if we decide to display last visit timestamp*/} 
                  {/*<div>
                    {item.lastVisit ?
                      <p>Last visited: {new Date(item.lastVisit).toString()}</p>
                    : null}

                    {item.lastDiscussionVisit ?
                      <p>Last visited comments: {new Date(item.lastDiscussionVisit).toString()}</p>
                    : null}
                  </div>*/}
                </div>

                <div className="update-card-action">
                  {item.entityType === "project" ?
                    <Button color="primary" outline onClick={() => {
                      navigate(`/project-detail/${item.entityId}`);
                    }}>
                      Go to project
                    </Button>
                  : null}

                  {item.entityType === "solution" ?
                    <Button color="primary" outline onClick={() => {
                      navigate(`/solution-detail/${item.projectId}/${item.entityId}`);
                    }}>
                      Go to solution
                    </Button>
                  : null}
                </div>
              </li>
            );
          })}
        </ul>

        {userUpdatesWithUpdatesWithTitles && !userUpdatesWithUpdatesWithTitles.length  && !IsLoading ?
          <div className="updates-empty-state">
            <img src={process.env.PUBLIC_URL + '/vector/updates-empty-state.png'} alt="No updates" />
            <p>No new updates on your projects & solutions</p>

            <Button color="primary" size="sm" className="updates-empty-state-my-projects-button" onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
              Close
            </Button>
          </div>
        : null}
      </div>
    </div>
  );
}