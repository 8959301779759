import React from 'react';
import { useNavigate, Link } from "react-router-dom";

import "./ProfileProjectCard.scss";

const solutionStatusDisplay = {
  in_progress: "In progress",
  completed: "Completed",
  discarded: "Discarded"
};

function ProfileProjectCard(props) {
  const navigate = useNavigate();
  const {
    project,
    solutionStatus,
  } = props;

  const projectThumbnail = project?.carousel_images ? project.carousel_images.split(',')[0] : null;

  return (
    <Link className={`profile-project-card-container ${solutionStatus} ${project.is_premium ? 'premium' : ''}`} to={`/project-detail/${project.id}`} target="_new">

      <div className="profile-project-card">

        <div className="profile-project-card-main">
          <div className="profile-project-card-thumbnail-section">
            {projectThumbnail ?
              <img src={projectThumbnail} alt={project.title} className="small-project-thumbnail" />
            :
              <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="small-project-thumbnail" />
            }

            {project.is_premium ?
              <div className="premium-container">
                <span className="project-price premium"><span>Premium</span></span>
              </div>
            : null}
          </div>

          <div className="profile-project-card-body">
            <span className="profile-project-name dark-gray-link">{project.title}</span>

            {project.intro_text ?
              <p className="profile-project-card-intro"> &quot;{project.intro_text} &quot;</p>
            : null}

            <div className={`profile-project-card-solution-status ${solutionStatus || 'yet-to-start' }`}>
              <img className="solution-icon" src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Solution" />
              <span className="solution-label">Solution: </span>

              {solutionStatus ?
                <span>{solutionStatusDisplay[solutionStatus]}</span>
              :
                <span>Yet to start</span>
              }
            </div>
          </div>
        </div>
      </div>
    </Link>
  );
}

export default ProfileProjectCard;
