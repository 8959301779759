import React from 'react';
import { Link } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ResourceCard.scss";

function ResourceCard(props) {
  const {
    resource,
    projectId,
    isDisabled,
    hideModuleIndicator,
    noLink
  } = props;

  return (
    // <p>foo</p>
    <>
      {noLink ?
        <div>
          <div className="resource-card" role="button">
            <img className="resource-card-icon" src={process.env.PUBLIC_URL + '/icons/resource-thumbnail.png'} alt="Resource" />

            <span className="resource-card-title">{resource.title}</span>

            {resource.modules?.length && !hideModuleIndicator ?
              <span className="resource-card-scope resource-card-module-specific">Module-specific</span>
            : null }
          </div>
        </div>
      :
        <Link to={`/resource-detail/${projectId}/${resource.id}`} onClick={(e)=> { if (isDisabled) { e.preventDefault(); }}}>
          <div className="resource-card" role="button">
            <img className="resource-card-icon" src={process.env.PUBLIC_URL + '/icons/resource-thumbnail.png'} alt="Resource" />

            <span className="resource-card-title">{resource.title}</span>

            {resource.modules?.length && !hideModuleIndicator ?
              <span className="resource-card-scope resource-card-module-specific">Module-specific</span>
            : null }
          </div>
        </Link>
      }
    </>
  );
}

export default ResourceCard;
