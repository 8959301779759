export const isName = function (val) {
  // console.log('isName: ' +val + ' ' +  /^[A-Za-z\s]*$/.test(val));
  return val.length && /^[A-Za-z\s]*$/.test(val);
};

export const isUsername = function (val) {
  // console.log('isUsername: ' +val + ' ' +  /^[a-z0-9_\.]+$/.test(val));
  return /^[a-z0-9_\.]+$/.test(val);
};

export const isPassword = function (val) {
  // console.log('isPassword: ' +val + ' ' +  /^[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(val));
  return /^[a-zA-Z0-9!@#$%^&*]{6,16}$/.test(val);
};

export const isEmail = function (val) {
  // console.log('isEmail: ' +val + ' ' +  /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(val));
  return /^[a-z0-9.]{1,64}@[a-z0-9.]{1,64}$/i.test(val);
};

export const isPhone = function (val) {
  // console.log('isPhone: ' +val + ' ' +  /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(val));
  return /^[\+]?[(]?[0-9]{3}[)]?[-\s\.]?[0-9]{3}[-\s\.]?[0-9]{4,6}$/im.test(val);
};

export const isDate = function (val) {
  // console.log('isDate: ' +val + ' ' +  val instanceof Date);
  return val instanceof Date;
};

export const isPastDate = function (val) {
  // console.log('isPastDate: ' +val + ' ' +  (val instanceof Date && val < new Date()));
  return val instanceof Date && val < new Date();
};

