import React, { useContext, useEffect, useState, useLayoutEffect, createRef } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { Helmet } from 'react-helmet-async';

import { keysToCamel } from '../../util/caseConverters';
import SkillsDisplay from "../cards/SkillsDisplay";
import HelpRequestCard from "../cards/HelpRequestCard";
// import ModuleCard from "../cards/ModuleCard";
import ModuleCardV2 from "../cards/ModuleCardV2";
import ResourceCard from "../cards/ResourceCard";
import SolutionCard from "../cards/SolutionCard";
import ProjectParticipantCard from "../cards/ProjectParticipantCard";
import ConfirmationModal from "../common/ConfirmationModal";
import InformationModal from "../common/InformationModal";
import AddParticipantModal from "../participants/AddParticipantModal";
import GettingStartedModal from "../common/GettingStartedModal";
import EditProjectParticipants from "../participants/EditProjectParticipants";
import EditHelpRequest  from "../helpRequests/EditHelpRequest";
import ViewHelpRequest  from "../helpRequests/ViewHelpRequest";
import RichTextModal from "../common/RichTextModal";
import ImageCarouselModal from "../common/ImageCarouselModal";
import UserProfileModal from "../common/UserProfileModal";
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import PageError from '../messages/PageError';
import PageInfo from '../messages/PageInfo';

import Discussion from '../discussion/Discussion';

import { toUTCDate } from '../../util/dateTime';

import "./ProjectDetail.scss";
import "./ProjectDetailProgressMeter.styles.css";

const typeDisplay = {
  standalone: 'Standalone',
  guided: 'Practice',
};

const roleDisplay = {
  'administrator': 'Administrator',
  'contributor': 'Participant',
  'owner': 'Owner',
  'outsider': 'Non-participant',
  'pending': 'Pending',
};

const withProgressMeter = true;

export default function ProjectDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getLogin, getProject, getProjectRole, getProjectModules, getProjectResources, getGenericResources, getProjectSolutions, getProjectSolutionCommentCount, getProjectParticipants, deleteProject, acceptInvite, declineInvite, leaveProject, getProjectHelpRequest, getProjectHelpRequests, updateProjectHelpRequest, updateEntityVisit, updateProjectStatus, markProjectGettingStartedModalSeen, logView, logError,getAllClaps } = useContext(DataContext);
  const { User, userUpdates, refreshUserUpdates } = useContext(UserContext);
  const { id } = useParams();
  const [project, setProject] = useState();
  const [role, setRole] = useState('');
  const [helpRequestText, setHelpRequestText] = useState('');
  const [helpRequestContactEmail, setHelpRequestContactEmail] = useState(User?.email || '');
  const [isHelpRequestContactEmailPreferred, setHelpRequestContactEmailPreferred] = useState(false);
  const [isHelpRequestContactCommentsPreferred, setHelpRequestContactCommentsPreferred] = useState(false);
  const [projectModules, setProjectModules] = useState();
  const [projectResources, setProjectResources] = useState();
  const [genericResources, setGenericResources] = useState();
  const [projectHelpRequests, setProjectHelpRequests] = useState();
  const [projectSolutions, setProjectSolutions] = useState();
  const [hasOwnSolutions, setHasOwnSolutions] = React.useState(true);
  const [hasPublishedSolutions, setHasPublishedSolutions] = React.useState(true);  
  const [projectParticipants, setProjectParticipants] = useState();
  const [isDiscussionOpen, setDiscussionOpen] = useState(false);
  const [hasUnreadComments, setHasUnreadComments] = useState(false);
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDeleteInformationModal, setShowDeleteInformationModal] = useState(false);
  const [showAddParticipantModal, setShowAddParticipantModal] = useState(false);
  const [showEditProjectParticipants, setShowEditProjectParticipants] = useState(false);
  const [deleteInformationMessage, setDeleteInformationMessage] = useState('The project has been deleted.');
  const [showEditHelpRequestModal , setShowEditHelpRequestModal] = useState(false);
  const [viewHelpRequest, setViewHelpRequest] = useState(null);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  // const [expandedImageHtml, setExpandedImageHtml] = useState('');
  // const [expandedImageIndex, setExpandedImageIndex] = useState(null);
  const [showGettingStartedModal, setShowGettingStartedModal] = useState(false);
  const [userProfileModalId, setUserProfileModalId] = useState(null);

  const ref = createRef();
  const [isDescriptionOverflow, setDescriptionOverflow] = React.useState(false);
  const [showFullDescription, setShowFullDescription] = React.useState(true);
  const [progressStep, setProgressStep] = React.useState(0);
  const [claps, setClaps] = React.useState([]);
  const [solutionCommentCounts, setSolutionCommentCounts] = React.useState([]);
  const [grandfatheredUntil, setGrandfatheredUntil] = React.useState(null);

  useLayoutEffect(() => {
    if (ref.current?.clientHeight < ref.current?.scrollHeight) {
      setDescriptionOverflow(true);
    }
  }, [ref]);

  useEffect(() => {
    if (!role || role === 'outsider') {
      setProgressStep(1);
    } else {
      if (hasOwnSolutions) {
        if (hasPublishedSolutions) {
          setProgressStep(4);
        } else {
          setProgressStep(3);
        }
      } else {
        setProgressStep(2);
      }
    }
  }, [hasOwnSolutions, hasPublishedSolutions, role]);

  const fetchData = function(showLoader) {
    setPageErrorMessage(null);

    getProject(id, showLoader).then((response) => {
      let project;
      let _role;

      if (response?.data?.length) {
        project = keysToCamel(response).data[0];
        setProject(project);

        const userId = User?.id;

        if (userId) {
          if (project?.ownerId === userId) {
            _role = 'owner';
            setRole(_role);
            fetchHelpRequest(_role, showLoader);
          } else {
            getProjectRole(userId, id, showLoader).then((response) => {
              if (response?.data?.length) {
                let { role, getting_started_dismissed, grandfathered_until } = response.data[0];
                setRole(role);
                fetchHelpRequest(role, showLoader);

                if (role === 'contributor' && !getting_started_dismissed) {
                  setShowGettingStartedModal(project.type);
                }

                if (role === 'contributor' && project?.isPremium && grandfathered_until && new Date(Date.parse(grandfathered_until)) > toUTCDate(new Date())) {
                  setGrandfatheredUntil(new Date(Date.parse(grandfathered_until)));
                } else {
                  setGrandfatheredUntil(null);
                }
              } else {
                _role = 'outsider';
                setRole(_role);
              }
            }).catch((error) => {
              console.log(error);
              // logError(error);
              _role = 'outsider';
              setRole(_role);
            });
          }

          updateEntityVisit(id, 'project').then((response) => {
            refreshUserUpdates();
          }).catch((error) => {
            // Silent fail
            console.log(error);
          });

        } else {
          _role = 'outsider';
          setRole(_role);
        }

        getProjectModules(id, showLoader).then((response) => {
          if (response?.data) {
            setProjectModules(response.data);
          }
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        });

        getProjectResources(id, showLoader).then((response) => {
          if (response?.data?.length) {
            // setProjectResources(response.data);

            setProjectResources(response.data);
          }
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        });

        if (project?.genericResources?.length) {
          getGenericResources(project.genericResources, showLoader).then((response) => {
            if (response?.data) {
              setGenericResources(response.data);
            }
          }).catch((error) => {
            console.log(error);
            // logError(error);
            setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
          });
        }

        getProjectHelpRequests(id, showLoader).then((response) => {
          if (response?.data) {
            const temp = response.data.filter(item => item.user === User?.id).concat(response.data.filter(item => item.user !== User?.id));
            setProjectHelpRequests(temp);
          }
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        });

        getProjectSolutions(id, showLoader).then((response) => {
          if (response?.data) {
            const temp = response.data.filter(item => item.owner_id === User?.id).concat(response.data.filter(item => item.owner_id !== User?.id))
            setProjectSolutions(temp);

            const _hasOwnSolutions = temp.some(item => item.owner_id === User?.id);
            const _hasPublishedSolutions = temp.some(item => item.owner_id === User?.id && item.status === 'completed');

            setHasOwnSolutions(_hasOwnSolutions);
            setHasPublishedSolutions(_hasPublishedSolutions);
          }
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        });

        getProjectParticipants(id, showLoader).then((response) => {
          if (response?.data) {
            setProjectParticipants(response.data);
          }
        }).catch((error) => {
          console.log(error);
          // logError(error);
          setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
        });

        getAllClaps().then((response) => {
          setClaps(response.data);
        }).catch((error) => {
          console.log(error);
          // logError(error);
          // No-op, silent fail
        });

        getProjectSolutionCommentCount(id).then((response) => {
          setSolutionCommentCounts(response.data);
        }).catch((error) => {
          console.log(error);
          // logError(error);
          // No-op, silent fail
        });
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
      console.log(error);
      // logError(error);
    });
  };

  const fetchHelpRequest = function(role, showLoader) {
    if (id && role && role!=='outsider' && role!=='pending' && role!=='requested') {
      getProjectHelpRequest(id, showLoader).then((response) => {
        if (response?.data?.length) {
          const result = keysToCamel(response.data[0]);
          setHelpRequestText(result.helpRequestText || '');
          setHelpRequestContactEmail(result.helpRequestContactEmail || '');
          setHelpRequestContactEmailPreferred(result.helpRequestEmailPreferred || false);
          setHelpRequestContactCommentsPreferred(result.helpRequestCommentsPreferred || false);
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occurred loading this project. Please refresh the page and try again.');
      });
    }
  };

  // useEffect(() => {
  //   getLogin().then((response) => {
  //     fetchData();
  //   }).catch((error) => {
  //     console.log(error);
  //     logView('project-detail', id);
  //     fetchData();
  //     // Silent fail
  //   });
  // }, []);

  useEffect(() => {
    setPageTitle('Project');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    let intervalId;

    // getLogin().then((response) => {
      fetchData();

      if (User?.id) {
        logView('project-detail', id, User?.id);

        intervalId = setInterval(() => {
          if (document.hasFocus()) {
            fetchData(false);
          }
        }, 10000);
      } else {
        logView('project-detail', id);
      }
    // }).catch((error) => {
    //   console.log(error);
    //   logView('project-detail', id);
    //   fetchData();
    //   // Silent fail
    // });

    return () => {
      clearInterval(intervalId);
    };

    // setProject(getProject(id));
    // setProjectSolutions(getProjectSolutions(id));
    // setProjectResources(getProjectResources(id));
  }, [id, User]);

  useEffect(() => {
    if (id && userUpdates?.withUpdates) {
      const hasNewComments = !!(userUpdates.withUpdates.find(item => item.entityType === 'project' && item.entityId === parseInt(id)));
      setHasUnreadComments(hasNewComments);
    }
  }, [userUpdates]);

  return (
    <div>
      <Helmet>
        <title>{project?.title || 'Project detail'} - Project Klub</title>
      </Helmet>

      {project && isDiscussionOpen ?
        <Discussion
          projectId={id}
          entity={project}
          entityId={id}
          entityType="project"
          entityName={project?.title}
          entityUrl={`/project-detail/${id}`}
          entityThumbnailUrl={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'}
          onClose={() => {
            setDiscussionOpen(false)
            fetchData();
          }} />
      : null }

      <div className={"project-detail-page full-screen-page " + (isDiscussionOpen ? "non-scrollable" : "") + (role && role === "outsider" ? "no-gutter" : "") }>

        <div className="full-screen-header">
          {/*<h2>{typeDisplay[project?.type]} Project</h2>*/}
          <h2>Project detail</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              // if (role==='outsider') {
              //   navigate('/projects/available-projects');
              // } else {
              //   navigate('/projects/my-projects');
              // }
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {pageErrorMessage ?
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {grandfatheredUntil ?
          <div className="page-info-container">
            <PageInfo messageText={`This is now a Premium project. You will need to upgrade to a Premium account to continue participating in this project after ${grandfatheredUntil.toDateString()}.`} />
          </div>
        : null}

        {project ?
          <div className={`full-screen-body project-detail ${withProgressMeter ? "with-progress-meter" : ""} ${project?.type?.toLowerCase()} ${project?.status?.toLowerCase()}`}>

            {project.status === 'pending' ?
              <div className="project-pending-message">
                <img src={process.env.PUBLIC_URL + '/icons/info-red.png'} alt="Information" />
                <div>
                  <p>This project is in 'pending' status. It will be reviewed and published soon, subject to approval.</p>
                  <ul>
                    <li>You can start adding project modules, resources and solutions.</li>
                    <li>Some actions, like adding participants, will be available after the project is reviewed and published.</li>
                  </ul>
                </div>
              </div>
            : null}

            <div className="project-header">
              {project?.isPremium ?
                <div className="project-price premium">
                  <span>Premium</span>
                </div>
              :
                <div className="project-price free">
                  <span>Free</span>
                </div>
              }

              <p className="project-title">
                {project?.title}
              </p>
              <div className="project-header-metadata">
                {/*<div className="project-thumbnail-container">
                  <div className="project-detail-thumbnail-section">
                    <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="project-thumbnail" />
                    <span className={"project-type " + project?.type}>{typeDisplay[project?.type]}</span>
                    {!project.isPublic ?
                      <div className="project-detail-private">
                        <img src={process.env.PUBLIC_URL + '/icons/lock.svg'} alt="Private" />
                        <span>Private</span>
                      </div>
                    : null}

                    <div className="project-detail-points-to-join">
                      {project.pointsToJoin ?
                        <div>
                          <img src={process.env.PUBLIC_URL + '/icons/coin.png'} alt="Points to join" />
                          <span>{project.pointsToJoin} coins</span>
                        </div>
                      :
                         <span>FREE to join</span>
                      }
                    </div>
                  </div>
                </div>*/}
                <div className="project-metadata-container">
                  <ul className="project-metadata">
                    <li className={`project-size project-size-${project.size}`}>
                      {/*<img src={process.env.PUBLIC_URL + '/icons/size-icon.png'} alt="Size" />*/}
                      <span>{project.size}</span>
                    </li>

{/*                    <li className="project-role">
                      <img src={process.env.PUBLIC_URL + '/icons/role-icon.png'} alt="Role" />
                      <span><strong>{roleDisplay[role]}</strong></span>
                    </li>*/}

                    {/*{projectParticipants?.length ?
                      <li className="project-participant-count">
                        <span>{projectParticipants.length} participants</span>
                      </li>
                    : null}*/}

                    <li>
                      {project.skills ?
                        <div className="project-detail-skills">
                          <SkillsDisplay skills={project.skills} showLabel={false} />
                        </div>
                      : null}
                    </li>
                  </ul>
                </div>

                {role==='owner' || role==='administrator' || User?.is_superadmin ?
                  <div>
                    <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-project" onClick={() => { navigate('/edit-project/' + id ); }}>
                      Edit
                    </Button>
                  </div>
                : null}
              </div>
            </div>

            {withProgressMeter && progressStep < 5 && progressStep > 0 ?
              <>

                  {/*{progressStep < 2 ?
                    <div className="project-steps-overview-graphic-container">
                      <img className="project-steps-overview-graphic" src={process.env.PUBLIC_URL + '/vector/join-steps-graphic-6.png'} alt="Getting started" />
                    </div>
                  : null}*/}

              <div className={`project-steps-section step-${progressStep}`}>
                {progressStep < 2 ?
                  <div className="join-steps-graphic-container">
                    {project?.carouselImages?.length ?
                      <img className="join-steps-graphic" src={project.carouselImages.split(',')[0]} alt={project?.title || 'Project thumbnail'} />
                    :
                      <img className="join-steps-graphic" src={process.env.PUBLIC_URL + '/vector/join-steps-graphic-4.png'} alt="Join project" />
                    }
                  </div>
                :
                  <ul className="project-progress-meter">
                    <li>{progressStep < 2 ? "Join the project" : "Project joined"}</li>
                    <li>{progressStep < 3  ? "Start working on a solution" : "Solution started"}</li>
                    <li>{progressStep < 4  ? "Complete your solution" : "Solution completed"}</li>
                  </ul>
                }

                <div className="project-next-steps">
                  <div className="project-next-steps-inner-container">
                    <h3>
                      {progressStep < 2 ?
                        <>
                          {project.upsellNotes?.split('&&&&').length > 1 ?
                            <>{project.upsellNotes.split('&&&&')[0]}</>
                          :
                            "Getting started"
                          }
                        </>
                      : ""}
                      {progressStep > 1 && progressStep < 4 ? "Next steps" : ""}
                      {progressStep === 4 ? "Further steps" : ""}
                    </h3>

                    {progressStep === 1 ?
                      <>
                        {project?.upsellNotes?.length > 1 ?
                          <ul>
                            {project.upsellNotes?.split('&&&&').slice(1).map((item, idx) => {
                              return (<li key={idx}>{item}</li>);
                            })}
                          </ul>
                        :
                          <ul>
                            <li>Join the project</li>
                            <li>Study the brief and design mocks</li>
                            <li>Use the resources to start working on it</li>
                          </ul>
                        }

                        <div className="project-steps-metadata-outer-container">
                          <h3 className="project-steps-metadata-intro">Project stats</h3>
                          <div className="project-steps-metadata-container">
                            {project.numResources && project.numResources > 0 ?
                              <div className={`project-resources project-steps-metadata ${project.numResources < 1 ? 'zero-value' : ''}`}>
                                <img src={process.env.PUBLIC_URL + '/icons/resource-thumbnail.png'} alt="Resources" />
                                <div className="metadata-value-label">
                                  <span className="metadata-value">{project.numResources || 0}</span>
                                  <span className="metadata-label">
                                    {project.numResources === 1 ? <>resource</> : <>resources</>}
                                  </span>
                                </div>
                              </div>
                            : null}

                            <div className={`project-solutions project-steps-metadata ${project.numSolutions < 1 ? 'zero-value' : ''}`}>
                              <img src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Solutions" />
                              <div className="metadata-value-label">
                                <span className="metadata-value">{project.numSolutions || 0}</span>
                                <span className="metadata-label">{project.numSolutions === 1 ? <>solution</> : <>solutions</>}</span>
                                {/*{project.numSolutions > 0 ?
                                  <span className="metadata-secondary-label">({projectSolutions?.filter(item => item.status === 'completed')?.length || 0} completed)</span>
                                : null}*/}
                              </div>
                            </div>

                            <div className={`project-messages project-steps-metadata ${project.numMessages < 1 ? 'zero-value' : ''}`}>
                              <img src={process.env.PUBLIC_URL + '/icons/discussions.png'} alt="Comments" />
                              <div className="metadata-value-label">
                                <span className="metadata-value">{project.numMessages || 0}</span>
                                <span className="metadata-label">
                                  {project.numMessages === 1 ? <>comment</> : <>comments</>}
                                </span>
                              </div>

                            </div>
                          </div>
                        </div>

                      </>
                    : null}

                    {progressStep === 2 ?
                      <ul>
                        {projectModules?.length ?
                          <li>Go through project modules &amp; resources</li>
                        : 
                          <li>Go through project resources</li>
                        }
                        <li>Come up with an approach</li>
                        <li>Add a solution and mark it “In progress” while you work on it</li>
                        <li>Raise a help request if you need help</li>
                      </ul>
                    : null}

                    {progressStep === 3 ?
                      <ul>
                        <li>Use comments to exchange feedback, and iterate on a solution.</li>
                        <li>It is okay to describe solution approach ahead of implementation and get early feedback!</li>
                        <li>Edit your solution and mark it “Completed” when it is ready.</li>
                      </ul>
                    : null}

                    {progressStep === 4 ?
                      <ul>
                        <li>Exchange feedback using comments and iterate on your solution</li>
                        <li>Try different approaches and add more solutions</li>
                        <li>Exchange help with anyone who has rasied a help request</li>
                      </ul>
                    : null}
                  </div>
                </div>
              </div>

              {/*{progressStep < 2 ?
                <div className="project-steps-overview-graphic-container">
                  <img className="project-steps-overview-graphic" src={process.env.PUBLIC_URL + '/vector/join-steps-graphic-2.png'} alt="Getting started" />
                </div>
              : null}*/}

              </>
            : null}

            <div className="project-detail-actions action-bar">
              <div className={`project-detail-actions-container single-action`}>
                <div className="project-detail-secondary-actions">
                  {(role==='outsider') ?
                    <>
                      {User?.id ?
                        <Button color="primary" size="sm" onClick={() => {
                          navigate('/join-project/' + id );
                        }}>
                          Join this project
                        </Button>
                      :
                        <Button color="primary" onClick={() => {
                          setTopNavEnabled(true);
                          navigate('/login?project-id=' + id);
                        }}>
                          Log in / sign up to join this project
                        </Button>
                      }
                    </>
                  : null }

                  {!(role==='outsider' || role==='pending' || role==='requested') || User?.is_superadmin ?
                    <>
                      {hasOwnSolutions && !hasPublishedSolutions ? null :
                        <Button color="primary" size="sm" type="button" onClick={() => {
                          navigate('/add-project-solution/' + id );
                        }}>
                          {!hasOwnSolutions ? 'Add a solution': 'Add another solution'}
                        </Button>
                      }

                      {projectSolutions?.length ?
                        <Button color={`${hasOwnSolutions && !hasPublishedSolutions ? 'primary' : 'secondary'}`} outline={!(hasOwnSolutions && !hasPublishedSolutions)} className="see-solutions-button" size="sm" type="button" onClick={() => {
                          document.getElementById('project-solutions').scrollIntoView({ 
                            behavior: 'smooth' 
                          });
                        }}>Go to solutions</Button>
                      : null }

                      {helpRequestText ?
                        <Button color="secondary" className="action-bar-get-help" size="sm" onClick={() => {
                          setShowEditHelpRequestModal(true);
                        }}>
                          Edit help request
                        </Button>
                      :
                        <Button color="secondary" outline className="action-bar-get-help" size="sm" onClick={() => {
                          setShowEditHelpRequestModal(true);
                        }}>
                          Get help
                        </Button>
                      }

                      {/*<Button color="secondary" size="sm" outline className="getting-started-button" type="button" onClick={() => {
                        setShowGettingStartedModal(true);
                      }}>
                        Getting started
                      </Button>*/}

                      <Button color="secondary" outline size="sm" className={`project-detail-discussion-button ${project?.numMessages? 'has-comments' : ''}`} disabled={(role==='outsider' || role==='pending' || role==='requested')} onClick={() => {
                        setDiscussionOpen(true);
                      }}>
                        Comments
                        {project?.numMessages ?
                          <span className={`discussion-count ${hasUnreadComments ? 'has-unread' : ''}`}>
                            {project?.numMessages}
                          </span>
                        : null}
                      </Button>

                      <Menu menuButton={
                        <MenuButton color="link" size="sm" className="project-detail-more-actions" onClick={() => {
                            }}>
                              <img src={process.env.PUBLIC_URL + '/icons/ellipsis.png'} alt="More actions" />
                            </MenuButton>
                          }
                        position="auto"
                        direction="top"
                        align="end"
                        arrow={true}
                        gap={4}>
                          {project?.status !== 'pending' && project?.type === 'standalone' && !project?.pointsToJoin && (role === 'owner' || User?.is_superadmin) ?
                            <>
                              <MenuItem onClick={() => {
                                // AddPeopleStandaloneProject
                                setShowAddParticipantModal(true);
                              }}>
                                Add people
                              </MenuItem>
                              <MenuItem onClick={() => { navigate('/edit-project/' + id ); }}>
                                Edit project
                              </MenuItem>
                            </>
                          : null }

                          {role === 'owner' ?
                            <MenuItem onClick={() => { setShowDeleteConfirmationModal(true); }}>
                              Delete project
                            </MenuItem>
                          : null}

                          {role !== 'outsider' ?
                            <MenuItem onClick={() => {
                              setShowEditHelpRequestModal(true);
                            }}>
                              { helpRequestText ? "Edit help request" : "Get help" }
                            </MenuItem>
                          : null }

                          {!(role==='outsider' || role==='pending' || role==='requested' || role==='owner') ?
                            <MenuItem onClick={() => { setShowGettingStartedModal(true); }}>
                              Getting started
                            </MenuItem>
                          : null}

                          {User?.is_superadmin ?
                              <MenuItem onClick={() => { 
                                var newStatus = window.prompt("Enter project status (values: open, pending)", project?.status);

                                if (newStatus === 'open' || newStatus === 'pending') {
                                  updateProjectStatus(id, newStatus).then((response) => {
                                    // No-op
                                  }).catch((error) => {
                                    alert('An error occurred.')
                                    console.log(error);
                                  });
                                }
                              }}>
                                Change project status
                              </MenuItem>
                          : null }

                          {role === 'contributor' || role === 'administrator' ?
                            <MenuItem onClick={() => {
                              setPageErrorMessage(null);

                              leaveProject(parseInt(id)).then((response) => {
                                if (response?.data?.result?.affectedRows) {
                                  // TODO page success
                                  fetchData();
                                } else {
                                  setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                                }
                              }).catch((error) => {
                                // logError(error);
                                setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                              });
                            }}>
                              Leave project
                            </MenuItem>
                          : null }
                      </Menu>
                    </>
                  : null}
                </div>
              </div>
            </div>


{/*              <div className="project-metadata-section">
                {project && typeof project.numParticipants !== "undefined" ?
                  <Link to={`/project-participants/${project?.id}`} className="project-participants">
                    <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Users" />
                    {project?.numParticipants === 1 ? 
                      <span>{project?.numParticipants} participant</span> 
                    :
                      <span>{project?.numParticipants} participants</span>
                    }
                  </Link>
                : null }
              </div>
*/}
{/*            <p className="project-short-description">
              {project?.shortDescription}
            </p>
*/}


            {/*{projectParticipants?.length && (role === 'owner' || User?.is_superadmin) ?
              <div className="project-detail-section project-detail-participants">
                <div>
                  <div className="project-participants-count">
                    <img src={process.env.PUBLIC_URL + '/icons/person-dark-gray.svg'} alt="Participants" />
                    <span>This project has <strong>{projectParticipants.filter(item => item.role!=='requested').length}</strong> participant(s)</span>
                  </div>

                    <>
                      {projectParticipants.filter(item => item.role==='requested').length && project?.status !== 'pending' ?
                        <div className="project-participants-requested-count" role="button" onClick={() => {
                          setShowEditProjectParticipants(true);
                        }}>
                          +
                          {projectParticipants.filter(item => item.role==='requested').length} join request(s)
                        </div>
                      : null}
                    </>
                </div>

                {project?.type === 'standalone' && project?.status !== 'pending' && !project?.pointsToJoin && (role === 'owner' || User?.is_superadmin) ?
                  <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-project-participants" onClick={() => {
                    setShowEditProjectParticipants(true);
                  }}>Edit participants</Button>
                : null }
              </div>
            : null }*/}


            {role === 'pending' ?
              <div className="pending-invite-section">
                <p>You have been invited to join this project.</p>
                <div className="pending-invite-actions">
                  <Button color="primary" size="sm" type="button" className="accept-invite" onClick={() => {
                      setPageErrorMessage(null);

                      acceptInvite(id).then((response) => {
                        fetchData();
                      }).catch((error) => {
                        console.log(error);
                        // logError(error);
                        setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                      });
                  }}>Accept and join project</Button>

                  <Button color="secondary" outline size="sm" type="button" className="decline-invite" onClick={() => {
                      setPageErrorMessage(null);

                      declineInvite(id).then((response) => {
                        fetchData();
                      }).catch((error) => {
                        console.log(error);
                        // logError(error);
                        setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                      });
                  }}>Decline invite</Button>
                </div>
              </div>
            : null}
  
            {role === 'requested' ?
              <div className="pending-request-section">
                <p>You have requested to join this project. Please wait for the project owner to add you.</p>
                <div className="pending-invite-actions">
                  <Button color="secondary" outline size="sm" type="button" className="withdraw-request" onClick={() => {
                      setPageErrorMessage(null);

                      leaveProject(parseInt(id)).then((response) => {
                        if (response?.data?.result?.affectedRows) {
                          // TODO page success
                          fetchData();
                        } else {
                          setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                        }
                      }).catch((error) => {
                        // logError(error);
                        setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                      });
                  }}>Withdraw request</Button>
                </div>
              </div>
            : null}

            <div className="project-detail-section project-detail-description-section">

              {/*<div className="project-detail-section-header">
                <h3 className="project-summary-title">Summary</h3>
                <Button color="link" outline size="sm" className="title-button expand-button" onClick={() => {
                  setShowExpandedSummary(true);
                }}>
                  Expand
                </Button>
              </div>*/}

              { role && role!=='outsider' && project.upsellNotes?.split('&&&&').length > 1 ?
                <div className="project-upsell-notes">
                  <h3 className="project-summary-title">{project.upsellNotes.split('&&&&')[0]}</h3>
                  <ul>
                    {project.upsellNotes.split('&&&&').slice(1).map((item, idx) => {
                      return (<li key={idx}>{item}</li>);
                    })}
                  </ul>
                </div>
              : null}


              {project?.shortDescription ?
                <div className="tldr project-short-description project-short-description">
                  <h3>Short summary</h3>

                  <p>
                    {project.shortDescription}
                  </p>
                </div>
              : null}

              <h3 className="project-summary-title">Long summary</h3>
              <div className="project-description-container">
                {project?.carouselImages?.length ?
                  <div className="project-description-carousel-container">
                    <div className="project-description-carousel-inner-container">
                    <Carousel className="project-description-carousel" width="100%" centerMode={true} centerSlidePercentage={100} preventMovementUntilSwipeScrollTolerance={true}>
                        {project.carouselImages?.split(',').map((item, idx) => {
                          var carouselText = project.carouselText || '';
                          carouselText = carouselText.split('&&&&');
                          var carouselTextDisplay = (carouselText?.length && carouselText[idx]) || null;
                          return (
                            <div key={idx} className="carousel-item" onClick={() => {
                              // setExpandedImageHtml('<div style="display: flex; align-items: center; justify-content: center;"><img src="' + item + '" alt="Project design mock"></img></div>');
                              // setExpandedImageIndex(idx);
                              window.open(item, "_blank", `toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=${window.screen.availWidth},height=${window.screen.availHeight}`);
                            }}>
                              <img src={item} alt="Project design mock" />
                              {carouselTextDisplay ?
                                <p className="legend">{carouselTextDisplay}</p>
                              : null}
                            </div>
                          );
                        })}
                    </Carousel>
                    </div>
                  </div>
                : null}

                <div className={`project-description content-description ${isDescriptionOverflow && !showFullDescription ? 'with-overflow' : ''} ${showFullDescription ? 'show-full' : ''}`} ref={ref}  onClick={(e) => {
                    if (e?.target?.href) {
                      window.open(e.target.href, '_new', 'noopener=yes');
                      e.preventDefault();
                    }
                  }}>
                  <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(project?.description)}} />
                </div>

                {isDescriptionOverflow && !showFullDescription ?
                  <div className="project-description-show-full-container">
                    <Button color="primary" outline size="sm" onClick={() => { setShowFullDescription(true) }}>
                      Show full summary
                    </Button>
                  </div>
                : null}
              </div>
            </div>


            <div className={`project-detail-list-sections role-${role}`}>
              {projectResources?.length || role === 'owner' || role === 'administrator' || User?.is_superadmin ?
                <div className="project-detail-section project-detail-resources">
                  <div className="project-detail-section-header">
                    {projectModules?.length ?
                      <h3>Project-level resources</h3>
                    : <h3>Project resources</h3> }
                    {role === 'owner' || role === 'administrator' || User?.is_superadmin ?
                      <Button color="link" outline size="sm" type="button" className="title-button add-button" onClick={() => {
                        navigate('/add-project-resource/' + id );
                      }}>Add resource</Button>
                    : null }
                  </div>

                  {projectResources?.filter(item => !item.modules).length ?
                    <ul className="project-resources-list">
                      {projectResources?.filter(item => !item.modules).map((item, idx) => {
                        return (
                          <li key={idx} className="project-resource-card">
                            <ResourceCard projectId={id} resource={item} isDisabled={(role==='outsider' || role==='pending' || role==='requested')} />
                          </li>
                        );
                      })}
                    </ul>
                  : null }
                </div>
              : null }

              {projectModules?.length || role === 'owner' || role === 'administrator' || User?.is_superadmin ?
                <div className="project-detail-section project-detail-modules">
                  <div className="project-detail-section-header">
                    <h3>Modules</h3>

                    {role === 'owner' || role === 'administrator' || User?.is_superadmin ?
                      <Button color="link" outline size="sm" type="button" className="title-button add-button" onClick={() => {
                        navigate('/add-project-module/' + id );
                      }}>Add module</Button>
                    : null }
                  </div>

                  {projectModules?.length ?
                    <ul className="project-modules-list">
                      {projectModules?.map((item, idx) => {
                        return (
                          <li key={idx} className="project-module-card">
                            <ModuleCardV2
                              projectId={id}
                              module={item}
                              isDisabled={(role==='outsider' || role==='pending' || role==='requested')}
                              resources={projectResources?.filter(resourceItem => {
                                const resourceModules = resourceItem.modules;
                                const resoureModuleIds =resourceModules.split(',');
                                return resoureModuleIds.includes(item.id.toString());
                              })}
                              />
                          </li>
                        );
                      })}
                    </ul>
                  : null }
                </div>
              : null }

              {genericResources?.length ?
                <div className="project-detail-section project-detail-resources project-detail-generic-resources">
                  <div className="project-detail-section-header">
                    <h3>General resources relevant to this project</h3>
                  </div>

                  {genericResources.length ?
                    <ul className="project-resources-list">
                      {genericResources.map((item, idx) => {
                        return (
                          <li key={idx} className="project-resource-card">
                            <ResourceCard projectId={item.project_id} resource={item} isDisabled={(role==='outsider' || role==='pending' || role==='requested')} />
                          </li>
                        );
                      })}
                    </ul>
                  : null }
                </div>
              : null }

              {project?.type !== 'discussion' ?
                <div className="project-detail-section project-detail-solutions" id="project-solutions">
                  <div className="project-detail-section-header">
                    <h3>Solutions</h3>
                    {!(role==='outsider' || role==='pending' || role==='requested') ?
                      <Button color="link" outline size="sm" type="button" className="title-button add-button" onClick={() => {
                        navigate('/add-project-solution/' + id );
                      }}>Add solution</Button>
                    : null }
                  </div>

                  {projectSolutions?.length ?
                    <ul className="project-solutions-list">
                      {projectSolutions?.map((item, idx) => {
                        return (
                          <li key={idx} className="project-solution-card">
                            <SolutionCard
                              projectId={id}
                              solution={item}
                              isDisabled={(role==='outsider' || role==='pending' || role==='requested')}
                              clapsCount={claps?.filter(clap => clap.entity_type === 'solution' && clap.entity_id === parseInt(item.id))?.length}
                              commentsCount={solutionCommentCounts?.find(countItem => parseInt(countItem.entity_id) === item.id)?.count}
                              hasNewComments = {!!(userUpdates?.withUpdates?.find(updateItem => updateItem.entityType === 'solution' && updateItem.entityId === item.id))}
                              />
                          </li>
                        );
                      })}
                    </ul>
                  : 
                    <p className="solutions-empty-state">
                      No solutions have been added yet. Be the first to add a solution!
                    </p>
                  }
                </div>
              : null }


              {(projectHelpRequests?.length) && !(role==='outsider' || role==='pending' || role==='requested') ?
                <div className="project-detail-section project-detail-help-requests">
                  <div className="project-detail-section-header">
                    <h3>Help requests</h3>

                    {!(role==='outsider' || role==='pending' || role==='requested') ?
                      <>
                        {helpRequestText ?
                          <Button color="link" outline size="sm" className="title-button edit-button" onClick={() => {
                            setShowEditHelpRequestModal(true);
                          }}>
                            Edit help request
                          </Button>
                        :
                          <Button color="link" outline size="sm" className="title-button add-button" onClick={() => {
                            setShowEditHelpRequestModal(true);
                          }}>
                            Get help
                          </Button>
                        }
                      </>
                    : null }
                  </div>

                  {projectHelpRequests?.length ?
                    <ul className="project-help-requests-list">
                      {projectHelpRequests?.map((item, idx) => {
                        return (
                          <li key={idx}>
                            <HelpRequestCard
                              helpRequest={item}
                              isDisabled={(role==='outsider' || role==='pending' || role==='requested')}
                              onClick={() => {
                                setViewHelpRequest({projectId: item.project, participantId: item.user, isOwner: item.user === User?.id});
                              }} />
                          </li>
                        );
                      })}
                    </ul>
                  : null }
                </div>
              : null }


              {projectParticipants?.length ?
                <div className="project-detail-section project-detail-participants-list">
                  <div className="project-detail-section-header">
                    <h3>Project participants</h3>
                  </div>

                  {projectParticipants.length ?
                    <ul className="project-participants-list">
                      {projectParticipants.map((item, idx) => {
                        return (
                          <ProjectParticipantCard
                            key={idx}
                            user={item}
                            solutionStatus={projectSolutions?.find(solution => solution.owner_id === item.user_id)?.status}
                            isDisabled={(role==='outsider' || role==='pending' || role==='requested')}
                            onClick={() => {
                              setUserProfileModalId(item.user_id)}
                            }
                            />
                        );
                      })}
                    </ul>
                  : null }
                </div>
              : null }

              {role === 'outsider' ?
                <div>
                  <div className="project-detail-section project-detail-page-end-cta-section">
                    <div className="project-detail-section-header">
                      <h3>Join the fun!</h3>
                    </div>
                    <div className="page-end-cta-upsell-container">
                      <img src={process.env.PUBLIC_URL + '/vector/project-join-illustration.png'} alt="Close" />
                      <div className="page-end-cta-upsell">
                        <p>If you find this project interesting or relevant to the skills you'd like to sharpen, join the fun!</p>
                        <p>Join the project, read through the resources, download the assets, and start adding your solution!</p>
                        <p>Don't forget to join the discussion in the project and solution comments, and exchange help and feedback.</p>
                        <div className="cta-container">
                          {User?.id ?
                            <Button color="primary" size="sm" onClick={() => {
                              navigate('/join-project/' + id );
                            }}>
                              Join this project
                            </Button>
                          :
                            <Button color="primary" size="sm" onClick={() => {
                              setTopNavEnabled(true);
                              navigate('/login?project-id=' + id);
                            }}>
                              Log in / sign up to join this project
                            </Button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              : null}
            </div>
          </div>
        : null }

        
      </div>


            {showEditProjectParticipants ?
              <EditProjectParticipants 
                projectId={id}
                projectType={project.type}
                pointsToJoin={project.pointsToJoin}
                onClose={() => {setShowEditProjectParticipants(false)}}
                />
            : null}

            {showAddParticipantModal ?
              <AddParticipantModal 
                projectId={id}
                onClose={() => {setShowAddParticipantModal(false)}}
                onAddParticipant={() => {
                  setPageErrorMessage(null);

                  // API call to refresh the project
                  getProjectParticipants(id).then((response) => {
                    if (response?.data) {
                      setProjectParticipants(response.data);
                    }
                  }).catch((error) => {
                    console.log(error);
                    // logError(error);
                    setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                  });
                }}
                />
            : null}
              
            {showDeleteConfirmationModal ?
              <ConfirmationModal 
                closeLabel="Cancel"
                onClose={() => {setShowDeleteConfirmationModal(false)}}
                submitLabel="Yes"
                onSubmit={() => {
                  // API call to delete the project
                  deleteProject(id).then((response) => {
                    if (response?.data?.result?.affectedRows) {
                      setDeleteInformationMessage('The project has been deleted.')
                    } else {
                      setDeleteInformationMessage('The project could not be be deleted.')
                    }
                    setShowDeleteInformationModal(true);
                    setShowDeleteConfirmationModal(false);
                  }).catch((error) => {
                    // logError(error);
                    setDeleteInformationMessage('The project could not be be deleted.')
                    setShowDeleteInformationModal(true);
                    setShowDeleteConfirmationModal(false);
                  });
                }}
                headerMessage="Confirmation"
                bodyMessage="Are you sure you want to delete this project? This action cannot be undone."
                />
            : null}

            {showDeleteInformationModal ?
              <InformationModal 
                closeLabel="Close"
                onClose={() => {
                  setShowDeleteInformationModal(false)
                  navigate('/projects/my-projects');
                }}
                headerMessage="Delete project"
                bodyMessage={deleteInformationMessage}
                />
            : null}

            {showEditHelpRequestModal ?
              <EditHelpRequest 
                text={helpRequestText}
                contactEmail={helpRequestContactEmail}
                isContactEmailPreferred={isHelpRequestContactEmailPreferred}
                isContactCommentsPreferred={isHelpRequestContactCommentsPreferred}
                onClose={() => {
                  setShowEditHelpRequestModal(false);
                }}
                onSave={(text, contactEmail, isEmailPreferred, isCommentsPreferred) => {
                  setPageErrorMessage(null);
                  updateProjectHelpRequest(id, text, contactEmail, isEmailPreferred, isCommentsPreferred).then((response) => {
                      fetchData();
                      fetchHelpRequest();
                      setShowEditHelpRequestModal(false);
                  }).catch((error) => {
                    // logError(error);
                    setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                    setShowEditHelpRequestModal(false);
                  });
                }}
                onRemove={() => {
                  updateProjectHelpRequest(id, null, '', false, false).then((response) => {
                    setPageErrorMessage(null);

                    if (response?.data?.result?.affectedRows) {
                      fetchData();
                      fetchHelpRequest();
                      setShowEditHelpRequestModal(false);
                    } else {
                      setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                      setShowEditHelpRequestModal(false);
                    }
                  }).catch((error) => {
                    // logError(error);
                    setPageErrorMessage('An error occurred. Please refresh the page and try again.');
                    setShowEditHelpRequestModal(false);
                  });
                }}
              />
            : null}

            {showGettingStartedModal  ?
              <GettingStartedModal 
                project={project}
                onDismiss={() => {
                  markProjectGettingStartedModalSeen(id).then((response) => {
                    // No-op
                    setShowGettingStartedModal(false); 
                    // FIXME: This shouldn't be needed! But for some reason it is!
                    document.body.style.overflow = 'auto';
                  }).catch((error) => {
                    // No-op
                    setShowGettingStartedModal(false); 
                    // FIXME: This shouldn't be needed! But for some reason it is!
                    document.body.style.overflow = 'auto';
                  });
                }}
              />
            : null}

            {viewHelpRequest?.participantId && viewHelpRequest?.projectId ?
              <ViewHelpRequest 
                participantId={viewHelpRequest.participantId}
                projectId={viewHelpRequest.projectId}
                isOwner={!!viewHelpRequest.isOwner}
                onEdit={() => {
                  setShowEditHelpRequestModal(true);
                  setViewHelpRequest(null);
                }}
                onOpenComments={() => {
                  setDiscussionOpen(true);
                  setViewHelpRequest(null);
                }}
                onClose={() => {
                  setViewHelpRequest(null);
                }}
              />
            : null}

            {/*{expandedImageHtml ?
              <RichTextModal
                onClose={() => {
                  setExpandedImageHtml('');
                }}
                title="Image preview"
                body={expandedImageHtml}
                />
            : null}*/}
            {/*{expandedImageIndex!==null && project?.carouselImages?.length ?
              <ImageCarouselModal
                onClose={() => {
                  setExpandedImageIndex(null);
                }}
                title="Project design mocks"
                selectedItem={expandedImageIndex}
                images={project.carouselImages.split(',')}
                text={project.carouselText?.length ? project.carouselText.split('&&&&') : []}
                />
              : null}*/}

            {project?.description && showExpandedSummary ?
              <RichTextModal
                onClose={() => {
                  setShowExpandedSummary(false);
                }}
                title="Project summary"
                body={project.description}
                />
            : null}

            {userProfileModalId ?
              <UserProfileModal 
                userId={userProfileModalId}
                onClose={() => setUserProfileModalId(null)}
                />
            : null}
    </div>
  );
}