import React from 'react';
import { Link } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import ResourceCard from "./ResourceCard";

import "./ModuleCardV2.scss";

function ModuleCard(props) {
  const {
    module,
    resources,
    projectId,
    isDisabled
  } = props;

  return (
    <Link className="module-card-v2-container" to={`/module-detail/${projectId}/${module.id}`} onClick={(e)=> { if (isDisabled) { e.preventDefault(); }}}>
      <div className="module-card-v2" role="button">
        <div className="module-card-title-container">
          <img className="module-card-icon" src={process.env.PUBLIC_URL + '/icons/module-thumbnail.png'} alt="Module" />
          <span className="module-card-title">Module: {module.title}</span>
        </div>

        {module?.short_description ?
          <div className="module-short-description">
            {module.short_description.split('&&&&').length > 1 ?
              <ul>
                {module.short_description.split('&&&&').slice(1).map((item, idx) => {
                  return (<li key={idx}>{item}</li>);
                })}
              </ul>
            :
              <p className="module-short-description">{module.short_description}</p>
            }
          </div>
        : null}

        {resources?.length ?
          <ul className="module-card-resources-list">
            {resources?.map((item, idx) => {
              return (
                <li key={idx} className="module-resource-card">
                  <div>
                    <ResourceCard projectId={projectId} resource={item} isDisabled={false} hideModuleIndicator={true} noLink={true} />
                  </div>
                </li>
              );
            })}
          </ul>
        : null }

      </div>
    </Link>
  );
}

export default ModuleCard;
