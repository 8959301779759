import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

import { UserContext } from '../../contexts/user-context';
import { DataContext } from '../../contexts/data-context';

import "./Confirmation.scss";

export default function Confirmation(props) {
  const [isVisible, setIsVisible] = useState('');
  const { isLoggingIn } = useContext(UserContext);
  const { logView } = useContext(DataContext);
  const {
    onBack,
    onFinish,
    freeCoins,
  } = props;

  useEffect(() => {
    setIsVisible(props.isVisible);
  }, [props.isVisible]);

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-confirmation');
    }
  }, [isVisible]);

  return (
    <div className={`onboarding-modal-confirmation ${isVisible ? 'visible' : 'hidden'}`}>
      {isLoggingIn ?
        <div>
          <div className="loader">Loading...</div>
          <span className="logging-in-message">Logging in</span>
        </div>
      :
      <div>
        <div className="modal-content-body"> 
          <div className="profile-created-indicator">
            <img src={process.env.PUBLIC_URL + '/icons/green-check.png'} alt="Success" />
            <span>Your profile setup is now complete!</span>
          </div>

          {/*<div className="free-credits-message">
            <p>Omnis voluptas assumenda est, omnis dolor repellendus.</p>
            
            <span className="free-credits-indicator">
              <img src={process.env.PUBLIC_URL + '/icons/coin.png'} alt="Coins" />
              {freeCoins} coins free!
            </span>
          </div>*/}
        </div>

        <div className="modal-actions-footer one-action">
          <Button color="primary" size="sm" onClick={onFinish}>
            Continue
          </Button>
        </div>
      </div>}
    </div>
  );
}