import React, { useState, useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { keysToCamel } from '../../util/caseConverters';
import PageError from '../messages/PageError';


import "./CreateModule.scss";

export default function CreateModule() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, moduleId } = useParams();
  const [role, setRole] = useState('');
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { createModule, editModule, getModule, getProjectRole, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);

  const [module, setModule] = useState();
  const [title, setTitle] = useState('');
  const [isTitleError, setTitleError] = useState(false);
  const [isDescriptionError, setDescriptionError] = useState(false);
  const [isShortDescriptionError, setShortDescriptionError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);

  const html = '<p></p>';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [description, setDescription] = useState(html);
  const [shortDescription, setShortDescription] = useState('');
  const [editHtml, setEditHtml] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  useEffect(() => {
    setPageTitle('Add a module');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('create-module');
  }, []);

  useEffect(() => {
    setPageErrorMessage(null);

    if (projectId) {
      const userId = User?.id;
      getProjectRole(userId, projectId).then((response) => {
        if (response?.data?.length) {
          setRole(response.data[0].role);
        } else {
          setRole('outsider');
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setPageErrorMessage('An error occurred. Please refresh the page and try again.');
        setRole('outsider');
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (moduleId) {
      setPageTitle('Edit Module');
      let module;
      setPageErrorMessage(null);

      getModule(projectId, moduleId).then((response) => {
        if (response?.data?.length) {
          module = keysToCamel(response).data[0];
          setModule(module);
          setTitle(module.title || '');
          setDescription(module.description || '');
          setShortDescription(module.shortDescription || '');
          const contentBlock = htmlToDraft(module.description);
          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
          setEditorState(EditorState.createWithContent(contentState));
        }
      }).catch((error) => {
        setPageErrorMessage('An error occurred loading this module. Please refresh the page and try again.');
        console.log(error);
        // logError(error);
      });
    }

  }, [projectId, moduleId]);

  useEffect(() => {
    const _isTitleError = !title.trim().length;
    setTitleError(_isTitleError);

    const _isDescriptionError = !description.trim().length || description.trim()==='<p></p>';
    setDescriptionError(_isDescriptionError);

    const _isShortDescriptionError = !shortDescription.trim().length;
    setShortDescriptionError(_isShortDescriptionError);

    const _doesFormHaveErrors = _isTitleError || _isDescriptionError || _isShortDescriptionError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [title, description]);

  const submitForm = (e) => {
    e.preventDefault();

    setShowFormErrors(true);
    setPageErrorMessage(null);

// debugger;
    if (!doesFormHaveErrors) {
      if (moduleId) {
        editModule(projectId, moduleId, title, description, shortDescription).then((response) => {
          if (response) {
            setPageErrorMessage(null);
            navigate('/project-detail/' + projectId);
          }
        }).catch((error) => {
          setPageErrorMessage('Module write failed. Please check your inputs and try again.');
          // logError(error);
        });
      } else {
        createModule(projectId, title, description, shortDescription).then((response) => {
          if (response) {
            setPageErrorMessage(null);
            navigate('/project-detail/' + projectId);
          }
        }).catch((error) => {
          setPageErrorMessage('Module write failed. Please check your inputs and try again.');
          // logError(error);
        });
      }
    }
  };

  return (
    <div className="create-module full-screen-page">
      <Helmet>
        <title>Create module - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        {moduleId ? <h2>Edit module</h2> : <h2>Add a module</h2> }

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="create-module-body">
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {!projectId || (role === 'owner' || role === 'administrator') ?
          <>
            <div className={`full-screen-page-body`}>
              <form onSubmit={submitForm} className={`${showFormErrors ? 'form-errors' : ''}`}>

                <div className={`form-group full-width ${isTitleError ? 'error-field' : ''}`}>
                  <Label for="module-title">Module title</Label>
                  <Input
                    id="module-title"
                    name="module-title"
                    placeholder="Enter a title for the module"
                    type="text"
                    value={title}
                    autoComplete="off"
                    // disabled={!!moduleId}
                    onChange={(e) => {
                      setTitle(e.target.value);
                    }}
                  />
                  {showFormErrors && isTitleError ?
                    <div className="form-field-error-message">
                      Enter a title
                    </div>
                   : null}
                </div>

                <div className={`form-group full-width ${isShortDescriptionError ? 'error-field' : ''}`}>
                  <Label for="module-title">Short summary</Label>
                  <Input
                    id="module-short-description"
                    name="module-short-description"
                    placeholder="Enter a short summary for the module"
                    type="textarea"
                    value={shortDescription}
                    autoComplete="off"
                    maxLength={600}
                    // disabled={!!moduleId}
                    onChange={(e) => {
                      setShortDescription(e.target.value);
                    }}
                  />
                  {showFormErrors && isTitleError ?
                    <div className="form-field-error-message">
                      Enter a short summary
                    </div>
                   : null}
                </div>

                <div className={`form-group full-width body-field-container ${isDescriptionError ? 'error-field' : ''}`}>
                  <Label for="module-description">Module summary</Label>

                  <div className="inline-radio-group">
                    <div className="styled-radio inline styled-radio-tab">
                      <label>
                        <Input type="radio" name="edit-mode" checked={!editHtml} onChange={() => {setEditHtml(false); }}
                          />
                        <span>Visual editor</span>
                      </label>
                    </div>
                    <div className="styled-radio inline styled-radio-tab">
                      <label>
                        <Input type="radio" name="edit-mode" checked={!!editHtml} onChange={() => {setEditHtml(true); }}
                          />
                        <span>HTML editor</span>
                      </label>
                    </div>
                  </div>

                  <div className="styled-radio-tab-body">
                    {editHtml ?
                      <Input
                        id="module-description-html"
                        name="module-description-html"
                        placeholder="Enter description"
                        type="textarea"
                        value={description}
                        autoComplete="off"
                        onChange={(e) => {
                          const html=e.target.value;
                          setDescription(html);
                          const contentBlock = htmlToDraft(html);
                          const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                          setEditorState(EditorState.createWithContent(contentState));
                        }}
                      />
                    :
                      <Editor
                        id="module-description"
                        name="module-description"
                        editorState={editorState}
                        wrapperClassName="resource-editor-wrapper"
                        editorClassName="resource-editor"
                        onEditorStateChange={onEditorStateChange}
                      />
                    }

                    <p className="styled-radio-tab-body-help-text">If you need to copy-paste code blocks, we recommend using <b>HTML mode</b>. Make sure you <b>escape your code</b> block (using <a href="https://codebeautify.org/html-escape-unescape" target="_new">a tool like this</a>), and enclose it in a <b>&lt;pre&gt;...&lt;/pre&gt;</b> block.</p>
                  </div>

                  {showFormErrors && isDescriptionError ?
                    <div className="form-field-error-message">
                      Enter module summary
                    </div>
                   : null}
                </div>
              </form>
            </div>

            <div className="create-project-actions form-footer">
              <div>
                <Button color="secondary" outline onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
                  Cancel
                </Button>

                <Button type="submit" color="primary" onClick={submitForm}>
                  {moduleId ?
                    <span>Update module</span>
                  :
                    <span>Add module</span>
                  }
                </Button>
              </div>
            </div>
          </>
          :
            <div className="page-error-container">
              <PageError messageText="You need to be an owner or administrator of this project to edit the project." />
            </div>
          }
      </div>

    </div>
  );
}