import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { UserContext } from '../../contexts/user-context';
// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";


import "./ProjectBasics.scss";

export default function ProjectBasics(props) {
  const { User } = useContext(UserContext);
  const [title, setTitle] = useState('');
  const [shortSummary, setShortSummary] = useState(props.projectDescription || '');
  // const [description, setDescription] = useState('');

  const html = '';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [description, setDescription] = useState(html);
  const [editHtml, setEditHtml] = useState(false);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setDescription(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [isVisible, setIsVisible] = useState(false);
  const [isTitleError, setTitleError] = useState(false);
  const [isDescriptionError, setDescriptionError] = useState(false);
  const [isShortSummaryError, setShortSummaryError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const {
    onPrevious,
    onCancel,
    onNext,
    isEdit,
    projectType,
  } = props;

  useEffect(() => {
    setTitle(props.projectTitle);
    // setDescription(props.projectDescription);
    setDescription(props.projectDescription);
    const contentBlock = htmlToDraft(props.projectDescription);
    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
    setEditorState(EditorState.createWithContent(contentState));

    setShortSummary(props.projectShortSummary);
    // setSkills(props.projectSkills);
    setIsVisible(props.isVisible);
  }, [props.projectTitle, props.projectDescription, props.isVisible]);

  useEffect(() => {
    const _isTitleError = !title.trim().length;
    setTitleError(_isTitleError);

    const _isDescriptionError = !description.trim().length || description.trim()==='<p></p>';
    setDescriptionError(_isDescriptionError);

    // const _isShortSummaryError = !shortSummary.trim().length;
    // setShortSummaryError(_isShortSummaryError);

    const _doesFormHaveErrors = _isTitleError || _isDescriptionError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [title, description]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(title, description, shortSummary || '');
    }
  };

  return (
    <div className={`project-basics ${isVisible ? 'visible' : 'hidden'}`}>
      <div className={`full-screen-page-body`}>
        {/*<h3 className="full-screen-sub-header">Project topic</h3>*/}
        <div className="intro-text">
          <div className="intro-text-illustration-container">
            <img src={process.env.PUBLIC_URL + '/vector/suggestion.svg'} alt="Suggestion" />
          </div>
          <p>We welcome your suggestions for web-dev practice project topics!</p>
          <p>What do you have in mind?</p>
        </div>
        <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>

          <div className={`form-group full-width ${isTitleError ? 'error-field' : ''}`}>
            <Label for="project-title">Title</Label>
            <Input
              id="project-title"
              name="project-title"
              placeholder="Enter a title for the project"
              type="text"
              value={title}
              autoComplete="off"
              disabled={isEdit}
              onChange={(e) => {
                setTitle(e.target.value);
              }}
            />
            {showFormErrors && isTitleError ?
              <div className="form-field-error-message">
                Enter a title
              </div>
             : null}
          </div>

          {isEdit ?
            <div className={`form-group short-summary full-width ${isShortSummaryError ? 'error-field' : ''}`}>
              <Label for="project-description">Short summary</Label>
              <Input
                id="project-short-summary"
                name="project-short-summary"
                placeholder="Enter a short summary of the project in one or two lines"
                type="textarea"
                value={shortSummary}
                maxLength={2000}
                autoComplete="off"
                onChange={(e) => {
                  setShortSummary(e.target.value);
                }}
              />
              {/*{showFormErrors && isShortSummaryError ?
                <div className="form-field-error-message">
                  Enter a short summary
                </div>
               : null}*/}
            </div>
          : null}

          <div className={`form-group full-width description-field-container ${isDescriptionError ? 'error-field' : ''}`}>
            <Label for="project-description">Summary</Label>

            {projectType === 'standalone' || User?.is_superadmin ?
              <div className="inline-radio-group">
                <div className="styled-radio inline styled-radio-tab">
                  <label>
                    <Input type="radio" name="size" checked={!editHtml} onChange={() => {setEditHtml(false); }}
                      />
                    <span>Visual editor</span>
                  </label>
                </div>
                <div className="styled-radio inline styled-radio-tab">
                  <label>
                    <Input type="radio" name="size" checked={!!editHtml} onChange={() => {setEditHtml(true); }}
                      />
                    <span>HTML editor</span>
                  </label>
                </div>
              </div>
            : null}
{/*            <Input 

              id="project-description"
              name="project-description"
              placeholder="Enter a description"
              type="textarea"
              value={description}
              autoComplete="off"
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />*/}
            <div className="styled-radio-tab-body">
              {editHtml ?
                <Input
                  id="project-description-html"
                  name="project-description-html"
                  placeholder="Enter description"
                  type="textarea"
                  value={description}
                  autoComplete="off"
                  onChange={(e) => {
                    const html=e.target.value;
                    setDescription(html);
                    const contentBlock = htmlToDraft(html);
                    const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                    setEditorState(EditorState.createWithContent(contentState));
                  }}
                />
              :
                <Editor
                  id="project-description"
                  name="project-description"
                  editorState={editorState}
                  wrapperClassName="resource-editor-wrapper"
                  editorClassName="resource-editor"
                  onEditorStateChange={onEditorStateChange}
                />
              }

              <ul className="styled-radio-tab-body-help-text">
                {projectType === 'standalone' ?
                  <li>If you need to copy-paste code blocks, we recommend using <b>HTML mode</b>. Make sure you <b>escape your code</b> block (using <a href="https://codebeautify.org/html-escape-unescape" target="_new">a tool like this</a>), and enclose it in a <b>&lt;pre&gt;...&lt;/pre&gt;</b> block.</li>
                : null}
                {projectType === 'standalone' ?
                  <li>Note: Don't forget to add <b>copyright / licensing policy</b> for your project, as part of the summary.</li>
                : null}
              </ul>
            </div>

            {showFormErrors && isDescriptionError ?
              <div className="form-field-error-message">
                Enter summary
              </div>
             : null}
          </div>

        </form>
      </div>

      <div className="create-project-actions form-footer">
        <div>
          {/*<Button color="secondary" outline onClick={() => { onPrevious() }}>
            Previous
          </Button>*/}

          <Button color="secondary" outline onClick={() => { onCancel() }}>
            Cancel
          </Button>

          <Button color="primary" type="submit" onClick={handleSubmit}>
            {projectType === 'standalone' || isEdit ? 'Next' : 'Suggest'}
          </Button>
        </div>
      </div>
    </div>
  );
}