import React from 'react';
import { Link } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ModuleCard.scss";

function ModuleCard(props) {
  const {
    module,
    projectId,
    isDisabled
  } = props;

  return (
    <Link to={`/module-detail/${projectId}/${module.id}`} onClick={(e)=> { if (isDisabled) { e.preventDefault(); }}}>
      <div className="module-card" role="button">
        <img className="module-card-icon" src={process.env.PUBLIC_URL + '/icons/module-thumbnail.png'} alt="Module" />
        
        <span className="module-card-title">{module.title}</span>
      </div>
    </Link>
  );
}

export default ModuleCard;
