import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PageError from '../messages/PageError';
import { DataContext } from '../../contexts/data-context';

import "./AddParticipantModal.scss";

function AddParticipantModal(props) {
  const { addParticipant, logView, logError } = useContext(DataContext);
  const [isUsernameError, setUsernameError] = useState(false);
  const [username, setUsername] = useState('');
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [pageError, setPageError] = useState(null);
  const {
    projectId,
    onClose,
    onAddParticipant,
  } = props;

  useEffect(() => {
    const _isUsernameError = !username.trim();
    const _doesFormHaveErrors = _isUsernameError;
    setUsernameError(_isUsernameError);
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [username]);

  useEffect(() => {
    logView('add-participant');
  }, []);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);
    setPageError(null);

    if (!doesFormHaveErrors) {
      addParticipant(projectId, username).then((response) => {
        if (response) {
console.log(response);
          setShowFormErrors(false);
          setSuccess(true);
          setUsername('');
          onAddParticipant();
        }
      }).catch((error) => {
        // logError(error);
        setPageError('This user could not be added. Please check if the username is correct and try again.');
      });
    }
  };

  const closeForm = () => {
    setShowFormErrors(false);
    setUsername('');
    setSuccess(false);

    onClose();
  };

  return (
    <Modal
      className="add-participant-modal"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      centered={true}>
      <ModalHeader>Add a participant to this project</ModalHeader>
      <ModalBody>
        {pageError ? 
          <div className="page-error-container">
            <PageError messageText={pageError} />
          </div>
        : null}

        {isSuccess ?
          <>
          <p className="add-participant-success">You have successfully invited this user to join your project. The user will be able to accept or decline your invitation.</p>
          <p>If you wish to withdraw your request, you can do so by clicking 'Edit Participants' on the project detail page.</p>
          </>
        :
          <form onSubmit={submitForm} className={`${showFormErrors ? 'form-errors' : ''}`}>
            <p className="add-participant-intro">We could not invite this user. Please check if the username is correct, and also if the user has already been invited.</p>
            <p>You can check the list of current users and invitees by clicking 'Edit Participants' on the project detail page.</p>
            <div className={`form-group full-width ${isUsernameError ? 'error-field' : ''}`}>
              <Label for="add-participant-username">Username</Label>
              <Input
                id="add-participant-username"
                name="add-participant-username"
                placeholder="Enter the username of the user you'd like to add"
                type="text"
                value={username}
                autoComplete="on"
                onChange={(e) => {
                  setUsername(e.target.value);
                }}
              />
              {showFormErrors && isUsernameError ?
                <div className="form-field-error-message">
                  Enter a username
                </div>
               : null}
            </div>
          </form>
        }
      </ModalBody>
      {isSuccess ?
        <ModalFooter>
          <div className="secondary-actions">
            <Button color="secondary" outline={true} onClick={closeForm}>
              Close
            </Button>
          </div>
        </ModalFooter>
      :
        <ModalFooter>
          <div className="secondary-actions">
            <Button color="secondary" outline={true} onClick={closeForm}>
              Cancel
            </Button>
          </div>
          <div className="primary-actions">
            <Button type="submit" color="primary" onClick={submitForm}>
              Add participant
            </Button>
          </div>
        </ModalFooter>
      }
    </Modal>
  );
}

export default AddParticipantModal;
