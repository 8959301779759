export const availableProjects = [
  {
    id: 1,
    name: 'Build a chat app using MERN',
    type: 'work',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    skills: [
        { slug: 'react', name: 'React' },
        { slug: 'node', name: 'Node' },
        { slug: 'express', name: 'Express' },
        { slug: 'mongodb', name: 'Mongo DB' },
      ],
    skillString: 'React, Node, Expreess, MongoDB',
    numParticipants: 5,
    price: 0,
    priceInCurrency: "Rs.0",
    isJoined: false,
    isPaid: false,
    discussionCount: 5,
  },
  {
    id: 2,
    name: 'Business profile card with PDF export',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'guided',
    skills: [
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    skillString: 'HTML, CSS',
    numParticipants: 3,
    price: 400,
    priceInCurrency: "Rs.400",
    isJoined: false,
    isPaid: false,
    discussionCount: 5,
  },
];
