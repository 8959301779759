import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";

import "./HelpCategories.scss";

export default function VisibilitySettings(props) {
  const [selectedHelpCategories, setSelectedHelpCategories] = useState([]);
  const [isVisible, setIsVisible] = useState(false);

  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const {
    onPrevious,
    onNext,
  } = props;

  const helpCategoryOptions = [
    {
      name: "planning_design_architecture",
      value: "Planning / design / architecture",
    },
    {
      name: "validation",
      value: "Project validation",
    },
    {
      name: "development_help",
      value: "Find people to help with development",
    },
    {
      name: "build_full_project",
      value: "Find people to build the full / most of the project",
    },
    {
      name: "feedback",
      value: "Get feedback",
    }
  ];

  useEffect(() => {
    setSelectedHelpCategories(props.helpCategories.split(',').filter(item => item!==''));
    setIsVisible(props.isVisible);
  }, [props.selectedHelpCategories, props.isVisible]);

  const handleSubmit = (e) => {
    e.preventDefault();
    onNext(selectedHelpCategories.toString());
  };

  return (
    <div className={`help-categories ${isVisible ? 'visible' : 'hidden'}`}>
      <div className={`full-screen-page-body`}>
        <h3 className="full-screen-sub-header">What are some ways you're likely to need help with this project?</h3>
        <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>
          <div className={`form-group full-width inline-radio-field ${doesFormHaveErrors ? 'error-field' : ''}`}>
            <span className="radio-title">Choose all that apply (if any) and click "Submit" to create the project.</span>

            <div className="help-category-options">
              {helpCategoryOptions.map((item, idx) => {
                return (<div className="styled-radio" key={idx}>
                  <label>
                    <Input type="checkbox"
                      name={item.name}
                      checked={selectedHelpCategories.includes(item.name)}
                      onChange={(e) => {
                        let temp;

                        if (e.target.checked) {
                          // console.log(selectedHelpCategories);
                          temp = selectedHelpCategories.map(x => x);
                          temp.push(item.name);
                        } else {
                          temp = selectedHelpCategories.filter(addedItem => addedItem !== e.target.name);
                        }

                        setSelectedHelpCategories(temp);
                      }} />
                    <span>{item.value}</span>
                  </label>
                </div>);
              })}
            </div>
          </div>
        </form>
      </div>

      <div className="create-project-actions form-footer">
        <div>
          <Button color="secondary" outline onClick={() => { onPrevious() }}>
            Previous
          </Button>
   
          <Button color="primary" onClick={handleSubmit}>
            Submit
          </Button>
        </div>
      </div>
    </div>
  );
}