export const discussions = [
  {
    id: 1,
    title: 'Lorem ipsum dolor sit amet consectetur adipiscing elit ',
    entityType: 'project',
    entityUrl: '/session-detail/5',
    thumbnailType: 'project-work',
    entityId: 1,
    numParticipants: 5,
    lastUpdatedString: "June 30 at 4:15PM",
    hasNewMessages: true,
  },
  {
    id: 2,
    title: 'Quis autem vel eum iure reprehenderit qui in ea',
    entityType: 'solution',
    entityUrl: '/something',
    thumbnailType: 'solution',
    entityId: 2,
    numParticipants: 3,
    lastUpdatedString: "June 29 at 2:25PM",
    hasNewMessages: false,
  },
  {
    id: 2,
    title: 'Voluptate velit esse quam nihil project molestiae consequatur.',
    entityType: 'topic',
    entityUrl: '/something',
    thumbnailType: 'discussion',
    entityId: 3,
    numParticipants: 2,
    lastUpdatedString: "June 28 at 5:12PM",
    hasNewMessages: false,
  },
];
