export const notifications = [
  {
    id: 1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit  "SEO" module  in their project.',
    targetText: 'Go to project',
    targetType: 'project',
    targetId: 1,
    timeString: "June 30 at 4:15PM",
  },
  {
    id: 2,
    text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil project molestiae consequatur.',
    targetText: 'Go to solution',
    targetType: 'solution',
    targetId: 2,
    timeString: "June 26 at 9:30PM",
  },
];
