import React  from 'react';

import "./PageInfo.scss";

export default function PageInfo(props) {
  const {
    message,
    messageText
  } = props;

  return (
    <div className="page-info">
      <img src={process.env.PUBLIC_URL + '/icons/info-white.png'} alt="Info" />
      <span>{messageText}</span>
    </div>
  );
}