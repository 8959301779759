import React, { useContext, useEffect, useState } from "react";

import { BrowserRouter, Routes, Route } from "react-router-dom";
// import { Navigate } from "react-router-dom";

import { PageContext } from './contexts/page-context';
import { UserContext } from './contexts/user-context';
import { DataContext } from './contexts/data-context';

import OnboardingModal from './components/onboarding/OnboardingModal';
import TopNavBar from './components/nav/TopNavBar';
// import SideNavBar from './components/nav/SideNavBar';

import Login from './components/pages/login/Login';
import Projects from './components/pages/Projects';
import MyProjects from './components/pages/MyProjects';
import AvailableProjects from './components/pages/AvailableProjects';
import CreateProject from './components/createProject/CreateProject';
import CreateModule from './components/modules/CreateModule';
import CreateResource from './components/resources/CreateResource';
import CreateSolution from './components/solutions/CreateSolution';
import ProjectDetail from './components/pages/ProjectDetail';
import JoinProject from './components/participants/JoinProject';
import ModuleDetail from './components/modules/ModuleDetail';
import ResourceDetail from './components/resources/ResourceDetail';
import SolutionDetail from './components/solutions/SolutionDetail';
import ProjectParticipants from './components/pages/ProjectParticipants';
// import SolutionDetail from './components/pages/SolutionDetail';

import Sessions from './components/pages/Sessions';
import GetHelp from './components/pages/GetHelp';
import CreateMentorship from './components/pages/CreateMentorship';
import SessionDetail from './components/pages/SessionDetail';

import UserProfile from './components/pages/UserProfile';

import Updates from './components/pages/Updates';
import Likes from './components/pages/Likes';
// import Discussions from './components/pages/Discussions';
import Profile from './components/pages/Profile';
import Terms from './components/pages/Terms';
import PrivacyPolicy from './components/pages/PrivacyPolicy';
import Contact from './components/pages/Contact';
import About from './components/pages/About';
import Emails from './components/admin/Emails';

// import Login from './components/pages/login/Login';
import Splash from './components/pages/splash/Splash';

import FinishProfile from "./components/onboarding/FinishProfile";
import DeletedAccountModal from "./components/common/DeletedAccountModal";
import StartupMessageModal from "./components/common/StartupMessageModal";
import PremiumReminderModal from "./components/common/PremiumReminderModal";
import ScrollToTop from "./components/common/ScrollToTop";

import { toUTCDate } from './util/dateTime';

function App() {
  const { IsTopNavEnabled, PageClass } = useContext(PageContext);
  // const { IsBottomNavEnabled } = useContext(PageContext);
  const { User, isLoggedIn, setLoggedIn, setUser, loginUser, isLoggingIn, requirePasswordChange, requireEmailVerification } = useContext(UserContext);
  const { getLogin } = useContext(DataContext);
  // const [showChangePasswordModal, setShowChangePasswordModal] = useState(true);
  const [startupMessageShown, setStartupMessageShown] = useState(false);
  const [primeReminderMessageShown, setPrimeReminderMessageShown] = useState(false);
  const queryParams = new URLSearchParams(window.location.search);
  const startupMessage = queryParams.get("m");

  const doGetLogin = () => {
    getLogin().then((response) => {
      if (response.data?.auth) {
        loginUser(response);
      }
    }).catch((error) => {
      console.log(error);
      // No-op
    })
  };

  useEffect(() => {
    doGetLogin();
  }, []);

  return (
    <div className={"app " + PageClass + (IsTopNavEnabled ? ' with-navbars' : ' without-navbars')}>

      <BrowserRouter>
        <ScrollToTop />

        {/*<SideNavBar />*/}
        {IsTopNavEnabled ? (
          <TopNavBar />
        ) : (
          null
        )}

        {startupMessage && !startupMessageShown ?
          <StartupMessageModal messageType={startupMessage} onClose={() => setStartupMessageShown(true)} />
        : null}

        {User?.premium_till && new Date(Date.parse(User.premium_till)) < toUTCDate(new Date()) && !primeReminderMessageShown && !User?.is_premium_reminder_dismissed ? 
          <PremiumReminderModal onClose={() => setPrimeReminderMessageShown(true)} />
        : null}

        {User?.is_delete  ?
          <DeletedAccountModal />
        : null}

        {User?.email && !User.is_delete && (!User.username || !User.country) ?
          <FinishProfile />
        : null}

        <div className={`app-container pull-right ${IsTopNavEnabled ? 'with-navbars' : 'without-navbars'}`}>
          <div className="page">
            <Routes>
              <Route exact path="/" element=<Splash /> />
              <Route exact path="/login"element={!isLoggedIn ? <OnboardingModal /> : <Login />} />
  {/*            <Route exact path="/login" element={isLoggedIn ? <Navigate to="/projects/my-projects" /> : <Login />}/>
              <Route exact path="/signup" element={isLoggedIn ? <Navigate to="/projects/my-projects" /> : <Signup />}/>
              <Route exact path="/signup-skills" element={isLoggedIn ? <Navigate to="/projects/my-projects" /> : <SignupSkills />}/>
              <Route exact path="/signup-profile" element={isLoggedIn ? <Navigate to="/projects/my-projects" /> : <SignupProfile />}/>*/}

              <Route exact path="/projects" element={<Projects />}>
                <Route index element={<AvailableProjects />} />
                <Route path="my-projects" element={!isLoggedIn ? <OnboardingModal /> : <MyProjects />} />
                <Route path="available-projects" element={<AvailableProjects />} />
              </Route>

              <Route exact path="/suggest-project" element={!isLoggedIn ? <OnboardingModal /> : <CreateProject />}/>
              <Route exact path="/project-detail/:id" element={<ProjectDetail />}/>
              <Route exact path="/edit-project/:id" element={!isLoggedIn ? <OnboardingModal /> : <CreateProject />}/>
              <Route exact path="/join-project/:id" element={!isLoggedIn ? <OnboardingModal /> : <JoinProject />}/>

              <Route exact path="/add-project-module/:projectId" element={!isLoggedIn ? <OnboardingModal /> : <CreateModule />}/>
              <Route exact path="/edit-project-module/:projectId/:moduleId" element={!isLoggedIn ? <OnboardingModal /> : <CreateModule />}/>
              <Route exact path="/module-detail/:projectId/:moduleId" element={!isLoggedIn ? <OnboardingModal /> : <ModuleDetail />}/>

              <Route exact path="/add-project-resource/:projectId" element={!isLoggedIn ? <OnboardingModal /> : <CreateResource />}/>
              <Route exact path="/add-module-resource/:projectId/:moduleId" element={!isLoggedIn ? <OnboardingModal /> : <CreateResource />}/>
              <Route exact path="/edit-project-resource/:projectId/:resourceId" element={!isLoggedIn ? <OnboardingModal /> : <CreateResource />}/>
              <Route exact path="/resource-detail/:projectId/:resourceId" element={!isLoggedIn ? <OnboardingModal /> : <ResourceDetail />}/>

              <Route exact path="/add-project-solution/:projectId" element={!isLoggedIn ? <OnboardingModal /> : <CreateSolution />}/>
              <Route exact path="/add-module-solution/:projectId/:moduleId" element={!isLoggedIn ? <OnboardingModal /> : <CreateSolution />}/>
              <Route exact path="/edit-project-solution/:projectId/:solutionId" element={!isLoggedIn ? <OnboardingModal /> : <CreateSolution />}/>
              <Route exact path="/solution-detail/:projectId/:solutionId" element={!isLoggedIn ? <OnboardingModal /> : <SolutionDetail />}/>

              <Route exact path="/project-participants/:id" element={!isLoggedIn ? <OnboardingModal /> : <ProjectParticipants />}/>
              {/*<Route exact path="/solution-detail/:id" element={!isLoggedIn ? <OnboardingModal /> : <SolutionDetail />}/>*/}
              {/*<Route exact path="/sessions" element={!isLoggedIn ? <OnboardingModal /> : <Sessions />}/>*/}
              {/*<Route exact path="/get-help" element={!isLoggedIn ? <OnboardingModal /> : <GetHelp />}/>*/}
              {/*<Route exact path="/create-mentorship" element={!isLoggedIn ? <OnboardingModal /> : <CreateMentorship />}/>*/}
              {/*<Route exact path="/session-detail/:id" element={!isLoggedIn ? <OnboardingModal /> : <SessionDetail />}/>*/}
              {/*<Route exact path="/user-profile/:id" element={!isLoggedIn ? <OnboardingModal /> : <UserProfile />}/>*/}
              <Route exact path="/updates" element={!isLoggedIn ? <OnboardingModal /> : <Updates />}/>
              <Route exact path="/likes" element={!isLoggedIn ? <OnboardingModal /> : <Likes />}/>
              {/*<Route exact path="/discussions" element={!isLoggedIn ? <OnboardingModal /> : <Discussions />}/>*/}
              <Route exact path="/profile" element={!isLoggedIn ? <OnboardingModal /> : <Profile />}/>
              <Route exact path="/terms" element={<Terms />}/>
              <Route exact path="/privacy-policy" element={<PrivacyPolicy />}/>
              <Route exact path="/contact" element={!isLoggedIn ? <OnboardingModal /> : <Contact />}/>
              <Route exact path="/about" element={<About />}/>
              <Route exact path="/emails" element={<Emails />}/>
            </Routes>
          </div>
        </div>

{/*        {IsBottomNavEnabled ? (
          <BottomNavBar />
        ) : (
          null
        )}*/}
      </BrowserRouter>
    </div>
  );
}

export default App;
