import React, { useEffect, useContext }  from 'react';
import { Modal, ModalHeader, ModalBody, Button } from 'reactstrap';
import { useNavigate, Link, useLocation } from "react-router-dom";

import { DataContext } from '../../contexts/data-context';
import { PageContext } from '../../contexts/page-context';

import "./OnboardingModal.scss";

export default function OnboardingModal() {
  const { logView, serverUrl } = useContext(DataContext);
  const { setIsLoading } = useContext(PageContext);
  const navigate = useNavigate();
  const location = useLocation();

  let redirectUrl = window.location.href;
  redirectUrl = redirectUrl

  useEffect(() => {
    logView('login-signup-modal');
  }, []);

  return (
    <div className="onboarding-modal-container">
      <Modal
        isOpen={true}
        backdrop="static"
        fade={true}
        centered={true}>
        <ModalHeader>
          <span>Log in or sign up</span>
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close-button"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>
        </ModalHeader>
        <ModalBody>
          <div className="onboarding-modal-body">
            <p>We use the third-party service <b>"Kinde"</b> to handle our login and sign-up.</p>

            <p>Your login information including cookies will be collected and processed by Kinde on our behalf. Click <b>"Login"</b> or <b>"Create an account"</b> below to proceed.</p>

            <div className="signup-sections">
              <div className="onboarding-section signup-section">
                <p>New user?</p>

                <div className="link-container primary">
                <a className="primary" href={`${serverUrl}/do_register?redirect_url=${window.location.href.replaceAll('login?project-id=', 'project-detail/').replaceAll('login', '')}`} onClick={() => { setIsLoading(true); }}>Create an account</a>
                </div>
              </div>

              <div className="onboarding-section login-section">
                <p>Already have an account?</p>

                <div className="link-container">
                  <a href={`${serverUrl}/do_login?redirect_url=${window.location.href.replaceAll('login?project-id=', 'project-detail/').replaceAll('login', '')}`} onClick={() => { setIsLoading(true) }}>Log in</a>
                </div>
              </div>
            </div>
         </div>
        </ModalBody>
      </Modal>
    </div>
  );
}