import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ConfirmationModal.scss";

function ConfirmationModal(props) {
  const {
    closeLabel,
    onClose,
    submitLabel,
    onSubmit,
    headerMessage,
    bodyMessage,
  } = props;

  return (
    <Modal
      className="confirmation-modal"
      isOpen={true}
      toggle={onClose}
      backdrop="static"
      centered={true}>
      <ModalHeader>{headerMessage}</ModalHeader>
      <ModalBody>
        <p>{bodyMessage}</p>
      </ModalBody>
      <ModalFooter>
        <div className="secondary-actions">
          <Button color="secondary" outline={true} onClick={() => { onClose(); }}>
            {closeLabel}
          </Button>
        </div>
        <div className="primary-actions">
          <Button color="primary" onClick={() => { onSubmit(); }}>
            {submitLabel}
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ConfirmationModal;
