import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';

import { PageContext } from '../../contexts/page-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./GetHelp.styles.css";

export default function GetHelp() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Get help / feedback');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
  }, []);

  return (
    <div className="get-help full-screen-page">
      <div className="full-screen-header">
        <h2>Get help / feedback</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body">
        <div className="get-help-intro-text">
          <p>
            Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium.
          </p>

          <ul>
            <li>Eum fuga soluta et unde velit vel quidem omnis qui exercitationem iste qui dignissimos numquam cum laboriosam animi</li>
            <li>Et obcaecati modi ab provident dolore et assumenda eveniet eum optio mollitia. Et saepe provident quo adipisci expedita ut excepturi harum est consequatur autem a quae distinctio</li>
            <li>Aut quisquam quia? 33 quos velit et omnis illo et voluptas nulla et nulla nihil</li>
          </ul>

          <p>
            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
          </p>
        </div>

        <div className="get-help-action-section">
          <img src={process.env.PUBLIC_URL + '/vector/ask-question.png'} alt="Ask a question" />
          <div>
            <h3>Ask the community a question</h3>
            <p>Go to the <Link to="/discussions">discussion groups</Link></p>
          </div>
        </div>

        <div className="get-help-action-section">
          <img src={process.env.PUBLIC_URL + '/vector/mentor.png'} alt="Mentor" />
          <div>
            <h3>Get an hour with a mentor</h3>
            <Button color="primary" size="sm" className="mentorship-request-button" onClick={() => {
              navigate('/create-mentorship');
            }}>
              Schedule a mentorship session
            </Button>
          </div>
        </div>
   
      </div>
    </div>
  );
}