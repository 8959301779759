export const sessions = [
  {
    id: 1,
    name: 'Fixing memory leaks in React components',
    status: "SCHEDULED",
    host: 'John Doe',
    hostUserId: 1,
     type: 'live',
    skills: [
        { slug: 'react', name: 'React' },
        { slug: 'node', name: 'Node' },
        { slug: 'express', name: 'Express' },
        { slug: 'mongodb', name: 'Mongo DB' },
      ],
    skillString: 'React, Node, Expreess, MongoDB',
    numParticipants: 5,
    price: 200,
    priceInCurrency: "Rs.200",
    isJoined: true,
    isPaid: true,
    durationString: "1 hour",
    timeString: "June 30, 2023 at 9:30PM, IST",
    sessionLink: 'https://www.somethingurl.com/',
    discussionCount: 5,
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    notes: [
      {
        id: 11,
        type: 'note',
        title: 'Note 1',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
      {
        id: 12,
        type: 'note',
        title: 'Note 2',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
    ],
  },
  {
    id: 2,
    host: 'Jane Doe',
    hostUserId: 1,
    status: "COMPLETE",
    name: 'Debugging session on module: PDF export',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'recording',
    skills: [
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    skillString: 'HTML, CSS',
    numParticipants: 3,
    price: 400,
    priceInCurrency: "Rs.400",
    isJoined: false,
    isPaid: false,
    timeString: "July 5, 2023 at 8:00AM, IST",
    durationString: "1 hour",
    project: {
      name: "Rolling dice simulator",
      type: "work",
    },
    sessionLink: 'https://www.somethingurl.com/',
    discussionCount: 5,
    notes: [
      {
        id: 11,
        type: 'note',
        title: 'Note 1',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
      {
        id: 12,
        type: 'note',
        title: 'Note 2',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
    ],
  },
  {
    id: 3,
    host: 'Jane Doe',
    hostUserId: 1,
    status: "COMPLETE",
    name: 'React debugging 101',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    type: 'recording',
    skills: [
        { slug: 'html', name: 'HTML' },
        { slug: 'css', name: 'CSS' },
      ],
    skillString: 'HTML, CSS',
    numParticipants: 3,
    price: 0,
    priceInCurrency: "Rs.0",
    isJoined: false,
    isPaid: false,
    timeString: "July 5, 2023 at 8:00AM, IST",
    durationString: "2 hours",
    sessionLink: 'https://www.somethingurl.com/',
    discussionCount: 5,
    notes: [
      {
        id: 11,
        type: 'note',
        title: 'Note 1',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
      {
        id: 12,
        type: 'note',
        title: 'Note 2',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
    ],
  },
  {
    id: 4,
    host: 'Jane Doe',
    hostUserId: 1,
    name: 'Polling techniques for MERN single page apps',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",
    status: "COMPLETE",
    type: 'recording',
    skills: [
        { slug: 'react', name: 'React' },
      ],
    skillString: 'React',
    numParticipants: 3,
    price: 300,
    priceInCurrency: "Rs.300",
    isJoined: false,
    isPaid: false,
    timeString: "July 10, 2023 at 1:30PM, IST",
    durationString: "1 hour",
    sessionLink: 'https://www.somethingurl.com/',
    discussionCount: 5,
    notes: [
      {
        id: 11,
        type: 'note',
        title: 'Note 1',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
      {
        id: 12,
        type: 'note',
        title: 'Note 2',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
    ],
  },
  {
    id: 5,
    host: 'Jane Doe',
    hostUserId: 1,
    name: 'Help with my project',
    description: "<p>Lorem ipsum dolor sit amet. Ut molestiae ratione eos obcaecati eligendi aut sunt itaque et harum atque. Cum exercitationem soluta hic provident natus non molestias perferendis aut voluptas voluptas in consequatur voluptatem id doloribus perspiciatis. Sit vitae consequuntur ab repellendus blanditiis ut sint ipsum. Et voluptas dolor aut magnam aliquam sit rerum exercitationem et nesciunt nostrum et culpa reprehenderit qui voluptatum aliquam.</p><p>Ex sint molestiae est autem perferendis et amet quia. Et voluptas provident aut omnis ratione eos blanditiis molestiae et voluptatem harum sed fugit modi est expedita dolorum! In repellat ipsam sed incidunt praesentium sed laudantium maxime et tenetur quidem non nemo quis.</p>",

    status: "SCHEDULED",
    type: 'mentorship',
    skills: [
        { slug: 'react', name: 'React' },
      ],
    skillString: 'React',
    numParticipants: 1,
    price: 300,
    priceInCurrency: "Rs.300",
    isJoined: true,
    isPaid: true,
    timeString: "July 10, 2023 at 1:30PM, IST",
    durationString: "1 hour",
    sessionLink: 'https://www.somethingurl.com/',
    discussionCount: 5,
    notes: [
      {
        id: 11,
        type: 'note',
        title: 'Note 1',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
      {
        id: 12,
        type: 'note',
        title: 'Note 2',
        text: 'Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo.'
      },
    ],
  },
];
