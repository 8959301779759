export const skills = [
  { slug: 'react', name: 'React' },
  { slug: 'node', name: 'Node' },
  { slug: 'nosql', name: 'NoSQL' },
  { slug: 'sql', name: 'SQL' },
  { slug: 'express', name: 'Express' },
  { slug: 'css', name: 'CSS' },
  { slug: 'html', name: 'HTML' },
  { slug: 'sass', name: 'SASS' },
  { slug: 'aws', name: 'AWS' },
];
