import React, { useState, createContext, useEffect } from 'react';

const PageContext = createContext();

function PageProvider(props){
  const [PageTitle, setPageTitle] = useState('');
  const [IsTopNavEnabled, setTopNavEnabled] = useState('');
  const [IsBottomNavEnabled, setBottomNavEnabled] = useState('');
  const [PageClass, setPageClass] = useState('default-page');
  const [IsLoading, setIsLoading] = useState(false);

  useEffect(() => {
    var body = document.querySelector('body');

    if (IsLoading) {
      body?.classList?.add('with-spinner');
    } else {
      body?.classList?.remove('with-spinner');
    }

  }, [IsLoading]);

  useEffect(() => {
    var body = document.querySelector('body');

    if (IsTopNavEnabled) {
      body?.classList?.add('with-navbar');
    } else {
      body?.classList?.remove('with-navbar');
    }

  }, [IsTopNavEnabled]);

  return(
    <PageContext.Provider value={{
      PageTitle,
      setPageTitle,
      IsTopNavEnabled,
      setTopNavEnabled,
      IsBottomNavEnabled,
      setBottomNavEnabled,
      PageClass,
      setPageClass,
      IsLoading,
      setIsLoading,
       }}>
      {props.children}
    </PageContext.Provider>
  );
}

export { PageContext, PageProvider };

