import React, { useContext } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { UserContext } from '../../contexts/user-context';
import "./StartupMessageModal.scss";

function StartupMessageModal(props) {
  const { User } = useContext(UserContext);
  const {
    onClose,
    messageType,
  } = props;

  return (
    <Modal
      className="startup-message-modal"
      isOpen={true}
      toggle={onClose}
      backdrop="static"
      centered={true}>
      <ModalHeader>
        {messageType === "p-s" ?
          <>Premium account upgrade successful</>
        : null}

        {messageType === "p-e1" || messageType === "p-e2" ?
          <>Could not upgrade account</>
        : null}

        {messageType === "p-e3" ?
          <>Account already Premium</>
        : null}
      </ModalHeader>
      <ModalBody>
        {messageType === "p-s" ?
          <>
            <div className="premium-display">
              <span className="project-price premium"><span>Premium</span></span>
            </div>
            <h3>Congrats, you've gone Premium!</h3>
            <p>You now have access to our best projects.</p>
            {User?.premium_till ?
              <ul>
                <li>Your account's Premium status expires on <b>{new Date(User.premium_till).toDateString()}</b>.</li>
                <li>This does not auto-renew. We will prompt you to purchase additional Premium tenure.</li>
              </ul>
            : null}
            <p>We hope you enjoy building great projects and skills!</p>
          </>
        : null}

        {messageType === "p-e3"  ?
          <>
            <p>It appears your account already has Premium status.</p>
            {User?.premium_till ?
              <>
                <p>Your account's Premium status expires on <b>{new Date(User.premium_till).toDateString()}</b>.</p>
                <p>It does not auto-renew. We will prompt you to purchase additional Premium tenure.</p>
              </>
            : null}
          </>
        : null}

        {messageType === "p-e1" || messageType === "p-e2" ?
          <>
            <p>There was an error upgrading to your account to Premium.</p>
            <p>Please refresh the browser window, ensure you are logged in, and try again.</p>
            <p>we aplologize for the inconvenience. If the issue persists, email us at <b>admin@projectklub.com</b>.</p>
          </>
        : null}

      </ModalBody>
      <ModalFooter className="single-action">
        <div className="primary-actions">
          <Button color="primary" onClick={() => { onClose(); }}>
            Close
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default StartupMessageModal;
