import React, { useEffect, useContext, useState, useRef } from 'react';
import { Link } from "react-router-dom";
import { Button, Label } from 'reactstrap';
import { MultiSelect } from "react-multi-select-component";
import { Helmet } from 'react-helmet-async';

import AvailableProjectsList from './AvailableProjectsList';
import { UserContext } from '../../contexts/user-context';
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate } from "react-router-dom";
import { keysToCamel } from '../../util/caseConverters';

import "./AvailableProjects.styles.css";

export default function AvailableProjects() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled, IsLoading } = useContext(PageContext);
  const { getUserAvailableProjects, getAvailableProjects, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [availableProjects, setAvailableProjects] = useState();
  const [filteredProjects, setFilteredProjects] = useState([]);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const [selectedFilterSizes, setSelectedFilterSizes] = useState([]);
  const selectedTypesRef = useRef([]);
  const selectedSizesRef = useRef([]);

  const filterTypeOptions = [
    {value: 'standalone', label: 'Standalone'},
    {value: 'guided', label: 'Practice'},
  ];
  const filterSizeOptions = [
    {value: 'tiny', label: 'Tiny'},
    {value: 'small', label: 'Small'},
    {value: 'medium', label: 'Medium'},
    {value: 'large', label: 'Large'},
  ];


  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Available projects');
    setTopNavEnabled(true);
    setBottomNavEnabled(true);
  }, []);

  useEffect(() => {
    const _types = selectedFilterTypes.map(item => item.value);
    const _sizes = selectedFilterSizes.map(item => item.value);

    selectedTypesRef.current = _types;
    selectedSizesRef.current = _sizes;

    filterProjects(availableProjects || []);
  }, [selectedFilterTypes, selectedFilterSizes]);

  const filterProjects = function(projects) {
    const _types = selectedTypesRef.current;
    const _sizes = selectedSizesRef.current;
    let _temp = JSON.parse(JSON.stringify(projects));

    if (_types.length || _sizes.length) {

      if (_types.length) {
        _temp = _temp.filter(item => _types.includes(item.type));
      }

      if (_sizes.length) {
        _temp = _temp.filter(item => _sizes.includes(item.size));
      }

      setFilteredProjects(_temp);
    } else {
      setFilteredProjects(_temp);
    }
  };

  const fetchData = function(showLoader) {
    const userId = User?.id;

    if (userId) {
      getUserAvailableProjects(userId, showLoader).then((res) => {
        if (res?.data) {
          const _projects = keysToCamel(res.data);
          setAvailableProjects(_projects);
          filterProjects(_projects);
          // console.log('User available projects data  for user ' + userId, res.data);
        }

        logView('available-projects');
      }).catch((error) => {
        // logError(error);
      });
    } else {
      getAvailableProjects(showLoader).then((res) => {
        if (res?.data) {
          const _projects = keysToCamel(res.data);
          setAvailableProjects(_projects);
          filterProjects(_projects);
          // console.log('Available projects', res.data);
        }

        logView('available-projects');
      }).catch((error) => {
        // logError(error);
      });
    }
  };

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchData(false);
      }
    }, 7000);
    return () => {
      clearInterval(intervalId);
    };
  }, [User]);

  return (
    <div className="available-projects">
      <Helmet>
        <title>Available projects - Project Klub</title>
      </Helmet>

      <div className="page-main-section">

        <div className="available-projects-heading-outer-container">
          {availableProjects ?
            <div className="available-projects-heading">
              <h2>
                <span className="count">{filteredProjects.length}</span>

                {selectedFilterTypes.length || selectedFilterSizes.length ?
                  <>
                    <span className="main-title">Filtered projects</span>

                    <Button color="link" size="sm" className="clear-filters" onClick={() => {
                      setSelectedFilterSizes([]);
                      setSelectedFilterTypes([]);
                    }}>
                      Clear filters
                    </Button>
                  </>
                :
                  <span className="main-title">available {filteredProjects.length === 1 ? 'project' : 'projects'} you can join
                  </span>
                }
              </h2>

              <div className="project-filters">
                {/*<div className="project-filter project-type-filter">
                  <MultiSelect
                    id="filter-types"
                    name="filter-types"
                    options={filterTypeOptions}
                    value={selectedFilterTypes}
                    onChange={setSelectedFilterTypes}
                    hasSelectAll={false}
                    disableSearch={true}
                    labelledBy="Type"
                    closeOnChangedValue={true}
                    ClearSelectedIcon={null}
                    valueRenderer={(selected, options) => {
                      if (!selected.length) {
                        return 'All types';
                      } else if (selected.length === 1) {
                        return selected[0].label;
                      } else if (selected.length === options.length) {
                        return 'All types';
                      } else {
                        return `${selected[0].label} + ${selected.length - 1}`;
                      }
                    }}
                  />
                </div>*/}

                <div className="project-filter project-size-filter">
                  <MultiSelect
                    id="filter-sizes"
                    name="filter-sizes"
                    options={filterSizeOptions}
                    value={selectedFilterSizes}
                    onChange={setSelectedFilterSizes}
                    hasSelectAll={false}
                    disableSearch={true}
                    labelledBy="Size"
                    closeOnChangedValue={true}
                    ClearSelectedIcon={null}
                    valueRenderer={(selected, options) => {
                      if (!selected.length) {
                        return 'All sizes';
                      } else if (selected.length === 1) {
                        return selected[0].label;
                      } else if (selected.length === options.length) {
                        return 'All sizes';
                      } else {
                        return `${selected[0].label} + ${selected.length - 1}`;
                      }
                    }}
                  />
                </div>
              </div>
            </div>
          : null }
        </div>

        {filteredProjects?.length ?
          <>
            <div className="available-projects-list-container">
              <AvailableProjectsList projects={filteredProjects} />

              <div className="available-projects-suggest-section">
                <h3>
                  <img src={process.env.PUBLIC_URL + '/vector/suggestion.svg'} alt="Suggestion" />
                  Suggest project topics!
                </h3>
                <p>We have more projects coming soon!</p>
                <p>Tell us what kind of projects you would like to see here.</p>

                <p>If you prefer, you may also email your suggestions to <a className="email-link" href="mailto:admin@projectklub.com" target="_new">admin@projectklub.com</a>. Thank you!</p>

                <div className="suggest-link-container">
                  <Link to="/suggest-project" className="link-as-button primary">
                    <span>Suggest a project</span>
                  </Link>
                </div>
              </div>
            </div>
          </>
        : 
          <>
            {!IsLoading && availableProjects ?
              <div className="available-projects-empty-state available-projects-list-container">
                <img src={process.env.PUBLIC_URL + '/vector/available-projects-empty-state.png'} alt="All projects joined" />

                {selectedFilterTypes.length || selectedFilterSizes.length ?
                  <p>You have joined all the projects that meet your filter criteria.</p>
                :
                  <p>You have joined all the available projects.</p>
                }

                <div>
                  <Button color="primary" size="sm" className="available-projects-my-projects-button" onClick={() => { navigate('/projects/my-projects') }}>
                    Go to my projects
                  </Button>

                  {selectedFilterTypes.length || selectedFilterSizes.length ?
                    <Button color="secondary" outline size="sm" className="empty-state-clear-filters" onClick={() => {
                      setSelectedFilterSizes([]);
                      setSelectedFilterTypes([]);
                    }}>
                      Clear filters
                    </Button>
                  : null}
                </div>
              </div>
            : null}
          </>
        }
      </div>
    </div>
  );
}