import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import PasswordStrengthBar from 'react-password-strength-bar';
import { isUsername, isPassword, isName, isEmail } from '../../util/validators';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate } from "react-router-dom";
import PageError from '../messages/PageError';

function DeleteAccountModal(props) {
  const [usernameOrEmail, setUsernameOrEmail] = useState('');
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isUsernameOrEmailError, setUsernameOrEmailError] = useState(false);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);

  const { deleteAccount, logView, logError } = useContext(DataContext);
  const { User, logoutUser } = useContext(UserContext);
  const navigate = useNavigate();

  const { onCancel } = props;

  const doDeleteAccount = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      setPageErrorMessage(null);

      deleteAccount().then((response) => {
        logoutUser();
        navigate('/');
        alert('Your account has been deleted.');
      }).catch((error) => {
        // logError(error);
        setPageErrorMessage('An error occurred when trying to delete your account. Please try again.');
        console.log(error);
      });
    }
  };

  useEffect(() => {
    logView('delete-account');
  }, []);

  useEffect(() => {
    const _isUsernameOrEmailError = !((usernameOrEmail === User?.username) || (usernameOrEmail === User?.email));
    setDoesFormHaveErrors(_isUsernameOrEmailError);
  }, [usernameOrEmail]);

  return (
      <Modal
        className="delete-account-modal"
        isOpen={true}
        toggle={onCancel}
        backdrop="static"
        centered={true}>
        <ModalHeader>Delete account</ModalHeader>
        <ModalBody>
          <form id="delete-account-form" className={`modal-content-body ${showFormErrors ? 'form-errors' : ''}`}>

            {pageErrorMessage ? 
              <div className="page-error-container">
                <PageError messageText={pageErrorMessage} />
              </div>
            : null}

            <p>Are you sure you want to delete your account? This action is permanent and cannot be undone.</p>
            <p>If you are sure, enter your username or email and click "Yes, delete my account".</p>
            <div className="half-width-container">
              <div className={`form-group half-width ${doesFormHaveErrors ? 'error-field' : ''}`}>
                <Label for="username-or-email">Username or email</Label>
                <Input
                  id="username-or-email"
                  name="username-or-email"
                  type="text"
                  autoComplete="off"
                  value={usernameOrEmail}
                  onChange={(e) => { setUsernameOrEmail(e.target.value);}}
                />
                {showFormErrors && doesFormHaveErrors ?
                  <div className="form-field-error-message">
                    Your username or email did not match our records. Please try again.
                  </div>
                 : null}
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <div className="secondary-actions">
            <Button color="secondary" outline={true} onClick={() => { onCancel(); }}>
              Cancel
            </Button>
          </div>
          <div className="primary-actions">
            <Button color="primary" onClick={doDeleteAccount}  type="submit" form="delete-account-form" >
              Yes, delete my account
            </Button>
          </div>
        </ModalFooter>
      </Modal>
  );
}

export default DeleteAccountModal;
