import React, { useState, useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap'
import Select from 'react-select'
import { MultiSelect } from "react-multi-select-component";
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Helmet } from 'react-helmet-async';

import PageError from '../messages/PageError';
import Spinner from '../common/Spinner';
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { keysToCamel } from '../../util/caseConverters';

import { solutionStatusOptions } from '../../constants/solutionStatusOptions';

import "./CreateSolution.scss";

export default function CreateSolution() {
  const navigate = useNavigate();
  const location = useLocation();
  const { projectId, solutionId, moduleId } = useParams();
  const [step, setStep] = useState(0);
  const [role, setRole] = useState('');
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { createSolution, editSolution, getSolution, getProjectRole, getAllProjectModules, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [projectModules, setProjectModules] = useState([]);
  const [selectedModules, setSelectedModules] = useState([]);
  const [selectedModulesStr, setSelectedModulesStr] = useState('');
  const [scope, setScope] = useState('all');
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [isAllowComments, setIsAllowComments] = useState(true);

  const [solution, setSolution] = useState();
  const [title, setTitle] = useState('');
  // const [body, setBody] = useState('');
  const [url, setUrl] = useState('');
  const [codeUrl, setCodeUrl] = useState('');
  const [feedbackAreas, setFeedbackAreas] = useState('');
  const [status, setStatus] = useState(solutionStatusOptions[0]);
  const [editHtml, setEditHtml] = useState(false);

  const html = '<p></p>';
  const contentBlock = htmlToDraft(html);
  const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
  const [editorState, setEditorState] = useState(EditorState.createWithContent(contentState));
  const [body, setBody] = useState(html);

  const onEditorStateChange = (editorState) => {
    setEditorState(editorState);
    setBody(draftToHtml(convertToRaw(editorState.getCurrentContent())));
  };

  const [isTitleError, setTitleError] = useState(false);
  const [isBodyError, setBodyError] = useState(false);
  const [isModulesError, setModulesError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  useEffect(() => {
    setPageTitle('Add a solution');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('create-solution', `${projectId}-${solutionId || '?'}`);
  }, []);

  useEffect(() => {
    if (projectId) {
      const userId = User?.id;
      getProjectRole(userId, projectId).then((response) => {
        if (response?.data?.length) {
          setRole(response.data[0].role);
        } else {
          setRole('outsider');
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        setRole('outsider');
      });
    }
  }, [projectId]);

  useEffect(() => {
    if (solutionId) {
      setStep(1);
      setPageErrorMessage(null);
      setPageTitle('Edit Solution');
      let solution;

      getSolution(projectId, solutionId).then((response) => {
        if (response?.data?.length) {
          solution = keysToCamel(response).data[0];
          setSolution(solution)
          setTitle(solution.title || '');

          if (solution.body) {
            setBody(solution.body);
            const contentBlock = htmlToDraft(solution.body);
            const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
            setEditorState(EditorState.createWithContent(contentState));
          }

          setUrl(solution.url || '');
          setCodeUrl(solution.codeUrl || '');
          setStatus(solutionStatusOptions.find(item => item.value === solution.status));
          setIsAllowComments(solution.allowComments);
          setFeedbackAreas(solution.feedbackAreas || '');
        }
      }).catch((error) => {
        setPageErrorMessage('An error occured. Please refresh the page and try again.');
        console.log(error);
        // logError(error);
      });
    }

    getAllProjectModules(projectId).then((response) => {
      setPageErrorMessage(null);
      
      if (response?.data) {
        setProjectModules(response.data.map((item) => {
         return { value: item.id, label: item.title };
        }));

        if (moduleId) {
          const projectMatchingModule = response.data.find(item => item.id === parseInt(moduleId));
          // console.log(projectMatchingModule);
          if (projectMatchingModule) {
            setSelectedModules([{
              label: projectMatchingModule.title,
              value: parseInt(moduleId)
            }]);
            setScope('modules');
          }
        }
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
    });

  }, [projectId, solutionId, moduleId]);

  useEffect(() => {
    if (solution?.modules && projectModules) {
      const moduleIds = solution.modules.split(',').map(item => parseInt(item));
      setSelectedModules(projectModules.filter(item => moduleIds.includes(item.value)));
      setScope(solution.modules.length ? 'modules' : 'all');
    }
  }, [solution, projectModules]);

  useEffect(() => {
// console.log('FOO', selectedModules);
// console.log(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''));
    setSelectedModulesStr(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''));
    // if (selectedModules.length) {
    //   setScope('modules');
    // } else {
    //   setScope('all');
    // }
  }, [selectedModules]);

  useEffect(() => {
    const _isTitleError = !title.trim().length;
    setTitleError(_isTitleError);

    const _isBodyError = !body.trim().length || body.trim()==='<p></p>'
    setBodyError(_isBodyError);

    const _isModulesError = scope === 'modules' && !selectedModulesStr?.length;
    // const _isModulesError = true;
    // alert(scope === 'modules' && !selectedModulesStr?.length);
    setModulesError(_isModulesError);

    const _doesFormHaveErrors = _isTitleError || _isBodyError || _isModulesError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [title, body, scope, selectedModulesStr]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      if (solutionId) {
        setPageErrorMessage(null);
        editSolution(projectId, solutionId, title, body, url, codeUrl, status.value, selectedModulesStr, isAllowComments, feedbackAreas).then((response) => {
          if (response) {
            navigate('/project-detail/' + projectId);
          }
        }).catch((error) => {
          setPageErrorMessage('An error occurred. Please check if the solution title is unique for the project, and try again.');
          // logError(error);
        });
      } else {
        setPageErrorMessage(null);
        createSolution(projectId, title, body, url, codeUrl, status.value, selectedModulesStr, isAllowComments, feedbackAreas).then((response) => {
          if (response) {
            navigate('/project-detail/' + projectId);
          }
        }).catch((error) => {
          setPageErrorMessage('An error occurred. Please check if the solution title is unique for the project, and try again.');
          // logError(error);
        });
      }
    } else {
      const _isTitleError = !title.trim().length;
      const _isBodyError = !body.trim().length || body.trim()==='<p></p>'
      const _isModulesError = scope === 'modules' && !selectedModulesStr?.length;
      const _doesFormHaveErrors = _isTitleError || _isBodyError || _isModulesError;

      if (_doesFormHaveErrors) {
        setStep(1);
      }

      if (_doesFormHaveErrors && !_isTitleError && !_isTitleError) {
        const element = document.getElementById('create-solution-body');

        if (element) {
          element.scrollTop = element.scrollHeight;
        }
      }
    }
  };

  return (
    <div className="create-solution full-screen-page">
      <Helmet>
        <title>{solutionId ? 'Edit solution' : 'Add a solution'} - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        {solutionId ? <h2>Edit solution</h2> : <h2>Add a solution</h2> }

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();

              if (step === 2) {
                setStep(1);
              } else {
                navigate(-1);
              }
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="create-solution-body">
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {projectId && (role === 'owner' || role === 'administrator' || role === 'contributor') ?
          <>
            <div className={`full-screen-page-body`} id="create-solution-body">
              <form onSubmit={submitForm} className={`${showFormErrors ? 'form-errors' : ''}`}>
                {step === 0 ?
                  <div className="intro">
                    <div className="illustration-container">
                      <img src={process.env.PUBLIC_URL + '/vector/add-solution.svg'} alt="Add a solution" />
                    </div>
                    <h3>Get started with a solution</h3>
                    <ul>
                      <li><b>Title:</b> mention something about the approach and tools you're choosing for this solution</li>
                      <li><b>Description:</b> desceribe your solution with as much context as you can.</li>
                      <li><b>Status:</b> mark your solution as "in progress" or "completed"</li>
                      <li><b>Links:</b> Add your solution's code and live URLs, if any</li>
                      <li><b>Feedback:</b> Specify whether other users can comment on your solution, and mention if you are lookin for feedback around any specific areas</li>
                    </ul>
                    <p className="info">You don't need to finish your solution before you can add it. You can always add an "in progress" solution, and exchange feedback with other users as you build.</p>
                  </div>
                : null }

                {step === 1 ?
                  <>
                    <div className={`form-group full-width ${isTitleError ? 'error-field' : ''}`}>
                      <Label for="solution-title">Solution title</Label>
                      <Input
                        id="solution-title"
                        name="solution-title"
                        placeholder="Enter a title for the solution"
                        type="text"
                        value={title}
                        autoComplete="off"
                        // disabled={!!solutionId}
                        onChange={(e) => {
                          setTitle(e.target.value);
                        }}
                      />
                      {showFormErrors && isTitleError ?
                        <div className="form-field-error-message">
                          Enter a title
                        </div>
                       : null}
                    </div>


                    <div className={`form-group full-width`}>
                      <Label for="solution-status">Solution status</Label>
                      <div className="solution-status-select-container">
                        <Select
                          name="solution-status"
                          id="solution-status"
                          options={solutionStatusOptions}
                          value={status}
                          onChange={setStatus}
                          isSearchable={false}
                          />
                      </div>
                    </div>

                    <div className={`form-group full-width body-field-container solution-body-container ${isBodyError ? 'error-field' : ''}`}>
                      <Label for="solution-body">Body / description</Label>

                      <div className="inline-radio-group">
                        <div className="styled-radio inline styled-radio-tab">
                          <label>
                            <Input type="radio" name="edit-mode" checked={!editHtml} onChange={() => {setEditHtml(false); }}
                              />
                            <span>Visual editor</span>
                          </label>
                        </div>
                        <div className="styled-radio inline styled-radio-tab">
                          <label>
                            <Input type="radio" name="edit-mode" checked={!!editHtml} onChange={() => {setEditHtml(true); }}
                              />
                            <span>HTML editor</span>
                          </label>
                        </div>
                      </div>

                      <div className="styled-radio-tab-body">
                        {editHtml ?
                          <Input
                            id="solution-body-html"
                            name="solution-body-html"
                            placeholder="Enter description"
                            type="textarea"
                            value={body}
                            autoComplete="off"
                            onChange={(e) => {
                              const html=e.target.value;
                              setBody(html);
                              const contentBlock = htmlToDraft(html);
                              const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                              setEditorState(EditorState.createWithContent(contentState));
                            }}
                          />
                        :
                          <Editor
                            id="solution-body"
                            name="solution-body"
                            editorState={editorState}
                            wrapperClassName="resource-editor-wrapper"
                            editorClassName="resource-editor"
                            onEditorStateChange={onEditorStateChange}
                          />
                        }

                        <ul className="styled-radio-tab-body-help-text">
                          <li>Include solution notes like: <b>how to run</b> or see output, what are <b>next steps</b> on this solution if any, and what kind of <b>feedback</b> you're looking for.</li>
                          <li>If you need to copy-paste <b>code blocks</b>, we recommend using <b>HTML mode</b>. Make sure you <b>escape your code</b> block (using <a href="https://codebeautify.org/html-escape-unescape" target="_new">a tool like this</a>), and enclose it in a <b>&lt;pre&gt;...&lt;/pre&gt;</b> block.</li>
                        </ul>
                      </div>

                      {showFormErrors && isBodyError ?
                        <div className="form-field-error-message">
                          Enter solution description
                        </div>
                      : null}
                    </div>



                    {/*Uncomment this later*/}
                    {/*<div className="form-group full-width inline-radio-field solution-options">
                      <Label>Solution scope</Label>
                      <div className="inline-radio-group">
                        <div className="styled-radio inline">
                          <label>
                            <Input type="radio" name="solution-scope" value={scope} checked={scope === 'all'} onChange={() => {
                              setScope('all');
                            }}/>
                            <span>Project-wide</span>
                          </label>
                        </div>
                        <div className="styled-radio inline">
                          <label>
                            <Input type="radio" name="solution-scope" value={scope} checked={scope === 'modules'} disabled={!projectModules?.length} onChange={() => { setScope('modules'); }}
                              />
                            <span>Specify modules</span>
                          </label>
                        </div>
                      </div>
                    </div>*/}

                    {/*<pre>{JSON.stringify(selectedModules.reduce((acc, val, idx) => acc + val.value + (idx + 1 < selectedModules.length ? ',' : ''), ''))}</pre>*/}
                    {/*<pre>{JSON.stringify(selectedModulesStr)}</pre>*/}

                    {scope !== 'all' ?
                      <div className={`form-group full-width ${isBodyError ? 'error-field' : ''}`}>
                        <Label for="solution-modules">Modules</Label>
                        <MultiSelect
                          id="solution-modules"
                          name="solution-modules"
                          options={projectModules}
                          value={selectedModules}
                          onChange={setSelectedModules}
                          hasSelectAll={false}
                          labelledBy="Choose modules this solution applies to"
                        />
                        {showFormErrors && isModulesError ?
                          <div className="form-field-error-message">
                            Choose modules
                          </div>
                         : null}
                      </div>
                    : null}
                  </>
                : null }
                {step === 2 ?
                  <>
                    <div className={`form-group full-width`}>
                      <Label for="solution-code-url">Code URL (if any)</Label>
                      <Input
                        id="solution-code-url"
                        name="solution-code-url"
                        placeholder="github.com/..."
                        type="url"
                        value={codeUrl}
                        autoComplete="off"
                        onChange={(e) => {
                          setCodeUrl(e.target.value);
                        }}
                      />
                    </div>

                    <div className={`form-group full-width`}>
                      <Label for="solution-title">Solution Link URL (if any)</Label>
                      <Input
                        id="solution-url"
                        name="solution-url"
                        placeholder="https://www..."
                        type="url"
                        value={url}
                        autoComplete="off"
                        onChange={(e) => {
                          setUrl(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-group full-width inline-radio-field solution-allow-comments">
                      <Label>Allow user comments on this solution?</Label>
                      <div className="inline-radio-group">
                        <div className="styled-radio inline">
                          <label>
                            <Input type="radio" name="size" value={true} checked={isAllowComments} onChange={() => { setIsAllowComments(true) }}
                              />
                            <span>Yes</span>
                          </label>
                        </div>
                        <div className="styled-radio inline">
                          <label>
                            <Input type="radio" name="size" value={false} checked={!isAllowComments} onChange={() => { setIsAllowComments(false) }}
                              />
                            <span>No</span>
                          </label>
                        </div>
                        {/*<div className="styled-radio inline">
                          <label>
                            <Input type="checkbox" name="is-allow-comments" value={isAllowComments} checked={!!isAllowComments} onChange={(e) => {
                              setIsAllowComments(e.target.checked || false);
                            }}/>
                            <span>Yes, allow user comments on this solution</span>
                          </label>
                        </div>*/}
                      </div>
                    </div>

                    {isAllowComments ?
                      <div className={`form-group full-width`}>
                        <Label for="solution-feedback-areas">List any specific questions / areas you would like feedback around (if any)</Label>
                        <Input
                          id="solution-feedback-areas"
                          name="solution-feedback-areas"
                          type="textarea"
                          value={feedbackAreas}
                          autoComplete="off"
                          // disabled={!!solutionId}
                          maxlength={2000}
                          onChange={(e) => {
                            setFeedbackAreas(e.target.value);
                          }}
                        />
                      </div>
                    : null }
                  </>
                : null }
              </form>
            </div>




            <div className="create-project-actions form-footer">
              <div>
                {step === 0 ?
                  <>
                    <Button color="secondary" outline onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
                      Cancel
                    </Button>

                    <Button color="primary" onClick={() => {setStep(1)}}>
                      Get started
                    </Button>
                  </>
                :
                  <>
                    {step === 1 ?
                      <>
                        <Button color="secondary" outline onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
                          Cancel
                        </Button>

                        <Button color="primary" onClick={() => {setStep(2)}}>
                          Next
                        </Button>
                      </>
                    :
                      <>
                        <Button color="secondary" outline onClick={() => { setStep(1) }}>
                          Previous
                        </Button>

                        <Button type="submit" color="primary" onClick={submitForm}>
                          {solutionId ?
                            <span>Update solution</span>
                          :
                            <span>Add solution</span>
                          }
                        </Button>
                      </>
                    }
                  </>
                }
              </div>
            </div>
          </>
        :
          <>
            {role ?
              <div className="page-error-container">
                <PageError messageText="You need to be an owner or administrator of this project to edit the project.<" />
              </div>
            :
              <Spinner />
            }
          </>
        }
      </div>

    </div>
  );
}