import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./GettingStartedModal.scss";

// Grok the project
// Brief: Read the project brief & design mocks if any
// Modules: Read through project "modules" if any, and understand the project structure
// Resources: Projects usually have assets, potential approaches and other elements you need under "resources"

// Create your solution
// Add a solution in the project's "solutions" section
// Outline your solution approach in the description
// Get feedback using solution comments while you build
// Add a link to your  in-progress / fully-built solution
// Get more feedback, and iterate

// Exchange help & feedback
// Browse other solutions in the project
// Raise and respond to help requests
// Use comments to discuss the project and solutions
// Improve your solution, or add more solutions

// Build together, grow together
// We hope you enjoy working on this project!


function GettingStartedModal(props) {
  const {
    project,
    onDismiss,
  } = props;

  const [currentStep, setCurrentStep] = useState(1);

  return (
    <Modal
      className="getting-started-modal"
      isOpen={true}
      toggle={onDismiss}
      backdrop="static"
      centered={true}>
      <ModalHeader>Getting started with this project</ModalHeader>
      <ModalBody>
        <div className="step-meter">
          <div className={`step-meter-step step-1 ${currentStep === 1 ? 'active': ''} `}>
            <span className="step-meter-step-number">1</span>

            {currentStep === 1 ?
              <h2 className="step-meter-active-title">
                Understand the project
              </h2>
             : null}
          </div>

          <div className={`step-meter-step step-2 ${currentStep === 2 ? 'active': ''} `}>
            <span className="step-meter-step-number">2</span>

            {currentStep === 2 ?
              <h2 className="step-meter-active-title">
                Craft your solution
              </h2>
             : null}
          </div>

          <div className={`step-meter-step step-3 ${currentStep === 3 ? 'active': ''} `}>
            <span className="step-meter-step-number">3</span>

            {currentStep === 3 ?
              <h2 className="step-meter-active-title">
                Exchange help & feedback
              </h2>
             : null}
          </div>

          <div className={`step-meter-step step-4 ${currentStep === 4 ? 'active': ''} `}>
            <span className="step-meter-step-number">4</span>

            {currentStep === 4 ?
              <h2 className="step-meter-active-title">
                Build & grow together
              </h2>
             : null}
          </div>
        </div>

        {currentStep === 1 ?
          <div className="getting-started-step step-1">
            {/*<h2>Grok the project</h2>*/}

            <div className="getting-started-steps-content">
              <div className="getting-started-steps-container">
                <ul>
                  <li><strong>Summary:</strong> Read the project summary & design mocks if any</li>
                  <li><strong>Modules:</strong> Read through project "modules" if any, and understand the project structure</li>
                  <li><strong>Resources:</strong> Projects usually have design assets, potential approaches and other elements you need to build, under "resources"</li>
                </ul>
              </div>

              {project.type === 'standalone' ?
                <p className="tip">Refer to the <strong>project brief</strong> about specific areas where the project owner has requested help. Some project owners also use <strong>help requests</strong> to describe the modules and areas that need help.</p>
              : null}
            </div>

            <div className="getting-started-illustration-container">
              <img className="getting-started-illustration" src={process.env.PUBLIC_URL + '/vector/getting-started-step-1.svg'} alt="Study the project brief and resources" />
            </div>
          </div>
        : null}

        {currentStep === 2 ?
          <div className="getting-started-step step-2">
            {/*<h2>Create your solution</h2>*/}

            <div className="getting-started-steps-content">
              <div className="getting-started-steps-container">
                <ul>
                  <li><strong>Add a solution</strong> in the project's "solutions" section</li>
                  <li>Outline your <strong>solution approach</strong> in the description</li>
                  <li>Get feedback using <strong>solution comments</strong> and <strong>help requests</strong> while you build</li>
                  <li>Add a <strong>link</strong> to your  in-progress / fully-built solution</li>
                  <li>Get more feedback, and iterate</li>
                </ul>
              </div>

              <p className="tip">Most projects have a resource titled "solution approach". Read through this to get an idea for a possible approach, if necessary.</p>

              {project.type === 'standalone' ?
                <p>Since this is a standalone project, make sure you are using the tools and technologies the project owner has specified in the brief.</p>
              :
                <p>Use any tools and technologies you are comfortable with, or are trying to learn!</p>
              }
            </div>

            <div className="getting-started-illustration-container">
              <img className="getting-started-illustration" src={process.env.PUBLIC_URL + '/vector/getting-started-step-2.png'} alt="Create your solution" />
            </div>
          </div>
        : null}

        {currentStep === 3 ?
          <div className="getting-started-step step-3">
            {/*<h2>Exchange help & feedback</h2>*/}
            <div className="getting-started-steps-content">
              <div className="getting-started-steps-container">
                <ul>
                  <li>Browse <strong>other solutions</strong> in the project</li>
                  <li>Raise and respond to <strong>help requests</strong></li>
                  <li>Use <strong>comments</strong> to discuss the project and solutions</li>
                  <li><strong>Iterate:</strong> Improve your solution, or add more solutions</li>
                </ul>
              </div>
            </div>

            <div className="getting-started-illustration-container">
              <img className="getting-started-illustration" src={process.env.PUBLIC_URL + '/vector/getting-started-step-3.svg'} alt="Exchange help and feedback" />
            </div>
          </div>
        : null}

        {currentStep === 4 ?
          <div className="getting-started-step step-4">
            {/*<h2>Build and grow together</h2>*/}

            <div className="getting-started-steps-content">
              <div className="getting-started-steps-container">
                <div>
                  <p>We hope you enjoy working on this project.</p>

                  <p>Happy hacking!</p>
                </div>
              </div>
            </div>

            <div className="getting-started-illustration-container">
              <img className="getting-started-illustration" src={process.env.PUBLIC_URL + '/vector/getting-started-step-4.svg'} alt="Build and grow together" />
            </div>
          </div>
        : null}
      </ModalBody>
      <ModalFooter>
        <div className="secondary-actions">
          {currentStep > 1 ?
            <Button color="secondary" outline onClick={() => { setCurrentStep(currentStep - 1) }}>
              Back
            </Button>
          : null }        
        </div>
        <div className="primary-actions">
          {currentStep === 4 ?
            <Button color="primary" onClick={onDismiss}>
              Close
            </Button>
          :
            <Button color="primary" outline onClick={() => { setCurrentStep(currentStep + 1) }}>
              Next
            </Button>            
          }
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default GettingStartedModal;
