import React, { useState, createRef, useLayoutEffect } from 'react';
import { Button } from 'reactstrap';
import SmallProjectCard from '../cards/SmallProjectCard';
import ProjectCardV2 from '../cards/ProjectCardV2';
import "./AvailableProjectsList.styles.css";

export default function AvailableProjectsList(props) {
  const { projects } = props;

  const [isOverflow, setOverflow] = React.useState(false);
  const [showFull, setShowFull] = React.useState(false);

  const ref = createRef();

  useLayoutEffect(() => {
    if (ref.current?.clientHeight < ref.current?.scrollHeight) {
      setOverflow(true);
    }
  }, [ref]);

  return (
    <>
      <div className={`available-projects-list large-cards ${isOverflow && !showFull ? 'with-overflow' : ''} ${showFull ? 'show-full' : ''}`} ref={ref}>
        {projects.map((item, idx) => {
          return (
            <ProjectCardV2 project={item} key={idx} />
          );
        })}
      </div>

      {isOverflow && !showFull ?
        <div className="available-projects-list-show-full-outer-container">
          <div className="available-projects-list-show-full-container">
            <Button color="primary" outline size="sm" onClick={() => { setShowFull(true) }}>
              Show all {projects.length}
            </Button>
          </div>
        </div>
      : null}
    </>
  );
}