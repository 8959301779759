import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";
import { UserContext } from '../../contexts/user-context';
import ProfileVerificationModal from '../profileVerification/ProfileVerificationModal';

import "./EarningsEnrollment.scss";

export default function EarningsEnrollment(props) {
  const { User } = useContext(UserContext);
  const [isEnroll, setEnroll] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const [isEnrollError, setEnrollError] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);

  const {
    onPrevious,
    onNext,
  } = props;

  useEffect(() => {
    setEnroll(props.isProjectEarningsEnrolled);

    setIsVisible(props.isVisible);
  }, [props.isProjectEarningsEnrolled, props.isVisible]);

  useEffect(() => {
    const _isEnrollError = isEnroll === null;
    setEnrollError(_isEnrollError);

    const _doesFormHaveErrors = _isEnrollError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [isEnroll]);

  const handleSubmit = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(isEnroll);
    }
  };

  return (
    <div className={`enrollment-settings ${isVisible ? 'visible' : 'hidden'}`}>
      <div className={`full-screen-page-body`}>
        <h3 className="full-screen-sub-header">Earnings program enrollment</h3>

        <p className="enrollment-text">At vero eos et accusam et justo duo dolores et ea rebum. Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

        <p className="enrollment-text">Stet clita kasd gubergren, no sea takimata sanctus est Lorem ipsum dolor sit amet.</p>

        <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>
          <div className={`${doesFormHaveErrors ? 'error-field' : ''}`}>
            <div className="styled-radio large full-width">
              <label>
                <Input type="radio"
                  name="isEnroll"
                  value={false}
                  checked={isEnroll === false}
                  onChange={(e) => {
                    setEnroll(e.target.value === 'true');
                  }}
                  />
                <span>Project is FREE for joinees</span>
              </label>
            </div>
            <div className="styled-radio large full-width">
              <label>
                <Input type="radio"
                  name="isEnroll"
                  value={true}
                  checked={isEnroll === true}
                  onChange={(e) => {
                    // console.log(!!User.isVerified);
                    setEnroll(e.target.value === 'true');
                  }}
                  />
                <span>Enroll project in the earnings program</span>
              </label>
            </div>

            {isEnroll ?
              <div className="verification-blurb">
                {User.isVerified ?
                  <div className="profile-verified">
                    <span>User profile verified</span>
                    <p>You can enroll this project in the earnings program. The points coins  for users to join the project will be set by the platform when we review it after you add resources and modules, and publish the project.</p>
                  </div>
                :
                  <div className="profile-unverified">
                    <span>Your user profile is not verified</span>
                    <p>The project will get auto-enrolled and published after verification is complete, if you click “Get verified” and start the verification process now.</p>
                    <button type="button" onClick={() => { setShowVerificationModal(true); }}>
                      Get verified
                    </button>
                  </div>
                }
              </div>
            : null}

            {showFormErrors && isEnrollError ?
              <div className="form-field-error-message">
                Choose one of these options
              </div>
             : null}
          </div>
        </form>
      </div>

      {showVerificationModal ?
        <ProfileVerificationModal
          toggleModal={() => { 
            setShowVerificationModal(!showVerificationModal);
          }}
          submitForm={() => { 
            // TODO implement
          }} />
      : null}

      <div className="create-project-actions form-footer">
        <div>
          <Button color="secondary" outline onClick={() => { onPrevious() }}>
            Previous
          </Button>
   
          <Button color="primary" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}