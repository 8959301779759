export const discussionMessages = [
  {
    id: 1,
    text: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit  "SEO" module  in their project.',
    userName: 'John Doe',
    userId: 1,
    userThumbnail: '/icons/person.svg',
    isCurrentUser: false,
    timeString: "June 30 at 4:15PM",
  },
  {
    id: 2,
    text: 'Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil project molestiae consequatur.',
    userName: 'John Doe',
    userId: 1,
    userThumbnail: '/icons/person.svg',
    isCurrentUser: true,
    timeString: "June 30 at 4:27PM",
  },
  {
    id: 3,
    text: 'Ut porro unde et deleniti quod ea perspiciatis quaerat vel explicabo consequatur et iure omnis.',
    userName: 'Jane Doe',
    userId: 1,
    userThumbnail: '/icons/person.svg',
    isCurrentUser: false,
    timeString: "July 1 at 6:15AM",
  },
  {
    id: 4,
    text: 'Ex corrupti veniam sit sequi internos vel voluptas consequatur qui omnis debitis? Est harum praesentium aut ullam dolores est omnis sunt est voluptas incidunt qui odio consequuntur sed quia provident.',
    userName: 'John Doe',
    userId: 1,
    userThumbnail: '/icons/person.svg',
    isCurrentUser: false,
    timeString: "July 3 at 8:14PM",
  },

];
