import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Menu, MenuItem, MenuButton, SubMenu } from '@szhsin/react-menu';
import '@szhsin/react-menu/dist/index.css';
import '@szhsin/react-menu/dist/transitions/slide.css';
import { Helmet } from 'react-helmet-async';

import PageError from '../messages/PageError';

import { keysToCamel } from '../../util/caseConverters';
import ConfirmationModal from "../common/ConfirmationModal";
import InformationModal from "../common/InformationModal";
import RichTextModal from "../common/RichTextModal";
import ResourceCard from "../cards/ResourceCard";
import ModuleCardV2 from "../cards/ModuleCardV2";
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';

import "./ResourceDetail.scss";

export default function ResourceDetail() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getResource, getProjectResources, getProjectModules, getProjectRole, getProject, getGenericResources, deleteResource, postFetchModules, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const { projectId, resourceId } = useParams();
  const [resource, setResource] = useState();
  const [modules, setModules] = useState([]);
  const [role, setRole] = useState('');
  const [showDeleteConfirmationModal, setShowDeleteConfirmationModal] = useState(false);
  const [showDeleteInformationModal, setShowDeleteInformationModal] = useState(false);
  const [deleteInformationMessage, setDeleteInformationMessage] = useState('The resource has been deleted.');
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [showExpandedSummary, setShowExpandedSummary] = useState(false);
  const [otherResources, setOtherResources] = useState([]);
  const [genericResources, setGenericResources] = useState([]);
  const [project, setProject] = useState();
  const [projectModules, setProjectModules] = useState([]);
  const [projectResources, setProjectResources] = useState([]);

  useEffect(() => {
    setPageTitle('Resource');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('resource-detail', `${projectId}-${resourceId}`);
  }, []);

  const editAllowedRoles = ['owner', 'administrator'];
  const contributorAllowedRoles = ['owner', 'administrator', 'contributor'];

  const fetchData = function(showLoader) {
    let resource;
    setPageErrorMessage(null);

    getResource(projectId, resourceId, showLoader).then((response) => {
      if (response?.data?.length) {
        resource = keysToCamel(response).data[0];
        setResource(resource);

        const userId = User?.id;
        if (userId) {
          getProjectRole(userId, projectId, showLoader).then((response) => {
            if (response?.data?.length) {
              setRole(response.data[0].role);
            } else {
              setRole('outsider');
            }
          }).catch((error) => {
            console.log(error);
            // logError(error);
            setRole('outsider');
          });
        }

        if (resource.modules) {
          postFetchModules(resource.modules, showLoader).then((response) => {
            setModules(response.data);
          }).catch((error) => {
            console.log(error);
            // logError(error);
            setPageErrorMessage('An error occured. Please refresh the page and try again.');
          });
        } else {
          setModules([]);
        }
      }
    }).catch((error) => {
      setPageErrorMessage('An error occured. Please refresh the page and try again.');
      // logError(error);
      console.log(error);
    });

    // 132 is a "special" invisible project used to house generic resources
    if (parseInt(projectId) !== 132) {
      getProjectResources(projectId, false).then((response) => {
        if (response?.data) {
          setProjectResources(response.data);
          setOtherResources(response.data?.filter(item => item.id !== parseInt(resourceId) && !item.modules));
        }
      }).catch((error) => {
        console.log(error);
        // logError(error);
        // No-op. Silent fail.
      });
    } else {
      setOtherResources([]);
      setGenericResources([]);
    }

    getProject(projectId, false).then((response) => {
      let project;

      if (response?.data?.length) {
        project = keysToCamel(response).data[0];
        setProject(project);

        if (project?.genericResources?.length) {
          getGenericResources(project.genericResources, false).then((response) => {
            if (response?.data) {
              setGenericResources(response.data);
            }
          }).catch((error) => {
            console.log(error);
            // logError(error);
            // No-op. Silent fail.
          });
        }
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      // No-op. Silent fail.
    });

    getProjectModules(projectId, false).then((response) => {
      if (response?.data) {
        setProjectModules(response.data);
      }
    }).catch((error) => {
      console.log(error);
      // logError(error);
      // No error. Silent fail.
    });
  };

  useEffect(() => {
    fetchData(true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchData(false);
      }
    }, 7000);

    return () => {
      clearInterval(intervalId);
    };
  }, [projectId, resourceId]);

  return (
    <div>
      <div className={"resource-detail-page full-screen-page"}>
        <Helmet>
          <title>{resource?.title || 'Resource detail'} - Project Klub</title>
        </Helmet>

        <div className="full-screen-header">
          <h2>Resource</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              // navigate('/project-detail/' + projectId);
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {resource ?
          <div className={`full-screen-body resource-detail`}>
            <div className="resource-header">
              <div className="resource-thumbnail-container">
                <div className="resource-detail-thumbnail-section">
                  <img src={process.env.PUBLIC_URL + '/icons/resource-thumbnail.png'} alt={resource.title} className="resource-thumbnail" />
                </div>
              </div>
              <div className="resource-title-container">
                <p className="project-title">
                  <Link className="project-title-link" to={`/project-detail/${projectId}`}>
                    {project?.title}
                  </Link>
                  {modules?.length === 1 ?
                    <>
                      {modules[0].parent_module ?
                        <span className="project-title-separator">/<span className="ellipsis">...</span>/</span>
                      :
                        <span className="project-title-separator">/</span>
                      }
                      <Link className="project-title-link" to={`/module-detail/${projectId}/${modules[0].id}`}>
                        {modules[0].title}
                      </Link>
                    </>
                  : null}
                </p>
                <p className="resource-title">Resource: {resource?.title}</p>

              </div>
              {(role && editAllowedRoles.includes(role)) || User.is_superadmin ?
                <div>
                  <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-resource" onClick={() => { navigate('/edit-project-resource/' + projectId + '/' + resourceId, { replace: true }) }}>
                    Edit
                  </Button>
                </div>
              : null}
            </div>

            {modules?.length > 1 ?
              <div className="resource-detail-modules">
                <div>
                  <h2 className="resource-detail-modules-desc">
                    This resource belongs to more than one module in this project:
                  </h2>
                  <ul>
                    {modules.map((item, idx) => {
                      return (<li key={idx}>
                        <Link to={`/module-detail/${projectId}/${item.id}`}>
                          <img className="module-icon" src={process.env.PUBLIC_URL + '/icons/module-thumbnail.png'} alt="Module" />
                          <span>{item.title}</span>
                        </Link>
                      </li>);
                    })}
                  </ul>
                </div>
              </div>
            : null}

            <div className="resource-description content-description" onClick={(e) => {
                if (e?.target?.href || e?.target?.parentElement?.href) {
                  window.open(e.target?.href || e.target.parentElement.href, '_new', 'noopener=yes');
                  e.preventDefault();
                }
              }}>

              {/*<div className="resource-detail-section-header">
                <Button color="link" outline size="sm" className="title-button expand-button" onClick={() => {
                  setShowExpandedSummary(true);
                }}>
                  Expand
                </Button>
              </div>*/}

              <div dangerouslySetInnerHTML={{__html: DOMPurify.sanitize(resource?.body)}} />
            </div>

            {project?.numModules ?
              <>
                {resource?.showProjectModules ?
                  <div className="resource-detail-section resource-detail-project-modules ">
                    <div className="resource-detail-section-header">
                      <h3>Start by exploring this project's modules:</h3>
                    </div>

                    <ul className="project-modules-list">
                      {projectModules?.map((item, idx) => {
                        return (
                          <li key={idx} className="project-module-card">
                            <ModuleCardV2
                              projectId={projectId}
                              module={item}
                              isDisabled={(role==='outsider' || role==='pending' || role==='requested')}
                              resources={projectResources?.filter(resourceItem => {
                                const resourceModules = resourceItem.modules;
                                const resoureModuleIds =resourceModules.split(',');
                                return resoureModuleIds.includes(item.id.toString());
                              })}
                              />
                          </li>
                        );
                      })}
                    </ul>
                  </div>
                :
                  <div className="back-to-project-message">
                    <span className="info">This project has multiple modules and/or resources. Go back to the <a href={`/project-detail/${projectId}`}>project page</a> to see all of this project's modules and resources.</span>
                  </div>
                }
              </>
            :
              <>
                {otherResources?.length ?
                  <div className="resource-detail-section resource-detail-resources">
                    <div className="resource-detail-section-header">
                      <h3>Other resources in this project</h3>
                    </div>

                    {otherResources?.length ?
                      <ul className="project-resources-list">
                        {otherResources?.map((item, idx) => {
                          return (
                            <li key={idx} className="project-resource-card">
                              <ResourceCard projectId={item.project_id} resource={item} />
                            </li>
                          );
                        })}
                      </ul>
                    : null }
                  </div>
                : null}

                {genericResources?.length ?
                  <div className="resource-detail-section resource-detail-resources resource-detail-generic-resources">
                    <div className="resource-detail-section-header">
                      <h3>Generic resources relevant to this project</h3>
                    </div>

                    {genericResources?.length ?
                      <ul className="project-resources-list">
                        {genericResources?.map((item, idx) => {
                          return (
                            <li key={idx} className="project-resource-card">
                              <ResourceCard projectId={item.project_id} resource={item} />
                            </li>
                          );
                        })}
                      </ul>
                    : null }
                  </div>
                : null}
              </>
            }
          </div>
        : null }

        {(role && editAllowedRoles.includes(role)) || User.is_superadmin ?
          <div className="resource-detail-actions form-footer">
            <div className="resource-detail-secondary-actions single-action">
              {role === 'owner' || role === 'administrator' ?
                <Menu menuButton={
                      <MenuButton color="link" size="sm" className="resource-detail-more-actions" onClick={() => {
                      }}>
                        <img src={process.env.PUBLIC_URL + '/icons/ellipsis.png'} alt="More actions" />
                      </MenuButton>
                    }
                  position="auto"
                  direction="top"
                  align="end"
                  arrow={true}
                  gap={4}
                  >
                  <MenuItem onClick={() => { navigate('/edit-project-resource/' + projectId + '/' + resourceId ); }}>Edit resource</MenuItem>
                  {role === 'owner' ?
                    <MenuItem onClick={() => { setShowDeleteConfirmationModal(true); }}>Delete resource</MenuItem>
                  : null}
                </Menu>
              : null}
            </div>
          </div>
        : null}
      </div>


      {showDeleteConfirmationModal ?
        <ConfirmationModal 
          closeLabel="Cancel"
          onClose={() => {setShowDeleteConfirmationModal(false)}}
          submitLabel="Yes"
          onSubmit={() => {
            // API call to delete the resource
            deleteResource(projectId, resourceId).then((response) => {
              if (response?.data?.result?.affectedRows) {
                setDeleteInformationMessage('The resource has been deleted.')
              } else {
                setDeleteInformationMessage('The resource could not be be deleted.')
              }
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
            }).catch((error) => {
              setDeleteInformationMessage('The resource could not be be deleted.')
              setShowDeleteInformationModal(true);
              setShowDeleteConfirmationModal(false);
              // logError(error);
            });
          }}
          headerMessage="Confirmation"
          bodyMessage="Are you sure you want to delete this resource? This action cannot be undone."
          />
      : null}

      {showDeleteInformationModal ?
        <InformationModal 
          closeLabel="Close"
          onClose={() => {
            setShowDeleteInformationModal(false);
            navigate('/project-detail/' + projectId);
          }}
          headerMessage="Delete resource"
          bodyMessage={deleteInformationMessage}
          />
      : null}

      {resource?.body && showExpandedSummary ?
        <RichTextModal 
          onClose={() => {
            setShowExpandedSummary(false);
          }}
          title="Resource"
          body={resource.body}
          />
      : null}
    </div>
  );
}