import React, { useEffect, useContext, useState } from 'react';
import { FormGroup, Input, Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import PageError from '../messages/PageError';
import PageSuccess from '../messages/PageSuccess';

import "./Contact.styles.css";

export default function Contact() {
  const [pageErrorMessage, setPageErrorMessage] = useState(null);
  const [pageSuccessMessage, setPageSuccessMessage] = useState(null);
  const [message, setMessage] = useState('');
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { postSendContactMessage, logView, logError } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Contact us');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('contact-form');
  }, []);

  const sendContactMessage = () => {
    setPageErrorMessage(null);
    setPageSuccessMessage(null);

    postSendContactMessage(message).then((response) => {
      if (response?.data?.result) {
        setPageSuccessMessage('Thank your for your message! We appreciate your feedback.');
      } else {
        setPageErrorMessage('An error occurred. Please refresh the page and try again.');
      }
    }).catch((err) => {
      // logError(err);
      setPageErrorMessage('An error occurred. Please refresh the page and try again.');
    });
  };

  return (
    <div className="terms full-screen-page">
      <Helmet>
        <title>Contact us - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        <h2>Contact us / Give us feedback</h2>

{/*        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>*/}

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body contact-body">
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        {pageSuccessMessage ? 
          <>
            <div className="page-error-container">
              <PageSuccess messageText={pageSuccessMessage} />
            </div>

            <div className="contact-actions">
              <Button color="primary" size="sm" className="contact-actions-back" onClick={() => {
                navigate(location.key === 'default' ? '/projects/' : -1);
              }}>
                Close
              </Button>
            </div>
          </>
        :
          <>
            <div className="intro-text">
              <div className="intro-text-illustration-container">
                <img src={process.env.PUBLIC_URL + '/vector/talk.svg'} alt="Suggestion" />
              </div>
              <div>
                <h3>We would love to hear from you!</h3>
                <p>Please let us know if you have any feedback, questions, concerns and anything else on your mind about our platform and our projects!</p>
              </div>
            </div>

            <div className="fom-group contact-text-container">
              {/*<Label for="contact-text" className="full-width">Est sequi dignissimos aut fuga harum aut fugit voluptates.</Label>*/}
              <Input
                id="contact-text"
                name="contact-text"
                className="contact-text"
                type="textarea"
                placeholder="Enter your questions, concerns, and / or feedback here"
                onChange={(e)=>{ setMessage(e.target.value) }}
              />
            </div>

            <div className="contact-actions">
              {/*<Button color="secondary" size="sm" className="contact-actions-back" outline onClick={() => {
                navigate(location.key === 'default' ? '/projects/' : -1);
              }}>
                Back
              </Button>*/}
              <Button color="primary" size="sm" disabled={!message?.trim().length} onClick={sendContactMessage}>
                Send
              </Button>
            </div>

          </>
        }

        {/*<div className="contact-social">
          <p>Please also hit us up on social media to get our announcements and to stay in touch with the Project Klub community!</p>

          <div className="contact-social-links">
            <Link to={'..'} className="facebook-link" 
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={process.env.PUBLIC_URL + '/icons/facebook.png'} alt="Facebook" />
            </Link>
            <Link to={'..'} className="instagram-link" 
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={process.env.PUBLIC_URL + '/icons/instagram.png'} alt="Instagram" />
            </Link>
            <Link to={'..'} className="linkedin-link" 
              onClick={(e) => {
                e.preventDefault();
              }}
            >
              <img src={process.env.PUBLIC_URL + '/icons/linkedin.png'} alt="LinkedIn" />
            </Link>
          </div>
        </div>*/}
      </div>
    </div>
  );
}