import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import TermsContent from '../legal/TermsContent';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import "./Terms.scss";

export default function Terms() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { logView } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Terms of use');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('terms');
  }, []);

  return (
    <div className="terms full-screen-page">
      <Helmet>
        <title>Terms - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        <h2>Terms of use</h2>

{/*        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>*/}

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body terms-body">
        <TermsContent />
      </div>

      {/*<div className="terms-actions">
        <Button color="secondary" outline size="sm" onClick={() => {
          navigate(location.key === 'default' ? '/projects/' : -1);
        }}>
          Go back
        </Button>
      </div>*/}
    </div>
  );
}