import React, { createContext, useState, useContext } from 'react';
import axios from "axios";
import {
  skills,
  userDetail,
  myProjects,
  availableProjects,
  sessions,
  notifications,
  discussions,
  discussionMessages,
} from '../mocks/mockData.js';
import { PageContext } from './page-context';

const DataContext = createContext();

let serverUrl = "https://projectklub.com";
if (window.location.host.indexOf('projectklub.net') !== -1) {
  serverUrl = "https://projectklub.net";
} else if (window.location.host.indexOf('projectklub.app') !== -1) {
  serverUrl = "https://projectklub.app";
} else if (window.location.host.indexOf('hintnet-client-app') !== -1) {
  serverUrl = "https://hintnet-server-app-4keh3.ondigitalocean.app";
} else if (window.location.host.indexOf('localhost') !== -1) {
  serverUrl = "http://localhost:8080";
}
// serverUrl = "http://192.168.1.104:8080"
let upgradeUrl = serverUrl + '/go-premium';

axios.defaults.withCredentials = true;



function DataProvider(props) {
  const { setIsLoading } = useContext(PageContext);
  const [userDetailState, setUserDetailState] = useState(userDetail);
  // const [csrfToken, setCsrfToken] = useState('');

  const toSqlDatetime = (inputDate) => {
    const date = new Date(inputDate)
    const dateWithOffest = new Date(date.getTime() - (date.getTimezoneOffset() * 60000))
    return dateWithOffest
      .toISOString()
      .slice(0, 19)
      .replace('T', ' ')
  }

  const apiCall = function(method, url, options, showLoader) {
    if (showLoader) { setIsLoading(true); }

    let dataOptions = {...options};

    // if (csrfToken) {
    //   dataOptions['_csrf'] = csrfToken;
    // }

    return axios[method](url, dataOptions).then((response) => {
      if (showLoader) { setIsLoading(false); }

      // if (response.data?.token) {
      //   setCsrfToken(response.data?.token);
      // }

      return response;
    }).catch((error) => {
      if (showLoader) { setIsLoading(false); }
      throw error;
    });
  }

  const addUserSkill = function(prop) {
    const tempUserDetail = structuredClone(userDetailState);
    tempUserDetail.skills.push(prop);
    setUserDetailState(tempUserDetail);
  }

  const removeUserSkill = function(prop) {
    const tempUserDetail = structuredClone(userDetailState);
    tempUserDetail.skills = tempUserDetail.skills.filter(skill => skill.slug !== prop.slug);
    setUserDetailState(tempUserDetail);
  }

  const toggleUserSkill = function(prop) {
    if (doesUserHaveSkill(prop)) {
      removeUserSkill(prop);
    } else {
      addUserSkill(prop);
    }
  }

  const doesUserHaveSkill = function(prop) {
    const matchingSkillsInUserProfile = userDetailState.skills?.some((userSkill, index)  => {
      return userSkill.slug === prop.slug
    });
    return matchingSkillsInUserProfile;
  }

  const reloadUserSkills = function(prop) {
    const tempUserDetail = structuredClone(userDetail);
    setUserDetailState(tempUserDetail);
  }

  const getSession = function(id) {
    return sessions.find(session => session.id.toString() === id);
  }

  const getUser = function(id, showLoader) {
    return apiCall('get', `${serverUrl}/user/${id}`, {}, !!showLoader);
  }

  const getUserBasics = function(id) {
    return apiCall('get', `${serverUrl}/user-basics/${id}`, {}, true);
  }

  const postUpdateUser = (name, about, skills, getProjectUpdateEmails, getFeatureUpdateEmails) => {
    // const options = { name, dob: toSqlDatetime(dob), about, skills };
    const options = { name, about, skills, getProjectUpdateEmails, getFeatureUpdateEmails };
    return apiCall('post', `${serverUrl}/update-user`, options, true);
  };

  const markPremiumReminderDismissed = () => {
    return apiCall('post', `${serverUrl}/mark-premium-reminder-dismissed`, {}, true);
  };

  const postFinishUserProfile = (username, country, about, skills, getProjectUpdateEmails, getFeatureUpdateEmails) => {
    // const options = { name, dob: toSqlDatetime(dob), about, skills };
    const options = { username, country, about, skills, getProjectUpdateEmails, getFeatureUpdateEmails };
    return apiCall('post', `${serverUrl}/finish-user-profile`, options, true);
  };

  const getProject = function(id, showLoader) {
    const params = paramsFromUrl({});
    return apiCall('get', `${serverUrl}/project/${id}`, params, !!showLoader);
  }

  const getProjectRole = function(userId, projectId, showLoader) {
    return apiCall('get', `${serverUrl}/project-role/${userId}/${projectId}`, {}, showLoader);
  }

  const getProjectParticipants = function(projectId, showLoader) {
    return apiCall('get', `${serverUrl}/project-participants/${projectId}`, {}, showLoader);
  }

  const getUserProjects = function(userId, showLoader) {
    const params = paramsFromUrl({});
    return apiCall('get', `${serverUrl}/user-projects/${userId}`, params, !!showLoader);
  }

  const getUserPublicProjects = function(userId, showLoader) {
    return apiCall('get', `${serverUrl}/user-public-projects/${userId}`, {}, true);
  }

  const getUserAvailableProjects = function(userId, showLoader) {
    const params = paramsFromUrl({});
    return apiCall('get', `${serverUrl}/user-available-projects/${userId}`, params, !!showLoader);
  }

  const getAvailableProjects = function(showLoader) {
    const params = paramsFromUrl({});
    return apiCall('get', `${serverUrl}/projects`, params, !!showLoader);
  }

  function paramsFromUrl(originalParams) {
    const params = {...originalParams};
    const queryParams = new URLSearchParams(window.location.search);

    const campaign = queryParams.get("c");
    if (campaign) {
      params.params = params.params || {};
      params.params.campaign = campaign;
    }

    const sid = queryParams.get("s");
    if (sid) {
      params.params = params.params || {};
      params.params.sid = sid;
    }

    return params;
  }

  const register = (username, password, name, country, email, about, skills, requestedVerificationTime, getProjectUpdateEmails, getFeatureUpdateEmails) => {
    return apiCall('post', `${serverUrl}/register`, {
      username, password, name, country, email, about, skills, requestedVerificationTime: toSqlDatetime(requestedVerificationTime), getProjectUpdateEmails, getFeatureUpdateEmails
    }, true);
  };

  const resetPassword = (email) => {
    return apiCall('post', `${serverUrl}/reset-password`, { email }, true);
  };

  const changePassword = (password) => {
    return apiCall('post', `${serverUrl}/change-password`, { password }, true);
  };

  const verifyEmail  = (code) => {
    return apiCall('post', `${serverUrl}/verify-email`, { code }, true);
  };

  const resendEmailVerificationCode = () => {
    return apiCall('post', `${serverUrl}/resend-email-verification-code`, {}, true);
  };

  const login = (username, password) => {
    return apiCall('post', `${serverUrl}/login`, { username, password }, true);
  };

  const logout = (username, password) => {
    return apiCall('post', `${serverUrl}/logout`, {}, true);
  };

  const deleteAccount = () => {
    return apiCall('post', `${serverUrl}/delete-account`, {}, true);
  };

  const getLogin = (username, password) => {
    return apiCall('get', `${serverUrl}/get-login`, {}, true);
  };

  const checkExistingUser = (username, email) => {
    return apiCall('post', `${serverUrl}/check-existing-user`, { username, email}, true);
  };

  const checkExistingUserName = (username) => {
    return apiCall('post', `${serverUrl}/check-existing-user-name`, { username }, true);
  };

  const acceptParticipant = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/accept-participant`, {
      projectId, participantId
    }, true);
  };

  const declineParticipant = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/decline-participant`, { projectId, participantId }, true);
  };

  const makeAdmin = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/update-participant`, {
      projectId, participantId, roleLevel: 1, role: 'administrator'
    }, true);
  };

  const makeContributor = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/update-participant`, {
      projectId, participantId, roleLevel: 2, role: 'contributor'
    }, true);
  };

  const removeParticipant = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/decline-participant`, {
      projectId, participantId
    }, true);
  };

  const joinProject = (projectId, introText) => {
    return apiCall('post', `${serverUrl}/join-project`, {
      projectId, introText
    }, true);
  };

  const leaveProject = (projectId) => {
    return apiCall('post', `${serverUrl}/leave-project`, { projectId }, true);
  };

  const updateProjectStatus = (projectId, status) => {
    return apiCall('post', `${serverUrl}/update-project-status`, {
      projectId,
      status
    }, true);
  };

  const markProjectGettingStartedModalSeen = (projectId) => {
    return apiCall('post', `${serverUrl}/mark-project-getting-started-modal-seen`, { projectId }, false);
  };

  const getAllClaps = (showLoader) => {
    return apiCall('get', `${serverUrl}/all-claps`, {}, showLoader);
  };

  const getUserClaps = (showLoader) => {
    return apiCall('get', `${serverUrl}/user-claps`, {}, showLoader);
  };

  const getUserReceivedClaps = (showLoader) => {
    return apiCall('get', `${serverUrl}/user-received-claps`, {}, showLoader);
  };

  const getUserGivenClaps = (showLoader) => {
    return apiCall('get', `${serverUrl}/user-given-claps`, {}, showLoader);
  };

  const getUserUnseenClaps = (showLoader) => {
    return apiCall('get', `${serverUrl}/user-unseen-claps`, {}, showLoader);
  };

  const postClap = (clappee, entityId, entityType, entityText, parentId, parentType, parentTitle, parentUrl) => {
    return apiCall('post', `${serverUrl}/clap`, { clappee, entityId, entityType, entityText, parentId, parentType, parentTitle, parentUrl}, true);
  };

  const postUpdateLastSawClaps = () => {
    return apiCall('post', `${serverUrl}/update-last-saw-claps`, {}, false);
  };

  const postDeleteClap = (entityId, entityType) => {
    return apiCall('post', `${serverUrl}/delete-clap`, { entityId, entityType }, true);
  };

  const createProject = (
    projectType,
    projectTitle,
    projectDescription,
    projectShortSummary,
    projectSkills,
    projectSize,
    projectDifficulty,
    isProjectPublic,
    isProjectOwnerVisible,
    isProjectAllowHelp,
    isProjectInstantJoin,
    isProjectEarningsEnrolled,
    helpCategories) => {

    const options = {
      projectType,
      projectTitle,
      projectDescription,
      projectShortSummary,
      projectSkills,
      projectSize,
      projectDifficulty,
      isProjectPublic,
      isProjectOwnerVisible,
      isProjectAllowHelp,
      isProjectInstantJoin,
      isProjectEarningsEnrolled,
      helpCategories
    };
    return apiCall('post', `${serverUrl}/create-project`, options, true);
  };

  const editProject = (
    projectId,
    projectDescription,
    projectShortSummary,
    projectSkills,
    projectSize,
    projectDifficulty,
    isProjectPublic,
    isProjectOwnerVisible,
    isProjectAllowHelp,
    isProjectInstantJoin,
    isProjectEarningsEnrolled,
    helpCategories) => {

    const options = {
      projectDescription,
      projectShortSummary,
      projectSkills,
      projectSize,
      projectDifficulty,
      isProjectPublic,
      isProjectOwnerVisible,
      isProjectAllowHelp,
      isProjectInstantJoin,
      isProjectEarningsEnrolled,
      helpCategories
    };
    return apiCall('post', `${serverUrl}/edit-project/${projectId}`, options, true);
  };

  const deleteProject = (projectId) => {
    return apiCall('post', `${serverUrl}/delete-project/${projectId}`, {}, true);
  };

  const checkExistingProject = (title) => {
    return apiCall('post', `${serverUrl}/check-existing-project`, {
      title: title.trim()
    }, true);
  };

  const suggestPracticeProject = (title, shortSummary, description) => {
    const message = `User suggested practice project. Title: ${title}, Short summary: ${shortSummary}, Description: ${description}`;
    return apiCall('post', `${serverUrl}/send-contact-message/`, { message }, true);
  };

  const getModule = function(projectId, moduleId, showLoader) {
    return apiCall('get', `${serverUrl}/module/${projectId}/${moduleId}`, {}, showLoader);
  };

  const createModule = (projectId, moduleTitle, moduleDescription, moduleShortDescription) => {
    return apiCall('post', `${serverUrl}/create-module/${projectId}`, {
      projectId,
      moduleTitle,
      moduleDescription,
      moduleShortDescription
    }, true);
  };

  const editModule = (projectId, moduleId, moduleTitle, moduleDescription, moduleShortDescription) => {
    return apiCall('post', `${serverUrl}/edit-module/${projectId}/${moduleId}`, {
      moduleTitle,
      moduleDescription,
      moduleShortDescription
    }, true);
  };

  const deleteModule = (projectId, moduleId) => {
    return apiCall('post', `${serverUrl}/delete-module/${projectId}/${moduleId}`, {}, true);
  };

  const getProjectModules = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-modules/${projectId}`, {}, showLoader);
  };

  const getAllProjectModules = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/all-project-modules/${projectId}`, {}, showLoader);
  };

  const getSubModules = (moduleId, showLoader) => {
    return apiCall('get', `${serverUrl}/sub-modules/${moduleId}`, {}, showLoader);
  };

  const postFetchModuleNames = (moduleIds, showLoader) => {
    return apiCall('post', `${serverUrl}/fetch-module-names/`, {
      moduleIds
    }, true);
  };

  const postFetchModules = (moduleIds, showLoader) => {
    return apiCall('post', `${serverUrl}/fetch-modules/`, {
      moduleIds
    }, true);
  };


  // Resources
  const getResource = function(projectId, resourceId, showLoader) {
    return apiCall('get', `${serverUrl}/resource/${projectId}/${resourceId}`, {}, showLoader);
  };

  const createResource = (projectId, title, body, url, type, modules) => {
    return apiCall('post', `${serverUrl}/create-resource/${projectId}`, {
      projectId,
      title,
      body,
      url,
      type,
      modules
    }, true);
  };

  const editResource = (projectId, resourceId, title, body, url, type, modules) => {
    return apiCall('post', `${serverUrl}/edit-resource/${projectId}/${resourceId}`, {
      projectId,
      resourceId,
      title,
      body,
      url,
      type,
      modules
    }, true);
  };

  const deleteResource = (projectId, resourceId) => {
    return apiCall('post', `${serverUrl}/delete-resource/${projectId}/${resourceId}`, {}, true);
  };

  const getProjectResources = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-resources/${projectId}`, {}, showLoader);
  };

  const getGenericResources = (resources, showLoader) => {
    return apiCall('get', `${serverUrl}/generic-resources/${resources}`, {}, showLoader);
  };


  // Solutions
  const getSolution = function(projectId, solutionId, showLoader) {
    return apiCall('get', `${serverUrl}/solution/${projectId}/${solutionId}`, {}, showLoader);
  };

  const createSolution = (projectId, title, body, url, codeUrl, status, modules, allowComments, feedbackAreas) => {
    return apiCall('post', `${serverUrl}/create-solution/${projectId}`, {
      projectId,
      title,
      body,
      url,
      codeUrl,
      status,
      modules,
      allowComments,
      feedbackAreas
    }, true);
  };

  const editSolution = (projectId, solutionId, title, body, url, codeUrl, status, modules, allowComments, feedbackAreas) => {
    return apiCall('post', `${serverUrl}/edit-solution/${projectId}/${solutionId}`, {
      projectId,
      solutionId,
      title,
      body,
      url,
      codeUrl,
      status,
      modules,
      allowComments,
      feedbackAreas
    }, true);
  };

  const changeSolutionStatus = (solutionId, status) => {
    return apiCall('post', `${serverUrl}/change-solution-status/${solutionId}`, {
      status,
    }, true);
  };

  const deleteSolution = (projectId, solutionId) => {
    return apiCall('post', `${serverUrl}/delete-solution/${projectId}/${solutionId}`, {}, true);
  };

  const getProjectSolutions = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-solutions/${projectId}`, {}, showLoader);
  };

  const getIsFollowingEntity = (entityType, entityId, showLoader) => {
    return apiCall('get', `${serverUrl}/is-following-entity/${entityType}/${entityId}`, {}, showLoader);
  };

  const followSolution = (solutionId, projectId) => {
    return apiCall('post', `${serverUrl}/follow-solution/${projectId}/${solutionId}`, {}, true);
  };

  const unfollowSolution = (solutionId) => {
    return apiCall('post', `${serverUrl}/unfollow-solution/${solutionId}`, {}, true);
  };

  const getUserSolutions = (userId) => {
    return apiCall('get', `${serverUrl}/user-solutions/${userId}`, {}, true);
  };


  const updateDiscussionVisit = (entityId, entityType, projectId) => {
    return apiCall('post', `${serverUrl}/update-discussion-visit`, {
      entityId,
      entityType,
      projectId
    }, false);
  };

  const updateEntityVisit = (entityId, entityType) => {
    return apiCall('post', `${serverUrl}/update-entity-visit`, {
      entityId,
      entityType
    }, false);
  };

  const addParticipant = (projectId, username) => {
    return apiCall('post', `${serverUrl}/add-participant`, {
      projectId,
      username
    }, true);
  };

  const acceptInvite = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/accept-invite`, {
      projectId,
      participantId
    }, true);
  };

  const declineInvite = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/decline-invite`, {
      projectId,
      participantId
    }, true);
  };

  const withdrawInvite = (projectId, participantId) => {
    return apiCall('post', `${serverUrl}/decline-participant`, {
      projectId,
      participantId
    }, true);
  };

  const updateProjectHelpRequest = (projectId, text, contactEmail, isEmailPreferred, isCommentsPreferred) => {
    return apiCall('post', `${serverUrl}/update-project-help-request`, {
      projectId,
      text,
      contactEmail,
      isEmailPreferred,
      isCommentsPreferred
    }, true);
  };

  const getProjectHelpRequest = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-help-request/${projectId}`, {}, showLoader);
  };

  const getProjectHelpRequests = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-help-requests/${projectId}`, {}, showLoader);
  };

  const getProjectHelpRequestDetail = (projectId, participantId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-help-request-detail/${projectId}/${participantId}`, {}, true);
  };

  const getProjectComments = (projectId, showLoader) => {
    return apiCall('get', `${serverUrl}/project-comments/${projectId}`, {}, showLoader);
  };

  const getProjectSolutionCommentCount = (projectId) => {
    return apiCall('get', `${serverUrl}/project-solution-comment-count/${projectId}`, {}, false);
  };

  const getSolutionComments = (projectId, solutionId, showLoader) => {
    return apiCall('get', `${serverUrl}/solution-comments/${projectId}/${solutionId}`, {}, showLoader);
  };

  const postComment = (projectId, entityType, entityId, message) => {
    return apiCall('post', `${serverUrl}/post-comment`, {
      projectId,
      entityType,
      entityId,
      message
    }, true);
  };

  const deleteComment = (id, entityType, entityId) => {
    return apiCall('post', `${serverUrl}/delete-comment`, {
      id,
      entityType,
      entityId
    }, true);
  };

  const getUserUpdates = (showLoader) => {
    return apiCall('get', `${serverUrl}/user-updates/`, {}, showLoader);
  };

  const fetchEntityTitles = (entityType, entityIds, showLoader) => {
    return apiCall('post', `${serverUrl}/fetch-entity-titles/`,
      {
        entityType,
        entityIds
      }, showLoader);
  };

  const postSendContactMessage = (message) => {
    return apiCall('post', `${serverUrl}/send-contact-message/`, { message }, true);
  };

  const getSeeEmails = () => {
    return apiCall('get', `${serverUrl}/see-emails/`, {}, true);
  };

  const logView = (view, details, userId) => {
    let deets = details || '';
    let dimensions = '';

    if (window?.innerWidth && window?.innerHeight) {
      dimensions = window.innerWidth + 'x' + window.innerHeight;
    }

    if (deets) {
      deets = deets + ', ' + dimensions;
    } else {
      deets = dimensions;
    }

    let params = {
      type: 'Client view',
      subType: view,
      details: deets,
    };

    if (userId) {
      params.userId = userId;
    }

    const updatedParams = paramsFromUrl(params);
    return apiCall('post', `${serverUrl}/log-event/`, updatedParams, false);
  };

  const logError = (error, details) => {
    return apiCall('post', `${serverUrl}/log-event/`, {
      type: 'Client error',
      subType: error,
      details
    }, false);
  };

  return(
    <DataContext.Provider value={{
      serverUrl,
      upgradeUrl,

      register,
      login,
      getLogin,
      logout,
      resetPassword,
      changePassword,
      verifyEmail,
      resendEmailVerificationCode,
      checkExistingUser,
      checkExistingUserName,
      deleteAccount,
      
      createProject,
      editProject,
      deleteProject,
      checkExistingProject,
      updateProjectStatus,
      markProjectGettingStartedModalSeen,
      suggestPracticeProject,

      createModule,
      editModule,
      deleteModule,
      getProjectModules,
      getAllProjectModules,
      getSubModules,
      getModule,
      postFetchModuleNames,
      postFetchModules,

      createResource,
      editResource,
      deleteResource,
      getProjectResources,
      getGenericResources,
      getResource,

      createSolution,
      editSolution,
      changeSolutionStatus,
      deleteSolution,
      getProjectSolutions,
      getUserSolutions,
      getSolution,
      followSolution,
      unfollowSolution,

      getIsFollowingEntity,
      updateDiscussionVisit,
      updateEntityVisit,

      // isUserAuthenticeted,
      skills,
      addUserSkill,
      doesUserHaveSkill,
      toggleUserSkill,
      myProjects,
      getUserProjects,
      getUserPublicProjects,
      getUserAvailableProjects,
      getAvailableProjects,
      userDetail: userDetailState,
      reloadUserSkills,
      availableProjects,
      sessions,
      getSession,
      notifications,
      discussions,
      discussionMessages,
      getUser,
      getUserBasics,
      postUpdateUser,
      markPremiumReminderDismissed,
      postFinishUserProfile,
      getProject,
      getProjectRole,
      getProjectParticipants,
      addParticipant,
      acceptInvite,
      declineInvite,
      
      acceptParticipant,
      declineParticipant,
      makeAdmin,
      makeContributor,
      removeParticipant,
      withdrawInvite,
      joinProject,
      leaveProject,

      getProjectHelpRequest,
      getProjectHelpRequests,
      getProjectHelpRequestDetail,
      updateProjectHelpRequest,

      getProjectComments,
      getProjectSolutionCommentCount,
      getSolutionComments,
      postComment,
      deleteComment,

      getAllClaps,
      getUserClaps,
      getUserReceivedClaps,
      getUserGivenClaps,
      getUserUnseenClaps,
      postClap,
      postUpdateLastSawClaps,
      postDeleteClap,

      getUserUpdates,
      fetchEntityTitles,

      postSendContactMessage,

      getSeeEmails,
      logView,
      logError,
     }}>
      {props.children}
    </DataContext.Provider>
  );
}

export { DataContext, DataProvider };

