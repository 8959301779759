import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

// import { UserContext } from '../../contexts/user-context';
import "./ViewHelpRequest.scss";
import { DataContext } from '../../contexts/data-context';
import PageError from '../messages/PageError';

function ViewHelpRequest(props) {
  // const { User } = useContext(UserContext);
  // const [helpRequestText, setHelpRequestText] = useState('');
  // const [contactEmail, setContactEmail] = useState('');
  // const [isContactCommentsPreferred, setContactCommentsPreferred] = useState(false);
  // const [isContactEmailPreferred, setContactEmailPreferred] = useState(false);
  // const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  // const [showFormErrors, setShowFormErrors] = useState(false);
  // const [isDescriptionError, setDescriptionError] = useState(false);
  // const [isOptionsError, setOptionsError] = useState(false);
  // const [isEmailError, setEmailError] = useState(false);
  const [helpRequest, setHelpRequest] = useState(null);
  const { getProjectHelpRequestDetail, logView, logError } = useContext(DataContext);
  const [pageErrorMessage, setPageErrorMessage] = useState(null);

  const {
    onClose,
    onEdit,
    onOpenComments,
    participantId,
    projectId,
    isOwner,
  } = props;

  const closeForm = () => {
    onClose();
  };

  const fetchData = function() {
    setPageErrorMessage(null);

    let _helpRequest;

    getProjectHelpRequestDetail(projectId, participantId).then((response) => {
      if (response?.data?.length) {
        _helpRequest = response.data[0];
        setHelpRequest(_helpRequest);
      }
    }).catch((error) => {
      setPageErrorMessage('An error occurred. Please refresh the page and try again.');
      console.log(error);
      // logError(error);
    });
  };

  useEffect(() => {
    fetchData();
  }, [projectId, participantId]);


  useEffect(() => {
    logView('view-help-request');
  }, []);


  return (
    <Modal
      className="view-help-request"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      centered={true}>
      <ModalHeader>Help request</ModalHeader>
      <ModalBody>
        {pageErrorMessage ? 
          <div className="page-error-container">
            <PageError messageText={pageErrorMessage} />
          </div>
        : null}

        <div className="view-help-request-header">
          <h2 className="view-help-request-name">
            <img src={process.env.PUBLIC_URL + '/icons/help-request.png'} alt="Help request" />
            <span>{helpRequest?.name}'s help request</span>
          </h2>

          {isOwner && onEdit ?
            <Button color="none" outline size="sm" type="button" className="title-button edit-button" onClick={() => { onEdit() }}>
              Edit
            </Button>
          : null}
        </div>

        <div className="view-help-request-body">
          <h3>Description</h3>
          <p>{helpRequest?.help_request_text}</p>
        </div>

        <div className="view-help-request-contact">
          <h3>Please Reach out with your help by:</h3>

          <ul>
            {helpRequest?.help_request_comments_preferred ?
              <li>
                <span><strong>Commenting</strong> on my solutions or in the project comments</span>
                {/*{onOpenComments ?
                  <Button color="link" outline size="sm" type="button" className="open-comments-button" onClick={() => { onOpenComments() }}>
                    (Open project comments)
                  </Button>
                : null}*/}
              </li>
            : null}

            {helpRequest?.help_request_email_preferred && helpRequest?.help_request_contact_email ?
              <li>Emailing me at <strong>{helpRequest.help_request_contact_email}</strong></li>
            : null}
          </ul>
        </div>
      </ModalBody>
      <ModalFooter className="single-action">
        <div className="primary-actions">
          <Button color="primary" onClick={closeForm}>
            <span>Close</span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ViewHelpRequest;
