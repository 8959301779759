import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import ProjectCard from '../cards/ProjectCard';
import ProjectCardV2 from '../cards/ProjectCardV2';
import SmallProjectCard from '../cards/SmallProjectCard';
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { keysToCamel } from '../../util/caseConverters';

import "./MyProjects.styles.css";

const smallCardThreshold = 90;

export default function MyProjects() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled, IsLoading } = useContext(PageContext);
  const { getUserProjects, logView, logError } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [myProjects, setMyProjects] = useState();
  const [myRequestedProjects, setMyRequestedProjects] = useState([]);
  const [myInvitedProjects, setMyInvitedProjects] = useState([]);
  const [myOwnProjects, setMyOwnProjects] = useState([]);
  const [myJoinedProjects, setMyJoinedProjects] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    setPageTitle('Available projects');
    setTopNavEnabled(true);
    setBottomNavEnabled(true);
    logView('my-projects');
  }, []);

  const fetchData = function(showLoader) {
    const userId = User?.id;

    if (userId) {
      getUserProjects(userId, showLoader).then((res) => {
        if (res?.data) {
          const _myProjects = keysToCamel(res.data);
          setMyProjects(_myProjects);
          setMyOwnProjects(_myProjects.filter(item => item.ownerId === userId));
          setMyRequestedProjects(_myProjects.filter(item => item.role === 'requested'));
          setMyInvitedProjects(_myProjects.filter(item => item.role === 'pending'));
          setMyJoinedProjects(_myProjects.filter(item => item.ownerId !== userId && !['requested', 'pending'].includes(item.role)));
        }
      }).catch((error) => {
        // logError(error);
      });
    }
  };

  useEffect(() => {
    let intervalId;

    // Maybe uncomment later if we decide to take new signups to Available projects after all
    // if (User?.email && !User.is_delete && (!User.username || !User.country)) {
    //   let redirectUrl = "/projects/available-projects";
    //   const queryParams = new URLSearchParams(window.location.search);
    //   const campaign = queryParams.get("c");
    //   const sid = queryParams.get("s");

    //   if (campaign) {
    //     redirectUrl = redirectUrl + "?c=" + campaign;
    //   }

    //   if (sid) {
    //     redirectUrl = redirectUrl + (campaign ? "&" : "?") + "s=" + sid;
    //   }

    //   navigate(redirectUrl, { replace: true });
    // } else {
      fetchData(true);

      intervalId = setInterval(() => {
        if (document.hasFocus()) {
          fetchData(false);
        }
      }, 5000);
    // }

    return () => {
      clearInterval(intervalId);
    };      
  }, [User]);

  return (
    <div className="my-projects">
      <Helmet>
        <title>My projects - Project Klub</title>
      </Helmet>
      <div className="page-main-section">
        {myProjects?.length ?
          <>
            {myInvitedProjects?.length ?
              <div className="my-projects-list-container invited">
                <h2>Invited to join <span className="count">({myInvitedProjects.length})</span></h2>
                <div className={`my-projects-list ${myProjects.length < smallCardThreshold ? 'large-cards' : 'small-cards'}`}>
                  {myInvitedProjects.map((item, idx) => {
                    if (myProjects.length < smallCardThreshold) {
                      return <ProjectCardV2 project={item} key={idx} hideShortDescription={true} />
                    } else {
                      return <SmallProjectCard project={item} key={idx} cardType="invited" />
                    }
                  })}
                </div>
              </div>
            : null}

            {myOwnProjects?.length ?
              <div className="my-projects-list-container own">
                <h2>Own projects <span className="count">({myOwnProjects.length})</span></h2>
                <div className={`my-projects-list ${myProjects.length < smallCardThreshold ? 'large-cards' : 'small-cards'}`}>
                  {myOwnProjects.map((item, idx) => {
                    if (myProjects.length < smallCardThreshold) {
                      return <ProjectCardV2 project={item} key={idx} hideShortDescription={true} />
                    } else {
                      return <SmallProjectCard project={item} key={idx} cardType={item.role} />
                    }
                  })}
                </div>
              </div>
            : null}


            {myJoinedProjects?.length ?
              <div className="my-projects-list-container joined">
                <h2>Joined projects <span className="count">({myJoinedProjects.length})</span></h2>
                <div className={`my-projects-list ${myProjects.length < smallCardThreshold ? 'large-cards' : 'small-cards'}`}>
                  {myJoinedProjects.map((item, idx) => {
                    if (myProjects.length < smallCardThreshold) {
                      return <ProjectCardV2 project={item} key={idx} hideShortDescription={true} />
                    } else {
                      return <SmallProjectCard project={item} key={idx} cardType={item.role} />
                    }
                  })}
                </div>
              </div>
            : null}

            {myRequestedProjects?.length ?
              <div className="my-projects-list-container requested">
                <h2>Requested to join <span className="count">({myRequestedProjects.length})</span></h2>
                <div className={`my-projects-list ${myProjects.length < smallCardThreshold ? 'large-cards' : 'small-cards'}`}>
                  {myRequestedProjects.map((item, idx) => {
                    if (myProjects.length < smallCardThreshold) {
                      return <ProjectCardV2 project={item} key={idx} hideShortDescription={true} />
                    } else {
                      return <SmallProjectCard project={item} key={idx} cardType={item.role} />
                    }
                  })}
                </div>
              </div>
            : null}

            <Button color="primary" className="my-projects-join-button" onClick={() => { navigate('/projects/available-projects') }}>
              Browse more projects to join
            </Button>
          </>
        : 
          <>
            {!IsLoading && myProjects ?
              <div className="my-projects-empty-state">
                <div className="my-projects-empty-state-ilustration">
                  <img src={process.env.PUBLIC_URL + '/vector/projects-empty-state-3.svg'} alt="No projects" className="empty-state-illustration" />
                </div>

                <p>Welcome to Project Klub!</p>

                <p>Join a project to get started building things.</p>

                <Button color="primary" className="my-projects-join-button" onClick={() => { navigate('/projects/available-projects') }}>
                  See available projects
                </Button>

                {/*<p className="my-projects-start-new-empty-state">
                  or, <a href="/suggest-project" onClick={(e) => {
                    e.preventDefault();
                    navigate('/suggest-project');
                  }}>start a standalone project</a>
                </p>*/}
              </div>
            : null}
          </>
        }
      </div>
 </div>
  );
}