import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button, Label, Input } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import SkillsDisplay from "../cards/SkillsDisplay";
import PageError from '../messages/PageError';
import PageSuccess from '../messages/PageSuccess';
import { keysToCamel } from '../../util/caseConverters';
import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';

import { toUTCDate } from '../../util/dateTime';

import "./JoinProject.scss";

const typeDisplay = {
  standalone: 'Standalone',
  guided: 'Practice',
};

const roleDisplay = {
  'administrator': 'Administrator',
  'contributor': 'Participant',
  'owner': 'Owner',
  'outsider': 'Non-participant',
  'pending': 'Pending',
};

export default function JoinProject() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getProject, getProjectRole, joinProject, logView, logError, upgradeUrl } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const { id } = useParams();
  const [role, setRole] = useState('');
  const [project, setProject] = useState();
  const [projectError, setProjectError] = useState();
  const [projectSuccess, setProjectSuccess] = useState();
  const [introText, setIntroText] = useState('');
  const [isInsufficientCoins, setInsufficientCoins] = useState(false);

  const fetchData = function() {
    setProjectSuccess(null);
    setProjectError(null);

    getProject(id).then((response) => {
      let project;

      if (response?.data?.length) {
        project = keysToCamel(response).data[0];
        setProject(project);

        if (!project.isPublic) {
          setProjectError('This project is not available to join.');
        } else if (project.status !== 'open') {
          setProjectError(`This project's status is "${project.status}". It will be available to join once the status has changed to "open".`);
        } else {
          setProjectError(null);
        }

        const userId = User?.id;
        if (userId) {
          if (project?.ownerId === userId) {
            setRole('owner');
          } else {
            getProjectRole(userId, id).then((response) => {
              if (response?.data?.length) {
                setRole(response.data[0].role);

                setProjectError(`You have been already added to the project. Your role is ${response.data[0].role}`);
              } else {
                setRole('outsider');
              }
            }).catch((error) => {
              console.log(error);
              // logError(error);
              setRole('outsider');
            });        
          }
        } else {
          setRole('outsider');
        }
      }
    }).catch((error) => {
      setProjectError('An error occurred. Please try again.');
      // logError(error);
      console.log(error);
    });
  };

  useEffect(() => {
    setPageTitle('Join project');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    fetchData();
  }, [id]);

  useEffect(() => {
    if (project && User && !!project.pointsToJoin && User.coins < project.pointsToJoin) {
      setInsufficientCoins(true);
    } else {
      setInsufficientCoins(false);
    }
  }, [project, User]);

  useEffect(() => {
    logView('join-project', id);
  }, []);

  return (
    <div>
      <div className="join-project-page full-screen-page">
        <Helmet>
          <title>Join project - Project Klub</title>
        </Helmet>

        <div className="full-screen-header">
          <h2>Join project</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>
        </div>

        {project ?
          <div  className={`full-screen-body join-project ${project?.type?.toLowerCase()} ${project?.status?.toLowerCase()}`}>
            {projectError ?
              <div className="page-message-container">
                <PageError messageText={projectError} />
              </div>
            :
              <div>
                {projectSuccess ?
                  <div className="join-success">
                    <div className="page-message-container">
                      <PageSuccess messageText={projectSuccess} />
                    </div>
                    <div className="join-success-message">
                      <img src={process.env.PUBLIC_URL + '/vector/welcome-project.svg'} alt="Getting started" />
                      <h3 className="join-success-message">Welcome to the project</h3>
                      <p>We hope you have lots of fun building this!</p>
                    </div>
                  </div>
                :
                  <div>
                    <div className="project-header">
                      {project?.isPremium ?
                        <div className="project-price premium">
                          <span>Premium</span>
                        </div>
                      :
                        <div className="project-price free">
                          <span>Free</span>
                        </div>
                      }
                      <p className="project-title">
                        {project?.title}
                      </p>
                      <div className="project-header-metadata">
                        {/*<div className="project-thumbnail-container">
                          <div className="project-detail-thumbnail-section">
                            <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="project-thumbnail" />
                            <span className={"project-type " + project?.type}>{typeDisplay[project?.type]}</span>
                            {!project.isPublic ?
                              <div className="project-detail-private">
                                <img src={process.env.PUBLIC_URL + '/icons/lock.svg'} alt="Private" />
                                <span>Private</span>
                              </div>
                            : null}

                            <div className="project-detail-points-to-join">
                              {project.pointsToJoin ?
                                <div>
                                  <img src={process.env.PUBLIC_URL + '/icons/coin.png'} alt="Points to join" />
                                  <span>{project.pointsToJoin} coins</span>
                                </div>
                              :
                                 <span>FREE to join</span>
                              }
                            </div>
                          </div>
                        </div>*/}
                        <div className="project-metadata-container">
                          <ul className="project-metadata">
                            <li className={`project-size project-size-${project.size}`}>
                              {/*<img src={process.env.PUBLIC_URL + '/icons/size-icon.png'} alt="Size" />*/}
                              <span>{project.size}</span>
                            </li>
                            <li>
                              {project.skills ?
                                <div className="project-detail-skills">
                                  <SkillsDisplay skills={project.skills} showLabel={false} />
                                </div>
                              : null}
                            </li>
                          </ul>
                        </div>

                        {role==='owner' || role==='administrator' || User?.is_superadmin ?
                          <div>
                            <Button color="link" outline size="sm" type="button" className="title-button edit-button edit-project" onClick={() => { navigate('/edit-project/' + id ); }}>
                              Edit
                            </Button>
                          </div>
                        : null}
                      </div>
                    </div>

                    <div className="project-join-body">

{/*                      <div className="project-join-section">
                        <h3>Coins required</h3>

                        {!!project.pointsToJoin ?
                          <div>
                            <p>This project requires <strong>{project.pointsToJoin} coins</strong>. These coins will be deducted from your coins balance when you join the project.</p>

                              <div className="inline-tooltip">
                                <p>Coins on profile: {User.coins}</p>

                                {isInsufficientCoins ?
                                  <Button color="primary" size="sm" onClick={() => {
                                    // TODO
                                  }}>
                                    Buy coins
                                  </Button>

                                : null}
                              </div>
                          </div>
                        :
                          <p>This project does not require coins.</p>
                        }
                      </div>*/}

                      {project?.isPremium && !(User?.premium_till && new Date(Date.parse(User.premium_till)) > toUTCDate(new Date())) ?
                          <div className="get-premium-section">
                            <h3>Go premium to join this project</h3>

                            <p>Don't miss out! Go Premium to build crucial skills with our best projects.</p>

                          {/*  <div className="illustration-container">
                              <img src={process.env.PUBLIC_URL + '/vector/early-access2.png'} alt="Free early access" />
                            </div>*/}
                            {/*<h3>Early alpha: limited offer</h3>*/}

                            <p>Since we are in early alpha, we are offering a FREE upgrade to Premium membership. This is a limited early-access offer that is likely to be active as long we are in early alpha.</p>

                            <ul>
                              <li className="info">No credit card required</li>
                              <li className="info">Premium doesn't auto-renew</li>
                              <li className="info">You can purchase an additional Premium term when it expires</li>
                            </ul>
                          </div>
                        :
                        <div className="project-join-section">
                          {!project.instantJoin ?
                            <div className="project-join-section">
                              <h3>Approval</h3>
                              <p>This project requires approval of the project owner to join. Click "Request to join" below.</p>
                            </div>
                          : null }

                          <h3>Introduction</h3>

                          <Label for="project-description">(Optional) Write a short note about why you would like to join this project and what you are looking to gain from working on it. This will be visible to the project administrators. </Label>
                          <Input
                            id="project-join-intro"
                            name="project-join-intro"
                            placeholder="Short intro"
                            type="textarea"
                            value={introText}
                            autoComplete="off"
                            onChange={(e) => {
                              setIntroText(e.target.value);
                            }}
                          />
                        </div>
                      }
                    </div>

                  </div>
                }
              </div>
            }
          </div>
        : null }

        <div className="join-project-actions single-action form-footer">
          <div className="project-detail-primary-actions single-action">
            {projectSuccess ?
              <Button color="primary" size="sm" onClick={() => {
                navigate(location.key === 'default' ? '/projects/' : -1);
                }}>
                Go back to project
              </Button>
            :
              <>
                {project?.isPremium && !(User?.premium_till && new Date(Date.parse(User.premium_till)) > toUTCDate(new Date())) ?
                    <a className="primary" href={`${upgradeUrl}?p=${id}`}>Upgrade now</a>
                  :
                    <>
                      {(role === 'outsider') ?
                        <Button color="primary" disabled={isInsufficientCoins} size="sm" onClick={() => {
                          joinProject(id, introText).then((response) => {
                            if (response?.data?.result?.affectedRows) {
                              setProjectError(null);

                              if (project.instantJoin) {
                                setProjectSuccess('Joined the project successfully');
                              } else {
                                setProjectSuccess('Successfully requested the owner to join the project. You will be added when the owner approves the request.');
                              }
                            } else {
                              setProjectError('Could not join project. Please try again.')
                            }
                          }).catch((error) => {
                            // logError(error);
                            setProjectError('Could not join project. Please try again.')
                          });
                        }}>
                          {!!project?.instantJoin ?
                            <span>
                            {!!project?.pointsToJoin ?
                              <span>Spend {project.pointsToJoin} coins and join project</span>
                            :
                              <span>Join project</span>
                            }
                            </span>
                          :
                            <span>Request to join</span>
                          }
                        </Button>
                      : null }
                    </>
                }
              </>
            }
          </div>

        </div>
      </div>
    </div>
  );
}