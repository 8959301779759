import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Carousel } from 'react-responsive-carousel';

import "./ImageCarouselModal.scss";

function ImageCarouselModal(props) {
  const {
    onClose,
    title,
    images,
    text,
    selectedItem
  } = props;

  const closeForm = () => {
    onClose();
  };

  return (
    <Modal
      className="image-carousel-modal"
      isOpen={true}
      toggle={closeForm}
      backdrop="static"
      centered={true}>
      <ModalHeader>
        <span>{title}</span>

        <div className="close-button" role="button" onClick={closeForm}>
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="image-carousel-container">
          <Carousel className="image-carousel"
            width="100%"
            centerMode={true}
            centerSlidePercentage={100}
            preventMovementUntilSwipeScrollTolerance={true}
            showIndicators={false}
            selectedItem={selectedItem}>
              {images.map((item, idx) => {
                return (
                  <div href={item} target="_blank" href={item} key={idx} className="image-carousel-item" onClick={() => {
                    window.open(item, "_blank", `toolbar=yes,scrollbars=yes,resizable=yes,top=0,left=0,width=${window.screen.availWidth},height=${window.screen.availHeight}`);
                  }}>
                    <img src={item} alt="Project design mock" />
                    {text && text[idx] ?
                      <p className="legend">{text[idx]}</p>
                    : null}
                  </div>
                );
              })}
          </Carousel>
        </div>
        <p className="image-carousel-help-text">
          <span>
            <img src={process.env.PUBLIC_URL + '/icons/lightbulb.svg'} alt="Tip" />
            Click on an image to open it in a wider window.
          </span>
        </p>
      </ModalBody>
      <ModalFooter className="single-action">
        <div className="primary-actions">
          <Button color="primary" onClick={closeForm}>
            <span>Close</span>
          </Button>
        </div>
      </ModalFooter>
    </Modal>
  );
}

export default ImageCarouselModal;
