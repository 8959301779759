import React, { useState, useEffect } from 'react';
import { Link, useLocation } from "react-router-dom";

import "./TabLink.styles.css";

function TabLink(props) {
  const [isActive, setActive] = useState();
  const {
    linkText,
    route,
    indexRoute,
    linkTo,
  } = props;
  const { pathname } = useLocation();

  useEffect(() => {
    const pathnameSansTrailingSlash = pathname.endsWith('/') ? pathname.substring(0, pathname.length - 1) : pathname;
    const indexRouteSansTrailingSlash = indexRoute?.endsWith('/') ? indexRoute?.substring(0, indexRoute.length - 1) : indexRoute;
    const routeSansTrailingSlash = route.endsWith('/') ? route.substring(0, route.length - 1) : route;

    setActive(pathnameSansTrailingSlash.endsWith(routeSansTrailingSlash) || pathnameSansTrailingSlash.endsWith(indexRouteSansTrailingSlash || 'none'));
  }, [pathname, props.route, props.indexRoute]);

  return (
    <Link to={linkTo} className={"tab-link " + (isActive ? "active" : null)}>{linkText}</Link>
  );
}

export default TabLink;
