import React, { useEffect, useContext, useState } from 'react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { UserContext } from '../../contexts/user-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import UserProfileModal from "../common/UserProfileModal";

import "./Likes.scss";

export default function Likes() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled, IsLoading } = useContext(PageContext);
  const { getUserClaps, postUpdateLastSawClaps, logView } = useContext(DataContext);
  const { User } = useContext(UserContext);
  const [likesCards, setLikesCards] = useState();
  const [userProfileModalId, setUserProfileModalId] = useState(null);
  const [receivedClaps, setReceivedClaps] = React.useState([]);
  const [givenClaps, setGivenClaps] = React.useState([]);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Likes');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('likes');
  }, []);

  const entityTypeDisplay = {
    comment: "comment",
    solution: "solution",
    project: "project",
  };

  const entityTypeThumbnail= {
    comment: process.env.PUBLIC_URL + '/icons/discussions.png',
    solution: process.env.PUBLIC_URL + '/icons/solution.png',
  };

  const fetchClaps = function(updateSet) {
    getUserClaps(true).then((response) => {
      setLikesCards(response.data);
      setGivenClaps(response.data.filter(item => item.clapper === User.id));
      setReceivedClaps(response.data.filter(item => item.clappee === User.id));
    }).catch((error) => {
      // logError(error);
      console.log(error);
    });
  };

  useEffect(() => {
    fetchClaps();
    postUpdateLastSawClaps();

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchClaps();
      }
    }, 7000);
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div className="likes full-screen-page">
      <Helmet>
        <title>Likes - Project Klub</title>
      </Helmet>

      <div className="full-screen-header">
        <h2>Likes given and received</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body likes-list">

        {receivedClaps?.length || givenClaps?.length ?
          <div className="user-profile-likes">
            <span className="total-label">
              Total:
            </span>
            {receivedClaps?.length ?
              <div>
                received&nbsp;
                <span className="likes-count">{receivedClaps.length} likes</span>
              </div>
            : null}

            {givenClaps?.length ?
              <div>
                given&nbsp;
                <span className="likes-count">{givenClaps.length} likes</span>
              </div>
            : null}
          </div>
        : null}

        <ul className="like-cards">
          {likesCards?.map((item, idx) => {
            return (
              <li key={idx} className="like-card">
                <div className="like-card-body">

                  <div className="entity-display">
                    <div className="entity-thumbnail">
                      <img src={entityTypeThumbnail[item.entity_type]} alt={entityTypeDisplay[item.entity_type]} />
                    </div>
                    <div className="entity-text">
                      <div className="entity-title">

                        {item.clapper === User.id ?
                          <>
                            <span>You liked </span>
                              <Button color="link" className="button-as-link"
                                onClick={() => { setUserProfileModalId(item.clappee)}}>
                                {item.clappee_name}
                              </Button>
                            &apos;s {entityTypeDisplay[item.entity_type]}:
                          </>
                        :
                          <>
                              <Button color="link" className="button-as-link"
                                onClick={() => { setUserProfileModalId(item.clapper)}}>
                                {item.clapper_name}
                              </Button>
                              &nbsp;liked your {entityTypeDisplay[item.entity_type]}:
                          </>
                        }
                      </div>
                      <h3 className="entity-text">
                        {item.entity_type === 'solution' ?
                          <Link to={`/solution-detail/${item.parent_id}/${item.entity_id}`}>
                            {item.entity_text}
                          </Link>
                        :
                          <div>
                            "{item.entity_text}"

                            <div className="entity-sub-text">
                              (This {entityTypeDisplay[item.entity_type]} appears in the {entityTypeDisplay[item.parent_type]}:&nbsp;
                              <Link to={item.parent_url}>
                                {item.parent_title}
                              </Link>)
                            </div>
                          </div>
                        }
                      </h3>
                    </div>
                  </div>

                </div>
              </li>
            );
          })}
        </ul>

        {likesCards && likesCards && !likesCards.length  && !IsLoading ?
          <div className="likes-empty-state">
            <p>You don't have any likes yet.</p>

            <Button color="primary" size="sm" className="updates-empty-state-my-projects-button" onClick={() => { navigate(location.key === 'default' ? '/projects/' : -1); }}>
              Close
            </Button>
          </div>
        : null}
      </div>

      {userProfileModalId ?
        <UserProfileModal 
          userId={userProfileModalId}
          onClose={() => setUserProfileModalId(null)}
          />
      : null}

    </div>
  );
}