import React, { useState } from 'react';
import { Link } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import RichTextModal from "../common/RichTextModal";
import { toUTCDate } from '../../util/dateTime';

import "./ProjectParticipantCard.scss";

const solutionStatusDisplay = {
  in_progress: "In progress",
  completed: "Completed",
  discarded: "Discarded"
};

function ProjectParticipantCard(props) {
  const {
    user,
    isDisabled,
    solutionStatus,
    onClick,
  } = props;
  const [showUserIntro, setShowUserIntro] = useState(false);
  const [isPremium, setIsPremium] = useState(user?.premium_till && new Date(Date.parse(user.premium_till)) > toUTCDate(new Date()));

  return (
    <li className="project-participant-card-container">
      <div className={`project-participant-card ${user?.help_request_text ? 'with-help-request' : ""} ${user?.intro_text ? 'with-intro-text' : ""} ${isPremium ? 'premium' : ""} ${solutionStatus} ${isDisabled ? ' disabled': ''} `} role="button" title={user?.intro_text} onClick={() => {
          if (!isDisabled) {
            onClick();
          }
        }}>
        {isPremium ?
          <img className="project-participant-card-icon" src={process.env.PUBLIC_URL + '/icons/person-purple.svg'} alt="User" />
        :
          <img className="project-participant-card-icon" src={process.env.PUBLIC_URL + '/icons/person-light-gray.svg'} alt="User" />
        }

        <span className="project-participant-card-username">
          {isPremium ?
            <img className="project-participant-card-premium-icon" src={process.env.PUBLIC_URL + '/icons/premium-purple.svg'} alt="Premium user" />
          : null}
          {user.name}
        </span>

        {solutionStatus && solutionStatusDisplay[solutionStatus] ?
          <span className={`project-participant-card-solution-status ${solutionStatus}`}>
            <img className="solution-icon" src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Solution" />
            <span>Solution: {solutionStatusDisplay[solutionStatus]}</span>
          </span>
        :
          <span className={`project-participant-card-solution-status yet-to-start`}>
            <img className="solution-icon" src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Solution" />
            <span>Solution: Yet to start</span>
          </span>
        }

        {user.help_request_text ?
          <span className="project-participant-card-has-help-request">
            <img className="help-request-icon" src={process.env.PUBLIC_URL + '/icons/help-request.png'} alt="Help request" />
            <span>Needs help</span>
          </span>
        : null}

        {showUserIntro && user?.intro_text ?
          <RichTextModal 
            onClose={() => {
              setShowUserIntro(false);
            }}
            title={`${user.name}'s introductory message when joining this project`}
            body={`<p style="font-size: 16px;">${user.intro_text}</p>`}
            />
        : null}
      </div>
    </li>
  );
}

export default ProjectParticipantCard;
