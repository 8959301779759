import React, { useContext, useEffect } from 'react';
import { Form, FormGroup, Label, Input, Button } from 'reactstrap';

import { PageContext } from '../../contexts/page-context';
import { useNavigate, Link, useLocation } from "react-router-dom";
import DateTimePicker from '../common/DateTimePicker';


import "./CreateMentorship.styles.css";

export default function CreateMentorship() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);

  useEffect(() => {
    setPageTitle('Request a mentorship session');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
  }, []);

  return (
    <div className="create-mentorship full-screen-page">
      <div className="full-screen-header">
        <h2>Request a mentorship session</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>
        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate('/projects/my-projects');
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>

      </div>

      <div className="full-screen-body create-mentorship-body">
        <p>Non quia reiciendis a unde quam rem nisi nihil! Ab accusantium maiores 33 quibusdam enim qui adipisci voluptatum non cupiditate delectus qui velit dolor ut maxime voluptatem hic unde cumque.</p>
        <ul>
          <li>Id illum dolorem aut odio culpa</li>
          <li>Non saepe voluptatem</li>
          <li>Est corporis voluptas vel corporis</li>
          <li>Blanditiis 33 dolorem illum</li>
        </ul>

        <Form className="create-mentorship-form">
          <FormGroup className="notes-container form-field-container">
            <Label for="mentorship-title" className="full-width">Skill areas & notes</Label>
            <Input
              id="mentorship-notes"
              name="mentorship-notes"
              className="mentorship-notes"
              type="textarea"
              placeholder="Lorem ipsum dolor sit amet. Et fugit dolores non minima error quo consequuntur esse 33 illum consequatur qui autem rerum. Id esse facilis ut dolores odit et expedita quia et obcaecati odio aut explicabo perspiciatis 33 excepturi pariatur et galisum nemo."              
              onChange={()=>{}}
            />
          </FormGroup>

          <div className="form-field-container create-mentorship-date-container">
            <Label for="mentorship-date">Preferred date & time</Label>

            <DateTimePicker
              initialValue={1664541900}
              onChange={(val) => {
              }}
              />
          </div>

        </Form>
      </div>

          <div className="create-mentorship-actions form-footer">
            <Button color="primary" onClick={() => {
              navigate('/sessions');
            }}>
              Finish
            </Button>
          </div>
    </div>
  );
}