import React, { useState, useEffect } from 'react';
import DateTimePicker from 'react-datetime-picker';
import { Button,  Label } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import { unixToDate, dateToUnix } from '../../util/dateTime';
import "./DateTimePicker.styles.css";

function DateTimePickerComponent(props) {
  const [value, setValue] = useState(new Date());
  const [showDateTimePicker, setShowDateTimePicker] = useState(false);
  const toggleDateTimePicker = () => setShowDateTimePicker(!showDateTimePicker);
  const {
    onChange,
    initialValue,
  } = props;

  useEffect(() => {
    if (props.initialValue) {
      setValue(unixToDate(props.initialValue));
    }
  }, [props.initialValue])

  return (
    <div className="date-time-picker-container">
      <Button className="date-time-picker" onClick={toggleDateTimePicker}>
        {initialValue ?
          <span>{
            new Intl.DateTimeFormat('en-GB', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(unixToDate(initialValue)))
          }
          </span>
        : 
          <span>Pick date & time</span>
        }
        <img src={process.env.PUBLIC_URL + process.env.PUBLIC_URL + '/icons/calendar-icon.svg'} alt="Pick date & time" aria-hidden="true" />
      </Button>

      <Modal
        isOpen={showDateTimePicker}
        toggle={toggleDateTimePicker}
        backdrop="static"
        centered={true}>
        <ModalHeader>Set date & time</ModalHeader>
        <ModalBody>
          <div className="date-time-picker-field">
            <Label>
              Choose date & time:
            </Label>
            <DateTimePicker
              openWidgetsOnFocus={false}
              amPmAriaLabel="Select AM/PM"
              format={"y-MM-dd h:mm a"}
              calendarAriaLabel="Toggle calendar"
              clearAriaLabel="Clear value"
              dayAriaLabel="Day"
              hourAriaLabel="Hour"
              maxDetail="minute"
              minuteAriaLabel="Minute"
              monthAriaLabel="Month"
              nativeInputAriaLabel="Date and time"
              closeWidgets={true}
              onChange={(val) => {
                setValue(val);
              }}
              secondAriaLabel="Second"
              value={value}
              yearAriaLabel="Year"
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="secondary-actions">
            <Button color="secondary" outline={true} onClick={() => {
              setValue(unixToDate(initialValue));
              toggleDateTimePicker();
            }}>
              Cancel
            </Button>
          </div>
          <div className="primary-actions">
            <Button color="primary" onClick={() => {
              onChange(dateToUnix(value));
              toggleDateTimePicker();
            }}>
              OK
            </Button>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default DateTimePickerComponent;
