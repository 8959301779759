import React, { useState, useEffect } from 'react';
import { Button, Input } from 'reactstrap';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import DateTimePicker from 'react-datetime-picker';
import "./ProfileVerificationModal.scss";

// TODO: Move table contents into a separate component & reuse between this and onboarding/ProfileVerification

function ProfileVerificationModal(props) {
  const [requestedVerificationTime, setRequestedVerificationTime] = useState('');
  const [scheduleVerificationNow, setScheduleVerificationNow] = useState('');
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isDatetimeError, setDatetimeError] = useState(false);

  const {
    toggleModal,
    onFinish,
  } = props;

  useEffect(() => {
    const _isDatetimeError = requestedVerificationTime === '';
    setDatetimeError(_isDatetimeError);
    setDoesFormHaveErrors(_isDatetimeError);
  }, [scheduleVerificationNow]);

  const setScheduleRadioValue = (e) => {
    setScheduleVerificationNow(e.target.value);
  }

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onFinish(requestedVerificationTime);
    }
  };

  return (
    <div className="profile-verification-modal">
      <Modal
        isOpen={true}
        toggle={toggleModal}
        backdrop="static"
        centered={true}>
        <ModalHeader>Profile verification</ModalHeader>
        <ModalBody>
          <div className={`profile-verification-modal-body ${showFormErrors ? 'form-errors' : ''}`}>
            <div className="modal-content-body">
              <div>Lorem ipsum dolor sit amet. Est distinctio reprehenderit qui eiu.</div>
            </div>

            <div className="verified-unverified-features">
              <div className="feature-row header-row">
                <div className="feature-column-1" aria-hidden="true"></div>
                <div className="feature-column-2">Un-verified</div>
                <div className="feature-column-3">Verified</div>
              </div>

              <div className="feature-row">
                <div className="feature-column-1">
                  Lorem ipsum dolor
                </div>
                <div className="feature-column-2">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
                <div className="feature-column-3">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
              </div>

              <div className="feature-row">
                <div className="feature-column-1">
                  Sed ut perspiciatis unde omnis
                </div>
                <div className="feature-column-2">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
                <div className="feature-column-3">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
              </div>

              <div className="feature-row">
                <div className="feature-column-1">
                  Et harum quidem rerum facilis est
                </div>
                <div className="feature-column-2">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-down.png'} alt="No" />
                </div>
                <div className="feature-column-3">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
              </div>

              <div className="feature-row">
                <div className="feature-column-1">
                  Nam libero tempore
                </div>
                <div className="feature-column-2">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-down.png'} alt="No" />
                </div>
                <div className="feature-column-3">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
              </div>

              <div className="feature-row">
                <div className="feature-column-1">
                  Temporibus autem quibusdam et aut
                </div>
                <div className="feature-column-2">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-down.png'} alt="No" />
                </div>
                <div className="feature-column-3">
                  <img src={process.env.PUBLIC_URL + '/icons/thumbs-up.png'} alt="Yes" />
                </div>
              </div>
            </div>

            <div className={`schedule-verification-container modal-content-body ${isDatetimeError ? 'error-field' : ''}`}>
              <div className="schedule-verification-inner-container">
                <div className="schedule-now-section schedule-verification-date-picker-container">
                  <p>Est distinctio reprehenderit qui eius quam qui.</p>
                  <DateTimePicker
                    openWidgetsOnFocus={false}
                    amPmAriaLabel="Select AM/PM"
                    // format={"y-MM-dd h:mm a"}
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    hourAriaLabel="Hour"
                    maxDetail="minute"
                    minuteAriaLabel="Minute"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date and time"
                    closeWidgets={true}
                    format={"dd/MM/y h:mm a"}
                    onChange={(val) => {
                      setRequestedVerificationTime(val)
                    }}
                    secondAriaLabel="Second"
                    value={requestedVerificationTime} // Set this to a state var
                    yearAriaLabel="Year"
                  />
                </div>
              </div>

              {showFormErrors && isDatetimeError ?
                <div className="form-field-error-message">
                  Enter a valid date & time for preferred contact
                </div>
               : null}
            </div>
          </div>
        </ModalBody>
        <ModalFooter className="primary-actions modal-actions-footer two-actions">
          <Button color="secondary" outline size="sm" onClick={toggleModal}>
            Cancel
          </Button>

          <Button type="submit" color="primary" size="sm" onClick={submitForm}>
            Submit
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ProfileVerificationModal;
