import React, { useState, useEffect, useContext } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';

import "./UserProfile.styles.css";

export default function UserProfile() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { id } = useParams();
  const { getUser } = useContext(DataContext);
  const [userDetail, setUserDetail] = useState();
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('User Profile');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    const user = getUser(id);
    setUserDetail(user);
  }, [id]);

  return (
    <div className="user-profile page full-screen-page">
      <Helmet>
        <title>Profile settings - Project Klub</title>
      </Helmet>

      <div className="full-screen-header">
        <h2>User Profile</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="back"
        >
          <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
        </Link>
      </div>

      <div className="full-screen-body user-profile-container">
        {userDetail ?
          <div>
            <div className="user-profile-basics">
              <img src={userDetail.pic} alt={userDetail.name} />
              <h2 className="user-name">{userDetail.name}</h2>
            </div>

            <div className="user-profile-about-container">
              <h4>About {userDetail.name}</h4>
              <div className="user-profile-about">
                {userDetail.about}
              </div>
            </div>

            <div className="user-profile-skills">
              <h4>Skills {userDetail.name} is interested in</h4>

              <div className="user-profile-skills-list">
                {userDetail.skills?.map((item, idx) => {
                  return (
                    <div key={idx} className="profile-skill-card skill-on-profile">
                      <img src={process.env.PUBLIC_URL + '/icons/skill-green.png'} alt="Skill" />
                      <div>{item.name}</div>
                    </div>
                  );
                })}
              </div>
            </div>

            <div className="user-profile-projects">
              <h4>{userDetail.name}'s' projects</h4>

              <ul className="user-profile-projects-list">
                {userDetail.projects?.map((item, idx) => {
                  return (
                    <li key={idx} className="user-profile-project-card">
                      <div className="user-profile-project-card-thumbnail-section">
                        <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + item?.type + '.png'} alt={item.name}  />
                        <span className="user-profile-project-type">{item.type}</span>
                      </div>
                      <div className="user-profile-project-name">{item.name}</div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        : null }

      </div>
    </div>
  );
}