import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";

import "./Confirmation.scss";

export default function Confirmation(props) {
  const {
    onFinish,
    isVisible,
    isEdit,
    projectType,
  } = props;

  return (
    <div className={`project-create-confirmation ${isVisible ? 'visible' : 'hidden'}`}>
      <div className="full-screen-page-body">
        <h3>
          <img src={process.env.PUBLIC_URL + '/icons/green-check.png'} alt="Success" />
          {projectType === 'guided' ?
            <span>Suggestion sent</span>
          :
            <>
              {isEdit?
                <span>Project updated</span>
              :
                <span>Project created</span>
              }
            </>
          }
        </h3>

        {projectType === 'guided' ?
          <>
            <h4>Thank you for suggesting a practice project topic!</h4>

            <div className="project-created-draft-message">
              <p>We will review your suggestion and add this practice project if it meets our quality and relevance criteria. We will notify you once it has been added.</p>
            </div>
          </>
        :
          <>
            {!isEdit ?
              <>
                <h4>Next steps</h4>
                <div className="project-created-draft-message">
                  <img src={process.env.PUBLIC_URL + '/vector/project-pending.png'} alt="Draft state" />
                  <ul>
                    <li>Your new project is pending review. It will be reviewed and published shortly.</li>
                    <li>Meanwhile, you can start adding modules, resources and solutions to your new project!</li>
                  </ul>
                </div>
              </>
            :
              <p className="project-edited-help-text">
                Tap "Finish" to proceed.
              </p>
            }
          </>
        }
      </div>

      <div className="create-project-actions single-action form-footer">
        <div className="single-action">
          <Button color="primary" onClick={() => { onFinish() }}>
            Finish
          </Button>
        </div>
      </div>
    </div>
  );
}