import React, { useContext, useState } from "react";
import { Container, Button, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { useNavigate, Link, useLocation, useMatch } from "react-router-dom";

// import { PageContext } from '../../contexts/page-context';
import { UserContext } from '../../contexts/user-context';
import { DataContext } from '../../contexts/data-context';
import { PageContext } from '../../contexts/page-context';

import { toUTCDate } from '../../util/dateTime';

import "./TopNavBar.styles.css";

function TopNavBar() {
  // const { PageTitle } = useContext(PageContext);
  const { isLoggedIn, setLoggedIn, logoutUser, hasUpdates, updatesCount, hasUnreadLikes, unreadLikesCount, User } = useContext(UserContext);
  const { userDetail, logout, serverUrl } = useContext(DataContext);
  const { setIsLoading } = useContext(PageContext);
  const navigate = useNavigate();
  const location = useLocation();
  const [isBurgerMenuVisible, setBurgerMenuVisible] = useState(false);
  const [isBurgerMenuExpanded, setBurgerMenuExpanded] = useState(false);
  const isIndexRoute = useLocation().pathname === '/projects/';
  const isAvailableProjectsRoute = useLocation().pathname === '/projects/available-projects';

  // function doLogout() {
  //   logout().then(() => {
  //     setBurgerMenuVisible(false);
  //     logoutUser();
  //   });
  // }

  function toggleBurgerMenu() {
    const _isBurgerMenuVisible = isBurgerMenuVisible;

    if (!_isBurgerMenuVisible) {
      setBurgerMenuVisible(true);

      setTimeout(function() {
        setBurgerMenuExpanded(true);
      }, 0);
    } else {
      setBurgerMenuExpanded(false);

      setTimeout(function() {
        setBurgerMenuVisible(false);
      }, 250);      
    }
  }

  return (
      <div className={`top-navbar ${isBurgerMenuVisible ? 'burger-menu-open' : ''} ${isBurgerMenuExpanded ? 'burger-menu-expanded' : ''}`}>
        <Container fluid="smx" className="top-navbar-container">
          {isBurgerMenuVisible ? (
            <div
              className="burger-menu-overlay"
              onClick={(e) => {
                e.preventDefault();
                toggleBurgerMenu();
              }}
              ></div>
          ) : (
            null
          )}
          {isBurgerMenuVisible ? (
            <div className="burger-menu">
              <Link
                to={'..'}
                onClick={(e) => {
                  e.preventDefault();
                  toggleBurgerMenu();
                }}
                className="close"
              >
                <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
              </Link>

              {isLoggedIn ?
                <>
                  {/*TODO: Change a bunch of these from buttons to links*/}

                  <Link to="/projects/available-projects" onClick={() => { toggleBurgerMenu() }}>
                    Available projects
                  </Link>

                  <Link to="/projects/my-projects" onClick={() => { toggleBurgerMenu() }}>
                    My projects
                  </Link>

                  <Link to="/suggest-project" onClick={() => { toggleBurgerMenu() }}>
                    Suggest a project
                  </Link>

                  <Link to="/profile" onClick={() => { toggleBurgerMenu() }}>
                    Profile
                  </Link>

                  <Link to="/updates" onClick={() => { toggleBurgerMenu() }}>
                    Updates
                    {updatesCount ?
                      <>&nbsp;({updatesCount})</>
                    : null}
                  </Link>

                  <Link to="/likes" onClick={() => { toggleBurgerMenu() }}>
                    Likes
                    {unreadLikesCount ?
                      <>&nbsp;({unreadLikesCount})</>
                    : null}
                  </Link>
                </>
              : null }

              <Link to="/about" onClick={() => { toggleBurgerMenu() }}>
                About us
              </Link>

              <Link to="/contact" onClick={() => { toggleBurgerMenu() }}>
                Contact / Feedback
              </Link>

              <Link to="/terms" onClick={() => { toggleBurgerMenu() }}>
                Terms of use
              </Link>

              <Link to="/privacy-policy" onClick={() => { toggleBurgerMenu() }}>
                Privacy policy
              </Link>

              {isLoggedIn ?
                <a href={`${serverUrl}/do_logout`} onClick={() => { setIsLoading(true) }}>
                  Log out
                </a>
              :
                <Link to="/login" onClick={() => { toggleBurgerMenu() }}>
                  Log in
                </Link>
              }

              {/*<div className="burger-menu-logo-container">
                <img src={process.env.PUBLIC_URL + '/logo.png'} alt="Logo" className="logo" />
              </div>*/}
            </div>
          ): null }

          <div className="top-navbar-inner-container">
            <div className="logo-container">
              <div className="logo-container">
                <Link to="/projects">
                  {/*<img src={process.env.PUBLIC_URL + '/logo-with-text.png'} alt="Logo" />*/}
                  <img src={process.env.PUBLIC_URL + '/logo-with-text.png'} alt="Logo" />
                  {/*<span>project klub</span>*/}
                </Link>
              </div>
              {/*<img src={process.env.PUBLIC_URL + '/logo-fg.png'} alt="Logo" className="logo" />*/}
              {/*<span className="topnav-page-title">{PageTitle}</span>*/}
              {/*<span className="topnav-page-title">SPYDR</span>*/}
            </div>
            <nav>
              <ul className={`topnav-actions-list ${isLoggedIn ? 'logged-in' : 'logged-out'}`}>
  {/*              {!location.pathname.includes('projects') ?
                  <li>
                    <Button color="link" size="sm" onClick={() => { navigate("/projects"); }}>
                      <img src={process.env.PUBLIC_URL + '/icons/my-projects.svg'} alt="Projects" className="topnav-action-icon" />
                    </Button>
                  </li>
                : null }
  */}              {/*<li>
                  <Button color="link" size="sm" onClick={() => { navigate("/discussions"); }}>
                    <img src={process.env.PUBLIC_URL + '/icons/discussions.png'} alt="Discussions" className="topnav-action-icon" />
                  </Button>
                </li>*/}
                <li className={`top-navbar-my-projects ${useMatch('/projects/my-projects') ? 'active': ''}`}>
                  <Link to="/projects/my-projects">
                    <span className="top-nav-link">My projects</span>
                  </Link>
                </li>
                <li className={`top-navbar-available-projects ${isIndexRoute || isAvailableProjectsRoute ? 'active': ''}`}>
                  <Link to="/projects/available-projects">
                    <span className="top-nav-link">Available projects</span>
                  </Link>
                </li>
                {/*<li className={`top-navbar-profile ${useMatch('/profile') ? 'active': ''}`}>
                  <Link to="/profile">
                    <span className="top-nav-link">Profile</span>
                    <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Profile" title="Profile settings" className="topnav-action-icon" />
                  </Link>
                </li>*/}
                <li className={`top-navbar-updates ${useMatch('/updates') ? 'active': ''}`}>
                  <Link to="/updates">
                    <span className="top-nav-link">Updates</span>
                    <img src={process.env.PUBLIC_URL + '/icons/bell.svg'} alt="Updates" title="Project updates" className="topnav-action-icon" />
                    {hasUpdates && updatesCount ? <span className="has-updates-indicator" aria-hidden="true">{updatesCount}</span> : null}
                  </Link>
                </li>
                <li className={`top-navbar-likes`}>
                  <Link to="/likes">
                    <span className="top-nav-link">Likes</span>
                    <img src={process.env.PUBLIC_URL + '/icons/likes.svg'} alt="Likes" title="Likes" className="topnav-action-icon" />
                    {hasUnreadLikes && unreadLikesCount ? <span className="has-unread-likes-indicator" aria-hidden="true">{unreadLikesCount}</span> : null}
                  </Link>
                </li>
                <li className={`top-navbar-create-project ${useMatch('/create-project') ? 'active': ''}`}>
                  <Link to="/suggest-project">
                    <span className="top-nav-link">Suggest a project</span>
                    <img src={process.env.PUBLIC_URL + '/icons/plus.png'} alt="New project" title="New project" className="topnav-action-icon" />
                  </Link>
                </li>
                {isLoggedIn ?
                  <>
                    <li className={`top-nav-profile-dropdown-container ${User?.premium_till && new Date(Date.parse(User.premium_till)) > toUTCDate(new Date()) ? 'premium' : ''}`}>
                      <UncontrolledDropdown className="top-nav-profile-dropdown">
                        <DropdownToggle caret>
                          {User?.premium_till && new Date(Date.parse(User.premium_till)) > toUTCDate(new Date()) ?
                            <img src={process.env.PUBLIC_URL + '/icons/premium-purple.svg'} alt="Premium user" />
                          :
                            <img src={process.env.PUBLIC_URL + '/icons/person-gray.svg'} alt="User" />
                          }
                          {User? User.username || User.name : 'Please wait...'}
                        </DropdownToggle>
                        <DropdownMenu container="body">
                          <DropdownItem onClick={() => { navigate('/profile') }}>
                            Profile settings
                          </DropdownItem>
                          <DropdownItem onClick={() => {
                            setIsLoading(true);
                            window.location.href = `${serverUrl}/do_logout`;
                          }}>
                            Log out
                          </DropdownItem>
                        </DropdownMenu>
                      </UncontrolledDropdown>
                    </li>
                  </>
                :
                  <>
                    <li className="top-navbar-login">
                      <Link to="/login">
                        <span className="top-nav-link">Log in</span>
                      </Link>
                    </li>
                  </>
                }
                {/*<li><a href={upgradeUrl + '?p=59'}>Go Premium</a></li>*/}
                {/*<li><a href={upgradeUrl + ''}>Go Premium</a></li>*/}
                <li className="burger-menu-item">
                  <Button color="link" size="sm" onClick={() => {
                    toggleBurgerMenu();
                  }}>
                    <img src={process.env.PUBLIC_URL + '/icons/reshot-icon-menu-D6U5PY3TJK.svg'} title="More actions" alt="More actions" className="topnav-action-icon" />
                  </Button>
                </li>
              </ul>
            </nav>
          </div>
        </Container>
      </div>
  );
}

export default TopNavBar;
