import React, { useState, createContext, useEffect, useContext } from 'react';
import { DataContext } from './data-context';

const UserContext = createContext();


function UserProvider(props){
  
  // const [isLoggedIn, setLoggedIn] = useState(false);
  // Temp set to default true to make localhost dev easier
  const [isLoggedIn, setLoggedIn] = useState(false);
  const [isLoggingIn, setLoggingIn] = useState(false);
  const [hasUpdates, setHasUpdates] = useState(false);
  const [updatesCount, setUpdatesCount] = useState(false);
  const [hasUnreadLikes, setHasUnreadLikes] = useState(false);
  const [unreadLikesCount, setUnreadLikesCount] = useState(false);
  const [requirePasswordChange, setRequirePasswordChange] = useState(false);
  const [requireEmailVerification, setRequireEmailVerification] = useState(false);
  const [userUpdates, setUserUpdates] = useState({});
  const [User, setUser] = useState({});
  const { getUser, getUserUpdates, getUserUnseenClaps, serverUrl } = useContext(DataContext);

  let logoutUrl = serverUrl + '/do_logout';

  const fetchUserUpdates = function(showLoader) {
    if (User?.id) {
      getUserUpdates(showLoader).then((response) => {
        const doesHaveUpdates = !!response?.data?.withUpdates?.length;
        setHasUpdates(doesHaveUpdates);
        setUpdatesCount(response?.data?.withUpdates?.length);
        setUserUpdates(response.data);
      }).catch((error) => {
        console.log(error);
        setUser(null);
        setLoggedIn(false);
      });

      fetchUserLikes(showLoader);
    }
  };

  const fetchUserLikes = function(showLoader) {
    getUserUnseenClaps(showLoader).then((response) => {
      const doesHaveUnreadLikes = !!response?.data?.length;
      // console.log(response);
      setHasUnreadLikes(doesHaveUnreadLikes);
      setUnreadLikesCount(response?.data?.length);
    }).catch((error) => {
      console.log(error);
    });
  };

  useEffect(() => {
    fetchUserUpdates(true);

    const intervalId = setInterval(() => {
      if (document.hasFocus()) {
        fetchUserUpdates(false);
      }
    }, 7000);
    return () => {
      clearInterval(intervalId);
    };
  }, [User]);

  const refreshUser = function() {
    if (User?.id) {
      getUser(User.id).then((response) => {
        setUser(response.data[0]);
        fetchUserUpdates(false);
      }).catch((error) => {
        console.log(error);
      });
    }
  };

  const refreshUserUpdates = function() {
    if (User?.id) {
      fetchUserUpdates(false);
    }
  };

  const loginUser = function(response) {
    if (response?.data?.auth  && response?.data?.result) {
      setLoggingIn(true);
      fetchUserUpdates(true);
      setTimeout(() => { 
        setUser(response.data.result);
        setLoggedIn(true);
        setLoggingIn(false);
        setRequirePasswordChange(!!response.data.result["require_password_change"]);
        setRequireEmailVerification(!!response.data.result["require_email_verification"]);
      }, 0);
    }
  }

  const logoutUser = function(response) {
    setUser(null);
    setHasUpdates(false);
    setLoggedIn(false);
    setRequireEmailVerification(false);
    setRequirePasswordChange(false);
    setLoggingIn(true);
    window.location.href = logoutUrl;
  }

  return(
    <UserContext.Provider value={{
      isLoggedIn,
      setLoggedIn,
      User,
      setUser,
      loginUser,
      logoutUser,
      isLoggingIn,
      hasUpdates,
      updatesCount,
      userUpdates,
      hasUnreadLikes,
      unreadLikesCount,
      refreshUser,
      refreshUserUpdates,
      requirePasswordChange,
      requireEmailVerification,
    }}>
      {props.children}
    </UserContext.Provider>
  );
}

export { UserContext, UserProvider };
