import React, { useState, useEffect, useContext }  from 'react';
import { Button, Label, Input } from 'reactstrap';

// import { DataContext } from '../../contexts/data-context';
// import { useNavigate } from "react-router-dom";

import "./VisibilitySettings.scss";

export default function VisibilitySettings(props) {
  const [isPublic, setPublic] = useState(true);
  const [isOwnerVisible, setOwnerVisible] = useState(true);
  const [isAllowHelp, setAllowHelp] = useState(true);
  const [isInstantJoin, setInstantJoin] = useState(true);

  const [isVisible, setIsVisible] = useState(false);
  const [isPublicError, setPublicError] = useState(false);
  const [isOwnerVisibleError, setOwnerVisibleError] = useState(false);
  const [isAllowHelpError, setAllowHelpError] = useState(false);
  const [isInstantJoinError, setInstantJoinError] = useState(false);

  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const {
    onPrevious,
    onNext,
  } = props;

  useEffect(() => {
    setPublic(props.isProjectPublic);
    setOwnerVisible(props.isProjectOwnerVisible);
    setAllowHelp(props.isProjectAllowHelp);
    setInstantJoin(props.isProjectInstantJoin);

    setIsVisible(props.isVisible);
  }, [props.isProjectPublic, props.isProjectOwnerVisible, props.isProjectAllowHelp, props.isProjectInstantJoin, props.isVisible]);

  useEffect(() => {
    const _isPublicError = isPublic === null;
    setPublicError(_isPublicError);

    const _isOwnerVisibleError = isOwnerVisible === null;
    setOwnerVisibleError(_isOwnerVisibleError);

    const _isAllowHelpError = isAllowHelp === null;
    setAllowHelpError(_isAllowHelpError);

    const _isInstantJoinError = isInstantJoin === null;
    setInstantJoinError(_isInstantJoinError);

    const _doesFormHaveErrors = _isPublicError || _isOwnerVisibleError || _isAllowHelpError || _isInstantJoinError;
    setDoesFormHaveErrors(_doesFormHaveErrors);
  }, [isPublic, isOwnerVisible, isAllowHelp, isInstantJoin]);

  const handleSubmit = (e) => {
    e.preventDefault();

    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(isPublic, isOwnerVisible, isAllowHelp, isInstantJoin);
    }
  };

  return (
    <div className={`visibility-settings ${isVisible ? 'visible' : 'hidden'}`}>
      <div className={`full-screen-page-body`}>
        <h3 className="full-screen-sub-header">Project visibility & privacy</h3>
        <form onSubmit={handleSubmit} className={`${showFormErrors ? 'form-errors' : ''}`}>
          <div className={`form-group full-width inline-radio-field ${doesFormHaveErrors ? 'error-field' : ''}`}>
            <span className="radio-title">Is this a private or public project?</span>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isPublic"
                    value={true}
                    checked={isPublic === true}
                    onChange={(e) => {
                      setPublic(e.target.value === 'true');
                      console.log(e.target.value, true)
                    }}
                    />
                  <span>Public</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isPublic"
                    value={false}
                    checked={isPublic === false}
                    onChange={(e) => {
                      setPublic(e.target.value === 'true');
                    }}
                    />
                  <span>Private</span>
                </label>
              </div>
            </div>

            {showFormErrors && isPublicError ?
              <div className="form-field-error-message">
                Choose one of these two options
              </div>
             : null}
          </div>

          {/*Commenting this out and defaulting this to true*/}
          {/*<div className={`form-group full-width inline-radio-field ${doesFormHaveErrors ? 'error-field' : ''}`}>
            <span className="radio-title">Show the project owner on project cards?</span>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isOwnerVisible"
                    value={true}
                    checked={isOwnerVisible === true}
                    onChange={(e) => {
                      setOwnerVisible(e.target.value === 'true');
                    }}
                    />
                  <span>Yes</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isOwnerVisible"
                    value={false}
                    checked={isOwnerVisible === false}
                    onChange={(e) => {
                      setOwnerVisible(e.target.value === 'true');
                    }}
                    />
                  <span>No</span>
                </label>
              </div>
            </div>

            {showFormErrors && isOwnerVisibleError ?
              <div className="form-field-error-message">
                Choose one of these two options
              </div>
             : null}
          </div>*/}

{/* Commenting this out for now - add back if we decide to keep this in feature set later */}
{/*          <div className={`form-group full-width inline-radio-field ${doesFormHaveErrors ? 'error-field' : ''}`}>
            <span className="radio-title">Allow contributors to seek help from other members?</span>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isAllowHelp"
                    value={true}
                    checked={isAllowHelp === true}
                    onChange={(e) => {
                      setAllowHelp(e.target.value === 'true');
                    }}
                    />
                  <span>Yes</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isAllowHelp"
                    value={false}
                    checked={isAllowHelp === false}
                    onChange={(e) => {
                      setAllowHelp(e.target.value === 'true');
                    }}
                    />
                  <span>No</span>
                </label>
              </div>

              {showFormErrors && isAllowHelpError ?
                <div className="form-field-error-message">
                  Choose one of these two options
                </div>
               : null}
            </div>
          </div>*/}

          <div className={`form-group full-width inline-radio-field ${doesFormHaveErrors ? 'error-field' : ''}`}>
            <span className="radio-title">Allow users to instantly join?</span>
            <div className="inline-radio-group">
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isInstantJoin"
                    value={true}
                    checked={isInstantJoin === true}
                    onChange={(e) => {
                      setInstantJoin(e.target.value === 'true');
                    }}
                    />
                  <span>Instant join</span>
                </label>
              </div>
              <div className="styled-radio inline">
                <label>
                  <Input type="radio"
                    name="isInstantJoin"
                    value={false}
                    checked={isInstantJoin === false}
                    onChange={(e) => {
                      setInstantJoin(e.target.value === 'true');
                    }}
                    />
                  <span>Ask to join</span>
                </label>
              </div>
            </div>

            {showFormErrors && isInstantJoinError ?
              <div className="form-field-error-message">
                Choose one of these two options
              </div>
             : null}
          </div>
        </form>
      </div>

      <div className="create-project-actions form-footer">
        <div>
          <Button color="secondary" outline onClick={() => { onPrevious() }}>
            Previous
          </Button>
   
          <Button color="primary" onClick={handleSubmit}>
            Next
          </Button>
        </div>
      </div>
    </div>
  );
}