import React from "react";
import { Link } from "react-router-dom";

import "./Footer.scss";

function Footer(props) {

  return (
    <footer className="footer">
      <div className="copyright">
        © Copyright Project Klub 2023
      </div>

      <nav>
        <ul>
          <li>
            <Link to="/terms">Terms & conditions</Link>
          </li>
          <li>
            <Link to="/privacy-policy">Privacy policy</Link>
          </li>
        </ul>
      </nav>
    </footer>
  );
}

export default Footer;
