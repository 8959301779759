// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("/public/vector/project-card-status-ribbon-orange.svg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* This CSS shouldn't be necessary, but for some reason the SCSS doesn't pick up image urls */\n\n.solution-card.mine:before {\n  content: '';\n  position: absolute;\n  right: -2px;\n  top: -2px;\n  height: 150px;\n  width: 150px;\n  background-image: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  background-size: 42px auto;\n  background-repeat: no-repeat;\n  background-position: right top;\n  z-index: 1;\n}\n", "",{"version":3,"sources":["webpack://./src/components/cards/SolutionCard.styles.css"],"names":[],"mappings":"AAAA,6FAA6F;;AAE7F;EACE,WAAW;EACX,kBAAkB;EAClB,WAAW;EACX,SAAS;EACT,aAAa;EACb,YAAY;EACZ,yDAA6E;EAC7E,0BAA0B;EAC1B,4BAA4B;EAC5B,8BAA8B;EAC9B,UAAU;AACZ","sourcesContent":["/* This CSS shouldn't be necessary, but for some reason the SCSS doesn't pick up image urls */\n\n.solution-card.mine:before {\n  content: '';\n  position: absolute;\n  right: -2px;\n  top: -2px;\n  height: 150px;\n  width: 150px;\n  background-image: url('/public/vector/project-card-status-ribbon-orange.svg');\n  background-size: 42px auto;\n  background-repeat: no-repeat;\n  background-position: right top;\n  z-index: 1;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
