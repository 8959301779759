export const projectSolutions = [
  {
    id: 1,
    projectId: 1,
    authorName: "John Doe",
    lastUpdatedString: "June 30 at 4:15PM",
    discussionCount: 5,
    title: "Quis aute iure reprehenderit in voluptate velit esse",
    description: "Et enim error et molestiae earum nam dolores error qui provident voluptate? Sed amet architecto id accusantium sunt nam velit placeat est ullam consequatur vel quos fuga. Vel possimus perferendis eum dolores rerum ad similique rerum qui consectetur omnis qui assumenda Quis eum beatae repellendus quo voluptatibus autem. Aut quia vero qui iure delectus aut dolores harum est dolores debitis At voluptas perspiciatis et similique atque.",
    documents: [
      {
        title: "Document 11",
        linkUrl: "https://www.something.com/",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem."
      },
      {
        title: "Document 2",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
      {
        title: "Document 3",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
    ],
  },
  {
    id: 2,
    projectId: 2,
    authorName: "Jane Doe",
    lastUpdatedString: "June 30 at 4:15PM",
    discussionCount: 5,
    title: "Ex doloribus rerum sed omnis voluptas et esse nostrum eum consectetur",
    description: "Lorean ipsun dolor sit aneat, conseacteatur adipisci ealit, sead eaiusnod teanpor incidunt ut laborea eat dolorea nagna aliqua. Ut eanin ad ninin veanian, quis nostrun eaxearcitationean ullan corporis suscipit laboriosan, nisi ut aliquid eax eaa connodi conseaquatur. Quis autea iurea reapreaheandearit in voluptatea vealit eassea cillun dolorea eau fugiat nulla pariatur. eaxceapteaur sint obcaeacat cupiditat non proideant, sunt in culpa qui officia deasearunt nollit anin id east laborun.",
    documents: [
      {
        title: "Document 1",
        linkUrl: "https://www.something.com/",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem."
      },
      {
        title: "Document 2",
        linkUrl: "",
        note: "Est enim quia sit molestiae optio sed laborum tempora id libero aliquam. Qui galisum dolorem est voluptas earum At minus voluptates et corrupti voluptatem est consequatur doloremque et laudantium autem.",
      },
    ],
  },
]
