import React, { useState, useEffect, useContext }  from 'react';
import { Button, Input } from 'reactstrap';

import { DataContext } from '../../contexts/data-context';
import EditSkills from '../common/EditSkills';

import "./EmailPreferences.scss";

export default function EmailPreferences(props) {
  const { logView } = useContext(DataContext);
  const [isGetProjectUpdateEmails, setGetProjectUpdateEmails] = useState(false);
  const [isGetFeatureUpdateEmails, setGetFeatureUpdateEmails] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const {
    onBack,
    onNext,
  } = props;

  useEffect(() => {
    setGetProjectUpdateEmails(props.isGetProjectUpdateEmails);
    setGetFeatureUpdateEmails(props.isGetFeatureUpdateEmails);
    setIsVisible(props.isVisible)
  }, [props.isGetProjectUpdateEmails, props.isGetFeatureUpdateEmails, props.isVisible]);

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-email-preferences');
    }
  }, [isVisible]);

  const submitForm = (e) => {
    e.preventDefault();
    onNext(isGetProjectUpdateEmails, isGetFeatureUpdateEmails);
  };

  return (
    <div className={`onboarding-modal-signup ${isVisible ? 'visible' : 'hidden'}`}>
      <form>

        <div className="modal-content-body onboarding-modal-signup-email-preferences-body">
          <div className="email-preferences-intro-text">
            <p>Select the emails updates you would like to receive.</p>
            <p>We recommend leaving these on, to not miss any of our updates.</p>
          </div>

          <div className="form-group email-preferences">
            <div className="email-preference">
              <div className="styled-radio">
                <label>
                  <Input type="checkbox" checked={true} disabled={true} />
                  
                  <span className="email-preference-label">Basic project alerts</span>
                  <span className="email-preference-help-text">Example: Project invites, accept / decline events when you request to join a project. These cannot be turned off.</span>
                </label>
              </div>
            </div>

            <div className="email-preference">
              <div className="styled-radio">
                <label>
                  <Input type="checkbox" checked={true} disabled={true} />
                  
                  <span className="email-preference-label">Platform updates</span>
                  <span className="email-preference-help-text">Example: Major updates that affect how you use the platform, changes in terms, pricing, etc. These cannot be turned off.</span>
                </label>
              </div>
            </div>

            <div className="email-preference">
              <div className="styled-radio">
                <label>
                  <Input type="checkbox"
                    checked={isGetProjectUpdateEmails}
                    onChange={(e) => {
                      setGetProjectUpdateEmails(e.target.checked);
                    }} />
                  
                  <span className="email-preference-label">Project update summaries</span>
                  <span className="email-preference-help-text">A periodic digest outlining updates to your project. Example: updates to solutions, comments, modules, resources, etc.</span>
                </label>
              </div>
            </div>

            <div className="email-preference">
              <div className="styled-radio">
                <label>
                  <Input type="checkbox"
                    checked={isGetFeatureUpdateEmails}
                    onChange={(e) => {
                      setGetFeatureUpdateEmails(e.target.checked);
                    }} />
                  <span className="email-preference-label">Feature announcements</span>
                  <span className="email-preference-help-text">New feature updates, or changes to existing features</span>
                </label>
              </div>
            </div>
          </div>
        </div>

        <div className="modal-actions-footer two-actions">
          <Button color="secondary" outline  size="sm" onClick={onBack}>
            Back
          </Button>

          <Button type="submit" color="primary"  size="sm" onClick={submitForm}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
}