import React, { useState, useEffect, useContext }  from 'react';
import { Button } from 'reactstrap';

import { DataContext } from '../../contexts/data-context';
import EditSkills from '../common/EditSkills';

import "./SetupSkills.scss";

export default function SetupSkills(props) {
  const { logView } = useContext(DataContext);
  const [skills, setSkills] = useState('');
  const [isVisible, setIsVisible] = useState(false);
  const [doesFormHaveErrors, setDoesFormHaveErrors] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);
  const [isSkillsError, setSkillsError] = useState(false);
  const {
    onBack,
    onNext,
  } = props;

  useEffect(() => {
    setSkills(props.skills);
    setIsVisible(props.isVisible);
  }, [props.skills, props.isVisible]);

  useEffect(() => {
    if (isVisible) {
      logView('finish-profile-setup-skills');
    }
  }, [isVisible]);

  // Commenting out to make skills optional for now
  // useEffect(() => {
  //   const _isSkillsError = skills.trim() === '';
  //   setSkillsError(_isSkillsError);

  //   const _doesFormHaveErrors = _isSkillsError;
  //   setDoesFormHaveErrors(_doesFormHaveErrors);
  // }, [skills]);

  const submitForm = (e) => {
    e.preventDefault();
    setShowFormErrors(true);

    if (!doesFormHaveErrors) {
      onNext(skills);
    }
  };

  return (
    <div className={`onboarding-modal-signup onboarding-modal-setup-skills ${isVisible ? 'visible' : 'hidden'}`}>
      <form className={`${showFormErrors ? 'form-errors' : ''}`}>

        <div className={`modal-content-body ${isSkillsError ? 'error-field' : ''}`}>
          <div className="setup-skills-intro-text">Add the skills you're interested in (optional)</div>
          <EditSkills
            skills={skills}
            onUpdate={(updatedSkills) => { setSkills(updatedSkills); }}
            />
          {showFormErrors && isSkillsError ?
            <div className="form-field-error-message">
              Enter skill name and click 'Add'
            </div>
           : null}

        </div>

        <div className="modal-actions-footer two-actions">
          <Button color="secondary" outline  size="sm" onClick={onBack}>
            Back
          </Button>

          <Button type="submit" color="primary"  size="sm" onClick={submitForm}>
            Next
          </Button>
        </div>
      </form>
    </div>
  );
}