import React, { useContext } from 'react';
import { Link } from "react-router-dom";
import { UserContext } from '../../contexts/user-context';
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./SolutionCard.scss";
import "./SolutionCard.styles.css";

import { solutionStatusOptions } from '../../constants/solutionStatusOptions';

function SolutionCard(props) {
  const { User } = useContext(UserContext);
  const {
    solution,
    projectId,
    isDisabled,
    clapsCount,
    commentsCount,
    hasNewComments,
  } = props;

  return (
    <Link to={`/solution-detail/${projectId}/${solution.id}`} onClick={(e)=> { if (isDisabled) { e.preventDefault(); }}}>
      <div className={`solution-card ${User?.id && User?.id === solution.owner_id ? 'mine' : ''} ${clapsCount || commentsCount ? 'with-metadata' : ''}`}>
        {User?.id && User?.id === solution.owner_id ?
          <span className="my-solution-indicator">Mine</span>
        : null}

        <div className="solution-card-owner">
          {solution.modules?.length ? 
            <div className="solution-card-scope-container">
              <span className="solution-card-scope solution-card-module-specific">Module-specific</span>
              {/*<span className="solution-card-scope solution-card-project-wide">Project-wide</span>*/}
            </div>
          : null }
          <span>Solution by <strong>{solution.owner_name}</strong></span>
        </div>

        <div className="solution-card-title-container">
          <img className="solution-card-icon" src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Solution" />
          <div className="solution-card-title">
            <div>{solution.title}</div>
            <div className={`solution-card-status ${solution.status}`}>
              {solutionStatusOptions.find(item => item.value === solution.status)?.label}
            </div>
          </div>
        </div>

        {clapsCount || commentsCount ?
          <div className="solution-metadata-container">
            {clapsCount?
              <span className="likes-count">{clapsCount} {clapsCount === 1 ? 'like' : 'likes'}</span>
            : null}

            {commentsCount ?
              <span className={`comments-count ${hasNewComments ? 'has-unread' : ''}`} title={hasNewComments ? 'There are new comments since you last visited': ''}>
                {commentsCount} {commentsCount === 1 ? 'comment' : 'comments'}
              </span>
            : null}
          </div>
        : null}

      </div>  
    </Link>
  );
}

export default SolutionCard;
