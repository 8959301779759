import React from 'react';
import { useNavigate, Link, createSearchParams } from "react-router-dom";
// import { Button,  Label } from 'reactstrap';
// import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

import "./ProjectCardV2.styles.css";
import SkillsDisplay from "./SkillsDisplay";

const typeDisplay = {
  standalone: 'Standalone',
  guided: 'Practice',
};

function ProjectCardV2(props) {
  const navigate = useNavigate();
  const {
    project,
    hideShortDescription
  } = props;
  const projectThumbnail = project?.carouselImages ? project.carouselImages.split(',')[0] : null;

  const queryParams = Object.fromEntries(new URLSearchParams(window.location.search))

  return (
    <div className={`project-card-v2-container ${project?.type?.toLowerCase()} ${project?.status?.toLowerCase()} ${project?.isPremium ? 'premium' : ''}`}>
      {project.status === 'draft' || project.status==='pending' ?
        <span className="project-name-floating-status-display">{project.status.toUpperCase()}</span>
      : null}

      <Link to={{ pathname: `/project-detail/${project.id}`, search: `?${createSearchParams(queryParams)}`}} className="project-card-v2">

        {project?.isPremium ?
          <div className="project-price premium">
            <span>Premium</span>
          </div>
        :
          <div className="project-price free">
            <span>Free</span>
          </div>
        }

        <div className="project-card-v2-thumbnail-section">
          {projectThumbnail ?
            <img src={projectThumbnail} alt={project.title} className="project-thumbnail" />
          :
            <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="project-thumbnail project-thumbnail-placeholder" />
          }
          {/*<span className={"project-type " + project?.type}>{typeDisplay[project.type]}</span>*/}

          {!project.isPublic ?
            <div className="project-card-v2-private">
              <img src={process.env.PUBLIC_URL + '/icons/lock.svg'} alt="Private" />
              <span>Private</span>
            </div>
          : null}
        </div>

        {project.numSolutions || project.numMessages || project.numResources ?
          <div className="project-card-v2-metadata-container">
            {/*<span className="project-participants project-card-v2-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/person.svg'} alt="Users" />
              <span>{project.numParticipants || 0} 
                {project.numParticipants === 1 ? <span>participant</span> : <span>participants</span>}
              </span>
            </span>*/}

            {project.numSolutions && project.numSolutions > 0 ?
              <span className="project-participants project-card-v2-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Participants" />
                <span>{project.numSolutions || 0} 
                  {project.numSolutions > 1 ? <span>solutions</span> : <span>solution</span>}
                </span>
              </span>
            : null}

            {project.numModules && project.numModules > 0 ?
              <span className="project-modules project-card-v2-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/module-thumbnail-grayscale.png'} alt="Modules" />
                <span>{project.numModules || 0} 
                  {project.numModules > 1 ? <span>modules</span> : <span>module</span>}
                </span>
              </span>
            : null}

            {project.numResources && project.numResources > 0 ?
              <span className="project-resources project-card-v2-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/resource-thumbnail.png'} alt="Resources" />
                <span>{project.numResources || 0} 
                  {project.numResources > 1 ? <span>resources</span> : <span>resource</span>}
                </span>
              </span>
            : null}

            {project.numMessages && project.numMessages > 0 ?
              <span className="project-messages project-card-v2-metadata">
                <img src={process.env.PUBLIC_URL + '/icons/discussions.png'} alt="Comments" />
                <span>{project.numMessages || 0} 
                  {project.numMessages > 1 ? <span>comments</span> : <span>comment</span>}
                </span>
              </span>
            : null}
          </div>
        : null}
        
        <div className="project-card-v2-main">
          <div className="project-card-v2-body">
            <span className="project-name dark-gray-link">{project.title}</span>

            {project.skills && project.size ?
              <div className="project-card-v2-skills">                
                <SkillsDisplay skills={project.skills} />

                {project.size ?
                  <span className={`project-card-v2-size project-card-v2-size-${project.size}`}>
                    <span>{project.size}</span>
                  </span>
                : null}
              </div>
            : null}

            {project.shortDescription && !hideShortDescription ?
              <p className="project-card-v2-short-summary">
                {project.shortDescription}
              </p>
            : null}
          </div>
        </div>


      {project.status && (project.status==='draft') ?
        <div className="project-card-v2-status-text">
          <img src={process.env.PUBLIC_URL + '/icons/info-red.png'} alt="Info" />
          <span>Add resources & a solution, then publish this project.</span>
        </div>
      : null}

      {project.status && (project.status==='pending') ?
        <div className="project-card-v2-status-text">
          <img src={process.env.PUBLIC_URL + '/icons/info-red.png'} alt="Info" />
          <span>This project is pending review. Will be published soon.</span>
        </div>
      : null}

      </Link>

{/*      {project.numHelpRequests && project.numHelpRequests > 0 ?
        <div className="project-card-v2-help-requests">
          <img src={process.env.PUBLIC_URL + '/icons/hand-green.png'} alt="Help / feedback requests" />
          <span>This project has {project.numHelpRequests} 
            {project.numHelpRequests > 1 ? <span> help requests</span> : <span> help request</span>}
          </span>
        </div>
      : null}
*/}

    </div>
  );
}

export default ProjectCardV2;
