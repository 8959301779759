import React, { useEffect, useContext } from 'react';
import { Button } from 'reactstrap';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';
import { useNavigate, Link, useLocation } from "react-router-dom";

import PrivacyPolicyContent from '../legal/PrivacyPolicyContent';

import "./PrivacyPolicy.scss";

export default function PrivacyPolicy() {
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { logView } = useContext(DataContext);
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    setPageTitle('Privacy policy');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);
    logView('privacy-policy');
  }, []);

  return (
    <div className="privacy-policy full-screen-page">
      <Helmet>
        <title>Privacy policy - Project Klub</title>
      </Helmet>
      <div className="full-screen-header">
        <h2>Privacy policy</h2>

        {location.key !== 'default' ?
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(-1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
            <span>Back</span>
          </Link>
        : null}

        <Link
          to={'..'}
          onClick={(e) => {
            e.preventDefault();
            navigate(location.key === 'default' ? '/projects/' : -1);
          }}
          className="close"
        >
          <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
        </Link>
      </div>

      <div className="full-screen-body privacy-policy-body">
        <PrivacyPolicyContent />
      </div>

      {/*<div className="privacy-policy-actions">
        <Button color="secondary" outline size="sm" onClick={() => {
          navigate(location.key === 'default' ? '/projects/' : -1);
        }}>
          Go back
        </Button>
      </div>*/}
    </div>
  );
}