import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams, Link, useLocation } from "react-router-dom";
import { Button } from 'reactstrap';
import DOMPurify from 'dompurify';
import { Helmet } from 'react-helmet-async';

import { PageContext } from '../../contexts/page-context';
import { DataContext } from '../../contexts/data-context';

import Discussion from '../discussion/Discussion';

import "./ProjectParticipants.styles.css";

export default function ProjectParticipants() {
  const navigate = useNavigate();
  const location = useLocation();
  const { setPageTitle, setTopNavEnabled, setBottomNavEnabled } = useContext(PageContext);
  const { getProjectParticipants, logView } = useContext(DataContext);
  const { id } = useParams();
  const [projectParticipants, setProjectParticipants] = useState();
  const [isDiscussionOpen, setDiscussionOpen] = useState(false);

  useEffect(() => {
    setPageTitle('Project Participants');
    setTopNavEnabled(false);
    setBottomNavEnabled(false);

    const project = getProjectParticipants(id);
    setProjectParticipants(project);
  }, [id]);

  useEffect(() => {
    logView('project-participants');
  }, []);

  return (
    <div>
      <div className="project-participants-page page full-screen-page">
        <Helmet>
          <title>Project participants - Project Klub</title>
        </Helmet>

        <div className="full-screen-header">
          <h2>Project Participants</h2>

          {location.key !== 'default' ?
            <Link
              to={'..'}
              onClick={(e) => {
                e.preventDefault();
                navigate(-1);
              }}
              className="back"
            >
              <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" title="Go back" />
              <span>Back</span>
            </Link>
          : null}

          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate(location.key === 'default' ? '/projects/' : -1);
            }}
            className="back"
          >
            <img src={process.env.PUBLIC_URL + '/icons/back-white.svg'} alt="Go back" />
          </Link>
          <Link
            to={'..'}
            onClick={(e) => {
              e.preventDefault();
              navigate('/my-projects');
            }}
            className="close"
          >
            <img src={process.env.PUBLIC_URL + '/icons/close-white.svg'} alt="Close" title="Close" />
          </Link>

        </div>

        {projectParticipants ?

          <div className={`full-screen-body project-participants`}>

            {projectParticipants?.map((item, idx) => {
              return (
                <div key={idx} className="project-participant-card">
                  <div>{item.userName}</div>
                </div>
              );
            })}

          </div>
        : null }
            
      </div>
    </div>
  );
}