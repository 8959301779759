import React from 'react';
import { useNavigate } from "react-router-dom";

import "./SmallProjectCard.styles.css";

const typeDisplay = {
  standalone: 'Standalone',
  guided: 'Practice',
};

function SmallProjectCard(props) {
  const navigate = useNavigate();
  const {
    project,
    showPointsToJoin,
    cardType
  } = props;

  const projectThumbnail = project?.carouselImages ? project.carouselImages.split(',')[0] : null;

  return (
    <div className={`small-project-card-container ${cardType} ${project?.type?.toLowerCase()} ${project?.status?.toLowerCase()}`} role="button" onClick={(e) => {
          e.preventDefault();
          navigate('/project-detail/' + project.id);
        }}>
      {project.status === 'draft' || project.status==='pending' ?
        <span className="small-project-name-floating-status-display">{project.status.toUpperCase()}</span>
      : null}

      <div className="small-project-card">

        <div className="small-project-card-main">
          <div className="small-project-card-thumbnail-section">
            {projectThumbnail ?
              <img src={projectThumbnail} alt={project.title} className="small-project-thumbnail" />
            :
              <img src={process.env.PUBLIC_URL + '/vector/project-thumbnail-' + project?.type + '.png'} alt={project.title} className="small-project-thumbnail" />
            }

            {/*<span className={"small-project-type " + project?.type}>{typeDisplay[project.type]}</span>*/}
{/*            {!project.isPublic ?
              <div className="small-project-card-private">
                <img src={process.env.PUBLIC_URL + '/icons/lock.svg'} alt="Private" />
                <span>Private</span>
              </div>
            : null}
*/}
  {/*          {showPointsToJoin ?
              <div className="project-card-points-to-join">
                <img src={process.env.PUBLIC_URL + '/icons/coin.png'} alt="Points to join" />
                <span>{project.pointsToJoin || 'FREE'} points</span>
              </div>
            : null}*/}
          </div>

          <div className="small-project-card-body">
            <span className="small-project-name dark-gray-link">{project.title}</span>
          </div>
        </div>

        <div className="small-project-card-metadata-container">
          {project.size ?
            <span className="small-project-card-metadata small-project-card-size">
              <img src={process.env.PUBLIC_URL + '/icons/size-icon.png'} alt="Size" />
              <span>{project.size}</span>
            </span>
          : null}

          {project.numSolutions && project.numSolutions > 0 ?
            <span className="small-project-card-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/solution.png'} alt="Participants" />
              <span>{project.numSolutions}</span>
            </span>
          : null}

          {/*{project.numModules && project.numModules > 0 ?
            <span className="small-project-card-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/module-thumbnail-grayscale.png'} alt="Modules" />
              <span>{project.numModules}</span>
            </span>
          : null}

          {project.numResources && project.numResources > 0 ?
            <span className="small-project-card-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/resource-thumbnail-grayscale.png'} alt="Resources" />
              <span>{project.numResources}</span>
            </span>
          : null}*/}

          {project.numMessages && project.numMessages > 0 ?
            <span className="small-project-card-metadata">
              <img src={process.env.PUBLIC_URL + '/icons/discussions.png'} alt="Comments" />
              <span>{project.numMessages}</span>
            </span>
          : null}

        </div>
      </div>
    </div>
  );
}

export default SmallProjectCard;
