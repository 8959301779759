export const projectParticipants = [
  {
    id: 1,
    projectId: 1,
    userId: 1,
    userName: "John Doe",
    addedByUser: 1,
    addedTimeString: "June 30 at 4:15PM",
    role: "Owner",
    applictaionMessage: "Quis aute iure reprehenderit in voluptate velit esse",
  },
  {
    id: 2,
    projectId: 1,
    userId: 1,
    userName: "Jane Doe",
    addedByUser: 1,
    addedTimeString: "June 30 at 4:15PM",
    role: "Contributor",
    applictaionMessage: "Quis aute iure reprehenderit in voluptate velit esse",
  },
  {
    id: 3,
    projectId: 1,
    userId: 1,
    userName: "Jack Doe",
    addedByUser: 1,
    addedTimeString: "June 30 at 4:15PM",
    role: "Admin",
    applictaionMessage: "Quis aute iure reprehenderit in voluptate velit esse",
  },
  {
    id: 4,
    projectId: 1,
    userId: 1,
    userName: "Jill Doe",
    addedByUser: 1,
    addedTimeString: "June 30 at 4:15PM",
    role: "pending",
    applictaionMessage: "Quis aute iure reprehenderit in voluptate velit esse",
  },
]
