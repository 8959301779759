import React, { useState, useEffect } from 'react';
import { Button, Label, Input } from 'reactstrap';

import SkillsDisplay from '../cards/SkillsDisplay';

import "./EditSkills.scss";

function EditSkills(props) {
  const [skillsList, setSkillsList] = useState();
  const [newSkill, setNewSkill] = useState('');
  const {
    skills,
    onUpdate,
  } = props;

  useEffect(() => {
    setSkillsList(skills?.length ? skills.split("&&&&") : []);
  }, [props]);

  return (
    <div className="edit-skills">

      <div className="form-groups full-widths">
        <Label for="fullName">Enter skill names, one at a time, and click 'Add'</Label>

        <div className="edit-skills-add-skill-container">
          <Input
            id="skillInput"
            name="Example: HTML"
            type="text"
            placeholder="Type skill name (example: Java)"
            value={newSkill}
            onChange={(e) => {
              setNewSkill(e.target.value);
            }}
          />
          <Button type="submit" color="secondary" outline size="sm" onClick={(e) => {
            e.preventDefault();

            if (newSkill) {
              skillsList.push(newSkill);
              setNewSkill('');
              onUpdate(skillsList.join("&&&&"));
            }
          }}>
            Add
          </Button>
        </div>
      </div>

      {skillsList?.length ?
        <SkillsDisplay
          skills={skillsList}
          deletable={true}
          onUpdate={(updatedSkills) => {
            onUpdate(updatedSkills.join("&&&&"));
          }} />
        : null}
    </div>
  );
}

export default EditSkills;
